import React from "react";
import { PublicationBox } from "../components/ReportTemplate/components/PublicationBox/PublicationBox";

export const renderPublication = (data, GridBox, publications, creators) => {
  const key = data.label;

  const publication = publications.find((item) => item.taskId === data.taskId);
  const publicationData = {
    ...data,
    ...publication,
  };

  return (
    <GridBox key={key} id={key}>
      <PublicationBox data={publicationData} creators={creators} />
    </GridBox>
  );
};
