import { capitalizeFirstLetter } from "src/utils/methods";
import { addHeaderToArray } from "../addHeaderToArray";

const SENTIMENT_NAMES = ["positive", "neutral", "negative"];

export const addSelectedCommentsToGridElements = (
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.commentsAnalysis;

  Object.keys(campaigns).forEach((key) => {
    const {
      sentimentData: { comments },
      project: { title },
    } = campaigns[key];

    if (!comments) return;

    if (key !== "aggregated") {
      addHeaderToArray(
        `Comments Analysis - ${title}`,
        !aggregation ? newGridElements : newHiddenGridElements,
        !aggregation ? 3 : 2,
        `comments-analysis-${title}`,
        savedTemplate,
        templateApplied,
      );
    }

    Object.keys(comments?.all).forEach((key) => {
      const name = SENTIMENT_NAMES.includes(key) ? `${key} sentiment` : key;

      const element = {
        key,
        commentList: comments?.all[key] || [],
        label: `comments-${key}`,
        provider: "comments",
        name: capitalizeFirstLetter(name),
        gridBoxType: "comments",
        section: `comments-analysis-${key}`,
        campaignId: key,
      };

      // applying saved template

      if (
        (!aggregation && key === "aggregated") ||
        (aggregation && key !== "aggregated")
      ) {
        newHiddenGridElements.push(element);
      } else if (savedTemplate?.length && !templateApplied) {
        if (savedTemplate.find((item) => item.i === element.label)) {
          newGridElements.push(element);
        } else {
          newHiddenGridElements.push(element);
        }
      }
      // applying default template
      // else if (comments.all[key]?.length) {
      newGridElements.push(element);
      // } else {
      // newHiddenGridElements.push(element);
      // }
    });
  });
};
