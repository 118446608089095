import React from "react";
import { Hashtags } from "../../../../Task/tabs/ProfileDataTab/single/Hashtags";

export const renderHashtags = (label, data, layout, GridBoxComponent) => {
  const { hashtags } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <Hashtags
        hashtags={Object.keys(hashtags).map((key) => ({
          value: key,
          count: hashtags[key],
        }))}
        height={height}
      />
    </GridBoxComponent>
  );
};
