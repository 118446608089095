import React, { useEffect, useState } from "react";
import "./EditSelectOptionsModal.scss";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalTitle } from "src/app/components/Modal/Modal";
import Select from "src/app/components/Select/Select";
import { tableDataType } from "src/app/components/Table/Table";
import { SelectOption } from "src/types";

interface Props {
  onClose: () => void;
  data: any;
  dataType: tableDataType;
}

const EditSelectOptionsModal: React.FC<Props> = (props) => {
  const { onClose, data, dataType } = props;

  const [options, setOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    const optionsAddedByUser =
      data?.settings?.singleSelectOptions || data?.data?.singleSelectOptions;
    if (Array.isArray(optionsAddedByUser)) {
      setOptions(optionsAddedByUser);
    }
  }, [data?.settings?.singleSelectOptions, data?.data?.singleSelectOptions]);

  return (
    <Modal
      className="edit-select-options-modal"
      onClose={onClose}
      onCancelClick={onClose}
      displayCancelButton
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_manage_select_options"
          defaultMessage="Manage select options"
        />
      </ModalTitle>

      <Select
        mode="edit-global"
        uuid={data.uuid}
        optionsData={options}
        fieldName={data.name}
        fieldValue={null}
        dataType={dataType}
      />
    </Modal>
  );
};

export default EditSelectOptionsModal;
