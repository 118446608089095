import { ReportGridBox } from "../components/ReportTemplate/components/ReportGridBox/ReportGridBox";
import { renderFollowersRange } from "./creator-insights/renderFollowersRange";
import { renderLocation } from "./creator-insights/renderLocation";
import { renderCreatorInterests } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderCreatorInterests";
import { renderCreatorsBrandAffinity } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderCreatorsBrandAffinity";

export const renderCreatorInsights = (data, creatorsInsights, layout) => {
  if (!creatorsInsights) return;

  switch (data.type) {
    case "location":
      return renderLocation(
        data.label,
        creatorsInsights,
        layout,
        ReportGridBox,
      );

    case "followers-range":
      return renderFollowersRange(
        data.label,
        creatorsInsights,
        layout,
        ReportGridBox,
      );

    case "creator-interests":
      return renderCreatorInterests(
        data.label,
        creatorsInsights,
        layout,
        ReportGridBox,
      );

    case "creators-brand-affinity":
      return renderCreatorsBrandAffinity(
        data.label,
        creatorsInsights,
        layout,
        ReportGridBox,
      );
  }
};
