import { IAccessLinkData } from "src/app/modals/ManageFieldModal/types";
import { TaskType, SelectOption } from "src/types";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import { DateObject } from "../../app/project/Project";
import { ISelectOption } from "../../app/project/ProjectTypes";
import { API_URLS } from "../../utils/API_URLS";
import {
  ADD_NEW_SUBTASK,
  ADD_NEW_TASK,
  ADD_PROJECT_META_FIELD,
  DELETE_FIELD,
  DELETE_PROJECT_META_FIELD,
  DUPLICATE_TASK,
  GET_MEMBERS_LIST,
  GET_PROJECTS_LIST,
  GET_PROJECT,
  GET_TASKS_AUTOCOMPLETE,
  CLEAR_TASKS_AUTOCOMPLETE,
  GET_TASKS_LIST,
  GET_TASKS_COUNTERS,
  CLEAR_TASKS_COUNTERS,
  UPDATE_TASKS_LIST,
  SET_ACTIVE_PROJECT_ID,
  SET_MEMBERS_OPTIONS,
  SET_SHOW_TASK,
  UPDATE_PROJECT_META_FIELD_NAME,
  UPDATE_PROJECT_META_FIELD_RANK,
  UPDATE_PROJECT_META_FIELD_VISIBILITY,
  UPDATE_PROJECT_META_VALUE,
  UPDATE_PROJECT_SINGLE_SELECT_FIELD,
  RENAME_PROJECT_ON_LIST,
  SET_PROJECT_LOADING,
  SET_PROJECT_LIST,
  SET_PROJECT_COLUMNS,
  UPDATE_PROJECT,
  CLEAN_GET_PROJECT_FAILED,
  CLEAR_PROJECT_BASIC_DATA,
  UPDATE_PROJECT_META_FIELD_ON_LIST,
  SET_EDITED_CELL_ID,
  SET_PROJECT_FIELD_ADDED_OR_UPDATED,
  SET_PROJECTS_LIST_OFFSET,
  SET_TASK_TYPE,
  GET_GLOBAL_PROJECT_META_FIELD_LIST,
  GET_GLOBAL_TASK_META_FIELD_LIST,
  CREATE_GLOBAL_PROJECT_META_FIELD,
  CLEAR_GLOBAL_META_FIELD_LIST,
  CLEAR_PROJECTS_LIST,
  SET_FILTERED_PROJECT_LIST,
  GET_FILTERED_PROJECTS_LIST,
  GET_PROJECT_TEMPLATES,
  UPDATE_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS,
  CLEAR_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS,
  SET_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS,
  SET_PROJECT_INITIAL_COLUMN_UUIDS,
  CLEAR_PROJECT_ERROR_MESSAGES,
  GET_PROJECT_DETAILS,
  CLEAR_PROJECT_DETAILS,
  REMOVE_PROJECT,
  SET_CREATOR_MESSAGE_PANE_OPEN,
  SET_LAST_SELECTED_PROJECT_ROW_ID,
  SET_SELECTED_PROJECTS,
  SET_CREATE_SINGLE_SPLIT_PAYMENTS_MODAL,
  SET_PROJECT_TABLE_VIEWS,
  GET_PROJECTS_AUTOCOMPLETE_LIST,
} from "./projectTypes";
import { IProject } from "./types";
import { WsTableView } from "../dictionary/types";

export interface ActionReturnType {
  type: string;
  payload: { request: { url: string } };
}

export function getProject(projectId: string, disableLoader?: boolean) {
  return {
    type: GET_PROJECT,
    payload: {
      request: {
        url: API_URLS.getProject.replace(":projectUuid:", projectId),
      },
      disableLoader,
    },
  };
}

export function cleanGetProjectFailed() {
  return {
    type: CLEAN_GET_PROJECT_FAILED,
  };
}

export function clearProjectBasicData() {
  return {
    type: CLEAR_PROJECT_BASIC_DATA,
  };
}

export function updateProject(projectId: string) {
  return {
    type: UPDATE_PROJECT,
    payload: {
      request: {
        url: API_URLS.getProject.replace(":projectUuid:", projectId),
      },
    },
  };
}

export function changeProjectFieldVisibility(uuid: string, isOn: boolean) {
  return {
    type: UPDATE_PROJECT_META_FIELD_VISIBILITY,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateProjectMetaFieldVisibility,
        data: {
          metaFieldUuid: uuid,
          metaFieldVisible: isOn,
        },
      },
      uuid,
      isVisible: isOn,
    },
  };
}

interface UpdateProjectMetaFieldProps {
  metaFieldUuid: string;
  metaFieldNewName: string;
  wsMetaFieldsToCreateAccess: IAccessLinkData | null;
  metaFieldValueSource: string | null;
  currencyCode?: string | null;
  summaryFieldOptions?: any;
  arithmeticFieldOptions?: any;
  icon: { name: string; color: string } | null;
  wsTeamUuids?: string[] | null;
  wsDictionarySubFieldUuid?: string | null;
}

export function updateProjectMetaField({
  metaFieldUuid,
  metaFieldNewName,
  wsMetaFieldsToCreateAccess,
  metaFieldValueSource,
  currencyCode,
  summaryFieldOptions,
  arithmeticFieldOptions,
  icon,
  wsTeamUuids,
  wsDictionarySubFieldUuid,
}: UpdateProjectMetaFieldProps) {
  return {
    type: UPDATE_PROJECT_META_FIELD_NAME,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateProjectMetaField,
        data: {
          metaFieldUuid,
          metaFieldNewName,
          currencyCode,
          summaryFieldOptions,
          arithmeticFieldOptions,
          metaFieldIcon: icon,
          ...(metaFieldValueSource && { metaFieldValueSource }),
          wsMetaFieldsToCreateAccess,
          wsTeamUuids,
          wsDictionarySubFieldUuid,
        },
      },
      uuid: metaFieldUuid,
      name: metaFieldNewName,
    },
  };
}

export function updateProjectSingleSelectField(
  metaFieldId: string,
  metaFieldNameUpdated: string,
  singleSelectOptions: SelectOption[],
) {
  return {
    type: UPDATE_PROJECT_SINGLE_SELECT_FIELD,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateProjectSingleSelectMetaField,
        data: {
          metaFieldId,
          metaFieldNameUpdated,
          singleSelectOptions,
        },
      },
      metaFieldId,
      metaFieldNameUpdated,
      singleSelectOptions: {
        singleSelectOptions,
      },
    },
  };
}

export function updateProjectMetaValue(
  ids: string | string[],
  metaFieldUuid: string,
  metaValue: string | boolean | DateObject | null | number,
) {
  const uuids = typeof ids === "string" ? [ids] : ids;

  return {
    type: UPDATE_PROJECT_META_VALUE,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateProjectMetaValue,
        data: {
          uuids,
          metaValue,
          metaFieldUuid,
        },
      },
      uuids,
      metaValue,
      metaFieldUuid,
    },
  };
}

export function renameProjectOnList(
  ids: string | string[],
  metaFieldUuid: string,
  metaValue: string | boolean | DateObject | null,
) {
  const uuids = typeof ids === "string" ? [ids] : ids;
  return {
    type: RENAME_PROJECT_ON_LIST,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateProjectMetaValue,
        data: {
          uuids,
          metaValue,
          metaFieldUuid,
        },
      },
      uuids,
      metaValue,
      metaFieldUuid,
    },
  };
}

export function updateProjectMetaFieldOnList(
  ids: string,
  metaFieldUuid: string,
  metaValue: string | boolean | DateObject | null,
  wsGlobalProjectMetaFieldUuid: string,
) {
  const uuids = typeof ids === "string" ? [ids] : ids;
  return {
    type: UPDATE_PROJECT_META_FIELD_ON_LIST,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateProjectMetaValue,
        data: {
          uuids,
          metaValue,
          metaFieldUuid,
          wsGlobalProjectMetaFieldUuid,
        },
      },
      uuids,
      metaValue,
      metaFieldUuid,
    },
  };
}

export function deleteProjectMetaField(uuid: string) {
  return {
    type: DELETE_PROJECT_META_FIELD,
    payload: {
      request: {
        method: "delete",
        url: API_URLS.deleteProjectMetaField.replace(":fieldId:", uuid),
      },
      uuid,
    },
  };
}

export function updateProjectMetaFieldRank(
  projectId: string,
  metaFieldUuid: string,
  metaFieldNewRank: string,
) {
  return {
    type: UPDATE_PROJECT_META_FIELD_RANK,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateProjectMetaFieldRank,
        data: {
          projectId,
          metaFieldUuid,
          metaFieldNewRank,
        },
      },
      projectId,
      metaFieldUuid,
      metaFieldNewRank,
    },
  };
}

export function getMembersList(wsWorkspaceUuid: string) {
  return {
    type: GET_MEMBERS_LIST,
    payload: {
      request: {
        url: API_URLS.getMemberList.replace(
          ":wsWorkspaceUuid:",
          wsWorkspaceUuid,
        ),
      },
    },
  };
}

export function getActiveMembersList(wsWorkspaceUuid: string) {
  return {
    type: GET_MEMBERS_LIST,
    payload: {
      request: {
        url: API_URLS.getActiveMemberList.replace(
          ":wsWorkspaceUuid:",
          wsWorkspaceUuid,
        ),
      },
    },
  };
}

export function getProjectAutocompleteList(wsWorkspaceUuid: string) {
  return {
    type: GET_PROJECTS_AUTOCOMPLETE_LIST,
    payload: {
      request: {
        url: API_URLS.getProjectAutocompleteList.replace(
          ":wsWorkspaceUuid:",
          wsWorkspaceUuid,
        ),
      },
    },
  };
}

export function setMemberOptions(options: ISelectOption[]) {
  return {
    type: SET_MEMBERS_OPTIONS,
    payload: {
      options,
    },
  };
}

export function deleteField(wsTaskMetaFieldUuid: string) {
  return {
    type: DELETE_FIELD,
    payload: {
      request: {
        method: "delete",
        url: API_URLS.deleteTaskMetaField.replace(
          ":wsTaskMetaFieldUuid:",
          wsTaskMetaFieldUuid,
        ),
      },
    },
  };
}

export function clearProjectsList() {
  return {
    type: CLEAR_PROJECTS_LIST,
  };
}

export function getProjectsList(
  wsWorkspaceUuid: string,
  limit?: number,
  offset?: number,
): ActionReturnType {
  let url = API_URLS.getProjectsList.replace(
    ":wsWorkspaceUuid:",
    wsWorkspaceUuid,
  );

  if (limit && offset) {
    url = `${url}?limit=${limit}&offset=${offset}`;
  }

  return {
    type: GET_PROJECTS_LIST,
    payload: {
      request: {
        url,
      },
    },
  };
}

export function getProjectTemplates(wsWorkspaceUuid: string) {
  const url = API_URLS.getProjectTemplates.replace(
    ":wsWorkspaceUuid:",
    wsWorkspaceUuid,
  );

  return {
    type: GET_PROJECT_TEMPLATES,
    payload: {
      request: {
        url,
      },
    },
  };
}

export function getFilteredProjectsList(wsWorkspaceUuid: string, params?: any) {
  const url = new URL(
    API_URLS.getProjectsList.replace(":wsWorkspaceUuid:", wsWorkspaceUuid),
  );
  const urlParams = new URLSearchParams(params);

  return {
    type: GET_FILTERED_PROJECTS_LIST,
    payload: {
      request: {
        url: params ? `${url}?${urlParams}` : url,
      },
    },
  };
}

export function addNewTask(
  title: string,
  projectId: string,
  uuid: string,
  rank: string,
  taskType: string | null,
  wsWorkspaceUuid: string,
  defaultMetaFieldsValues: {
    key: string;
    taskType: string;
    value: string;
  }[],
) {
  return {
    type: ADD_NEW_TASK,
    payload: {
      request: {
        method: "post",
        url: API_URLS.createTask,
        data: {
          projectId,
          taskName: title,
          taskRank: rank,
          taskId: uuid,
          taskType,
          wsWorkspaceUuid,
          defaultMetaFieldsValues,
        },
      },
    },
  };
}

export function addNewSubtask(
  projectId: string,
  taskName: string,
  parentTaskId: string,
) {
  return {
    type: ADD_NEW_SUBTASK,
    request: {
      method: "post",
      url: API_URLS.createTask,
      data: {
        projectId,
        taskName,
        parentTaskId,
      },
    },
  };
}

export function getTasksList(projectUuid: string, taskType: string) {
  const url = new URL(
    API_URLS.getTasksList.replace(":projectUuid:", projectUuid),
  );
  url.searchParams.append("wsTaskType", taskType);

  return {
    type: GET_TASKS_LIST,
    payload: {
      request: {
        url,
      },
    },
  };
}

export function getTasksCounters(projectUuid: string) {
  return {
    type: GET_TASKS_COUNTERS,
    payload: {
      request: {
        url: API_URLS.getTasksCounters.replace(":projectUuid:", projectUuid),
      },
    },
  };
}

export function clearTasksCounters() {
  return {
    type: CLEAR_TASKS_COUNTERS,
  };
}

export function updateTasksList(projectUuid: string, taskType: string) {
  const url = new URL(
    API_URLS.getTasksList.replace(":projectUuid:", projectUuid),
  );
  url.searchParams.append("wsTaskType", taskType);

  return {
    type: UPDATE_TASKS_LIST,
    payload: {
      request: {
        url,
      },
    },
  };
}

export function getTasksAutocomplete(projectId: string, taskType: string) {
  return {
    type: GET_TASKS_AUTOCOMPLETE,
    payload: {
      request: {
        url: API_URLS.taskListAutocomplete
          .replace(":projectUuid:", projectId)
          .replace(":taskType:", taskType),
      },
    },
  };
}

export function clearTasksAutocomplete() {
  return {
    type: CLEAR_TASKS_AUTOCOMPLETE,
  };
}

export function setActiveProjectId(projectId: string) {
  return {
    type: SET_ACTIVE_PROJECT_ID,
    payload: {
      activeProjectId: projectId,
    },
  };
}

export function setShowTask(value: boolean) {
  return {
    type: SET_SHOW_TASK,
    payload: {
      showTask: value,
    },
  };
}

export function setCreatorMessagePaneOpen(isOpen: boolean) {
  return {
    type: SET_CREATOR_MESSAGE_PANE_OPEN,
    payload: {
      isOpen,
    },
  };
}

interface AddProjectMetaFieldProps {
  uuid: string;
  projectId: string;
  name: string;
  type: string;
  taskType: string | null;
  rank: string;
  shared: boolean;
  wsMetaFieldsToCreateAccess: IAccessLinkData | null;
  metaFieldValueSource: string | null;
  currencyCode?: string | null;
  summaryFieldOptions?: any;
  arithmeticFieldOptions?: any;
  icon?: any;
  wsDictionaryUuid?: string;
  wsTeamUuids?: string[] | null;
  wsDictionarySubFieldUuid?: string | null;
}

export function addProjectMetaField({
  uuid,
  projectId,
  name,
  type,
  taskType,
  rank,
  shared,
  wsMetaFieldsToCreateAccess,
  metaFieldValueSource,
  currencyCode,
  summaryFieldOptions,
  arithmeticFieldOptions,
  icon,
  wsDictionaryUuid,
  wsTeamUuids,
  wsDictionarySubFieldUuid,
}: AddProjectMetaFieldProps) {
  return {
    type: ADD_PROJECT_META_FIELD,
    payload: {
      request: {
        method: "post",
        url: API_URLS.createProjectMetaField,
        data: {
          metaFieldUuid: uuid,
          projectId,
          metaFieldName: name,
          metaFieldType: type,
          metaFieldTaskType: taskType,
          metaFieldRank: rank,
          shared,
          currencyCode,
          summaryFieldOptions,
          arithmeticFieldOptions,
          metaFieldIcon: icon,
          wsMetaFieldsToCreateAccess,
          ...(metaFieldValueSource && { metaFieldValueSource }),
          wsDictionaryUuid,
          wsTeamUuids,
          wsDictionarySubFieldUuid,
        },
      },
      uuid,
      name,
      type,
      taskType,
      rank,
    },
  };
}

export function duplicateTask(
  taskId: string,
  duplicatedTaskId: string,
  taskName: string,
  taskRank: string,
) {
  const data = {
    taskId,
    duplicatedTaskId,
    taskName,
    taskRank,
  };
  return {
    type: DUPLICATE_TASK,
    payload: {
      request: {
        method: "post",
        url: API_URLS.duplicateTask,
        data,
      },
    },
  };
}

export function setTaskType(taskType: string) {
  return {
    type: SET_TASK_TYPE,
    payload: {
      taskType,
    },
  };
}

export function setProjectList(projectList: any) {
  return { type: SET_PROJECT_LIST, payload: { projectList } };
}

export function removeProjectByProjectUuid(uuid: string) {
  return { type: REMOVE_PROJECT, payload: { uuid } };
}

export function setFilteredProjectList(filteredProjectsList: any) {
  return { type: SET_FILTERED_PROJECT_LIST, payload: { filteredProjectsList } };
}

export function setProjectColumns(projectColumns: any) {
  return { type: SET_PROJECT_COLUMNS, payload: { projectColumns } };
}

export function setProjectColumnVisibleByDefaultUuids(
  visibleColumnUuids: string[],
) {
  return {
    type: SET_PROJECT_INITIAL_COLUMN_UUIDS,
    payload: { visibleColumnUuids },
  };
}

export function setProjectLoading(projectLoading: boolean) {
  return {
    type: SET_PROJECT_LOADING,
    payload: {
      projectLoading,
    },
  };
}

export function setEditedCellId(editedCellId: string) {
  return {
    type: SET_EDITED_CELL_ID,
    payload: { editedCellId },
  };
}

export function setProjectFieldAddedOrUpdated(
  projectFieldAddedOrUpdated: boolean,
) {
  return {
    type: SET_PROJECT_FIELD_ADDED_OR_UPDATED,
    payload: { projectFieldAddedOrUpdated },
  };
}

export function setProjectsListOffset(offset: number) {
  return { type: SET_PROJECTS_LIST_OFFSET, payload: { offset } };
}

export function getGlobalTaskMetaFieldList(
  wsWorkspaceUuid: string,
  projectUuid: string,
  taskType: string,
) {
  return {
    type: GET_GLOBAL_TASK_META_FIELD_LIST,
    payload: {
      request: {
        url: API_URLS.getGlobalTaskMetaFieldList
          .replace(":workspaceUuid:", wsWorkspaceUuid)
          .replace(":projectUuid:", projectUuid)
          .replace(":taskType:", taskType),
      },
    },
  };
}

export function getGlobalProjectMetaFieldList(
  wsWorkspaceUuid: string,
  projectUuid: string,
  taskType: TaskType | null,
) {
  let url = API_URLS.getGlobalProjectMetaFieldList
    .replace(":workspaceUuid:", wsWorkspaceUuid)
    .replace(":projectUuid:", projectUuid);

  if (taskType) {
    url = url.replace(":taskType:", taskType);
  } else {
    url = url.replace(":taskType:/", "/");
  }

  return {
    type: GET_GLOBAL_PROJECT_META_FIELD_LIST,
    payload: {
      request: {
        url,
      },
    },
  };
}

type wsGlobalProjectMetaField = {
  wsGlobalProjectMetaFieldUuid: string;
  wsProjectUuid: string;
  wsProjectMetaFieldRank: string;
  wsProjectMetaFieldUuid: string;
  taskType: TaskType | null;
};

export function createGlobalProjectMetaField(
  wsGlobalProjectMetaField: wsGlobalProjectMetaField[],
) {
  return {
    type: CREATE_GLOBAL_PROJECT_META_FIELD,
    payload: {
      request: {
        method: "post",
        url: API_URLS.createGlobalProjectMetaField,
        data: {
          wsGlobalProjectMetaField,
        },
      },
    },
  };
}

export function clearGlobalMetaFieldList() {
  return {
    type: CLEAR_GLOBAL_META_FIELD_LIST,
  };
}

export function clearGlobalMetaFieldProjectWizardFields() {
  return {
    type: CLEAR_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS,
  };
}

export function updateGlobalMetaFieldProjectWizardFields(
  uuid: string,
  value: string | SelectOption[],
) {
  return {
    type: UPDATE_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS,
    payload: { uuid, value },
  };
}

export function setGlobalMetaFieldProjectWizardFields(fields: Metadata[]) {
  return {
    type: SET_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS,
    payload: { fields },
  };
}

export function clearProjectErrorMessages() {
  return {
    type: CLEAR_PROJECT_ERROR_MESSAGES,
  };
}

export function getProjectDetails(projectId: string) {
  return {
    type: GET_PROJECT_DETAILS,
    payload: {
      request: {
        method: "get",
        url: API_URLS.getProjectDetails.replace(":projectUuid:", projectId),
      },
    },
  };
}

export function clearProjectDetails() {
  return {
    type: CLEAR_PROJECT_DETAILS,
  };
}

export function setLastSelectedProjectRowID(lastSelectedProjectRowID: string) {
  return {
    type: SET_LAST_SELECTED_PROJECT_ROW_ID,
    payload: { lastSelectedProjectRowID },
  };
}

export function setSelectedProjects(selectedProjects: IProject[]) {
  return {
    type: SET_SELECTED_PROJECTS,
    payload: { selectedProjects },
  };
}

export function setCreateSingleSplitModalOpen(isOpen: boolean) {
  return {
    type: SET_CREATE_SINGLE_SPLIT_PAYMENTS_MODAL,
    payload: {
      isOpen,
    },
  };
}

export function setProjectTableViews(wsTableViews: WsTableView[]) {
  return {
    type: SET_PROJECT_TABLE_VIEWS,
    payload: { wsTableViews },
  };
}
