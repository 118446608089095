import React, { useMemo } from "react";

import RGL, { WidthProvider } from "react-grid-layout";
import { ReactComponent as InstagramIcon } from "src/images/social/instagram-color.svg";
import { ReactComponent as TikTokIcon } from "src/images/social/tiktok-color.svg";
import { ReactComponent as YoutubeIcon } from "src/images/social/youtube-color.svg";
import { ReactComponent as SnapchatIcon } from "src/images/social/snapchat.svg";
import { ReactComponent as XIcon } from "src/images/social/x.svg";
import { ReactComponent as FacebookIcon } from "src/images/social/facebook-color.svg";
import { useSelector } from "react-redux";
import { SocialProvider } from "src/types";
import { renderMetaField } from "src/app/generator-data/functions/renderMetaField";
import { ShowcasePrintingLoader } from "../../../ShowcasePrintingLoader/ShowcasePrintingLoader";
import { ShowcaseLoader } from "../../../ShowcaseLoader/ShowcaseLoader";
import { ShowcaseGridBox } from "../../components/ShowcaseGridBox/ShowcaseGridBox";
import { BasicInfo } from "../../components/BasicInfo/BasicInfo";
import { STATIC_ELEMENTS } from "../../ShowcaseTemplate";
import { renderMetrics } from "../../renderMetrics";
import { ShowcaseSeparator } from "../../components/ShowcaseSeparator/ShowcaseSeparator";

// !!! THIS MUST BE DECLARED OUTSIDE OF COMPONENT !!!
const ReactGridLayout = WidthProvider(RGL);
// !!! THIS MUST BE DECLARED OUTSIDE OF COMPONENT !!!

export function ShowcaseCreatorsTab(props) {
  const {
    setLayout,
    workspaceData,
    data,
    recentlyPublishedReady,
    setRecentlyPublishedReady,
    format,
    progressValue,
    progressTarget,
    separators,
    primaryColor,
  } = props;

  const generating = useSelector((state) => state.showcaseReducer.generating);
  const printing = useSelector((state) => state.showcaseReducer.printing);
  const layout = useSelector((state) => state.showcaseReducer.layout);
  const gridElements = useSelector(
    (state) => state.showcaseReducer.gridElements,
  );

  const ShowcaseBoxes = useMemo(() => {
    return gridElements.map((box) => {
      switch (box.gridBoxType) {
        case "metadata":
          return renderMetaField(box, data, ShowcaseGridBox);
        case SocialProvider.Instagram:
        case SocialProvider.TikTok:
        case SocialProvider.Youtube:
        case SocialProvider.Snapchat:
        case SocialProvider.Twitter:
        case SocialProvider.Facebook:
          return renderMetrics(
            box,
            data,
            layout,
            recentlyPublishedReady,
            setRecentlyPublishedReady,
          );
      }
    });
  }, [
    gridElements,
    layout,
    format,
    data,
    recentlyPublishedReady,
    setRecentlyPublishedReady,
  ]);

  const renderProviderIcon = (provider) => {
    switch (provider) {
      case SocialProvider.Instagram:
        return <InstagramIcon />;
      case SocialProvider.TikTok:
        return <TikTokIcon />;
      case SocialProvider.Youtube:
        return <YoutubeIcon />;
      case SocialProvider.Snapchat:
        return <SnapchatIcon />;
      case SocialProvider.Twitter:
        return <XIcon />;
      case SocialProvider.Facebook:
        return <FacebookIcon />;
    }
  };

  const filteredLayout = layout.filter(
    (item) =>
      gridElements.find((box) => item.i === box.label) ||
      STATIC_ELEMENTS.some((el) => item.i.includes(el)),
  );

  return (
    <div style={{ padding: `${format.rowHeight / 2}px 0` }}>
      {printing ? (
        <ShowcasePrintingLoader value={progressValue} target={progressTarget} />
      ) : null}

      {generating ? (
        <ShowcaseLoader />
      ) : (
        <ReactGridLayout
          style={{ width: `${format.pageWidth}px` }}
          className="layout"
          layout={filteredLayout}
          cols={format.cols}
          rowHeight={format.rowHeight}
          compactType={null}
          margin={[24, 20]}
          preventCollision
          onLayoutChange={(newLayout) => setLayout(newLayout)}
          draggableHandle=".react-drag-handle"
        >
          <ShowcaseGridBox
            id="basic-info"
            key="basic-info"
            className="basic-info-wrapper"
          >
            <BasicInfo data={data} />
          </ShowcaseGridBox>

          {ShowcaseBoxes}

          {separators.map((item) => {
            return (
              <div
                id={item.key}
                key={item.key}
                className="showcase-template__separator"
              >
                <ShowcaseSeparator
                  id={item.key}
                  workspaceData={workspaceData}
                  primaryColor={primaryColor}
                  renderProviderIcon={renderProviderIcon}
                  provider={item.provider}
                  creatorName={data.name}
                  printing={printing}
                />
              </div>
            );
          })}
        </ReactGridLayout>
      )}
    </div>
  );
}
