import { addHeaderToLayout } from "src/app/ReportGenerator/functions/addHeaderToLayout";
import { stripEmojis } from "../../../utils/methods";
import { addSpaceIfNeeded } from "./addSpaceIfNeeded";

export const addPublicationsToLayout = (
  gridElements,
  newLayout,
  format,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.allPublications;

  Object.keys(campaigns).forEach((key) => {
    const campaign = campaigns[key];

    const {
      project: { title },
    } = campaign;

    if (!aggregation && key !== "aggregated") {
      addHeaderToLayout(`publication-${title}`, newLayout, format, 3);
    }

    gridElements
      .filter(
        (item) => item.gridBoxType === "publication" && item.campaignId === key,
      )
      .forEach((item) => {
        const lastEl = addSpaceIfNeeded("publication", newLayout, format);

        let x = 0;
        let y = 0;
        const w = 4;
        const h = 7;

        if (lastEl.w === 12 || lastEl.x === 8) {
          x = 0;
          y = lastEl.y + lastEl.h;
        }
        // second in row and half the width
        else if (lastEl.x === 0 || lastEl.x === 4) {
          x = lastEl.x + 4;
          y = lastEl.y;
        }

        newLayout.push({ i: item.label, x, y, w, h });
      });
  });
};
