import React, { forwardRef, useEffect, useRef, useState } from "react";
import "./ReportGridBox.scss";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { GridBox } from "../../../../../generator-data/components/GridBox/GridBox";
import {
  setApplyBoxesContent,
  setReportGridElements,
  setReportHiddenGridElements,
} from "../../../../../../redux";

const TYPES_TO_CONFIRM_BEFORE_DELETION = ["creator", "publication"];

export const ReportGridBox = forwardRef((props, ref) => {
  const { key, id, children, ...rest } = props;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const type = id.includes("creator-insights")
    ? "creator-insights"
    : id.split("-")[0];

  const { creatorId } = useParams();

  const contentRef = useRef();

  useEffect(() => {
    if (!ref.current) return;
    contentRef.current = ref?.current?.querySelector(
      ".react-grid-item__content",
    );
  }, [ref]);

  const dispatch = useDispatch();

  const {
    reportReducer: { gridElements, hiddenGridElements, applyBoxesContent },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!applyBoxesContent) return;

    const savedBoxes = JSON.parse(
      window.localStorage.getItem("reportSavedBoxes"),
    );

    if (!savedBoxes?.length) return;

    const creatorBoxes = savedBoxes?.find(
      (item) => item.creatorId === creatorId,
    );

    if (!creatorBoxes) return;

    const boxContent = creatorBoxes?.boxes?.find((item) => item.id === id);

    if (boxContent && contentRef?.current) {
      const type = id.split("-")[0];

      if (type === "header") {
        if (boxContent.content) {
          updateHeaderTitle(boxContent.content);
        }
      } else {
        contentRef.current.innerHTML = boxContent.content;
      }
    }

    dispatch(setApplyBoxesContent(false));
  }, [applyBoxesContent]);

  const saveBoxContent = () => {
    if (!contentRef?.current) return;

    const savedBoxes = JSON.parse(
      window.localStorage.getItem("reportSavedBoxes"),
    );

    const type = id.split("-")[0];

    let contentToSave = null;

    if (type === "header") {
      const input = document.querySelector(`#${id} .section-header__text`);
      contentToSave = input?.value || "";
    } else {
      contentToSave = contentRef.current.innerHTML;
    }

    let newSavedBoxes = [];
    const newBoxContent = { id, content: contentToSave };

    if (savedBoxes?.length) {
      const creatorBoxes = savedBoxes.find(
        (item) => item.creatorId === creatorId,
      );

      if (creatorBoxes) {
        const boxContent = creatorBoxes?.boxes?.find((item) => item.id === id);

        if (!boxContent) {
          newSavedBoxes = savedBoxes.map((item) => {
            if (item.creatorId === creatorId) {
              return {
                ...item,
                boxes: [...item.boxes, newBoxContent],
              };
            }

            return item;
          });
        } else {
          newSavedBoxes = savedBoxes.map((item) => {
            if (item.creatorId === creatorId) {
              return {
                ...item,
                boxes: item.boxes.map((box) => {
                  if (box.id === id) {
                    return newBoxContent;
                  }
                  return box;
                }),
              };
            }

            return item;
          });
        }
      } else {
        newSavedBoxes = [...savedBoxes, { creatorId, boxes: [newBoxContent] }];
      }
    } else {
      newSavedBoxes = [{ creatorId, boxes: [newBoxContent] }];
    }

    window.localStorage.setItem(
      "reportSavedBoxes",
      JSON.stringify(newSavedBoxes),
    );
  };

  const updateHeaderTitle = (newValue) => {
    const newGridElements = gridElements.map((item) => {
      if (item.label === id) {
        return {
          ...item,
          text: newValue,
        };
      }
      return item;
    });

    dispatch(setReportGridElements(newGridElements));
  };

  const handleRemoveClick = () => {
    if (TYPES_TO_CONFIRM_BEFORE_DELETION.includes(type)) {
      setShowConfirmationModal(true);
    } else {
      removeFromLayout();
    }
  };

  const removeFromLayout = () => {
    const newElement = gridElements?.find((item) => item.label === id);
    const newGridElements = gridElements?.filter((item) => item.label !== id);

    dispatch(setReportGridElements(newGridElements));
    dispatch(setReportHiddenGridElements([...hiddenGridElements, newElement]));
  };

  return (
    <GridBox
      key={key}
      ref={ref}
      {...rest}
      saveBoxContent={saveBoxContent}
      removeFromLayout={handleRemoveClick}
    >
      {children}

      {showConfirmationModal && (
        <RemoveModal
          onClose={() => setShowConfirmationModal(false)}
          objectNames={[
            gridElements?.find((item) => item.label === id)?.title ?? "",
          ]}
          removeFunction={() => {
            removeFromLayout();
            setShowConfirmationModal(false);
          }}
        />
      )}
    </GridBox>
  );
});
