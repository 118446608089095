import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatDistance } from "date-fns";
import classNames from "classnames";
import { useIntl } from "react-intl";
import moment from "moment";
import { Link } from "react-router-dom";

import campaignPlaceholder from "src/images/project-placeholder.svg";
import { ReactComponent as ClipIcon } from "src/images/clip.svg";
import avatarPlaceholder from "src/images/avatar.svg";
import { getDateLabel } from "src/app/methods/getDateLabel";
import {
  splitNumbers,
  getFullDate,
  capitalizeFirstLetter,
  WsMessengerMessageStatusEnum,
} from "src/utils/methods";
import { translateMessage } from "src/app/methods/translateMessage";
import AvatarPlaceholder from "src/images/avatar-bg.svg";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import { AppConfig } from "src/config/AppConfig";
import { getCountedSelectValues } from "src/app/components/AggregatedSelect/components/AggregatedLabels/AggregatedLabels";
import { convertToLocalTimezone } from "../../../../methods/convertToLocalTimezone";
import XssFilter from "../../../XssFilter/XssFilter";
import { SkeletonCircle, SkeletonText } from "../../../Skeleton/Skeleton";
import indaHash from "../../assets/indahash.png";
import {
  ActivityLogOperationType,
  ActivityLogsWithoutProjectContext,
} from "../../../../SettingsModal/enums";
import WsRecruitmentTaskCreated from "./WsRecruitmentTaskCreated";
import { getRoute, ROUTES } from "../../../../../utils/ROUTES";
import WsCreatorDatabaseSubmissionFormTaskCreated from "./WsCreatorDatabaseSubmissionFormTaskCreated";
import { Img } from "../../../Img/Img";

export const ActivityItem = (props) => {
  const { data } = props;

  const [trigger, setTrigger] = useState({});

  const intl = useIntl();
  const isURLExternal = !!data.wsAccessLinkUrl;
  const envName = AppConfig.getEnvironmentName();

  const {
    activityReducer: { loading },
    mainReducer: { activeWorkspaceUuid },
    projectReducer: { membersList },
    selectDataSetReducer: { selectDataSetList },
  } = useSelector((state) => state);

  // Trigger
  useEffect(() => {
    if (!data.trigger) return;

    if (data.trigger.type === "wsMember") {
      const memberTrigger = membersList?.find(
        (item) => item.id === data.trigger.id,
      );

      setTrigger({
        ...memberTrigger,
        type: "member",
      });
    } else if (data.trigger.type === "accessLinkGuest") {
      setTrigger({
        type: "accessLinkGuest",
        name: "",
        avatarUrl: AvatarPlaceholder,
      });
    } else {
      setTrigger({ type: "system", name: "application", avatarUrl: indaHash });
    }
  }, [data, membersList]);

  const getTaskUrl = () => {
    return `/workspace/${activeWorkspaceUuid}/projects/${data.wsProjectUuid}/${data.wsTaskUuid}`;
  };

  const getGlobalTaskUrl = () => {
    return getRoute(
      ROUTES.GLOBAL_TASK_DETAILS,
      {
        wsWorkspaceUuid: activeWorkspaceUuid,
      },
      {
        displayGlobalTask: data.wsGlobalTaskUuid,
      },
    );
  };

  const getDictionaryUrl = () => {
    return getRoute(ROUTES.DICTIONARY_ELEMENT_DETAILS, {
      wsWorkspaceUuid: activeWorkspaceUuid,
      wsDictionaryUrl: data.wsDictionaryUrl,
      wsDictionaryUuid: data.wsDictionaryUuid,
    });
  };

  const getDictionaryElementUrl = () => {
    return getRoute(
      ROUTES.DICTIONARY_ELEMENT_DETAILS,
      {
        wsWorkspaceUuid: activeWorkspaceUuid,
        wsDictionaryUrl: data.wsDictionaryUrl,
        wsDictionaryUuid: data.wsDictionaryUuid,
      },
      {
        displayDictionaryPreview: data.wsDictionaryElementUuid,
      },
    );
  };

  const getProjectUrl = () => {
    return `/workspace/${activeWorkspaceUuid}/projects/${data.wsProjectUuid}`;
  };

  const getMetaFieldUrl = () => {
    return `/workspace/${activeWorkspaceUuid}/projects/${data.wsProjectUuid}${
      data.wsMetaFieldTaskType ? `/${data.wsMetaFieldTaskType}` : ""
    }`;
  };

  const getCommentUrl = () => {
    return `/workspace/${activeWorkspaceUuid}/projects/${data.wsProjectUuid}/${data.wsTaskUuid}/${data.wsTaskCommentUuid}`;
  };

  const accessLinkUrl = data.wsAccessLinkUrl || getMetaFieldUrl();

  const isFieldTextTypeEdited = () => {
    return (
      data.wsProjectMetaFieldType === MetaFieldType.Text ||
      data.wsGlobalTaskMetaFieldType === MetaFieldType.Text ||
      (data.wsTaskMetaFieldType === MetaFieldType.Text &&
        data.operationType.includes("value_updated"))
    );
  };

  const getActivityItem = (type) => {
    if (type === "project") {
      return {
        fieldType: data.wsProjectMetaFieldType,
        singleSelectOptions: data?.wsProjectMetaFieldData?.singleSelectOptions,
        currencyCode: data?.wsProjectMetaFieldData?.currencyCode,
      };
    }

    if (type === "wsGlobalTask") {
      return {
        fieldType: data.wsGlobalTaskMetaFieldType,
        singleSelectOptions:
          data?.wsGlobalTaskMetaFieldData?.singleSelectOptions,
        currencyCode: data?.wsGlobalTaskMetaFieldData?.currencyCode,
      };
    }

    if (type === "wsDictionary") {
      return {
        fieldType: data.wsDictionaryMetaFieldType,
        singleSelectOptions: data?.data?.singleSelectOptions,
        currencyCode: data?.data?.currencyCode,
      };
    }

    return {
      fieldType: data.wsTaskMetaFieldType,
      singleSelectOptions: data?.wsTaskMetaFieldOptions?.singleSelectOptions,
      currencyCode: data?.wsTaskMetaFieldOptions?.currencyCode,
    };
  };

  const getNewMetaValue = (value, dataType) => {
    const {
      fieldType,
      wsSelectDataSetUuid,
      singleSelectOptions,
      currencyCode,
    } = getActivityItem(dataType);

    switch (fieldType) {
      case MetaFieldType.Content:
      case MetaFieldType.Creator:
      case "publicationRelated":
        return (
          <ActivityItemLink
            title={data.wsRelatedTaskTitle}
            url={`/workspace/${activeWorkspaceUuid}/projects/${data.wsProjectUuid}/${data.newValue}`}
          />
        );

      case MetaFieldType.Member:
        const assignee = membersList?.find((item) => item.id === value);

        return <>@{assignee?.name}</>;

      case MetaFieldType.Number:
        return <i>"{splitNumbers(value)}"</i>;

      case MetaFieldType.Percent:
        return <i>"{value * 100}%"</i>;

      case MetaFieldType.Currency:
        return (
          <>
            <i>
              "{currencyCode}
              &nbsp;
              {value}
            </i>
            "
          </>
        );

      case MetaFieldType.SingleSelect: {
        const options = wsSelectDataSetUuid
          ? selectDataSetList[`dataSetType_${wsSelectDataSetUuid}`]
          : singleSelectOptions;

        const singleSelectValue = options?.find((item) => item.value === value);

        if (!singleSelectValue)
          return (
            <i>
              {translateMessage({
                intl,
                id: "ws_unknown_value",
                defaultMessage: "unknown value",
              })}
            </i>
          );

        return (
          <span
            className="activity__table-element-label"
            style={{
              color: `#${singleSelectValue.color}`,
              backgroundColor: `#${singleSelectValue.backgroundColor}`,
            }}
          >
            {singleSelectValue.name}
          </span>
        );
      }

      case MetaFieldType.MultiSelect: {
        if (!value) {
          return (
            <i>
              {translateMessage({
                intl,
                id: "ws_unknown_value",
                defaultMessage: "unknown value",
              })}
            </i>
          );
        }

        const options = wsSelectDataSetUuid
          ? selectDataSetList[`dataSetType_${wsSelectDataSetUuid}`]
          : singleSelectOptions;
        const maxLabelsNumber = 3;
        const tempValue = value.split(", ") || [];
        const tooltipContent = tempValue
          .slice(maxLabelsNumber, tempValue.length)
          .map((item) => options?.find((option) => option.value === item).name)
          .join(", ");

        return (
          <>
            {tempValue.slice(0, maxLabelsNumber).map((item) => {
              const multiValue = options?.find(
                (option) => option.value === item,
              );

              if (!multiValue)
                return (
                  <i>
                    {translateMessage({
                      intl,
                      id: "ws_unknown_value",
                      defaultMessage: "unknown value",
                    })}
                  </i>
                );

              return (
                <span
                  className="activity__table-element-label activity__table-element-label--multi"
                  style={{
                    color: `#${multiValue.color}`,
                    backgroundColor: `#${multiValue.backgroundColor}`,
                  }}
                >
                  {multiValue.name}
                </span>
              );
            })}

            {tempValue.length > maxLabelsNumber && (
              <TooltipPortal content={tooltipContent} noHeightLimit>
                <span className="activity__table-element-label activity__table-element-label--counter">
                  +{tempValue.length - maxLabelsNumber}
                </span>
              </TooltipPortal>
            )}
          </>
        );
      }

      case MetaFieldType.AggregatedSelect: {
        if (!value) {
          return (
            <i>
              {translateMessage({
                intl,
                id: "ws_unknown_value",
                defaultMessage: "unknown value",
              })}
            </i>
          );
        }

        const options = singleSelectOptions;
        const maxLabelsNumber = 3;
        const tempValue = [];

        value.split(", ")?.forEach((item) => {
          const foundOption = options.find((el) => item === el.value) || [];

          if (foundOption) tempValue.push(foundOption);
        });

        const labels = getCountedSelectValues(tempValue);

        const tooltipContent = labels
          .map((item) => `${item.name} x ${item.count}`)
          .slice(maxLabelsNumber, labels.length)
          .join(", ");

        return (
          <>
            {labels.slice(0, maxLabelsNumber).map((item) => {
              if (!item)
                return (
                  <i>
                    {translateMessage({
                      intl,
                      id: "ws_unknown_value",
                      defaultMessage: "unknown value",
                    })}
                  </i>
                );

              return (
                <span
                  className="activity__table-element-label activity__table-element-label--multi"
                  style={{
                    color: `#${item.color}`,
                    backgroundColor: `#${item.backgroundColor}`,
                  }}
                >
                  {item.name}
                </span>
              );
            })}

            {labels.length > maxLabelsNumber && (
              <TooltipPortal content={tooltipContent} noHeightLimit>
                <span className="activity__table-element-label activity__table-element-label--counter">
                  +{labels.length - maxLabelsNumber}
                </span>
              </TooltipPortal>
            )}
          </>
        );
      }

      case MetaFieldType.Date:
        const formattedValue = getDateLabel(
          "en-EN",
          moment(value),
          false,
          intl,
        );

        return (
          <i>
            "
            {typeof formattedValue?.date === "string"
              ? formattedValue?.date
              : getFullDate(formattedValue?.date.toDate())}
            "
          </i>
        );

      case MetaFieldType.Text:
      case MetaFieldType.DictionaryElement:
        return (
          <i className="activity__table-element-text">"{XssFilter(value)}"</i>
        );

      case MetaFieldType.BoolVal:
        return (
          <i className="activity__table-element-text">
            "{value === "1" ? "checked" : "unchecked"}"
          </i>
        );
      default:
        return value;
    }
  };

  const filterActivityTitle = (text) => {
    if (text === null) {
      return null;
    }

    return XssFilter(text, [], [], true);
  };

  const limitNameLength = (string) => {
    if (string === null) {
      return null;
    }

    return `${string.substring(0, 100)}${string.length >= 100 ? "..." : ""}`;
  };

  const handleRenderActivityValue = () => {
    try {
      return renderActivityValue();
    } catch (err) {
      if (envName !== "live") {
        return <i style={{ color: "red" }}>value handling error</i>;
      }
      throw err; // for live env, handle error on upper level to skip rendering the row entirely
    }
  };

  const renderActivityValue = () => {
    switch (data.operationType) {
      // TASK
      case ActivityLogOperationType.WsTaskCreated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_created",
              defaultMessage: "created",
            })}
            &nbsp;
            {data.taskType}&nbsp;
            <ActivityItemLink
              title={limitNameLength(filterActivityTitle(data.wsTaskTitle))}
              url={getTaskUrl()}
            />
          </>
        );
      case ActivityLogOperationType.WsRecruitmentTaskCreated:
        return (
          <WsRecruitmentTaskCreated
            taskLink={
              <ActivityItemLink
                title={limitNameLength(filterActivityTitle(data.wsTaskTitle))}
                url={getTaskUrl()}
              />
            }
            projectLink={
              <ActivityItemLink
                title={limitNameLength(
                  filterActivityTitle(data.wsProjectTitle),
                )}
                url={getProjectUrl()}
              />
            }
          />
        );

      case ActivityLogOperationType.WsTaskDeleted:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_activity_removed",
              defaultMessage: "removed",
            })}
            &nbsp;{data.taskType}&nbsp;
            {limitNameLength(filterActivityTitle(data.wsTaskTitle))}&nbsp;
          </>
        );

      case ActivityLogOperationType.WsTaskDuplicated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_task_duplicated",
              defaultMessage: "duplicated",
            })}
            &nbsp;
            {data.taskType}&nbsp;
            <ActivityItemLink
              title={limitNameLength(filterActivityTitle(data.wsTaskTitle))}
              url={getTaskUrl()}
            />
          </>
        );
      // WS GLOBAL TASK
      case ActivityLogOperationType.WsGlobalTaskCreated:
        if (isFromCreatorDatabaseSubmissionForm) {
          return (
            <WsCreatorDatabaseSubmissionFormTaskCreated
              taskLink={
                <ActivityItemLink
                  title={limitNameLength(
                    filterActivityTitle(data.wsGlobalTaskTitle),
                  )}
                  url={getGlobalTaskUrl()}
                />
              }
            />
          );
        }

        return (
          <>
            {translateMessage({
              intl,
              id: "ws_created_global_task",
              defaultMessage: "created global task",
            })}
            &nbsp;
            <ActivityItemLink
              title={limitNameLength(
                filterActivityTitle(data.wsGlobalTaskTitle),
              )}
              url={getGlobalTaskUrl()}
            />
          </>
        );

      // TASK META FIELD
      case ActivityLogOperationType.WsTaskMetaFieldUpdated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_activity_edited",
              defaultMessage: "edited",
            })}
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsTaskMetaFieldTitle)}
              url={getMetaFieldUrl()}
            />
            &nbsp;
            {translateMessage({
              intl,
              id: "ws_in",
              defaultMessage: "in",
            })}
            &nbsp;
            <ActivityItemLink
              title={capitalizeFirstLetter(data.wsMetaFieldTaskType)}
              url={getMetaFieldUrl()}
            />
          </>
        );

      case ActivityLogOperationType.WsTaskMetaFieldCreated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_created",
              defaultMessage: "created",
            })}
            &nbsp;
            {translateMessage({
              intl,
              id: "ws_column",
              defaultMessage: "column",
            })}
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsTaskMetaFieldTitle)}
              url={getMetaFieldUrl()}
            />
            &nbsp;
            {translateMessage({
              intl,
              id: "ws_in",
              defaultMessage: "in",
            })}
            &nbsp;
            <ActivityItemLink
              title={capitalizeFirstLetter(data.wsMetaFieldTaskType)}
              url={getMetaFieldUrl()}
            />
          </>
        );

      case ActivityLogOperationType.WsTaskMetaFieldDeleted:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_activity_removed",
              defaultMessage: "removed",
            })}
            &nbsp;{data.wsMetaFieldTaskType}&nbsp;
            {translateMessage({
              intl,
              id: "ws_column",
              defaultMessage: "column",
            })}
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsTaskMetaFieldTitle)}
              url={getMetaFieldUrl()}
            />
          </>
        );

      case ActivityLogOperationType.WsTaskMetaValueUpdated:
        if (data.wsTaskMetaFieldType === "file") {
          if (data?.wsAccessLinkMetaFieldUuid) {
            return (
              <>
                {trigger.type !== "accessLinkGuest" && (
                  <>
                    {translateMessage({
                      intl,
                      id: "ws_updated",
                      defaultMessage: "updated",
                    })}
                    &nbsp;
                  </>
                )}
                <ActivityItemLink
                  title={limitNameLength(data.wsTaskTitle)}
                  url={getTaskUrl()}
                />
                &nbsp;
                <ActivityItemLink
                  title={filterActivityTitle(data.wsTaskMetaFieldTitle)}
                  url={getMetaFieldUrl()}
                />
                &nbsp;
                {trigger.type === "accessLinkGuest" && (
                  <>
                    {translateMessage({
                      intl,
                      id: "ws_updated",
                      defaultMessage: "updated",
                    })}
                    &nbsp;
                  </>
                )}
                {translateMessage({
                  intl,
                  id: "ws_in",
                  defaultMessage: "in",
                })}
                &nbsp;
                <ActivityItemLink
                  title={filterActivityTitle(data.wsAccessLinkMetaFieldTitle)}
                  url={accessLinkUrl}
                  external={isURLExternal}
                />
              </>
            );
          }

          return (
            <>
              {translateMessage({
                intl,
                id: "ws_updated",
                defaultMessage: "updated",
              })}
              &nbsp;
              <ActivityItemLink
                title={limitNameLength(data.wsTaskTitle)}
                url={getTaskUrl()}
              />
              &nbsp;
              <ActivityItemLink
                title={filterActivityTitle(data.wsTaskMetaFieldTitle)}
                url={getMetaFieldUrl()}
              />
            </>
          );
        }

        if (
          data.wsTaskMetaFieldType === "accessLink" &&
          data.newValue === "token"
        ) {
          return (
            <>
              {translateMessage({
                intl,
                id: "ws_regenerated_link_to",
                defaultMessage: "regenerated link to",
              })}
              &nbsp;
              <ActivityItemLink
                title={filterActivityTitle(data.wsTaskMetaFieldTitle)}
                url={accessLinkUrl}
                external={isURLExternal}
              />
              &nbsp;
              {translateMessage({
                intl,
                id: "ws_access_link_for",
                defaultMessage: "for",
              })}
              &nbsp;
              <ActivityItemLink
                title={limitNameLength(data.wsTaskTitle)}
                url={getTaskUrl()}
              />
            </>
          );
        }

        if (
          data.wsTaskMetaFieldType === "accessLink" &&
          data.newValue === "status:enabled"
        ) {
          return (
            <>
              {translateMessage({
                intl,
                id: "ws_activated_link_to",
                defaultMessage: "activated link to",
              })}
              &nbsp;
              <ActivityItemLink
                title={filterActivityTitle(data.wsTaskMetaFieldTitle)}
                url={accessLinkUrl}
                external={isURLExternal}
              />
              &nbsp;
              {translateMessage({
                intl,
                id: "ws_access_link_for",
                defaultMessage: "for",
              })}
              &nbsp;
              <ActivityItemLink
                title={limitNameLength(data.wsTaskTitle)}
                url={getTaskUrl()}
              />
            </>
          );
        }

        if (
          data.wsTaskMetaFieldType === "accessLink" &&
          data.newValue === "status:disabled"
        ) {
          return (
            <>
              {translateMessage({
                intl,
                id: "ws_deactivated_link_to",
                defaultMessage: "deactivated link to",
              })}
              &nbsp;
              <ActivityItemLink
                title={filterActivityTitle(data.wsTaskMetaFieldTitle)}
                url={accessLinkUrl}
                external={isURLExternal}
              />
              &nbsp;
              {translateMessage({
                intl,
                id: "ws_access_link_for",
                defaultMessage: "for",
              })}
              &nbsp;
              <ActivityItemLink
                title={limitNameLength(data.wsTaskTitle)}
                url={getTaskUrl()}
              />
            </>
          );
        }

        if (data?.wsAccessLinkMetaFieldUuid?.length > 0) {
          return (
            <>
              {trigger.type !== "accessLinkGuest" && (
                <>
                  {translateMessage({
                    intl,
                    id: "ws_set-past",
                    defaultMessage: "set",
                  })}
                  &nbsp;
                </>
              )}
              <ActivityItemLink
                title={limitNameLength(data.wsTaskTitle)}
                url={getTaskUrl()}
              />
              &nbsp;
              <ActivityItemLink
                title={filterActivityTitle(data.wsTaskMetaFieldTitle)}
                url={getMetaFieldUrl()}
              />
              &nbsp;
              {trigger.type === "accessLinkGuest" && (
                <>
                  {translateMessage({
                    intl,
                    id: "ws_set-past",
                    defaultMessage: "set",
                  })}
                  &nbsp;
                </>
              )}
              {translateMessage({
                intl,
                id: "ws_activity_to",
                defaultMessage: "to",
              })}
              &nbsp;
              {getNewMetaValue(data.newValue, "task")}
              &nbsp;
              {translateMessage({
                intl,
                id: "ws_in",
                defaultMessage: "in",
              })}
              &nbsp;
              <ActivityItemLink
                title={filterActivityTitle(data.wsAccessLinkMetaFieldTitle)}
                url={accessLinkUrl}
                external={isURLExternal}
              />
            </>
          );
        }

        return (
          <>
            {translateMessage({
              intl,
              id: "ws_set-past",
              defaultMessage: "set",
            })}
            &nbsp;
            <ActivityItemLink
              title={limitNameLength(data.wsTaskTitle)}
              url={getTaskUrl()}
            />
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsTaskMetaFieldTitle)}
              url={getMetaFieldUrl()}
            />
            &nbsp;
            {translateMessage({
              intl,
              id: "ws_activity_to",
              defaultMessage: "to",
            })}
            &nbsp;
            {getNewMetaValue(data.newValue, "task")}
          </>
        );
      case ActivityLogOperationType.WsGlobalTaskMetaValueUpdated:
        if (data.wsGlobalTaskMetaFieldType === "file") {
          return (
            <>
              {translateMessage({
                intl,
                id: "ws_updated",
                defaultMessage: "updated",
              })}
              &nbsp;
              <ActivityItemLink
                title={limitNameLength(data.wsGlobalTaskTitle)}
                url={getGlobalTaskUrl()}
              />
              &nbsp;
              <ActivityItemLink
                title={filterActivityTitle(data.wsGlobalTaskMetaFieldTitle)}
                url={getGlobalTaskUrl()}
              />
            </>
          );
        }

        return (
          <>
            {translateMessage({
              intl,
              id: "ws_set-past",
              defaultMessage: "set",
            })}
            &nbsp;
            <ActivityItemLink
              title={limitNameLength(data.wsGlobalTaskTitle)}
              url={getGlobalTaskUrl()}
            />
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsGlobalTaskMetaFieldTitle)}
              url={getGlobalTaskUrl()}
            />
            &nbsp;
            {translateMessage({
              intl,
              id: "ws_activity_to",
              defaultMessage: "to",
            })}
            &nbsp;
            {getNewMetaValue(data.newValue, "wsGlobalTask")}
          </>
        );
      case ActivityLogOperationType.WsGlobalTaskMetaValueDeleted:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_deleted",
              defaultMessage: "deleted",
            })}
            &nbsp;
            <ActivityItemLink
              title={limitNameLength(data.wsGlobalTaskTitle)}
              url={getGlobalTaskUrl()}
            />
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsGlobalTaskMetaFieldTitle)}
              url={getGlobalTaskUrl()}
            />
            &nbsp;
            {translateMessage({
              intl,
              id: "ws_deleted_value_global_task",
              defaultMessage: "value",
            })}
            &nbsp;
          </>
        );
      case ActivityLogOperationType.WsGlobalTaskMetaFieldCreated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_created_global_task_meta_field",
              defaultMessage: "created global task meta field",
            })}
            &nbsp;
            <i>"{filterActivityTitle(data.wsGlobalTaskMetaFieldTitle)}"</i>
          </>
        );
      case ActivityLogOperationType.WsGlobalTaskMetaFieldUpdated:
      case ActivityLogOperationType.WsGlobalTaskMetaFieldSelectOptionsUpdated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_updated_global_task_meta_field",
              defaultMessage: "updated global task meta field",
            })}
            &nbsp;
            <i>"{filterActivityTitle(data.wsGlobalTaskMetaFieldTitle)}"</i>
          </>
        );
      case ActivityLogOperationType.WsGlobalTaskMetaFieldDeleted:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_deleted_global_task_meta_field",
              defaultMessage: "deleted global task meta field",
            })}
            &nbsp;
            <i>"{filterActivityTitle(data.wsGlobalTaskMetaFieldTitle)}"</i>
          </>
        );

      // TASK COMMENT
      case ActivityLogOperationType.WsTaskCommentCreated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_commented_on",
              defaultMessage: "commented on",
            })}
            &nbsp;
            <ActivityItemLink
              title={limitNameLength(filterActivityTitle(data.wsTaskTitle))}
              url={getCommentUrl()}
            />
            &nbsp;
            {limitNameLength(filterActivityTitle(data.wsTaskCommentContent))
              .length > 1 && (
              <i>
                "
                {limitNameLength(
                  filterActivityTitle(data.wsTaskCommentContent),
                )}
                "
              </i>
            )}
            {data.wsTaskCommentAttachments.length > 1 && (
              <>
                <ClipIcon className="activity__table-element-clip" />
                {data.wsTaskCommentAttachments.length}
              </>
            )}
          </>
        );
      // CONTENT PROPOSAL
      case ActivityLogOperationType.WsContentProposalCreated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_added_content_proposal_in",
              defaultMessage: "added content proposal in",
            })}
            &nbsp;
            <ActivityItemLink
              title={limitNameLength(filterActivityTitle(data.wsTaskTitle))}
              url={getCommentUrl()}
            />
            &nbsp;
            {translateMessage({
              intl,
              id: "content",
              defaultMessage: "content",
            })}
          </>
        );
      case ActivityLogOperationType.WsContentProposalModerated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_moderated_content_proposal_in",
              defaultMessage: "moderated content proposal in",
            })}
            &nbsp;
            <ActivityItemLink
              title={limitNameLength(filterActivityTitle(data.wsTaskTitle))}
              url={getCommentUrl()}
            />
            &nbsp;
            {translateMessage({
              intl,
              id: "content",
              defaultMessage: "content",
            })}
          </>
        );
      case ActivityLogOperationType.WsContentProposalDeleted:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_deleted_content_proposal_in",
              defaultMessage: "deleted content proposal in",
            })}
            &nbsp;
            <ActivityItemLink
              title={limitNameLength(filterActivityTitle(data.wsTaskTitle))}
              url={getCommentUrl()}
            />
            &nbsp;
            {translateMessage({
              intl,
              id: "content",
              defaultMessage: "content",
            })}
          </>
        );

      // PROJECT
      case ActivityLogOperationType.WsProjectCreated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_created_campaign",
              defaultMessage: "created campaign",
            })}
            &nbsp;
            <ActivityItemLink
              title={limitNameLength(filterActivityTitle(data.wsProjectTitle))}
              url={getProjectUrl()}
            />
          </>
        );

      case ActivityLogOperationType.WsProjectDeleted:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_deleted_campaign",
              defaultMessage: "deleted campaign",
            })}
            &nbsp;{limitNameLength(filterActivityTitle(data.wsProjectTitle))}
            &nbsp;
          </>
        );
      case ActivityLogOperationType.WsProjectDuplicated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_duplicated_campaign",
              defaultMessage: "duplicated campaign",
            })}
            &nbsp;
            <ActivityItemLink
              title={limitNameLength(filterActivityTitle(data.wsProjectTitle))}
              url={getProjectUrl()}
            />
          </>
        );

      // PROJECT META FIELD
      case ActivityLogOperationType.WsProjectMetaFieldUpdated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_modified",
              defaultMessage: "modified",
            })}
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsProjectMetaFieldTitle)}
              url={getMetaFieldUrl()}
            />
          </>
        );

      case ActivityLogOperationType.WsProjectMetaFieldCreated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_created_campaign_overview",
              defaultMessage: "created campaign overview",
            })}
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsProjectMetaFieldTitle)}
              url={getMetaFieldUrl()}
            />
          </>
        );

      case ActivityLogOperationType.WsProjectMetaFieldDeleted:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_deleted_campaign_overview",
              defaultMessage: "deleted campaign overview",
            })}
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsProjectMetaFieldTitle)}
              url={getMetaFieldUrl()}
            />
          </>
        );

      case ActivityLogOperationType.WsProjectMetaValueUpdated:
      case ActivityLogOperationType.WsProjectMetaValueDeleted:
        if (data.wsProjectMetaFieldType === "file") {
          if (data?.wsAccessLinkMetaFieldUuid?.length > 0) {
            return (
              <>
                {trigger.type !== "accessLinkGuest" && (
                  <>
                    {translateMessage({
                      intl,
                      id: "ws_updated",
                      defaultMessage: "updated",
                    })}
                    &nbsp;
                  </>
                )}
                <ActivityItemLink
                  title={filterActivityTitle(data.wsProjectMetaFieldTitle)}
                  url={getMetaFieldUrl()}
                />
                &nbsp;
                {trigger.type === "accessLinkGuest" && (
                  <>
                    {translateMessage({
                      intl,
                      id: "ws_updated",
                      defaultMessage: "updated",
                    })}
                    &nbsp;
                  </>
                )}
                {translateMessage({
                  intl,
                  id: "ws_in",
                  defaultMessage: "in",
                })}
                &nbsp;
                <ActivityItemLink
                  title={filterActivityTitle(data.wsAccessLinkMetaFieldTitle)}
                  url={accessLinkUrl}
                  external={isURLExternal}
                />
              </>
            );
          }

          return (
            <>
              {translateMessage({
                intl,
                id: "ws_updated",
                defaultMessage: "updated",
              })}
              &nbsp;
              <ActivityItemLink
                title={filterActivityTitle(data.wsProjectMetaFieldTitle)}
                url={getMetaFieldUrl()}
              />
            </>
          );
        }

        if (data?.wsAccessLinkMetaFieldUuid?.length > 0) {
          return (
            <>
              {trigger.type !== "accessLinkGuest" && (
                <>
                  {translateMessage({
                    intl,
                    id: "ws_set-past",
                    defaultMessage: "set",
                  })}
                  &nbsp;
                </>
              )}
              <ActivityItemLink
                title={filterActivityTitle(data.wsProjectMetaFieldTitle)}
                url={getMetaFieldUrl()}
              />
              &nbsp;
              {trigger.type === "accessLinkGuest" && (
                <>
                  {translateMessage({
                    intl,
                    id: "ws_set-past",
                    defaultMessage: "set",
                  })}
                  &nbsp;
                </>
              )}
              {translateMessage({
                intl,
                id: "ws_to",
                defaultMessage: "to",
              })}
              &nbsp;
              {getNewMetaValue(data.newValue, "project")}
              &nbsp;
              {translateMessage({
                intl,
                id: "ws_in",
                defaultMessage: "in",
              })}
              &nbsp;
              <ActivityItemLink
                title={filterActivityTitle(data.wsAccessLinkMetaFieldTitle)}
                url={accessLinkUrl}
                external={isURLExternal}
              />
            </>
          );
        }

        return (
          <>
            {translateMessage({
              intl,
              id: "ws_set-past",
              defaultMessage: "set",
            })}
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsProjectMetaFieldTitle)}
              url={getMetaFieldUrl()}
            />
            &nbsp;
            {translateMessage({
              intl,
              id: "ws_to",
              defaultMessage: "to",
            })}
            &nbsp;
            {getNewMetaValue(data.newValue, "project")}
          </>
        );
      case ActivityLogOperationType.WsDictionaryMetaFieldCreated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_created",
              defaultMessage: "created",
            })}
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsDictionaryTitle)}
              url={getDictionaryUrl()}
            />
            &nbsp;
            {translateMessage({
              intl,
              id: "ws_dictionary_column",
              defaultMessage: "column",
            })}
            &nbsp;
            <i>"{data.wsDictionaryMetaFieldTitle}"</i>
          </>
        );
      case ActivityLogOperationType.WsDictionaryMetaValueUpdated:
        if (data.wsDictionaryMetaFieldType === "file") {
          return (
            <>
              {translateMessage({
                intl,
                id: "ws_updated",
                defaultMessage: "updated",
              })}
              &nbsp;
              <ActivityItemLink
                title={limitNameLength(data.wsDictionaryElementTitle)}
                url={getDictionaryElementUrl()}
              />
              &nbsp;
              <ActivityItemLink
                title={filterActivityTitle(data.wsDictionaryMetaFieldTitle)}
                url={getDictionaryElementUrl()}
              />
            </>
          );
        }

        if (data?.newValue === null) {
          return (
            <>
              {translateMessage({
                intl,
                id: "ws_deleted",
                defaultMessage: "deleted",
              })}
              &nbsp;
              <ActivityItemLink
                title={filterActivityTitle(data.wsDictionaryTitle)}
                url={getDictionaryUrl()}
              />
              &nbsp;
              {translateMessage({
                intl,
                id: "ws_dictionary_column",
                defaultMessage: "column",
              })}
              &nbsp;
              <i>"{data.wsDictionaryMetaFieldTitle}"</i>
              &nbsp;
              {translateMessage({
                intl,
                id: "ws_value_deleted",
                defaultMessage: "value",
              })}
            </>
          );
        }

        return (
          <>
            {translateMessage({
              intl,
              id: "ws_updated",
              defaultMessage: "updated",
            })}
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsDictionaryTitle)}
              url={getDictionaryUrl()}
            />
            &nbsp;
            {translateMessage({
              intl,
              id: "ws_dictionary_column",
              defaultMessage: "column",
            })}
            &nbsp;
            <i>"{data.wsDictionaryMetaFieldTitle}"</i>
            &nbsp;
            {translateMessage({
              intl,
              id: "ws_to",
              defaultMessage: "to",
            })}
            &nbsp;
            {getNewMetaValue(data.newValue, "wsDictionary")}
          </>
        );
      case ActivityLogOperationType.WsDictionaryElementCreated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_created",
              defaultMessage: "created",
            })}
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsDictionaryTitle)}
              url={getDictionaryUrl()}
            />
            &nbsp;
            {translateMessage({
              intl,
              id: "ws_dictionary_element",
              defaultMessage: "element",
            })}
            &nbsp;
            <ActivityItemLink
              title={filterActivityTitle(data.wsDictionaryElementTitle)}
              url={getDictionaryElementUrl()}
            />
          </>
        );
      case ActivityLogOperationType.WsGlobalTaskMetaFieldWorkspaceSubmissionFormUpdated:
        return (
          <>
            {translateMessage({
              intl,
              id: "ws_updated_workspace_submission_form",
              defaultMessage: "updated Creator Submission Form",
            })}
          </>
        );
      case ActivityLogOperationType.WsCreatorSubmissionFormStatusUpdated:
        if (data.newStatus) {
          return (
            <>
              {translateMessage({
                intl,
                id: "ws_enabled_workspace_submission_form",
                defaultMessage: "enabled Creator Submission Form",
              })}
            </>
          );
        }

        return (
          <>
            {translateMessage({
              intl,
              id: "ws_disabled_workspace_submission_form",
              defaultMessage: "disabled Creator Submission Form",
            })}
          </>
        );

      case ActivityLogOperationType.WsMessengerMessageChangedStatus:
        if (data.status === WsMessengerMessageStatusEnum.Sent) {
          return (
            <>
              {translateMessage({
                intl,
                id: "ws_messenger_message_changed_sent_to",
                defaultMessage: "sent message to",
              })}
              &nbsp;
              <ActivityItemLink
                title={limitNameLength(filterActivityTitle(data.wsTaskTitle))}
                url={getTaskUrl()}
              />
            </>
          );
        }

        if (data.status === WsMessengerMessageStatusEnum.Error) {
          return (
            <>
              {translateMessage({
                intl,
                id: "ws_messenger_message_changed_error_to",
                defaultMessage: "tried sent message with errors to",
              })}
              &nbsp;
              <ActivityItemLink
                title={limitNameLength(filterActivityTitle(data.wsTaskTitle))}
                url={getTaskUrl()}
              />
            </>
          );
        }

        if (data.status === WsMessengerMessageStatusEnum.Skipped) {
          return (
            <>
              {translateMessage({
                intl,
                id: "ws_messenger_message_changed_skipped_to",
                defaultMessage: "tried sent message but skipped, to",
              })}
              &nbsp;
              <ActivityItemLink
                title={limitNameLength(filterActivityTitle(data.wsTaskTitle))}
                url={getTaskUrl()}
              />
            </>
          );
        }

      default:
        return "";
    }
  };

  const getFormattedDate = () => {
    if (loading && !data?.createdDate?.date) return null;

    return moment(
      new Date(convertToLocalTimezone(data.createdDate.date)),
    ).format("DD.MM.YYYY HH:mm");
  };

  const logWithoutProjectContext = ActivityLogsWithoutProjectContext.includes(
    data.operationType,
  );
  const isFromCreatorDatabaseSubmissionForm =
    data?.wsExtensionUuid !== null && data?.wsExtensionUuid !== undefined;

  const getActivityLogImage = () => {
    if (loading) {
      return <SkeletonCircle size={24} marginRight={8} />;
    }

    if (
      data?.operationType === ActivityLogOperationType.WsRecruitmentTaskCreated
    ) {
      return (
        <img src={data?.wsTaskCoverUrl || avatarPlaceholder} loading="lazy" />
      );
    }

    if (isFromCreatorDatabaseSubmissionForm) {
      return (
        <Img
          src={data?.wsGlobalTaskCoverUrl || avatarPlaceholder}
          fallbackImageSrc={avatarPlaceholder}
          loading="lazy"
        />
      );
    }

    return <img src={trigger?.avatarUrl || avatarPlaceholder} loading="lazy" />;
  };

  const renderContent = () => {
    return (
      <div className="activity__table-element">
        {/* Activity */}
        <div
          className={classNames("activity__table-element-activity", {
            "activity__table-element-activity--text": isFieldTextTypeEdited(),
          })}
        >
          {getActivityLogImage()}

          {loading ? (
            <SkeletonText width={500} height={18} />
          ) : (
            <span
              key={data?.createdDate?.date}
              className={classNames(
                "activity__table-element-activity-value animate-on-update",
                {
                  "activity__table-element-activity-value--text":
                    isFieldTextTypeEdited(),
                },
              )}
            >
              {trigger.type !== "accessLinkGuest" &&
                data.operationType !==
                  ActivityLogOperationType.WsRecruitmentTaskCreated &&
                ActivityLogOperationType.WsGlobalTaskCreated &&
                !isFromCreatorDatabaseSubmissionForm && (
                  <>@{trigger?.name}&nbsp;</>
                )}
              {handleRenderActivityValue()}
            </span>
          )}
        </div>

        {/* Time */}
        <div
          key={data?.createdDate?.date}
          className="activity__table-element-time animate-on-update"
          title={getFormattedDate()}
        >
          {loading ? (
            <SkeletonText width={120} height={18} />
          ) : data.createdDate ? (
            formatDistance(
              new Date(convertToLocalTimezone(data?.createdDate?.date)),
              new Date(),
              {
                addSuffix: true,
              },
            )
          ) : (
            "-"
          )}
        </div>

        {/* Project */}
        <div
          key={data.wsProjectTitle}
          className="activity__table-element-project animate-on-update "
        >
          {loading ? (
            <SkeletonText width="100%" height={18} />
          ) : logWithoutProjectContext ? (
            <span>-</span>
          ) : (
            <>
              <img
                src={data.wsProjectCoverUrl || campaignPlaceholder}
                loading="lazy"
              />
              <Link
                className="activity__table-element-project-name"
                to={getProjectUrl()}
              >
                {filterActivityTitle(data.wsProjectTitle)}
              </Link>
            </>
          )}
        </div>
      </div>
    );
  };

  const handleRenderContent = () => {
    try {
      return renderContent();
    } catch (err) {
      return null;
    }
  };

  return handleRenderContent();
};

function ActivityItemLink(props) {
  const { title, url, external } = props;

  const formattedTitle = title?.replace(/[\r\n]/gm, "");

  return external ? (
    <a className="activity__table-element-link" href={url}>
      {formattedTitle}
    </a>
  ) : (
    <Link className="activity__table-element-link" to={url}>
      {formattedTitle}
    </Link>
  );
}
