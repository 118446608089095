import { addHeaderToLayout } from "src/app/ReportGenerator/functions/addHeaderToLayout";
import { addSpaceIfNeeded } from "./addSpaceIfNeeded";

export const addPostsDataToLayout = (
  gridElements,
  newLayout,
  format,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.executiveSummary;

  Object.keys(campaigns).forEach((key) => {
    const campaign = campaigns[key];

    const {
      project: { title },
    } = campaign;

    if (!aggregation && key !== "aggregated") {
      addHeaderToLayout(`post-data-${title}`, newLayout, format, 3);
    }

    gridElements
      .filter(
        (item) => item.gridBoxType === "post-data" && item.campaignId === key,
      )
      .forEach((data) => {
        const lastEl = addSpaceIfNeeded(data.type, newLayout, format);

        let x = 0;
        let y = 0;
        const w = 3;
        const h = 2;

        // first in row and half the width
        const spaceLeft = 12 - (lastEl.x + lastEl.w);

        if (spaceLeft >= 3) {
          x = lastEl.x + lastEl.w;
          y = lastEl.y;
        } else {
          x = 0;
          y = lastEl.y + lastEl.h;
        }

        const key = `post-data-${data.name
          .replaceAll(" ", "-")
          ?.replaceAll(".", "")
          .toLowerCase()}-${data.uuid}`;
        newLayout.push({ i: key, x, y, w, h });
      });
  });
};
