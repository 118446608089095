import React from "react";

import { FemalePerAge } from "../../../../Task/tabs/ProfileDataTab/single/FemalePerAge";

export const renderFemalePerAge = (label, data, layout, GridBoxComponent) => {
  const {
    user_audience_data: { audienceWomen, gendersPerAge },
  } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <FemalePerAge
        audienceWomen={audienceWomen}
        female={gendersPerAge?.female}
        height={height}
      />
    </GridBoxComponent>
  );
};
