import React from "react";

import { Followers } from "../../../../Task/tabs/ProfileDataTab/single/Followers";

export const renderFollowers = (label, data, layout, GridBoxComponent) => {
  const {
    user_audience_data: { notable, paidPostPerformance },
    user_data: { followers },
  } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <Followers
        followers={followers}
        notableUsers={notable}
        paidPostPerformance={paidPostPerformance}
        height={height}
      />
    </GridBoxComponent>
  );
};
