import { SelectOption } from "src/types";
import {
  GET_SELECT_DATA_SET_OPTIONS,
  GET_SELECT_DATA_SET_OPTIONS_SUCCESS,
  SET_SELECT_DATA_SET_OPTIONS_LOADING,
} from "./selectDataSetTypes";

interface SelectDataSetList {
  [key: string]: SelectOption[];
}

export interface SelectDataSetReducerState {
  selectDataSetList: SelectDataSetList;
  isSelectDataSetListLoading: boolean;
  isSelectDataSetRequestLoading: boolean;
}

const initialState: SelectDataSetReducerState = {
  selectDataSetList: {} as SelectDataSetList,
  isSelectDataSetListLoading: false,
  isSelectDataSetRequestLoading: false,
};

const selectDataSetReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SELECT_DATA_SET_OPTIONS:
      return {
        ...state,
        isSelectDataSetRequestLoading: true,
      };

    case GET_SELECT_DATA_SET_OPTIONS_SUCCESS:
      const url = action.payload.config.url.split("/");
      const dataSetType = `dataSetType_${url[url.length - 1]}`;

      return {
        ...state,
        isSelectDataSetRequestLoading: false,
        selectDataSetList: {
          ...state.selectDataSetList,
          [dataSetType]: action.payload.data.content,
        },
      };

    case SET_SELECT_DATA_SET_OPTIONS_LOADING: {
      const { isSelectDataSetListLoading } = action.payload;
      return {
        ...state,
        isSelectDataSetListLoading,
      };
    }

    default:
      return state;
  }
};

export default selectDataSetReducer;
