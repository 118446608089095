import { CAMPAIGN_AUDIENCE_DATA } from "../../utils/variables";
import { addHeaderToArray } from "../addHeaderToArray";

export const addAudienceDataToGridElements = (
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.campaignAudienceData;

  Object.keys(campaigns).forEach((key) => {
    const campaign = campaigns[key];

    const {
      audienceData,
      project: { title },
    } = campaign;

    if (!audienceData) return;

    if (key !== "aggregated") {
      addHeaderToArray(
        `Audience Data - ${title}`,
        !aggregation ? newGridElements : newHiddenGridElements,
        3,
        `audience-data-${title}`,
        savedTemplate,
        templateApplied,
        "audience-data",
      );
    }

    CAMPAIGN_AUDIENCE_DATA.forEach((item) => {
      const element = {
        label: `audience-data-${item.value}-${key}`,
        provider: "audience-data",
        type: item.value,
        gridBoxType: "audience-data",
        campaignId: key,
        name: item.label,
        w: item.w,
        h: item.h,
      };

      // applying saved template
      if (
        (!aggregation && key === "aggregated") ||
        (aggregation && key !== "aggregated")
      ) {
        newHiddenGridElements.push(element);
      } else if (savedTemplate?.length && !templateApplied) {
        if (savedTemplate.find((item) => item.i === element.label)) {
          newGridElements.push(element);
        } else {
          newHiddenGridElements.push(element);
        }
      } else if (
        item.initialHidden ||
        !audienceData[item.source] ||
        audienceData[item.source].length === 0
      ) {
        newHiddenGridElements.push(element);
      }
      // applying default template
      else {
        newGridElements.push(element);
      }
    });
  });
};
