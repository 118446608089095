import { RouterChildContext } from "react-router";
import _ from "lodash";
import { API_URLS } from "src/utils/API_URLS";
import { AppConfig } from "../../config/AppConfig";

export enum CreatorShowcaseContext {
  CreatorDatabase = "creator-database",
  CreatorList = "creator-list",
  CreatorDiscoveryList = "creator-discovery-list",
  CreatorDiscoverySearch = "creator-discovery-search",
}

export interface OpenCreatorShowcaseProps {
  history: RouterChildContext["router"]["history"];
  context: CreatorShowcaseContext;

  ids: string[];
  creatorId: string;
  workspaceId: string;
  search?: string;
}

export const openCreatorShowcase = (props: OpenCreatorShowcaseProps) => {
  const { history, ...rest } = props;

  const url = new URL(`${AppConfig.getAppRoute()}/creator-showcase`);

  const params = { tab: "cover", ...rest };

  url.searchParams.set("mode", JSON.stringify(params));

  window.location.href = url.href;
};

export const getShowcaseSettings = () => {
  const url = new URL(window.location.href);
  const settings = JSON.parse(url.searchParams.get("mode") || "{}");
  return settings || {};
};

type ShowcaseSettingKey =
  | "context"
  | "tab"
  | "ids"
  | "creatorId"
  | "workspaceId"
  | "search";

export const getShowcaseSettingByKey = (key: ShowcaseSettingKey) => {
  const url = new URL(window.location.href);
  const settings = JSON.parse(url.searchParams.get("mode") || "{}");
  if (settings) return settings[key];
  return undefined;
};

export const updateModeParam = (
  history: RouterChildContext["router"]["history"],
  key: string,
  value: string,
) => {
  const url = new URL(window.location.href);
  const settings = JSON.parse(url.searchParams.get("mode") || "{}");

  const newParams = {
    ...settings,
    [key]: value,
  };

  url.searchParams.set("mode", JSON.stringify(newParams));
  history.push(url.pathname + url.search);
};

export const showAnotherCreator = (
  history: RouterChildContext["router"]["history"],
  creatorUuid: string,
) => {
  updateModeParam(history, "creatorId", creatorUuid);
};

export const getShowcaseDataUrl = () => {
  const context = getShowcaseSettingByKey("context");

  switch (context) {
    case CreatorShowcaseContext.CreatorDatabase:
      return API_URLS.getCreatorDatabaseReportData;

    case CreatorShowcaseContext.CreatorList:
      return API_URLS.getCampaignCreatorReportData;

    case CreatorShowcaseContext.CreatorDiscoveryList:
      return API_URLS.getCDTReportData;

    case CreatorShowcaseContext.CreatorDiscoverySearch:
      return API_URLS.getCDTSearchReportData;

    default:
      return API_URLS.getCampaignCreatorReportData;
  }
};

export const MAX_CREATORS_FOR_GENERATE_SHOWCASE = 150;
export const MIN_CREATORS_TO_ASYNC_GENERATING_REPORTS = 11;
export const ASYNC_GENERATING_CAMPAIGN_REPORT = true;
export const LEVEL_GZIP_REPORT = 9;
