import { addHeaderToLayout } from "src/app/ReportGenerator/functions/addHeaderToLayout";
import { addSpaceIfNeeded } from "./addSpaceIfNeeded";

export const addSentimentAnalysisToLayout = (
  newLayout,
  format,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.commentsAnalysis;

  Object.keys(campaigns).forEach((key) => {
    const {
      sentimentData: { sentimentValues },
      project: { title },
    } = campaigns[key];

    if (!sentimentValues) return;

    if (!aggregation && key !== "aggregated") {
      addHeaderToLayout(`sentiment-analysis-${title}`, newLayout, format, 3);
    }

    Object.entries(sentimentValues).forEach((item) => {
      const [key] = item;

      const lastEl = addSpaceIfNeeded("sentiment-analysis", newLayout, format);

      let x = 0;
      let y = 0;
      const w = 3;
      const h = 2;

      // first in row and half the width
      const spaceLeft = 12 - (lastEl.x + lastEl.w);

      if (spaceLeft >= 3) {
        x = lastEl.x + lastEl.w;
        y = lastEl.y;
      } else {
        x = 0;
        y = lastEl.y + lastEl.h;
      }

      const i = `sentiment-analysis-${key}`;
      newLayout.push({ i, x, y, w, h });
    });
  });
};
