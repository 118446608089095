/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./AggregatedSelect.scss";

import _ from "lodash";
import { uuidv7 } from "uuidv7";
import classNames from "classnames";

import { injectIntl, IntlShape, WrappedComponentProps } from "react-intl";
import { useScrollLock } from "src/app/methods/useScrollLock";
import { sleep } from "src/utils/methods";
import { SelectOption } from "src/types";
import useOnClickOutside from "../../methods/useOnClickOutside";

import { tableDataType } from "../Table/Table";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import DropdownPortal from "../DropdownPortal";
import SelectLabel from "./components/SelectLabel/SelectLabel";
import { AggregatedLabels } from "./components/AggregatedLabels/AggregatedLabels";
import { TooltipedEllipsis } from "../TooltipedEllipsis/TooltipedEllipsis";
import { MenuOverlay } from "./components/MenuOverlay/MenuOverlay";
import Pictogram, { PictogramVariants } from "../Pictogram/Pictogram";

interface SelectProps extends WrappedComponentProps<"intl"> {
  optionsData: any;
  uuid: string;
  fieldValue: any;
  updateValue?: any;
  emptyFieldPlaceholder?: React.ReactNode;
  dataType?: tableDataType;
  intl: IntlShape;
  readOnly?: boolean;
  singleLine?: boolean;
}

function AggregatedSelect({
  optionsData,
  uuid,
  fieldValue,
  updateValue,
  emptyFieldPlaceholder,
  dataType,
  readOnly,
  singleLine,
}: SelectProps) {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [multiValue, setMultiValue] = useState<any>();
  const [isOpen, setIsOpen] = useState<any>(false);

  const { lockScroll, unlockScroll } = useScrollLock();

  const dropdownRef = useRef(null);

  const updateMultiValueIfNeeded = () => {
    const newValue = multiValue?.map((el: { value: string }) => el.value);
    const currentValue = fieldValue || [];
    if (!_.isEqual(currentValue, newValue) && updateValue) {
      updateValue(newValue);
    }
  };

  useOnClickOutside(dropdownRef, () => {
    if (isOpen) {
      setIsOpen(false);
      unlockScroll();
      updateMultiValueIfNeeded();
    }
  });

  useEffect(() => {
    if (optionsData) {
      setOptions(optionsData);
    } else {
      setOptions([]);
    }
  }, [optionsData]);

  useEffect(() => {
    if (options.length) {
      const newValue: any = [];

      fieldValue?.forEach((item: string) => {
        const foundOption =
          options.find((el: { value: string }) => item === el.value) || [];

        if (foundOption) newValue.push(foundOption);
      });

      setMultiValue(newValue);
    }
  }, [fieldValue, options]);

  const handleLabelClick = () => {
    lockScroll();
    setIsOpen(true);
  };

  const removeFromValue = (index: number) => {
    const newValue = [...multiValue];
    newValue.splice(index, 1);
    setMultiValue(newValue);
  };

  const handleOptionClick = (item: SelectOption) => {
    const newValue = multiValue || [];
    setMultiValue([...newValue, item]);
  };

  const renderValue = () => {
    if (multiValue?.length > 0) {
      return (
        <AggregatedLabels
          multiValue={multiValue}
          setIsOpen={setIsOpen}
          emptyFieldPlaceholder={emptyFieldPlaceholder}
          handleLabelClick={handleLabelClick}
          readOnly={readOnly}
          singleLine={singleLine}
        />
      );
    }
    return (
      <SelectLabel
        value={{}}
        onClickHandler={handleLabelClick}
        emptyFieldPlaceholder={emptyFieldPlaceholder}
        readOnly={readOnly}
      />
    );
  };

  const renderMenuContent = () => (
    <>
      <span className="label-selector__menu-header">
        <IDHFormattedMessage id="ws_selected" defaultMessage="Selected" />
      </span>

      <SelectedLabels />

      <span className="label-selector__menu-header">
        <IDHFormattedMessage
          id="ws_select_from_the_list"
          defaultMessage="Select from the list"
        />
      </span>

      <div className="drag-n-drop-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className={classNames("options-list")}>
          {options
            ?.sort((a: any, b: any) => {
              return a.rank.localeCompare(b.rank);
            })
            ?.map((item: SelectOption) => {
              return (
                <div
                  data-value={item.name}
                  key={item.value}
                  className={classNames("option")}
                  onClick={() => handleOptionClick(item)}
                >
                  <span className="label-selector__label">
                    {item.icon ? (
                      <Pictogram
                        identifier={item.icon.name}
                        variant={
                          item.icon.variant
                            ? item.icon.variant
                            : PictogramVariants.Original
                        }
                      />
                    ) : null}
                    <TooltipedEllipsis content={item.name} maxWidth={210} />
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );

  const SelectedLabels = useCallback(() => {
    return multiValue?.length > 0 ? (
      <div className="label-selector__selected-labels">
        {multiValue?.map((item: any, index: number) => (
          <SelectLabel
            key={`${item.value}-${index}`}
            index={index}
            value={item}
            onClickHandler={handleLabelClick}
            emptyFieldPlaceholder={emptyFieldPlaceholder}
            rejectMulti={removeFromValue}
          />
        ))}
      </div>
    ) : null;
  }, [multiValue]);

  const getWidth = () => {
    if (multiValue?.length >= 2) return 200;
    return "auto";
  };

  return (
    <div
      ref={dropdownRef}
      id={uuid}
      className="label-selector__wrapper"
      style={{ width: getWidth() }}
    >
      <DropdownPortal
        className="label-selector__dropdown"
        overlay={<MenuOverlay>{renderMenuContent()}</MenuOverlay>}
        visible={isOpen}
      >
        {renderValue()}
      </DropdownPortal>
    </div>
  );
}

export default injectIntl(AggregatedSelect);
