import { SelectOption } from "src/types";
import { Column, MetaData, MetaDataValue, Row } from "./types";

export const removeRows = (list: Row[], uuids: string[]) => {
  return list.filter((listItem) => !uuids.includes(listItem.uuid));
};

export const updateRow = (list: Row[], newRow: Row) => {
  return list.map((listItem) => {
    if (listItem.uuid === newRow.uuid) {
      return newRow;
    }
    return listItem;
  });
};

export const updateList = (
  list: Row[],
  uuids: string[],
  metaFieldUuid: string,
  metaValue: MetaDataValue | null,
) => {
  const updatedList = list.map((listItem) => {
    if (!uuids.includes(listItem.uuid)) {
      return listItem;
    }

    // Case: metaValue is null, remove the metadata
    if (metaValue === null) {
      return {
        ...listItem,
        metadata: listItem.metadata.filter((m) => m.uuid !== metaFieldUuid),
      };
    }

    // Case: metaValue is not null
    const foundMetaDataItem = listItem.metadata.find(
      (m) => m.uuid === metaFieldUuid,
    );

    if (foundMetaDataItem) {
      return {
        ...listItem,
        metadata: listItem.metadata.map((m) => {
          if (m.uuid === metaFieldUuid) {
            return { ...m, value: metaValue };
          }
          return m;
        }),
      };
    }
    return {
      ...listItem,
      metadata: [
        ...listItem.metadata,
        { uuid: metaFieldUuid, value: metaValue },
      ],
    };
  });

  return updatedList;
};

export const updateColumns = (
  columns: Column[],
  metaFieldId: string,
  singleSelectOptions: SelectOption[],
) => {
  const updatedColumns = columns.map((colItem) =>
    colItem.uuid === metaFieldId
      ? { ...colItem, settings: { singleSelectOptions } }
      : colItem,
  );

  return updatedColumns;
};

export const addNewRows = (
  list: Row[],
  newRows: { uuid: string; name: string }[],
): any[] => {
  const listItemsLocal = [...list];

  newRows.forEach((newRow) => {
    listItemsLocal.push({
      metadata: [{ uuid: newRow.uuid, value: newRow.name }] as MetaData[],
      title: newRow.name,
      titleMetaFieldUuid: newRow.uuid,
      uuid: newRow.uuid,
    });
  });

  listItemsLocal.sort((a, b) =>
    a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase()),
  );

  return listItemsLocal;
};
