import React from "react";
import { CreatorBox } from "../components/ReportTemplate/components/CreatorBox/CreatorBox";

export const renderCreator = (data, GridBox, creators) => {
  const key = data.label;

  const creator = creators.find((item) => item.taskId === data.taskId);
  const creatorData = {
    ...data,
    ...creator,
  };

  return (
    <GridBox key={key} id={key} smallPadding>
      <CreatorBox data={creatorData} />
    </GridBox>
  );
};
