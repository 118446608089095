import React from "react";

import { LocationByCity } from "../../../../Task/tabs/ProfileDataTab/single/LocationByCity";

export const renderLocationByCity = (label, data, layout, GridBoxComponent) => {
  const {
    user_audience_data: { audienceCityReach },
  } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <LocationByCity audienceCityReach={audienceCityReach} height={height} />
    </GridBoxComponent>
  );
};
