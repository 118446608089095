import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { getReportSettingByKey } from "src/app/ReportGenerator/utils/reportFunctions";

export const DATA_AGGREGATION_SECTIONS = [
  {
    value: "creatorsInsights",
    label: (
      <IDHFormattedMessage
        id="ws_creator_insights"
        defaultMessage="Creator Insights"
      />
    ),
    type: "creator-insights",
  },
  {
    value: "creatorsInTheCampaign",
    label: (
      <IDHFormattedMessage
        id="ws_creator_in_the_campaign"
        defaultMessage="Creator in the Campaign"
      />
    ),
    type: "creator",
  },
  {
    value: "campaignAudienceData",
    label: (
      <IDHFormattedMessage
        id="ws_campaign_audience_data"
        defaultMessage="Campaign Audience Data"
      />
    ),
    type: "audience-data",
  },
  // {
  //   value: "audienceOverlap",
  //   label: (
  //     <IDHFormattedMessage
  //       id="ws_audience_overlap"
  //       defaultMessage="Audience Overlap"
  //     />
  //   ),
  // },
  // {
  //   value: "executiveSummary",
  //   label: (
  //     <IDHFormattedMessage
  //       id="ws_executive_summary"
  //       defaultMessage="Executive Summary"
  //     />
  //   ),
  //   type: "post-data",
  // },
  {
    value: "topPerformingPublications",
    label: (
      <IDHFormattedMessage
        id="ws_top_performing_publications"
        defaultMessage="Top Performing Publications"
      />
    ),
    type: "top-publication",
  },
  {
    value: "allPublications",
    label: (
      <IDHFormattedMessage
        id="ws_all_publications"
        defaultMessage="All Publications"
      />
    ),
  },
  {
    value: "summary",
    label: <IDHFormattedMessage id="ws_summary" defaultMessage="Summary" />,
  },
  {
    value: "commentsAnalysis",
    label: (
      <IDHFormattedMessage
        id="ws_comments_analysis"
        defaultMessage="Comments analysis"
      />
    ),
  },
];

export const canAggregate = (defaultValue) => {
  const campaignsCount = getReportSettingByKey("ids")?.length;

  if (campaignsCount <= 1) {
    return false;
  }

  return defaultValue;
};

export const getAggregatedOrFirstByKey = (campaigns, key) => {
  const campaignsCount = Object.keys(campaigns)?.length;

  if (campaignsCount === 0) return [];

  if (campaignsCount <= 1) {
    return Object.values(campaigns)[0][key];
  }

  return campaigns.aggregated[key];
};
