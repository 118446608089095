import { capitalizeFirstLetter, stripEmojis } from "../../../../utils/methods";
import { addHeaderToArray } from "../addHeaderToArray";

export const REPORT_ADDITIONAL_PUBLICATION_METAFIELDS = [
  "currency",
  "number",
  "percent",
];

export const addTopPerformingPublicationsToGridElements = (
  formatMessage,
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.topPerformingPublications;

  Object.keys(campaigns).forEach((key) => {
    const campaign = campaigns[key];

    const {
      publications,
      project: { title },
    } = campaign;

    const publicationTopMetadata = publications[0]?.metadata.filter((item) =>
      REPORT_ADDITIONAL_PUBLICATION_METAFIELDS.includes(item.type),
    );

    if (key !== "aggregated") {
      addHeaderToArray(
        `Top Performing Publications - ${title}`,
        newHiddenGridElements,
        2,
        `top-publication-${title}`,
        savedTemplate,
        templateApplied,
      );
    }

    publicationTopMetadata?.forEach((item) => {
      addHeaderToArray(
        `Top Performing Publications: ${capitalizeFirstLetter(item.name)}`,
        newHiddenGridElements,
        aggregation ? 3 : 2,
        `top-publication-${item.uuid}`,
        savedTemplate,
        templateApplied,
      );

      publications
        .sort((a, b) => {
          const valueA = a?.metadata.find(
            (pubA) => pubA.uuid === item.uuid,
          )?.value;
          const valueB = b?.metadata.find(
            (pubB) => pubB.uuid === item.uuid,
          )?.value;
          return valueB - valueA;
        })
        .slice(0, 6)
        .filter(
          (publication) =>
            publication.metadata.find((field) => field.uuid === item.uuid)
              ?.value,
        )
        .forEach((publication) => {
          const label = `top-publication-${item.uuid}-${stripEmojis(
            publication.title,
          )
            .slice(0, 15)
            .replace(/(\r\n|\n|\r)/gm, "")
            .replaceAll(" ", "-")
            .replaceAll(".", "")
            .replaceAll("/", "")
            .replaceAll("?", "")
            .toLowerCase()}-${publication.taskId}`;

          const element = {
            ...publication,
            label,
            gridBoxType: `top-publication`,
            section: `top-publication-${item.uuid}`,
            campaignId: `${key}-${item.uuid}`,
          };

          if (
            (!aggregation && key === "aggregated") ||
            (aggregation && key !== "aggregated")
          ) {
            newHiddenGridElements.push(element);
          } else if (savedTemplate?.length && !templateApplied) {
            if (savedTemplate.find((item) => item.i === element.label)) {
              newGridElements.push(element);
            } else {
              newHiddenGridElements.push(element);
            }
          }
          // applying default template
          else {
            newHiddenGridElements.push(element);
          }
        });
    });
  });
};
