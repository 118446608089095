import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
  ReactNode,
} from "react";
import classNames from "classnames";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  RichUtils,
  RawDraftInlineStyleRange,
  EditorCommand,
  RawDraftContentState,
  RawDraftContentBlock,
} from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "@draft-js-plugins/mention";
import createEmojiPlugin from "@draft-js-plugins/emoji";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import { useSelector } from "react-redux";

import useOnClickOutside from "src/app/methods/useOnClickOutside";
import { RootState } from "src/redux/reducers";
import { ReactComponent as AtSign } from "../../../images/at-sign.svg";
import { ReactComponent as EmojiIcon } from "../../../images/emoji.svg";
import { extractEmails, setCaretPosition } from "../../../utils/methods";
import multiByteSlice from "../../../utils/multibyteSlice";

import "draft-js/dist/Draft.css";
import "./TextBox.scss";
import mentionsStyles from "./utils/MentionStyles.module.scss";
import emojiStyles from "./utils/EmojiStyles.module.scss";

interface EntityRanges {
  type: string;
  key: number;
  offset: number;
  length: number;
}

interface Props {
  id?: string;
  className?: string;
  value?: string;
  placeholder?: string;
  onFocus?: () => void;
  onClick?: () => void;
  onBlur?: () => void;
  setEncodedValue?: (value: string) => void;
  showMentionList?: boolean;
  setShowMentionList?: Dispatch<SetStateAction<boolean>>;
  addAttachmentIcon?: ReactNode;
  emojisToBottom?: boolean;
  showButtons?: boolean;
  enableMentions?: boolean;
  handleUseOnClickOutside?: () => void;
  readOnly?: boolean;
  editorHeaderChildren?: ReactNode;
  editorBottomChildren?: ReactNode;
  bottomLeftButton?: ReactNode;
  bottomRightButton?: ReactNode;
  enableEmoji?: boolean;
  shouldDisableParentElementFocus?: boolean;
}

const changeFontWeight = (str: string) => {
  const replacedStr = str.replace(/font-weight:\s*500/g, "font-weight: 400");
  return replacedStr;
};

export const TextBox = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const {
    id,
    className,
    value,
    placeholder,
    onFocus,
    onClick,
    onBlur,
    setEncodedValue,
    showMentionList,
    setShowMentionList,
    emojisToBottom,
    showButtons,
    enableMentions,
    handleUseOnClickOutside,
    readOnly,
    editorHeaderChildren,
    editorBottomChildren,
    bottomLeftButton,
    bottomRightButton,
    enableEmoji,
    shouldDisableParentElementFocus,
  } = props;

  const innerRef = useRef<Editor | null>(null);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromText(typeof value === "string" ? value : ""),
    ),
  );
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [applyValue, setApplyValue] = useState(false);

  // @ts-ignore
  useOnClickOutside(ref, () => {
    if (handleUseOnClickOutside) {
      handleUseOnClickOutside();
      if (innerRef.current) {
        innerRef.current.blur();
      }
    }
  });

  const [mentionPlugin] = useState(
    createMentionPlugin({
      entityMutability: "IMMUTABLE",
      theme: mentionsStyles,
      mentionPrefix: "@",
      mentionTrigger: "@",
      supportWhitespace: true,
    }),
  );
  const [emojiPlugin] = useState(
    createEmojiPlugin({
      theme: emojiStyles,
      // useNativeArt: true,
    }),
  );
  const [linkifyPlugin] = useState(
    createLinkifyPlugin({
      component(props) {
        const goToUrl = () => {
          window.open(props.href, "_blank", "noreferrer, noopener");
        };

        return (
          <a
            {...props}
            className="text-box__hyperlink"
            rel="noopener noreferrer nofollow"
            target="_blank"
            onClick={goToUrl}
          />
        );
      },
    }),
  );

  const plugins = [
    ...(enableMentions ? [mentionPlugin] : []),
    ...(enableEmoji ? [emojiPlugin] : []),
    linkifyPlugin,
  ];

  const { membersList } = useSelector(
    (state: RootState) => state.projectReducer,
  );

  const mentions =
    membersList
      ?.filter((member) => {
        return member.status !== "removed";
      })
      .map((member) => {
        return {
          id: member.id,
          name: member.name,
          email: member.email,
          avatar: member.avatarUrl,
        };
      }) || [];

  const getMentionsWithPositions = (content: string): RawDraftContentState => {
    const defaultState = {
      blocks: [
        {
          key: "ddik6",
          text: content,
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    };

    if (!content.length) return defaultState;

    const lines = content?.split("\n");
    const blocks: Array<RawDraftContentBlock> = [];
    let entityMap = {};

    lines.forEach((line, idx) => {
      // MENTIONS
      const matches = line.match(/\[([a-zA-Z0-9:-]*?)\]/g);
      const entityRanges: Array<EntityRanges> = [];
      const inlineStyleRanges: Array<RawDraftInlineStyleRange> = [];
      let text = line;

      if (matches !== null && matches?.length > 0 && membersList?.length > 0) {
        matches.forEach((key, index) => {
          const uuid = key.match(
            /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g,
          );
          let lengthDiff = 0;

          const emojiParts = text?.split(key);
          const emojis =
            emojiParts[0].match(/\p{Extended_Pictographic}/gu) || [];

          emojis.forEach((emoji: any) => {
            lengthDiff += [...emoji].length;
          });

          if (uuid) {
            const wsMemberUuid = uuid[0];

            const member = membersList.find((member) => {
              return member.id === wsMemberUuid;
            });

            if (member) {
              if (text.includes(key)) {
                const i = text.indexOf(key);

                entityRanges.push({
                  type: "mention",
                  key: index,
                  offset: i - lengthDiff,
                  length: member.name.length + 1,
                });
                lengthDiff += Math.abs(key.length - member.name.length);
              }

              entityMap = {
                ...entityMap,
                [index]: {
                  type: "mention",
                  mutability: "SEGMENTED",
                  data: {
                    mention: {
                      id: member.id,
                      name: member.name,
                      email: member.email,
                      avatar: member.avatarUrl,
                    },
                  },
                },
              };

              text = text.replace(key, `@${member.name}`);
            }
          }
        });
      }

      // BOLD
      const matchesBold = line.match(/<b>(.*?)<\/b>/g);

      if (matchesBold !== null && matchesBold?.length > 0) {
        matchesBold.forEach((key) => {
          let lengthDiff = 0;

          if (text.includes(key)) {
            const i = text.indexOf(key);
            inlineStyleRanges.push({
              style: "BOLD",
              offset: i - lengthDiff,
              length: key.length - 7,
            });
            lengthDiff += Math.abs(key.length - key.length - 7);
          }
          text = text.replace("<b>", "").replace("</b>", "");
        });
      }

      // ITALIC
      const matchesItalic = line.match(/<i>(.*?)<\/i>/g);

      if (matchesItalic !== null && matchesItalic?.length > 0) {
        matchesItalic.forEach((key) => {
          let lengthDiff = 0;

          if (text.includes(key)) {
            const i = text.indexOf(key);
            inlineStyleRanges.push({
              style: "ITALIC",
              offset: i - lengthDiff,
              length: key.length - 7,
            });
            lengthDiff += Math.abs(key.length - key.length - 7);
          }
          text = text.replace("<i>", "").replace("</i>", "");
        });
      }

      blocks.push({
        key: `bis6${idx}`,
        text: text || "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges,
        entityRanges,
        data: {},
      });
    });

    return { blocks, entityMap };
  };

  useEffect(() => {
    setApplyValue(true);
  }, []);

  useEffect(() => {
    if (editorState.getDecorator()) {
      handleChange(
        EditorState.createWithContent(
          convertFromRaw(getMentionsWithPositions(value ?? "")),
        ),
      );
    }
  }, [value, applyValue]);

  const handleChange = useCallback((_editorState) => {
    if (setEncodedValue) {
      const encodedValue = getTextWithEncodedMentions(_editorState);
      setEncodedValue(encodedValue);
    }

    setEditorState(_editorState);
  }, []);

  const handleOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  useEffect(() => {
    if (setShowMentionList) {
      handleOpenChange(true);
    }
  }, [showMentionList]);

  useEffect(() => {
    handleOpenChange(showMentionList);
  }, [showMentionList]);

  const handleSearchChange = useCallback(({ value }) => {
    setSuggestions(defaultSuggestionsFilter(value, mentions));
  }, []);

  const handleKeyCommand = (command: EditorCommand, state: EditorState) => {
    const newState = RichUtils.handleKeyCommand(state, command);
    if (newState) {
      handleChange(newState);
      return "handled";
    }

    return "not-handled";
  };

  const getStyledString = (
    text: string,
    range: RawDraftInlineStyleRange,
    shift: number,
    tag: "i" | "b",
  ) => {
    const textBefore = multiByteSlice(text, 0, range.offset + shift);
    const textToStyle = multiByteSlice(
      text,
      range.offset + shift,
      range.offset + range.length + shift,
    );
    const textAfter = multiByteSlice(
      text,
      range.offset + range.length + shift,
      text.length,
    );

    return `${textBefore}<${tag}>${textToStyle}</${tag}>${textAfter}`;
  };

  const getTextWithEncodedMentions = (value: EditorState) => {
    const { blocks } = convertToRaw(value.getCurrentContent());
    const { entityMap } = convertToRaw(value.getCurrentContent());

    return blocks
      .map((block) => {
        const { entityRanges, inlineStyleRanges, text } = block;

        let newText = text;
        let shift = 0;

        entityRanges.forEach((range) => {
          const object = entityMap[range.key];
          if (object.type === "mention") {
            const memberUuid = `[wsMember:${object.data.mention.id}]`;
            const mentionText = `@${object.data.mention.name}`;
            newText = newText.replace(mentionText, memberUuid);
            shift += memberUuid.length - mentionText.length;
          }
        });

        const TAG_LENGTH = 7;
        inlineStyleRanges.forEach((range) => {
          if (range.style === "BOLD") {
            newText = getStyledString(newText, range, shift, "b");
            shift += TAG_LENGTH;
          } else if (range.style === "ITALIC") {
            newText = getStyledString(newText, range, shift, "i");
            shift += TAG_LENGTH;
          }
        });

        return newText;
      })
      .join("\n");
  };

  const getAnchorPosition = () => {
    const currentBlockKey = editorState.getSelection().getStartKey();
    const offset = editorState.getSelection().getAnchorOffset();
    const key = editorState
      .getCurrentContent()
      .getBlockMap()
      .keySeq()
      .findIndex((k) => k === currentBlockKey);

    return {
      offset,
      key,
    };
  };

  function split(str: string, index: number) {
    const result = [str.slice(0, index), str.slice(index)];

    return result;
  }

  const addAtSignToValue = async () => {
    const anchor = getAnchorPosition();
    const { offset } = anchor;
    const { key } = anchor;
    let blockKey = null;
    let atCount = 0;
    let multilinePosition = 0;
    let offsetDiff = 0;

    const { blocks } = convertToRaw(editorState.getCurrentContent());
    const { entityMap } = convertToRaw(editorState.getCurrentContent());

    for (let i = 0; i < key; i++) {
      multilinePosition += blocks[i].text.length;
    }
    multilinePosition += offset;

    const focusedBlock = blocks[key];
    const dividedText = split(focusedBlock.text, offset);
    let newText = "";

    const firstChar = dividedText[0][0];
    const lastChar = dividedText[0][dividedText[0].length - 1];

    if (lastChar === "@") {
      newText = focusedBlock.text;
    } else if (lastChar && lastChar !== " ") {
      newText = `${dividedText[0]} @${dividedText[1]}`;
      offsetDiff = 2;
    } else {
      newText = `${dividedText[0]}@${dividedText[1]}`;
      offsetDiff = 1;
    }

    if (lastChar !== "@") {
      atCount = dividedText[0]?.split("@").length;

      const emailsCount = extractEmails(dividedText[0])?.length;
      if (emailsCount) {
        atCount -= emailsCount;
      }
    }

    if (firstChar === "@") {
      atCount -= 1;
    }

    const newBlocks = blocks.map((item) => {
      if (focusedBlock.key === item.key) {
        blockKey = item.key;

        const newEntityRanges = item.entityRanges.map((range) => {
          if (range.offset > offset) {
            return {
              ...range,
              offset: range.offset + offsetDiff,
            };
          }
          return range;
        });

        atCount += newEntityRanges.length;

        return {
          ...item,
          text: newText,
          entityRanges: newEntityRanges,
        };
      }
      return item;
    });

    const newState = EditorState.createWithContent(
      convertFromRaw({
        blocks: newBlocks,
        entityMap,
      }),
    );

    await handleChange(newState);

    if (blockKey && atCount) {
      const blockContainer = document.querySelector(
        `[data-offset-key="${blockKey}-${atCount}-0"]`,
      );

      if (blockContainer) {
        setCaretPosition(blockContainer, 1);
      } else {
        if (innerRef.current) {
          innerRef.current.focus();
        }
      }
    }
  };

  const formatPastedText = (text: string, html: string | undefined) => {
    // Draft js treats font-weight 500 as bold text, this is to prevent this behaviour.
    let newHTML;
    if (html) {
      newHTML = changeFontWeight(html);
    }
    return { text, html: newHTML };
  };

  const { MentionSuggestions } = mentionPlugin;
  const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

  return (
    <div
      id={id}
      className={classNames(
        "text-box",
        {
          "text-box--emojis-to-bottom": emojisToBottom,
          "text-box--readOnly": readOnly,
        },
        className,
      )}
      onClick={() => {
        if (!shouldDisableParentElementFocus && innerRef.current) {
          innerRef.current.focus();
        }

        if (onClick) {
          onClick();
        }
      }}
    >
      <textarea
        className="event-trigger"
        ref={ref}
        onFocus={() => {
          if (innerRef.current) {
            innerRef.current.focus();
          }
        }}
      />

      {editorHeaderChildren}

      <Editor
        ref={innerRef}
        editorKey="editor"
        editorState={editorState}
        handleKeyCommand={handleKeyCommand}
        onChange={handleChange}
        plugins={plugins}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        formatPastedText={formatPastedText}
        readOnly={readOnly}
      />

      <EmojiSuggestions />

      {editorBottomChildren}

      {showButtons && (
        <div className="text-box__buttons">
          <div className="text-box__buttons-left">
            {enableMentions && (
              <>
                <AtSign
                  onClick={(e) => {
                    e.stopPropagation();
                    addAtSignToValue();
                  }}
                />
                <MentionSuggestions
                  open={open}
                  onOpenChange={handleOpenChange}
                  suggestions={suggestions}
                  onSearchChange={handleSearchChange}
                />
              </>
            )}

            {bottomLeftButton}

            <div className="text-box__buttons-left-emoji">
              <EmojiIcon />
              <EmojiSelect closeOnEmojiSelect />
            </div>
          </div>
          <div className="text-box__buttons-right">{bottomRightButton}</div>
        </div>
      )}
    </div>
  );
});
