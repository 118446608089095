import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { RootState } from "src/redux/reducers";
import {
  ExtensionTypeEnum,
  WsApiConnection,
  WsExtension,
  WsExtensionCreatorDatabaseMetaFieldList,
  WsExtensionCreatorDatabaseSubmissionForm,
} from "src/types";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import ExtensionsList from "../Extensions/components/ExtensionsList/ExtensionsList";
import RecruitmentForm from "../Extensions/components/RecruitmentForm/RecruitmentForm";
import Loader from "../../../components/Loader/Loader";
import {
  getWorkspaceExtensionList,
  setWorkspaceExtensionList,
} from "../../../../redux";
import { GlobalMetaFieldList } from "../Extensions/components/MetaFieldMembers/GlobalMetaFieldList";
import GlobalMetaFieldMembers from "../Extensions/components/MetaFieldMembers/GlobalMetaFieldDetails/GlobalMetaFieldList/GlobalMetaFieldMembers";
import {
  ChangeSettingsSectionParam,
  changeSettingsSection,
  findExtensionByExtensionType,
} from "../../methods";
import InstagramAccountVerification from "../Extensions/components/InstagramAccountVerification/InstagramAccountVerification";
import { ContextType, SettingsTab } from "../../enums";

import "./CreatorDatabaseConfiguration.scss";

interface CreatorDatabaseConfigurationProps {
  onClose: () => void;
  contextData: any;
}

export enum CreatorDatabaseConfigurationSection {
  ExtensionsList = "ExtensionsList",
  SubmissionForm = "SubmissionForm",
  MetaFieldList = "MetaFieldList",
  MetaFieldMembers = "MetaFieldMembers",
  InstagramAccountVerification = "InstagramAccountVerification",
}

const baseInitialData = {
  enabled: false,
  uuid: "",
  relatedUuid: null,
  wsWorkspaceUuid: null,
  wsProjectUuid: null,
};

const instagramAccountVerificationInitialData = {
  ...baseInitialData,
  settings: {
    brandName: "",
    encodedData: "",
    token: "",
  },
  type: ExtensionTypeEnum.ApiConnection,
} satisfies WsApiConnection;

const creatorDatabaseSubmissionFormInitialData: WsExtensionCreatorDatabaseSubmissionForm =
  {
    ...baseInitialData,
    type: ExtensionTypeEnum.CreatorDatabaseSubmissionForm,
    settings: {
      token: "",
      wsGlobalMetaFieldUuid: "",
    },
  };

const creatorDatabaseMetaFieldListInitialData: WsExtensionCreatorDatabaseMetaFieldList =
  {
    ...baseInitialData,
    type: ExtensionTypeEnum.CreatorDatabaseMetaFieldList,
  };

export function CreatorDatabaseConfiguration({
  onClose,
  contextData,
}: CreatorDatabaseConfigurationProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    mainReducer: { settingsModalData },
    extensionReducer: {
      workspaceExtensionList,
      isWorkspaceExtensionListLoading,
    },
  } = useSelector((state: RootState) => state);

  const { section: CurrentCreatorDatabaseConfigurationSection } =
    settingsModalData;

  const setExtensionsSection = (section: ChangeSettingsSectionParam) => {
    changeSettingsSection(history, section);
  };

  useEffect(() => {
    const initialExtensionsList: Array<WsExtension> = [];

    const hasCreatorDatabaseSubmissionForm = workspaceExtensionList.some(
      (extension) =>
        extension.type === ExtensionTypeEnum.CreatorDatabaseSubmissionForm,
    );

    if (!hasCreatorDatabaseSubmissionForm) {
      initialExtensionsList.push(creatorDatabaseSubmissionFormInitialData);
    }

    const hasInstagramVerification = workspaceExtensionList.some(
      (extension) => extension.type === ExtensionTypeEnum.ApiConnection,
    );

    if (!hasInstagramVerification) {
      initialExtensionsList.push(instagramAccountVerificationInitialData);
    }

    if (initialExtensionsList.length > 0) {
      dispatch(
        setWorkspaceExtensionList([
          ...workspaceExtensionList,
          ...initialExtensionsList,
        ]),
      );
    }
  }, [workspaceExtensionList]);

  useEffect(() => {
    if (typeof contextData?.uuid !== "string") return;
    dispatch(getWorkspaceExtensionList(contextData.uuid));
  }, [contextData?.uuid]);

  const renderComponent = () => {
    switch (CurrentCreatorDatabaseConfigurationSection) {
      case CreatorDatabaseConfigurationSection.ExtensionsList:
        return (
          <ExtensionsList
            title={
              <IDHFormattedMessage
                id="ws_creator_database"
                defaultMessage="Creator Database"
              />
            }
            currentSettingsTab={SettingsTab.CreatorDatabaseConfiguration}
            onClose={onClose}
            setExtensionsSection={setExtensionsSection}
            extensionList={[
              creatorDatabaseMetaFieldListInitialData,
              ...workspaceExtensionList,
            ]}
            permissions={contextData?.permissions}
            wsProjectUuid={null}
            isProjectExtensions={
              contextData ? contextData.type === ContextType.wsProject : false
            }
          />
        );
      case CreatorDatabaseConfigurationSection.SubmissionForm: {
        const submissionFormItem = workspaceExtensionList.find(
          (extension) =>
            extension.type === ExtensionTypeEnum.CreatorDatabaseSubmissionForm,
        );

        if (!submissionFormItem) return null;

        return (
          <RecruitmentForm
            onClose={onClose}
            setExtensionsSection={setExtensionsSection}
            contextData={contextData}
            recruitmentListItem={submissionFormItem}
            extensionType={ExtensionTypeEnum.CreatorDatabaseSubmissionForm}
          />
        );
      }
      case CreatorDatabaseConfigurationSection.MetaFieldList:
        return (
          <GlobalMetaFieldList
            onClose={onClose}
            setExtensionsSection={setExtensionsSection}
            contextData={contextData}
          />
        );
      case CreatorDatabaseConfigurationSection.MetaFieldMembers:
        return (
          <GlobalMetaFieldMembers
            onClose={onClose}
            setExtensionsSection={setExtensionsSection}
          />
        );
      case CreatorDatabaseConfigurationSection.InstagramAccountVerification: {
        const instagramAccountVerificationItem = findExtensionByExtensionType(
          workspaceExtensionList,
          ExtensionTypeEnum.ApiConnection,
        );

        if (
          !instagramAccountVerificationItem ||
          instagramAccountVerificationItem.type !==
            ExtensionTypeEnum.ApiConnection
        )
          return null;
        return (
          <InstagramAccountVerification
            data={instagramAccountVerificationItem}
            setExtensionsSection={setExtensionsSection}
            onClose={onClose}
            contextData={contextData}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className="extensions">
      {isWorkspaceExtensionListLoading ? <Loader /> : renderComponent()}
    </div>
  );
}
