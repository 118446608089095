export const sortLayout = (layout) => {
  return layout.sort((a, b) => {
    const endYA = a.y + a.h;
    const endYB = b.y + b.h;

    if (
      a.y === b.y ||
      (a.y >= b.y && endYA <= endYB) ||
      (b.y > a.y && endYB < endYA)
    ) {
      return a.x - b.x;
    }

    return a.y - b.y;
  });
};
