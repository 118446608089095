import React, { createContext, useMemo, useState } from "react";
import "./DictionaryFilters.scss";
import { uuidv7 as uuid } from "uuidv7";

import { ReactComponent as FilterIcon } from "src/images/filter.svg";

import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import {
  PaneTopbar,
  PaneTopbarLeft,
  PaneTopbarToolbar,
} from "src/app/components/Pane/PaneTopbar/PaneTopbar";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { SidebarItemType } from "src/app/Sidebar/SidebarItems";
import { useIntl } from "react-intl";
import {
  setDictionaryFilter,
  setDictionaryFilters,
} from "./redux/dictionaryFiltersActions";
import SingleFilter from "./components/SingleFilter/SingleFilter";
import { Pane } from "../Pane/Pane";
import {
  defaultDictionaryFilters,
  getDefaultCreatorDatabaseFilters,
  getDefaultDictionaryFilters,
  getDefaultProviderFilters,
} from "./defaultDictionaryFilters";
import { FiltersContext } from "./dictionaryFiltersData";

export const DictionaryFiltersContext = createContext();

export function DictionaryFilters({
  context,
  dictionaryFilters,
  dispatchDictionaryFilters,
  filtersVisible,
  setFiltersVisible,
  columns,
  hiddenColumns = [],
  selectedWsTableViewUuid = null,
}) {
  const { filters } = dictionaryFilters;
  const [renderKey, setRenderKey] = useState(uuid());

  const identity = useSelector((state) => state.mainReducer.identity);

  const params = useParams();

  const intl = useIntl();

  const foundDictionarySidebarItem = identity?.sidebarItems?.find(
    (item) =>
      item.type === SidebarItemType.Dictionary &&
      item.wsDictionaryUuid === params.dictionaryUuid,
  );

  const clearFilters = () => {
    dispatchDictionaryFilters(setDictionaryFilters([]));
    setRenderKey(uuid());
  };

  const sendFilter = (fieldUuid, value) => {
    const saveInLocalStorage = selectedWsTableViewUuid === null;

    dispatchDictionaryFilters(
      setDictionaryFilter(fieldUuid, value, saveInLocalStorage),
    );
  };

  const getDefaultFilters = () => {
    switch (context) {
      case FiltersContext.CreatorDatabase:
        return getDefaultCreatorDatabaseFilters(intl);

      case FiltersContext.Dictionary:
        return getDefaultDictionaryFilters(
          foundDictionarySidebarItem.wsDictionaryElementName,
        );

      default:
        return getDefaultCreatorDatabaseFilters(intl);
    }
  };

  const allFilters = [...getDefaultFilters(), ...columns];

  return (
    <DictionaryFiltersContext.Provider
      value={{ dictionaryFilters, dispatchDictionaryFilters }}
    >
      <Pane
        showPane={filtersVisible}
        hidePane={() => setFiltersVisible(false)}
        width={309}
      >
        <div className="dictionary-filters" key={renderKey}>
          <PaneTopbar>
            <PaneTopbarLeft>
              <FilterIcon className="filters-header__icon" />
              <span className="filters-header__title">
                <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
              </span>
            </PaneTopbarLeft>
            <PaneTopbarToolbar hidePane={() => setFiltersVisible(false)} />
          </PaneTopbar>

          <div className="pane__container filters">
            {allFilters.length === 0 && (
              <div className="filter-box-empty">
                <IDHFormattedMessage
                  id="ws_no_data_to_display"
                  defaultMessage="No data to display"
                />
              </div>
            )}
            <div className="projects-table-filters__fields-wrap">
              {allFilters?.map(
                (c) =>
                  !hiddenColumns.includes(c.type) && (
                    <SingleFilter
                      col={c}
                      sendFilter={sendFilter}
                      defaultValue={filters ? filters[c.uuid]?.value : null}
                      key={`${renderKey}-${c.uuid}`}
                    />
                  ),
              )}
            </div>
          </div>
        </div>
        <Button
          variant="light"
          size="medium"
          className="project-filters-button"
          onClick={clearFilters}
        >
          <IDHFormattedMessage
            id="ws_clear_filters"
            defaultMessage="Clear filters"
          />
        </Button>
      </Pane>
    </DictionaryFiltersContext.Provider>
  );
}
