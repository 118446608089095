import React from "react";
import "./IDHAALogo.scss";

import { ReactComponent as LogoIcon } from "src/images/idh-aa-logo.svg";

interface Props {
  size: number;
  customLogo?: string | undefined;
}

export function IDHAALogo(props: Props) {
  const { size, customLogo } = props;

  return (
    <span className="idh-aa-logo" style={{ width: size, height: size }}>
      {customLogo ? <img src={customLogo} alt="custom-logo" /> : <LogoIcon />}
    </span>
  );
}
