import { RouterChildContext } from "react-router";
import _ from "lodash";
import { API_URLS } from "src/utils/API_URLS";
import { AppConfig } from "src/config/AppConfig";

export enum ReportGeneratorContext {
  Campaign = "campaign",
  CampaignList = "campaign-list",
}

export interface OpenReportGeneratorProps {
  history: RouterChildContext["router"]["history"];
  context: ReportGeneratorContext;
  ids: string[];
  workspaceId: string;
  search?: string;
}

export const openReportGenerator = (props: OpenReportGeneratorProps) => {
  const { history, ...rest } = props;

  const url = new URL(`${AppConfig.getAppRoute()}/report-generator`);

  url.searchParams.set("mode", JSON.stringify(rest));

  window.location.href = url.href;
};

export const getReportGeneratorSettings = () => {
  const url = new URL(window.location.href);
  const settings = JSON.parse(url.searchParams.get("mode") || "{}");
  return settings || {};
};

type ShowcaseSettingKey = "ids" | "context" | "workspaceId" | "search";

export const getReportSettingByKey = (key: ShowcaseSettingKey) => {
  const url = new URL(window.location.href);
  const settings = JSON.parse(url.searchParams.get("mode") || "{}");
  if (settings) return settings[key];
  return undefined;
};

export const updateModeParam = (
  history: RouterChildContext["router"]["history"],
  key: string,
  value: string,
) => {
  const url = new URL(window.location.href);
  const settings = JSON.parse(url.searchParams.get("mode") || "{}");

  const newParams = {
    ...settings,
    [key]: value,
  };

  url.searchParams.set("mode", JSON.stringify(newParams));
  history.push(url.pathname + url.search);
};

export const getShowcaseDataUrl = () => {
  // const context = getShowcaseSettingByKey("context");

  // switch (context) {
  // default:
  return API_URLS.getCampaignCreatorReportData;
  // }
};

export const MAX_CREATORS_FOR_GENERATE_SHOWCASE = 150;
export const MIN_CREATORS_TO_ASYNC_GENERATING_REPORTS = 11;
export const ASYNC_GENERATING_CAMPAIGN_REPORT = true;
export const LEVEL_GZIP_REPORT = 9;
