import React, { useEffect, useMemo, useReducer, useState } from "react";

import { useHistory, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import {
  getDictionaryUuidFromCurrentUrl,
  isStringUuid,
  customEqual,
} from "src/utils/methods";
import WebsocketListener from "src/WebsocketListener";
import { ReactComponent as PlusIcon } from "src/images/plus-transparent.svg";
import NoResultsScreen from "src/app/components/NoResultsScreen/NoResultsScreen";
import noResultsImg from "src/images/create-product.svg";
import noFilteredResultsImg from "src/images/empty-folder-group.svg";
import { ReactComponent as PlusSignIcon } from "src/images/plus-white.svg";
import { SidebarItemType } from "src/app/Sidebar/SidebarItems";
import { getDictionaryList, clearDictionaryList } from "src/redux";
import { ROUTES, getRoute } from "src/utils/ROUTES";
import moment from "moment";
import { uuidv7 } from "uuidv7";
import { openDictionaryDetails } from "../components/DictionaryDetails/methods";
import { useQuery } from "../methods/useQuery";
import DictionaryDetails from "../components/DictionaryDetails/DictionaryDetails";
import { Button } from "../components/Button/Button";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import DictionarySortDropdown from "../components/DictionarySortDropdown/DictionarySortDropdown";
import { AddNewDictionaryElementModal } from "./components/AddNewDictionaryElementModal/AddNewDictionaryElementModal";
import { ReactComponent as FilterIcon } from "../../images/filter.svg";
import dictionaryFiltersReducer, {
  dictionaryFiltersReducerInitialState,
} from "./redux/dictionaryFiltersReducer";
import {
  setDictionaryFilters,
  resetDictionaryFiltersReducer,
  setDictionaryInitialColumnRanks,
  resetHiddenColumns,
  setSelectedTableView,
  setDictionarySort,
  setDictionarySortBy,
  toggleDictionaryColumnVisibility,
  setDictionaryFilter,
  setHiddenDictionaryColumnIds,
} from "../components/DictionaryFilters/redux/dictionaryFiltersActions";
import { DictionaryFilters } from "../components/DictionaryFilters/DictionaryFilters";
import GlideDataGridWrapper from "../components/GlideDataGrid/GlideDataGridWrapper";
import MetaDataOptionsDropdown, {
  MetaDataOptionsContext,
} from "../components/TableOptionsDropdown/MetaDataOptionsDropdown";
import {
  generateLackingRanks,
  generateMetadataRanks,
} from "../CreatorDatabase/redux/creatorDatabaseFiltersReducer";
import { tableDataType } from "../components/Table/Table";
import { FiltersContext } from "../components/DictionaryFilters/dictionaryFiltersData";
import TableViewSelector from "../TableView/TableViewSelector";
import { createDictionaryTableView } from "../TableView/tableViewFunctions";
import { SaveTableViewModal } from "../TableView/components/SaveTableViewModal/SaveTableViewModal";

export default function Dictionary() {
  const activeWorkspaceUuid = useSelector(
    (state) => state.mainReducer.activeWorkspaceUuid,
  );
  const identity = useSelector(
    (state) => state.mainReducer.identity,
    customEqual,
  );
  const dictionaryList = useSelector(
    (state) => state.dictionaryReducer.dictionaryList,
    customEqual,
  );
  const isDictionaryListLoading = useSelector(
    (state) => state.dictionaryReducer.isDictionaryListLoading,
  );
  const { wsTableViews } = dictionaryList;

  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const showDiscoveryDetails = isStringUuid(
    query.get("displayDictionaryPreview") || "",
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [dictionaryFilters, dispatchDF] = useReducer(
    dictionaryFiltersReducer,
    dictionaryFiltersReducerInitialState(dictionaryList.columns),
  );
  const [elementToScrollUuid, setElementToScrollUuid] = useState(null);
  const [initialRankGenerationDone, setInitialRankGenerationDone] =
    useState(false);
  const [showSaveTableViewModal, setShowSaveTableViewModal] = useState(false);
  const [newTableViewName, setNewTableViewName] = useState("");

  const {
    filters,
    hiddenDictionaryColumnIds,
    dictionaryColumnRanks,
    selectedTableView = {
      label: "Custom",
      value: null,
    },
  } = dictionaryFilters;

  const params = useParams();

  const foundDictionarySidebarItem = identity?.sidebarItems?.find(
    (item) =>
      item.type === SidebarItemType.Dictionary &&
      item.wsDictionaryUuid === params.dictionaryUuid,
  );

  const header = foundDictionarySidebarItem?.wsDictionaryName;

  const description = `Here you can manage a database of ${header.toLowerCase()}.`;

  const filtersLength =
    Object.keys(filters || {}).length > 0 &&
    `: ${Object.keys(filters || {}).length}`;

  const handleGetDictionaryList = (dictionaryUuid, filtersObj) => {
    dispatch(getDictionaryList(dictionaryUuid, filtersObj));
  };

  const handleRowNameClick = (dictionaryElementUuid) => {
    openDictionaryDetails(history, dictionaryElementUuid);
  };

  const handleRestoreDefault = () => {
    const lsItem = localStorage.getItem("ws-dictionary-settings");

    if (!lsItem) {
      return;
    }

    const settingsData = lsItem ? JSON.parse(lsItem) : null;
    delete settingsData[activeWorkspaceUuid];

    localStorage.setItem(
      "ws-dictionary-settings",
      JSON.stringify(settingsData),
    );

    dispatchDF(
      setDictionaryInitialColumnRanks(
        generateMetadataRanks(dictionaryList.columns),
      ),
    );
    dispatchDF(resetHiddenColumns());
    setInitialRankGenerationDone(true);
  };

  const saveTableView = async () => {
    const visibleColumns = dictionaryList.columns
      .filter((field) => !hiddenDictionaryColumnIds?.includes(field.uuid))
      .map((field) => ({ uuid: field.uuid, exampleKey: "aaaa" }));

    const uuid = uuidv7();

    await createDictionaryTableView({
      filters: dictionaryFilters.filters,
      sortBy: dictionaryFilters.sortBy,
      sort: dictionaryFilters.sort,
      visibleColumns,
      wsWorkspaceUuid: activeWorkspaceUuid,
      wsDictionaryUuid: params.dictionaryUuid,
      name: newTableViewName,
      uuid,
    });
    handleGetDictionaryList(params.dictionaryUuid, dictionaryFilters);
    dispatchDF(
      setSelectedTableView({
        label: newTableViewName,
        value: uuid,
      }),
    );
  };

  const renderHeaderToolbar = () => (
    <>
      {dictionaryList.list.length > 0 && (
        <Button
          size="small"
          textWithIcon
          variant="blue"
          onClick={() => setIsModalOpen(true)}
        >
          <PlusIcon />
          <span>
            <IDHFormattedMessage id="ws_add" defaultMessage="Add" />
            &nbsp;
            {foundDictionarySidebarItem?.wsDictionaryElementName &&
              foundDictionarySidebarItem?.wsDictionaryElementName}
          </span>
        </Button>
      )}
      <Button
        variant="white"
        size="small"
        textWithIcon
        onClick={() => setShowFilters((prev) => !prev)}
        active={Object.keys(filters).length > 0}
      >
        <FilterIcon />
        <span>
          <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
          {filtersLength}
        </span>
      </Button>
      {dictionaryList.list.length > 0 && (
        <DictionarySortDropdown
          fields={dictionaryList.columns}
          dictionaryFilters={dictionaryFilters}
          dispatchDictionaryFilters={dispatchDF}
        />
      )}

      {identity?.permissions?.workspace?.includes("manage_table_views") && (
        <Button
          variant="white"
          size="small"
          textWithIcon
          onClick={() => setShowSaveTableViewModal(true)}
        >
          <IDHFormattedMessage id="ws_save_view" defaultMessage="Save view" />
        </Button>
      )}

      <TableViewSelector
        wsTableViews={wsTableViews}
        selectedWsTableView={selectedTableView}
        setSelectedWsTableView={(newValue) =>
          dispatchDF(setSelectedTableView(newValue))
        }
        setFilters={(newFilters) =>
          dispatchDF(setDictionaryFilters(newFilters))
        }
        setFilter={(uuid, value) =>
          dispatchDF(setDictionaryFilter(uuid, value))
        }
        setHiddenColumnIds={(columnIds) =>
          dispatchDF(setHiddenDictionaryColumnIds(columnIds))
        }
        setSortBy={(newValue) => dispatchDF(setDictionarySortBy(newValue))}
        setSort={(newValue) => dispatchDF(setDictionarySort(newValue))}
        resetToDefaultSettings={handleRestoreDefault}
        columnIds={dictionaryList?.columns?.map((field) => field.uuid)}
      />

      <MetaDataOptionsDropdown
        context={MetaDataOptionsContext.Dictionary}
        fields={dictionaryList.columns}
        fieldRanks={dictionaryColumnRanks}
        hiddenColumns={hiddenDictionaryColumnIds}
        dispatchFunction={dispatchDF}
        resetToDefaultSettings={handleRestoreDefault}
      />
    </>
  );

  useEffect(() => {
    if (!params.dictionaryUuid || !dictionaryFilters) return;
    handleGetDictionaryList(params.dictionaryUuid, dictionaryFilters);
  }, [params.dictionaryUuid, JSON.stringify(dictionaryFilters)]);

  useEffect(() => {
    if (initialRankGenerationDone) return;
    const settingsData = JSON.parse(
      localStorage.getItem("ws-dictionary-settings"),
    );

    let settingsForWorkspace;

    if (settingsData) {
      settingsForWorkspace = settingsData[activeWorkspaceUuid];
    }

    const settingsForDictionary = settingsForWorkspace
      ? settingsForWorkspace[getDictionaryUuidFromCurrentUrl()]
      : { dictionaryColumnRanks: [] };

    if (
      dictionaryList?.columns?.length &&
      !settingsForDictionary?.dictionaryColumnRanks?.length
    ) {
      dispatchDF(
        setDictionaryInitialColumnRanks(
          generateMetadataRanks(dictionaryList.columns),
        ),
      );
      setInitialRankGenerationDone(true);
    } else if (
      dictionaryList?.columns.length &&
      dictionaryList?.columns.length !== dictionaryColumnRanks.length
    ) {
      dispatchDF(
        setDictionaryInitialColumnRanks(
          generateLackingRanks(
            dictionaryList?.columns,
            settingsForDictionary?.dictionaryColumnRanks,
          ),
        ),
      );
      setInitialRankGenerationDone(true);
    }
  }, [
    activeWorkspaceUuid,
    initialRankGenerationDone,
    dictionaryList.columns,
    dictionaryColumnRanks,
  ]);

  useEffect(() => {
    // @ts-ignore
    dispatchDF(resetDictionaryFiltersReducer());
  }, [params.dictionaryUuid]);

  useEffect(() => {
    dispatch(clearDictionaryList());
    // @ts-ignore
    dispatchDF(resetDictionaryFiltersReducer());
  }, []);

  const filteredColumns = useMemo(
    () =>
      dictionaryList.columns
        .filter((col) => !hiddenDictionaryColumnIds?.includes(col.uuid))
        .sort((a, b) => {
          const rankA = dictionaryColumnRanks?.find(
            (col) => col.uuid === a.uuid,
          )?.rank;
          const rankB = dictionaryColumnRanks?.find(
            (col) => col.uuid === b.uuid,
          )?.rank;

          if (rankA && rankB) return rankA.localeCompare(rankB);
          return 0;
        }),
    [dictionaryList.columns, hiddenDictionaryColumnIds, dictionaryColumnRanks],
  );

  return (
    <>
      <GlideDataGridWrapper
        header={header}
        description={description}
        columnsData={filteredColumns}
        rowsData={dictionaryList.list}
        isLoading={isDictionaryListLoading}
        titleColumnLabel={foundDictionarySidebarItem?.wsDictionaryElementName}
        context={tableDataType.Dictionary}
        renderHeaderToolbar={renderHeaderToolbar}
        handleRowNameClick={handleRowNameClick}
        filtersAndSortingOptions={dictionaryFilters}
        filtersAndSortingOptionsDispatch={dispatchDF}
        elementToScrollUuid={elementToScrollUuid}
        setElementToScrollUuid={setElementToScrollUuid}
        totalRowsCount={dictionaryList.totalCount}
        noResultsScreen={
          <NoResultsScreen
            title={
              <span>
                <IDHFormattedMessage
                  id="ws_there_are_no"
                  defaultMessage="There are no"
                />{" "}
                {foundDictionarySidebarItem?.wsDictionaryName}
              </span>
            }
            subtitle={
              <span>
                <IDHFormattedMessage
                  id="ws_add_your_first"
                  defaultMessage="Add your first"
                />{" "}
                {foundDictionarySidebarItem?.wsDictionaryElementName}
              </span>
            }
            imgUrl={noResultsImg}
            bottomContent={
              <Button
                size="large"
                variant="blue"
                onClick={() => setIsModalOpen(true)}
              >
                <PlusSignIcon />
                <span>
                  <IDHFormattedMessage id="ws_add" defaultMessage="Add" />{" "}
                  {foundDictionarySidebarItem?.wsDictionaryElementName}
                </span>
              </Button>
            }
          />
        }
        noFilteredResultsScreen={
          <NoResultsScreen
            title={
              <IDHFormattedMessage
                id="ws_no_results_found"
                defaultMessage="No results found"
              />
            }
            subtitle={
              <IDHFormattedMessage
                id="ws_remove_filters"
                defaultMessage="No results match the filter criteria. Remove filter or clear all filters to show results."
              />
            }
            imgUrl={noFilteredResultsImg}
            bottomContent={
              <Button
                size="large"
                variant="blue"
                onClick={() => dispatchDF(setDictionaryFilters([]))}
              >
                <IDHFormattedMessage
                  id="ws_clear_filters"
                  defaultMessage="Clear filters"
                />
              </Button>
            }
          />
        }
        openGlideElement={(elementUuid) =>
          getRoute(
            ROUTES.DICTIONARY_ELEMENT_DETAILS,
            {
              wsWorkspaceUuid: activeWorkspaceUuid,
              wsDictionaryUrl: foundDictionarySidebarItem?.wsDictionaryName,
              wsDictionaryUuid: params.dictionaryUuid,
            },
            { displayDictionaryPreview: elementUuid },
          )
        }
      />

      <DictionaryFilters
        context={FiltersContext.Dictionary}
        dictionaryFilters={dictionaryFilters}
        dispatchDictionaryFilters={dispatchDF}
        setFiltersVisible={setShowFilters}
        filtersVisible={showFilters}
        columns={dictionaryList.columns}
      />

      {showDiscoveryDetails && <DictionaryDetails />}
      {isModalOpen && (
        <AddNewDictionaryElementModal
          onClose={() => setIsModalOpen(false)}
          workspaceUuid={params.workspaceUuid}
          wsDictionaryName={
            foundDictionarySidebarItem?.wsDictionaryElementName ?? ""
          }
          dictionaryUuid={params.dictionaryUuid}
          setElementToScrollUuid={setElementToScrollUuid}
        />
      )}

      {showSaveTableViewModal && (
        <SaveTableViewModal
          onClose={() => setShowSaveTableViewModal(false)}
          name={newTableViewName}
          setName={setNewTableViewName}
          saveFunction={saveTableView}
        />
      )}

      <WebsocketListener />
    </>
  );
}
