import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { uuidv7 } from "uuidv7";

import AddButton from "src/app/components/AddButton/AddButton";
import { DropdownMenuItem } from "src/app/components/Dropdown/Dropdown";
import DropdownPortal from "src/app/components/DropdownPortal";
import Pictogram, {
  PictogramVariants,
} from "src/app/components/Pictogram/Pictogram";
import { MenuOverlay } from "src/app/components/Select/components/MenuOverlay/MenuOverlay";
import SelectLabel from "src/app/components/Select/components/SelectLabel/SelectLabel";
import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import { translateMessage } from "src/app/methods/translateMessage";
import { SelectOption } from "src/types";
import { ReactComponent as TickIcon } from "src/images/tick-blue.svg";
import useOnClickOutside from "src/app/methods/useOnClickOutside";
import { getUnusedColor } from "src/app/components/Select/utils";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { generateRankString } from "src/utils/rankStrings";
import { ReactComponent as RemoveIcon } from "src/images/close-alt.svg";
import classNames from "classnames";
import { MessageDetails, MessageRecipient } from "./types";

interface EmailDropdownProps {
  recipient: MessageRecipient;
  handleUpdateMetaValue: (newValue: string | null) => void;
  setMessageDetails: React.Dispatch<
    React.SetStateAction<MessageDetails | null>
  >;
}

export default function EmailDropdown({
  recipient,
  handleUpdateMetaValue,
  setMessageDetails,
}: EmailDropdownProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null,
  );
  const dropdownRef = useRef(null);

  const intl = useIntl();

  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  const options = recipient.crmEmail.data?.singleSelectOptions ?? [];
  const searchResults =
    options.filter((item) =>
      item.name.toUpperCase().includes(searchValue.toUpperCase()),
    ) ?? [];

  const createSelectOption = async () => {
    const newRank = options.length ? options[options.length - 1].rank : "a";

    setMessageDetails((prev) => {
      if (!prev) return null;

      const foundRecipient = prev.recipients.find(
        (prevRecipient) => prevRecipient.wsTaskUuid === recipient.wsTaskUuid,
      );

      if (!foundRecipient) return prev;

      return {
        ...prev,
        recipients: prev.recipients.map((prevRecipient) => {
          if (prevRecipient.wsTaskUuid === recipient.wsTaskUuid) {
            return {
              ...prevRecipient,
              crmEmail: {
                ...prevRecipient.crmEmail,
                data: {
                  singleSelectOptions: [
                    ...(prevRecipient.crmEmail.data.singleSelectOptions ?? []),
                    {
                      name: searchValue,
                      value: uuidv7(),
                      color: getUnusedColor(options).color.replace("#", ""),
                      backgroundColor: getUnusedColor(
                        options,
                      ).backgroundColor.replace("#", ""),
                      rank: generateRankString(newRank, ""),
                    },
                  ],
                },
              },
            };
          }
          return prevRecipient;
        }),
      };
    });

    setSearchValue("");
  };

  const handleOptionClick = (item: SelectOption) => {
    const foundOption = options.find((option) => option.value === item.value);
    if (!foundOption) return;
    setSelectedOption(foundOption);
    setIsOpen(false);
    handleUpdateMetaValue(item.name);
  };

  const handleRemoveSelectedOption = () => {
    setSelectedOption(null);
    handleUpdateMetaValue(null);
  };

  const renderMenuContent = () => {
    return (
      <>
        <input
          className="search-input"
          value={searchValue}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={translateMessage({
            intl,
            id: "ws_select_email_or_enter_address",
            defaultMessage: "Select email or enter address",
          })}
        />
        <div className="options-list email__dropdown">
          {searchResults.map((item) => {
            return (
              <div className="option" key={item.value}>
                <DropdownMenuItem
                  className="email__dropdown-item"
                  toggleGlobalState
                  data-value={item.name}
                  onClick={() => handleOptionClick(item)}
                >
                  <div className="dropdown__menu-item-left">
                    <span
                      className="select__label"
                      style={{
                        backgroundColor: `#${item.backgroundColor}`,
                        color: `#${item.color}`,
                        transition: `color 0.2s ease, background-color 0.2s ease`,
                      }}
                    >
                      {item.icon && (
                        <Pictogram
                          identifier={item.icon.name}
                          variant={
                            item.icon.variant
                              ? item.icon.variant
                              : PictogramVariants.Original
                          }
                        />
                      )}
                      <TooltipedEllipsis content={item.name} maxWidth={150} />
                    </span>
                    {item.value === selectedOption?.value && <TickIcon />}
                  </div>
                </DropdownMenuItem>
              </div>
            );
          })}
        </div>

        {searchValue && (
          <DropdownMenuItem onClick={createSelectOption}>
            <div className="dropdown__menu-item-left">
              <span className="dropdown__menu-item-left-create">
                <IDHFormattedMessage id="ws_create" defaultMessage="Create" />
              </span>
              <span
                className="select__label select__label--to-create"
                style={{
                  backgroundColor: getUnusedColor(options).backgroundColor,
                  color: getUnusedColor(options).color,
                }}
              >
                {searchValue}
              </span>
            </div>
            <div className="dropdown__menu-item-right" />
          </DropdownMenuItem>
        )}
      </>
    );
  };

  useEffect(() => {
    const selectedValue = recipient.crmEmail.data?.singleSelectOptions?.find(
      (option) => option.value === recipient.crmEmail.value,
    );
    if (!selectedValue) return;
    setSelectedOption(selectedValue);
  }, [recipient]);

  return (
    <div className="task-meta-fields__value-content">
      <div className="value-field">
        <div>
          <div className="select__wrapper" ref={dropdownRef}>
            <DropdownPortal
              visible={isOpen}
              overlay={
                <MenuOverlay
                  showEditOptionPane={false}
                  handleClick={() => {}}
                  className="email__dropdown-menu"
                >
                  {renderMenuContent()}
                </MenuOverlay>
              }
            >
              <SelectLabel
                value={selectedOption}
                onClickHandler={() => {
                  setIsOpen(true);
                }}
                emptyFieldPlaceholder={<AddButton variant="rect" />}
                maxWidth={340}
              />
            </DropdownPortal>
          </div>
        </div>
        <RemoveIcon
          className={classNames(
            "value-field__icon",
            selectedOption && "value-field__icon--visible",
          )}
          onClick={handleRemoveSelectedOption}
        />
      </div>
    </div>
  );
}
