import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { allCells, ButtonCellType } from "@glideapps/glide-data-grid-cells";
import { injectIntl } from "react-intl";
import DataEditor, {
  Item,
  BooleanCell,
  Rectangle,
  GridSelection,
  ImageCell,
  DataEditorProps,
  GridCell,
  GridCellKind,
  GridColumn,
  SpriteMap,
} from "@glideapps/glide-data-grid";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { ReactComponent as RemoveIcon } from "src/images/close-alt.svg";
import { ReactComponent as NavigationIcon } from "src/images/navigation.svg";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import {
  getDictionaryAutoCompletesForColumns,
  getImageSource,
  getSelectDataSetForColumns,
  isThumbnailsExist,
} from "src/utils/methods";
import { IMember } from "src/app/project/ProjectTypes";
import { DictionaryElementSelector } from "src/app/dropdowns/DictionaryElementSelector/DictionaryElementSelector";
import creatorPlaceholder from "src/images/creator-placeholder.svg";
import { RootState } from "src/redux/reducers";
import {
  Column,
  MetaData,
  MetaDataFileValue,
  Row,
} from "src/redux/dictionary/types";
import { DateObject } from "src/app/project/Project";
import { SelectOption } from "src/types";
import {
  initialHoveredCellSettings,
  isDateObject,
  initialTheme,
  initialDropdownSettings,
  initialTooltipSettings,
  MAX_VISIBLE_MULTI_SELECT_OPTIONS,
  MAX_CHARS_LENGTH,
  generateSpecificGridCell,
  headerIconsObj,
  generateColumns,
  dispatchUpdatedMetaDataValue,
  willElementRenderInsideHoverArea,
  isAccessLevelReadOnly,
  initialHoveredHeaderSettings,
  initialStaticCellSettings,
  getCellPosition,
  rowMarkersSettings,
  areArraysTheSame,
  addNewSelectedRows,
  HEADER_HEIGHT,
  ROW_HEIGHT,
} from "./utils";
import { GlideDataGridTooltip } from "./components/GlideDataGridTooltip/GlideDataGridTooltip";
import { ActionDropdownMenu } from "./components/ActionDropdownMenu/ActionDropdownMenu";
import {
  GlideDataGridProps,
  SortDirection,
  ExtraGlideDataGridMetaFieldTypesEnum,
} from "./types";
import {
  languages,
  dateFormatForBackend,
  datePickerDateFormat,
} from "../CustomDatePicker/utils";
import SelectDropdown from "./components/SelectDropdown/SelectDropdown";
import { GlideDataGridDropdownWrapper } from "./components/GlideDataGridDropdownWrapper/GlideDataGridDropdownWrapper";
import { TextBoxWrapper } from "../TextBoxWrapper/TextBoxWrapper";
import SocialProfileIcons from "../IconsWithStatus/SocialProfileIcons/SocialProfileIcons";
import { setDictionarySort } from "../DictionaryFilters/redux/dictionaryFiltersActions";
import { ValueInputWrapper } from "./components/ValueInputWrapper/ValueInputWrapper";
import MembersDropdown from "./components/MembersDropdown/MembersDropdown";
import { openDictionaryDetails } from "../DictionaryDetails/methods";
import { Img } from "../Img/Img";
import GlideDataGridSelectedRowsBar from "./components/GlideDataGridSelectedRowsBar/GlideDataGridSelectedRowsBar";
import { tableDataType } from "../Table/Table";
import RatingSelector from "../RatingSelector/RatingSelector";
import { GlideDataGridHeader } from "./components/GlideDataGridHeader/GlideDataGridHeader";
import FilesDropdownWrapper from "./components/FilesDropdownWrapper/FilesDropdownWrapper";
import EmptyCell from "./components/Cells/jsxCells/EmptyCell";
import EmptyFileCell from "./components/Cells/jsxCells/EmptyFileCell";
import StaticFilesLoadingCell from "./components/Cells/jsxCells/StaticFilesLoadingCell";
import creatorCellRenderer, {
  TitleCell,
} from "./components/Cells/canvasCells/TitleCell";

import "@glideapps/glide-data-grid/dist/index.css";
import "./GlideDataGrid.scss";
import ProjectSelector from "../ProjectSelector/ProjectSelector";

function GlideDataGrid({
  columnsData,
  rowsData,
  isLoading,
  context,
  handleRowNameClick,
  filtersAndSortingOptions,
  filtersAndSortingOptionsDispatch,
  showSearch,
  setShowSearch,
  openGlideElement,
  totalRowsCount,
  intl,
}: GlideDataGridProps) {
  const [columns, setColumns] = useState<GridColumn[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownSettings, setDropdownSettings] = useState(
    initialDropdownSettings,
  );
  const [hoveredCellSettings, setHoveredCellSettings] = useState(
    initialHoveredCellSettings,
  );
  const [hoveredHeaderSettings, setHoveredHeaderSettings] = useState(
    initialHoveredHeaderSettings,
  );
  const [tooltipSettings, setTooltipSettings] = useState(
    initialTooltipSettings,
  );
  const [staticCellSettings, setStaticCellSettings] = useState(
    initialStaticCellSettings,
  );
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [selection, setSelection] = useState<GridSelection>();
  const [selectedRowsData, setSelectedRowsData] = useState<Row[]>([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const inputRef = useRef<any>(null);
  const hoveredItemRef = useRef<HTMLDivElement>(null);

  const dictionaryAutoCompletes = useSelector(
    (state: RootState) => state.dictionaryReducer.dictionaryAutoCompletes,
  );

  const locale = useSelector((state: RootState) => state.mainReducer.locale);

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const membersList = useSelector(
    (state: RootState) => state.projectReducer.activeMembersList,
  );

  const selectDataSetList = useSelector(
    (state: RootState) => state.selectDataSetReducer.selectDataSetList,
  );

  const onHeaderHovered = useCallback(
    (location: Item, bounds: Rectangle) => {
      const [col] = location;

      const hoveredColumn = columns[col];

      setHoveredHeaderSettings(initialHoveredHeaderSettings);
      setTooltipSettings(initialTooltipSettings);

      if (hoveredColumn) {
        setHoveredHeaderSettings({
          value: hoveredColumn.title,
          style: {
            backgroundColor: "white",
            ...getCellPosition(bounds),
          },
          bounds,
          uuid: hoveredColumn.id ?? "",
        });
      }
    },
    [columns],
  );

  const onItemHovered = useCallback(
    (location: Item, bounds: Rectangle) => {
      if (isFocused || isDropdownOpen) return;

      const [col, row] = location;
      setHoveredCellSettings(initialHoveredCellSettings);
      setTooltipSettings(initialTooltipSettings);

      const hoveredColumn = columns[col];

      if (
        hoveredColumn &&
        hoveredColumn.id !== ExtraGlideDataGridMetaFieldTypesEnum.Empty
      ) {
        const cellCoordinates = {
          cell: location,
          bounds,
        };

        const cellPosition = getCellPosition(bounds);

        // This is a hack to display action dropdown
        if (hoveredColumn.id === ExtraGlideDataGridMetaFieldTypesEnum.Action) {
          setHoveredCellSettings({
            type: ExtraGlideDataGridMetaFieldTypesEnum.Action,
            value: "",
            ...cellCoordinates,
            style: {
              backgroundColor: "transparent",
              ...cellPosition,
            },
            readOnly: false,
          });
          return;
        }

        if (hoveredColumn.id === ExtraGlideDataGridMetaFieldTypesEnum.Title) {
          setHoveredCellSettings({
            type: ExtraGlideDataGridMetaFieldTypesEnum.Title,
            value: rowsData[row].title,
            ...cellCoordinates,
            style: {
              backgroundColor: "transparent",
              ...cellPosition,
            },
            readOnly: false,
          });
          return;
        }

        const hoveredColumnData = columnsData.find(
          (colData) => colData.uuid === hoveredColumn.id,
        );

        if (
          !hoveredColumnData ||
          hoveredColumnData?.type === MetaFieldType.BoolVal
        )
          return;

        const hoveredCellMetaData = rowsData[row].metadata.find(
          (m) => m.uuid === hoveredColumn.id,
        );

        const hoveredColumnDataSettings = {
          type: hoveredColumnData.type,
          readOnly:
            isAccessLevelReadOnly(hoveredColumnData.accessLevel) ||
            Boolean(hoveredColumnData.valueSource),
        };

        const backgroundColor =
          [
            MetaFieldType.Text,
            MetaFieldType.Number,
            MetaFieldType.Currency,
            MetaFieldType.Percent,
          ].includes(hoveredColumnData.type) || !hoveredCellMetaData?.value
            ? "#fff"
            : "transparent";

        if (
          !hoveredCellMetaData ||
          !hoveredCellMetaData.value ||
          (Array.isArray(hoveredCellMetaData.value) &&
            !hoveredCellMetaData.value.length)
        ) {
          setHoveredCellSettings({
            ...hoveredColumnDataSettings,
            value: null,
            ...cellCoordinates,
            style: {
              backgroundColor,
              ...cellPosition,
            },
          });
          return;
        }

        if (hoveredColumnData.type === MetaFieldType.Text) {
          setHoveredCellSettings({
            ...hoveredColumnDataSettings,
            value: hoveredCellMetaData.value,
            ...cellCoordinates,
            style: {
              backgroundColor,
              ...cellPosition,
            },
          });
          return;
        }

        setHoveredCellSettings({
          ...hoveredColumnDataSettings,
          value: hoveredCellMetaData?.value,
          ...cellCoordinates,
          style: {
            backgroundColor,
            ...cellPosition,
          },
        });

        if (hoveredColumnData.type === MetaFieldType.Member) {
          const foundMember: IMember | undefined = membersList?.find(
            (member: IMember) => member.id === hoveredCellMetaData?.value,
          );
          if (!foundMember || foundMember.name.length <= MAX_CHARS_LENGTH) {
            setTooltipSettings(initialTooltipSettings);
          } else {
            setTooltipSettings({
              value: foundMember.name,
              type: hoveredColumnData.type,
              bounds: cellCoordinates.bounds,
            });
          }
        }

        if (hoveredColumnData.type === MetaFieldType.MultiSelect) {
          if (
            !hoveredCellMetaData ||
            typeof hoveredCellMetaData.value === "string"
          ) {
            setTooltipSettings(initialTooltipSettings);
            return;
          }

          const hoveredCellValueArr = hoveredCellMetaData.value as string[];

          const multiSelectSettings = hoveredColumnData.data as {
            singleSelectOptions: SelectOption[];
          };

          const wsSelectMultiDataSetUuid =
            hoveredColumnData.data.wsSelectDataSetUuid ?? null;
          const selectMultiOptions = wsSelectMultiDataSetUuid
            ? selectDataSetList[`dataSetType_${wsSelectMultiDataSetUuid}`]
            : multiSelectSettings.singleSelectOptions;

          const selectedOptions = selectMultiOptions?.filter(
            (option: SelectOption) => {
              return hoveredCellValueArr.find((val) => val === option.value);
            },
          );

          if (selectedOptions?.length >= MAX_VISIBLE_MULTI_SELECT_OPTIONS) {
            setTooltipSettings({
              value: selectedOptions
                .map((item: SelectOption) => item.name)
                .join(", "),
              type: hoveredColumnData.type,
              bounds: cellCoordinates.bounds,
            });
          } else {
            setTooltipSettings(initialTooltipSettings);
          }
        }
      }
    },
    [
      columns,
      rowsData,
      columnsData,
      showSearch,
      membersList,
      isFocused,
      isDropdownOpen,
    ],
  );

  const handleRemoveValue = (cell: Item) => {
    if (hoveredCellSettings.value || dropdownSettings.value) {
      updateMetaData(cell, null);
    }
    setHoveredCellSettings((prev) => ({ ...prev, value: null }));
    setTooltipSettings(initialTooltipSettings);
  };

  const renderRemoveIcon = (readOnly: boolean, className?: string) => {
    if (readOnly) return null;
    return (
      <RemoveIcon
        className={classNames(
          "glide-data-grid__hovered-item-remove-icon",
          showSearch &&
            "glide-data-grid__hovered-item-remove-icon--transparent",
          className,
        )}
        onClick={() => {
          handleRemoveValue(hoveredCellSettings.cell);
        }}
      />
    );
  };

  const updateMetaData = useCallback(
    (cell: Item, newValue: any) => {
      const [col, row] = cell;

      let rowDataItems: Row[] = [];
      const columnItem = columns[col];

      if (
        selectedRowsData.length > 1 &&
        selectedRowsData.find(
          (selectedRow) => selectedRow.uuid === rowsData[row].uuid,
        )
      ) {
        rowDataItems = selectedRowsData;
      } else {
        rowDataItems.push(rowsData[row]);
      }

      let newValueLocal = newValue;

      const foundColData = columnsData.find(
        (colData) => colData.uuid === columnItem.id,
      );

      if (
        isAccessLevelReadOnly(foundColData?.accessLevel) ||
        Boolean(foundColData?.valueSource)
      )
        return;

      if (newValueLocal !== null) {
        if (foundColData?.type === MetaFieldType.Date) {
          const metaDataDateValue = newValue as DateObject;
          newValueLocal = metaDataDateValue.date;
        }
      }

      if (!columnItem.id) return;

      dispatchUpdatedMetaDataValue(
        rowDataItems,
        columnItem.id,
        newValueLocal,
        context,
        dispatch,
      );
    },
    [columns, rowsData, context, selectedRowsData],
  );

  const renderHoveredCellContent = () => {
    switch (hoveredCellSettings.type) {
      case MetaFieldType.File:
        return <div className="glide-data-grid__hovered-item--only-remove" />;
      case MetaFieldType.Number:
      case MetaFieldType.Currency:
      case MetaFieldType.Percent:
        return (
          <div
            className={classNames(
              "glide-data-grid__hovered-item--remove-with-metafield",
              isScrolling && "glide-data-grid__hovered-item--non-clickable",
            )}
            ref={hoveredItemRef}
          >
            <ValueInputWrapper
              readOnly={hoveredCellSettings.readOnly}
              type={hoveredCellSettings.type}
              ref={inputRef}
              value={
                typeof hoveredCellSettings.value === "string"
                  ? hoveredCellSettings.value
                  : ""
              }
              onChange={(newValue: string) => {
                if (newValue.trim()) {
                  updateMetaData(hoveredCellSettings.cell, newValue);
                } else {
                  handleRemoveValue(hoveredCellSettings.cell);
                }
                setIsFocused(false);
              }}
              onFocus={() => {
                if (!hoveredCellSettings.readOnly) {
                  setIsFocused(true);
                }
              }}
              handleUseOnClickOutside={() => {
                setIsFocused(false);
              }}
            />
            {renderRemoveIcon(hoveredCellSettings.readOnly)}
          </div>
        );
      case MetaFieldType.Text:
        return (
          <div
            className={classNames(
              "glide-data-grid__hovered-item--remove-with-metafield",
              isScrolling && "glide-data-grid__hovered-item--non-clickable",
            )}
            ref={hoveredItemRef}
          >
            <TextBoxWrapper
              ref={inputRef}
              value={hoveredCellSettings.value}
              readOnly={hoveredCellSettings.readOnly}
              onChange={(newValue: string) => {
                if (newValue.trim()) {
                  updateMetaData(hoveredCellSettings.cell, newValue);
                } else {
                  handleRemoveValue(hoveredCellSettings.cell);
                }
                setIsFocused(false);
              }}
              onFocus={() => {
                if (!hoveredCellSettings.readOnly) {
                  setIsFocused(true);
                }
              }}
              handleUseOnClickOutside={() => {
                setIsFocused(false);
              }}
            />
            {renderRemoveIcon(hoveredCellSettings.readOnly)}
          </div>
        );
      case MetaFieldType.Rating:
        return (
          <div
            className={classNames(
              "glide-data-grid__hovered-item--remove-with-metafield",
              isScrolling && "glide-data-grid__hovered-item--non-clickable",
            )}
            ref={hoveredItemRef}
          >
            <RatingSelector
              value={parseInt(
                (hoveredCellSettings.value ?? "").toString() || "0",
                10,
              )}
              onValueChange={(newValue) =>
                updateMetaData(hoveredCellSettings.cell, newValue)
              }
            />
          </div>
        );
      case MetaFieldType.Date:
      case MetaFieldType.SingleSelect:
      case MetaFieldType.MultiSelect:
      case MetaFieldType.AggregatedSelect:
      case MetaFieldType.Member:
        return (
          <div className="glide-data-grid__hovered-item--only-remove">
            {renderRemoveIcon(hoveredCellSettings.readOnly)}
          </div>
        );
      case MetaFieldType.DictionaryElement:
        const dictionaryElementUuid =
          typeof hoveredCellSettings.value === "string"
            ? hoveredCellSettings.value
            : "";
        return (
          <div
            className={classNames(
              "glide-data-grid__hovered-item--only-remove",
              isScrolling && "glide-data-grid__hovered-item--non-clickable",
            )}
            ref={hoveredItemRef}
          >
            <NavigationIcon
              className="glide-data-grid__hovered-item-link-icon"
              onClick={(e) => {
                e.stopPropagation();
                openDictionaryDetails(history, dictionaryElementUuid);
              }}
            />
            {renderRemoveIcon(
              hoveredCellSettings.readOnly,
              "glide-data-grid__hovered-item-remove-icon--no-shadow",
            )}
          </div>
        );
      case ExtraGlideDataGridMetaFieldTypesEnum.Title:
        const [, row] = hoveredCellSettings.cell;
        const elementData = rowsData[row];
        return (
          <div
            ref={hoveredItemRef}
            className={classNames(
              "glide-data-grid__hovered-item--title-cell",
              {
                "glide-data-grid__hovered-item--clickable": handleRowNameClick,
                "glide-data-grid__hovered-item--with-data":
                  elementData.socialProfiles,
              },
              isScrolling && "glide-data-grid__hovered-item--non-clickable",
            )}
            onClick={() => {
              if (handleRowNameClick) {
                handleRowNameClick(rowsData[row].uuid);
              }
            }}
          >
            {context === tableDataType.CreatorDatabase && (
              <Img
                className="glide-data-grid__hovered-item-cover"
                src={getImageSource(
                  elementData?.cover,
                  "tiny",
                  creatorPlaceholder,
                )}
                fallbackImageSrc={creatorPlaceholder}
              />
            )}
            <div className="glide-data-grid__hovered-item-title-with-icons">
              <Link
                onClick={(e) => e.stopPropagation()}
                to={() => openGlideElement(elementData.uuid)}
              >
                {elementData.title.substring(0, 19)}
              </Link>
              {elementData.socialProfiles && (
                <SocialProfileIcons data={elementData.socialProfiles} />
              )}
            </div>
          </div>
        );
      case MetaFieldType.Project:
        // TODO: https://app.asana.com/0/1159791777517936/1208972781734357/f

        return (
          <ProjectSelector
            value={null}
            wsProjectAutocompleteList={[]}
            wsWorkspaceUuid={activeWorkspaceUuid}
          />
        );

      default:
        return null;
    }
  };

  const renderDropdown = () => {
    switch (dropdownSettings.type) {
      case MetaFieldType.File:
        return (
          <FilesDropdownWrapper
            filesData={dropdownSettings.value as MetaDataFileValue[]}
            uuid={columns[dropdownSettings.cell[0]].id as string}
            rowUuid={rowsData[dropdownSettings.cell[1]]?.uuid}
            closeDropdown={() => setIsDropdownOpen(false)}
            readOnly={dropdownSettings.readOnly}
            bounds={dropdownSettings.bounds}
            setStaticCellSettings={setStaticCellSettings}
            context={context}
          />
        );
      case MetaFieldType.Date:
        return (
          <DatePicker
            popperClassName="glide-data-grid__dropdown-date-picker"
            onChange={(newDate: any) => {
              const parsedDate = moment(newDate);
              const dateFormatted = parsedDate.format(dateFormatForBackend);
              updateMetaData(dropdownSettings.cell, {
                date: dateFormatted,
              });
              setIsDropdownOpen(false);
            }}
            open={isDropdownOpen}
            className="glide-data-grid__date-picker"
            onClickOutside={() => setIsDropdownOpen(false)}
            dateFormat={datePickerDateFormat}
            selected={
              isDateObject(dropdownSettings?.value)
                ? new Date(dropdownSettings.value.date)
                : new Date()
            }
            locale={languages[locale]}
          />
        );
      case MetaFieldType.Member:
        const foundColData = columnsData.find(
          (colData) => colData.uuid === columns[dropdownSettings.cell[0]].id,
        );

        return (
          <MembersDropdown
            uuid={columns[dropdownSettings.cell[0]].id as string}
            dataType={context}
            wsTeamUuids={foundColData?.data?.wsTeamUuids}
            onClose={() => setIsDropdownOpen(false)}
            choosePerson={(member: IMember) => {
              updateMetaData(dropdownSettings.cell, member.id);
              setIsDropdownOpen(false);
            }}
          />
        );
      case MetaFieldType.SingleSelect:
        return (
          <SelectDropdown
            mode="single"
            variant="disable_all"
            optionsData={
              dropdownSettings.options?.wsSelectDataSetUuid
                ? selectDataSetList[
                    `dataSetType_${dropdownSettings.options?.wsSelectDataSetUuid}`
                  ] || []
                : dropdownSettings.options?.singleSelectOptions
            }
            updateValue={(newValue) => {
              updateMetaData(dropdownSettings.cell, newValue);
              setHoveredCellSettings({
                ...dropdownSettings,
                style: {},
                value: newValue,
              });
              setIsDropdownOpen(false);
            }}
            uuid={columns[dropdownSettings.cell[0]].id as string}
            fieldValue={
              typeof dropdownSettings.value === "string"
                ? dropdownSettings.value
                : undefined
            }
            context={context}
            closeDropdownHanler={() => setIsDropdownOpen(false)}
          />
        );

      case MetaFieldType.MultiSelect:
        return (
          <SelectDropdown
            mode="multi"
            variant="disable_all"
            optionsData={
              dropdownSettings.options?.wsSelectDataSetUuid
                ? selectDataSetList[
                    `dataSetType_${dropdownSettings.options?.wsSelectDataSetUuid}`
                  ] || []
                : dropdownSettings.options?.singleSelectOptions
            }
            updateValue={(newValue) => {
              if (!newValue.length) {
                handleRemoveValue(dropdownSettings.cell);
              } else {
                updateMetaData(dropdownSettings.cell, newValue);
                setHoveredCellSettings({
                  ...dropdownSettings,
                  style: {},
                  value: newValue,
                });
              }
            }}
            uuid={columns[dropdownSettings.cell[0]].id as string}
            fieldValue={
              Array.isArray(dropdownSettings.value)
                ? (dropdownSettings.value as string[])
                : undefined
            }
            context={context}
            closeDropdownHanler={() => setIsDropdownOpen(false)}
          />
        );

      case MetaFieldType.DictionaryElement:
        return (
          <DictionaryElementSelector
            onValueChange={(newValue) => {
              setIsDropdownOpen(false);
              updateMetaData(dropdownSettings.cell, newValue);
            }}
            value={dropdownSettings.value}
            wsDictionaryUuid={dropdownSettings.options?.wsDictionaryUuid}
            wsDictionarySubFieldUuid={
              dropdownSettings.options?.wsDictionarySubFieldUuid
            }
            onClose={() => setIsDropdownOpen(false)}
            displayOnlyMenu
          />
        );

      case ExtraGlideDataGridMetaFieldTypesEnum.Action:
        const [_, row] = hoveredCellSettings.cell;
        return (
          <ActionDropdownMenu
            context={context}
            setIsDropdownOpen={setIsDropdownOpen}
            rowData={rowsData[row]}
          />
        );
      case MetaFieldType.Project:
        // TODO: https://app.asana.com/0/1159791777517936/1208972781734357/f
        return null;

      default:
        return null;
    }
  };

  const resetDropdownSettings = () => {
    setDropdownSettings(initialDropdownSettings);
  };

  const onCellClicked = useCallback(
    (cell: Item, bounds: Rectangle) => {
      const [col, row] = cell;
      const clickedColumn = columns[col];
      const clickedRow = rowsData[row];
      if (!clickedColumn || !clickedRow) return;

      onItemHovered(cell, bounds);

      const basicSetup = {
        cell,
        bounds,
      };

      if (clickedColumn.id === ExtraGlideDataGridMetaFieldTypesEnum.Action) {
        setIsDropdownOpen(true);
        setDropdownSettings({
          ...basicSetup,
          type: ExtraGlideDataGridMetaFieldTypesEnum.Action,
          value: "",
          readOnly: false,
        });
        return;
      }

      const foundColData = columnsData.find(
        (colData) => colData.uuid === columns[col].id,
      );

      const foundMetaDataItem = rowsData[row].metadata.find(
        (item: MetaData) => item.uuid === clickedColumn.id,
      );

      if (foundColData) {
        const readOnly =
          isAccessLevelReadOnly(foundColData.accessLevel) ||
          Boolean(foundColData.valueSource);

        // TODO: Add support of other dropdowns readOnly
        if (readOnly && foundColData.type !== MetaFieldType.File) return;
        const dropdownData = {
          value: foundMetaDataItem?.value ?? null,
          readOnly,
        };

        if (
          foundColData.type === MetaFieldType.Date ||
          foundColData.type === MetaFieldType.Member ||
          (foundColData.type === MetaFieldType.File &&
            Array.isArray(foundMetaDataItem?.value))
        ) {
          setIsDropdownOpen(true);
          setDropdownSettings({
            type: foundColData.type,
            ...basicSetup,
            ...dropdownData,
          });
        } else if (
          foundColData.type === MetaFieldType.SingleSelect ||
          foundColData.type === MetaFieldType.MultiSelect ||
          foundColData.type === MetaFieldType.DictionaryElement
        ) {
          setIsDropdownOpen(true);
          setDropdownSettings({
            type: foundColData.type,
            ...basicSetup,
            ...dropdownData,
            options:
              typeof foundColData.data !== "undefined" ? foundColData.data : {},
          });
        }
      }
    },
    [columns, rowsData, columnsData, isFocused],
  );

  const handleEmptyField = () => {
    if (
      hoveredCellSettings.type === MetaFieldType.Text ||
      hoveredCellSettings.type === MetaFieldType.Number ||
      hoveredCellSettings.type === MetaFieldType.Currency ||
      hoveredCellSettings.type === MetaFieldType.Percent ||
      hoveredCellSettings.type === MetaFieldType.Project
    ) {
      setHoveredCellSettings((prev) => ({ ...prev, value: "" }));
      setTimeout(() => {
        inputRef.current?.focus();
      }, 15);
    } else {
      onCellClicked(hoveredCellSettings.cell, hoveredCellSettings.bounds);
    }
  };

  const generateGridCell = (
    rowItem: Row,
    colItem: GridColumn,
    columnsData: Column[],
    membersList: IMember[],
  ): GridCell => {
    if (colItem.id === ExtraGlideDataGridMetaFieldTypesEnum.Action) {
      return {
        kind: GridCellKind.Custom,
        cursor: "pointer",
        allowOverlay: false,
        copyData: "-",
        readonly: true,
        data: {
          kind: "button-cell",
          backgroundColor: ["#f2f2f6", "#e0e2ea"],
          color: "#6A6D7C",
          borderRadius: 8,
          title: "⋮",
        },
        themeOverride: {
          baseFontStyle: "700 16px",
        },
      } as ButtonCellType;
    }

    if (colItem.id === ExtraGlideDataGridMetaFieldTypesEnum.Title) {
      if (context === tableDataType.CreatorDatabase) {
        let coverImageUrl;

        if (isThumbnailsExist(rowItem.cover, "tiny")) {
          coverImageUrl = rowItem.cover?.thumbnails.tiny as string;
        }

        const titleCell: TitleCell = {
          kind: GridCellKind.Custom,
          allowOverlay: false,
          readonly: false,
          copyData: rowItem.title,
          data: {
            kind: "title-cell",
            name: rowItem.title,
            image: coverImageUrl,
            placeholderImage: creatorPlaceholder,
            socialProfiles: rowItem.socialProfiles,
          },
        };
        return titleCell;
      }
      return {
        kind: GridCellKind.Text,
        allowOverlay: false,
        readonly: true,
        displayData: rowItem.title || " ",
        data: rowItem.title || " ",
      };
    }

    if (colItem.id === ExtraGlideDataGridMetaFieldTypesEnum.Empty) {
      return {
        kind: GridCellKind.Text,
        allowOverlay: false,
        readonly: false,
        displayData: " ",
        data: " ",
      };
    }

    const foundMetaDataItem = rowItem.metadata.find(
      (item: MetaData) => item.uuid === colItem.id,
    );

    const foundColData = columnsData.find(
      (colData) => colData.uuid === colItem.id,
    );

    if (!foundMetaDataItem || !foundColData || !foundMetaDataItem.value) {
      if (foundColData?.type === MetaFieldType.BoolVal) {
        return {
          kind: GridCellKind.Boolean,
          readonly:
            isAccessLevelReadOnly(foundColData.accessLevel) ||
            Boolean(foundColData.valueSource),
          allowOverlay: false,
          data: false,
        } as BooleanCell;
      }
      if (foundColData?.type === MetaFieldType.Rating) {
        return {
          kind: GridCellKind.Image,
          allowOverlay: false,
          rounding: 0,
          data: [`/workspace/images/star.svg`],
        } as ImageCell;
      }
      return {
        kind: GridCellKind.Text,
        allowOverlay: false,
        readonly: false,
        displayData: " ",
        data: " ",
      };
    }

    return generateSpecificGridCell(
      foundMetaDataItem.value,
      foundColData,
      membersList,
      dictionaryAutoCompletes,
      selectDataSetList,
      intl,
    );
  };

  const onHeaderClicked = useCallback(
    (colIndex: number) => {
      const clickedCol = columns[colIndex];
      if (
        !clickedCol ||
        !clickedCol.icon ||
        !filtersAndSortingOptions ||
        !filtersAndSortingOptionsDispatch
      )
        return;

      const newSortOption =
        clickedCol.icon === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending;
      // @ts-ignore
      filtersAndSortingOptionsDispatch(setDictionarySort(newSortOption));
    },
    [columns, filtersAndSortingOptions, filtersAndSortingOptionsDispatch],
  );

  const getCellContent = useCallback<DataEditorProps["getCellContent"]>(
    (cell): GridCell => {
      const [col, row] = cell;
      if (isLoading || !rowsData[row] || !columns[col]) {
        return {
          kind: GridCellKind.Loading,
          allowOverlay: false,
        };
      }
      const generatedGridCell = generateGridCell(
        rowsData[row],
        columns[col],
        columnsData,
        membersList,
      );
      return generatedGridCell;
    },
    [
      columns,
      rowsData,
      columnsData,
      isLoading,
      membersList,
      dictionaryAutoCompletes,
      isFocused,
      JSON.stringify(selectDataSetList),
      intl,
    ],
  );

  const headerIcons = useMemo<SpriteMap>(() => headerIconsObj, []);

  const getRowThemeOverride = useCallback(
    // Hide border from extra rows that fill the missing space
    (i) => (i > rowsData.length ? { borderColor: "transparent" } : undefined),
    [rowsData],
  );

  const renderHoveredHeader = () => {
    return (
      <GlideDataGridHeader
        title={hoveredHeaderSettings.value || ""}
        bounds={hoveredHeaderSettings.bounds}
        setTooltipSettings={setTooltipSettings}
        arrowDirection={
          filtersAndSortingOptions?.sortBy === hoveredHeaderSettings.uuid
            ? filtersAndSortingOptions?.sort
            : null
        }
      />
    );
  };

  const renderHoveredCell = () => {
    if (
      hoveredCellSettings.value === null &&
      hoveredCellSettings.type &&
      hoveredCellSettings.type !== MetaFieldType.Rating
    ) {
      if (hoveredCellSettings.readOnly) return null;
      if (hoveredCellSettings.type === MetaFieldType.File) {
        const [col, row] = hoveredCellSettings.cell;
        return (
          <EmptyCell isScrolling={isScrolling} ref={hoveredItemRef}>
            <EmptyFileCell
              uuid={columns[col].id as string}
              rowUuid={rowsData[row].uuid}
              closeDropdown={() => setIsDropdownOpen(false)}
              bounds={hoveredCellSettings.bounds}
              setStaticCellSettings={setStaticCellSettings}
              wsWorkspaceUuid={activeWorkspaceUuid}
              context={context}
            />
          </EmptyCell>
        );
      }
      return (
        <EmptyCell
          isScrolling={isScrolling}
          handleEmptyField={handleEmptyField}
          ref={hoveredItemRef}
        />
      );
    }
    return renderHoveredCellContent();
  };

  const handleGridSelectionChange = useCallback(
    (_newSelection: GridSelection) => {
      // Prevents unselecting rows when clicking on a cell
      if (_newSelection.current) return;

      const selectedRows = _newSelection.rows.toArray();

      setSelectedRowsData(
        selectedRows.map((selectedRow) => rowsData[selectedRow]),
      );

      setSelection(_newSelection);
    },
    [rowsData, setSelection],
  );

  const onColumnResize = useCallback(
    (col: GridColumn, newSize: number) => {
      const index = columns.findIndex((c) => c.id === col.id);

      const newCols = [...columns];

      if (newCols[index] !== undefined && newCols[index].id === "action")
        return;

      newCols[index] = {
        ...newCols[index],
        width: newSize,
      };
      setColumns(newCols);
    },
    [columns],
  );

  useEffect(() => {
    const generatedColumns = generateColumns(
      columnsData,
      totalRowsCount,
      rowsData.length,
      filtersAndSortingOptions,
    );
    setColumns(generatedColumns);
  }, [columnsData, filtersAndSortingOptions, totalRowsCount, rowsData.length]);

  const editor = document.querySelector(".dvn-scroller");

  useEffect(() => {
    if (editor) {
      editor.addEventListener("scroll", () => {
        setHoveredCellSettings(initialHoveredCellSettings);
        setHoveredHeaderSettings(initialHoveredHeaderSettings);
        setTooltipSettings(initialTooltipSettings);
        setIsDropdownOpen(false);
        setIsFocused(false);
      });
      return () => {
        editor.removeEventListener("scroll", () => {
          setHoveredCellSettings(initialHoveredCellSettings);
          setHoveredHeaderSettings(initialHoveredHeaderSettings);
          setTooltipSettings(initialTooltipSettings);
        });
      };
    }
  }, [editor]);

  useEffect(() => {
    if (document) {
      document.documentElement.style.overflow = "hidden";
      return () => {
        document.documentElement.style.overflow = "";
      };
    }
  }, []);

  useEffect(() => {
    if (isDropdownOpen) {
      setTooltipSettings(initialTooltipSettings);
    } else {
      resetDropdownSettings();
    }
  }, [isDropdownOpen]);

  const cols = JSON.stringify(
    columnsData.map((column) => ({
      type: column.type,
      options: Array.isArray(column.data)
        ? []
        : {
            singleSelectOptions: column.data.singleSelectOptions?.sort((a, b) =>
              a.name.localeCompare(b.name),
            ),
            ...column.data,
          },
    })),
  );

  useEffect(() => {
    getDictionaryAutoCompletesForColumns(dispatch, JSON.parse(cols));
    getSelectDataSetForColumns(dispatch, JSON.parse(cols));
  }, [cols]);

  useEffect(() => {
    if (!hoveredItemRef.current || isFocused) return;

    const handleScroll = () => {
      setIsScrolling(true);

      setTimeout(() => {
        setIsScrolling(false);
      });
    };

    hoveredItemRef.current.addEventListener("wheel", handleScroll);
    return () => {
      if (hoveredItemRef.current) {
        hoveredItemRef.current.removeEventListener("wheel", handleScroll);
      }
    };
  }, [hoveredItemRef.current, isFocused]);

  useEffect(() => {
    if (showSearch) {
      const searchContainer = document.querySelector(".gdg-seveqep");
      if (searchContainer) {
        const hideElements = () => {
          setHoveredCellSettings(initialHoveredCellSettings);
          setTooltipSettings(initialTooltipSettings);
          setHoveredHeaderSettings(initialHoveredHeaderSettings);
        };

        searchContainer.addEventListener("mouseenter", hideElements);
        return () => {
          searchContainer.removeEventListener("mouseenter", hideElements);
        };
      }
    }
  }, [showSearch]);

  useEffect(() => {
    if (!selection || !selectedRowsData.length) return;

    if (selection.rows.length === selectedRowsData.length) {
      const selectedIndexesFromRowsData = selectedRowsData.map(
        (selectedRowData) => {
          return rowsData.findIndex(
            (rowData) => rowData.uuid === selectedRowData.uuid,
          );
        },
      );
      if (
        areArraysTheSame(selection.rows.toArray(), selectedIndexesFromRowsData)
      )
        return;

      setSelection((prev) => {
        if (prev) {
          return {
            ...prev,
            rows: addNewSelectedRows(selectedIndexesFromRowsData),
          };
        }
      });
    }
  }, [selection, rowsData, selectedRowsData]);

  useEffect(() => {
    if (!selection) {
      setSelectedRowsData([]);
    }
  }, [selection]);

  return (
    <>
      <div className="glide-data-grid__line" />
      <DataEditor
        customRenderers={[...allCells, creatorCellRenderer]}
        getCellContent={getCellContent}
        columns={columns}
        rows={rowsData.length}
        smoothScrollX
        smoothScrollY
        freezeColumns={2}
        theme={initialTheme}
        rowHeight={ROW_HEIGHT}
        headerHeight={HEADER_HEIGHT}
        onColumnResize={
          context === tableDataType.Dictionary ? onColumnResize : undefined
        }
        className="glide-data-grid__editor"
        onItemHovered={(args) => {
          if (args.kind === "header") {
            onHeaderHovered(args.location, args.bounds);
          } else if (hoveredHeaderSettings.value) {
            setHoveredHeaderSettings(initialHoveredHeaderSettings);
          }

          if (
            args.kind === "cell" &&
            willElementRenderInsideHoverArea(editor, args.bounds)
          ) {
            onItemHovered(args.location, args.bounds);
          } else {
            if (!isFocused && !isDropdownOpen) {
              setHoveredCellSettings(initialHoveredCellSettings);
            }
            if (args.kind === "out-of-bounds") {
              setTooltipSettings(initialTooltipSettings);
            }
          }
        }}
        getCellsForSelection
        columnSelect="none"
        rowSelectionMode="multi"
        rowMarkers={rowMarkersSettings}
        getRowThemeOverride={getRowThemeOverride}
        headerIcons={headerIcons}
        showSearch={showSearch}
        drawFocusRing={false}
        onSearchClose={() => setShowSearch(false)}
        onCellClicked={(cell, event) => onCellClicked(cell, event.bounds)}
        onCellEdited={(cell, newValue) => updateMetaData(cell, newValue.data)}
        onHeaderClicked={onHeaderClicked}
        onGridSelectionChange={handleGridSelectionChange}
        gridSelection={selection}
      />

      {hoveredHeaderSettings.value && (
        <div
          className="glide-data-grid__hovered-header"
          ref={setPopperRef}
          style={{
            ...hoveredHeaderSettings.style,
          }}
        >
          {renderHoveredHeader()}
        </div>
      )}

      {hoveredCellSettings.type && (
        <div
          className="glide-data-grid__hovered-item"
          ref={setPopperRef}
          style={{
            ...hoveredCellSettings.style,
          }}
        >
          {renderHoveredCell()}
        </div>
      )}

      {staticCellSettings.numberOfFiles > 0 && (
        <StaticFilesLoadingCell settings={staticCellSettings} />
      )}

      {tooltipSettings.value &&
        (tooltipSettings.type === MetaFieldType.MultiSelect ||
          tooltipSettings.type === MetaFieldType.Member ||
          tooltipSettings.type === "header") && (
          <GlideDataGridTooltip
            content={tooltipSettings.value}
            bounds={tooltipSettings.bounds}
            type={tooltipSettings.type}
            referenceElement={popperRef}
          />
        )}

      {isDropdownOpen && (
        <GlideDataGridDropdownWrapper
          handleClickOverlay={
            dropdownSettings.type !== MetaFieldType.SingleSelect &&
            dropdownSettings.type !== MetaFieldType.MultiSelect
              ? () => setIsDropdownOpen(false)
              : undefined
          }
          bounds={dropdownSettings.bounds}
          referenceElement={popperRef}
        >
          {renderDropdown()}
        </GlideDataGridDropdownWrapper>
      )}
      <GlideDataGridSelectedRowsBar
        selectedRowsData={selectedRowsData}
        context={context}
        setSelection={setSelection}
      />
    </>
  );
}

export default injectIntl(GlideDataGrid);
