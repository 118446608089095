import classNames from "classnames";
import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useSelector } from "react-redux";
import { ReactComponent as IndahashLogo } from "../../images/indahash-full.svg";

export function ShowcaseSeparator(props) {
  const {
    className,
    id,
    primaryColor,
    workspaceData,
    renderProviderIcon,
    provider,
    creatorName,
    hideInfo,
    printing,
  } = props;

  const showcaseTitle = useSelector(
    (state) => state.showcaseReducer.showcaseTitle,
  );

  return (
    <div
      id={id}
      key={id}
      className={classNames("showcase-template__separator", className)}
    >
      {workspaceData && !hideInfo && (
        <div className="showcase-template__separator__info">
          <span>
            {workspaceData.name}
            &nbsp;✦&nbsp;
            {showcaseTitle}
          </span>
          <span>#</span>
        </div>
      )}

      {primaryColor && (
        <div
          className="showcase-template__separator-colored-line"
          style={{ backgroundColor: primaryColor }}
        />
      )}

      <div
        className={classNames("showcase-template__separator-line", {
          "showcase-template__separator-line--hidden": printing,
        })}
      />

      {provider !== "last" ? (
        <>
          {renderProviderIcon && (
            <div className="showcase-template__separator-provider">
              {renderProviderIcon(provider)}
              {creatorName}
            </div>
          )}

          {Object.keys(workspaceData || {}).length > 0 ? (
            <div className="showcase-template__separator-campaign-logo">
              {workspaceData?.logo ? (
                <img src={workspaceData.logo} alt="logo" />
              ) : (
                <IndahashLogo />
              )}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}
