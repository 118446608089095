export const SET_PROJECT_SORT_BY = "SET_PROJECT_SORT_BY";
export const SET_PROJECT_SORT = "SET_PROJECT_SORT";
export const SET_PROJECT_FILTERS = "SET_PROJECT_FILTERS";
export const SET_PROJECT_FILTER = "SET_PROJECT_FILTER";
export const TOGGLE_PROJECTS_LIST_COLUMN_VISIBILITY =
  "TOGGLE_PROJECTS_LIST_COLUMN_VISIBILITY";
export const SET_PROJECT_INITIAL_COLUMN_RANKS =
  "SET_PROJECT_INITIAL_COLUMN_RANKS";
export const SET_PROJECT_COLUMN_RANK = "SET_PROJECT_COLUMN_RANK";
export const SET_PROJECT_INITIAL_HIDDEN_COLUMNS =
  "SET_PROJECT_INITIAL_HIDDEN_COLUMNS";
export const SET_SELECTED_TABLE_VIEW = "SET_SELECTED_TABLE_VIEW";
