import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export const summaryPlacementOptions = [
  {
    label: (
      <IDHFormattedMessage
        id="ws_show_as_the_second_page"
        defaultMessage="Show as the second page"
      />
    ),
    value: "top",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_show_as_the_second_to_last_page"
        defaultMessage="Show as the second-to-last page"
      />
    ),
    value: "bottom",
  },
  {
    label: (
      <IDHFormattedMessage id="ws_do_not_show" defaultMessage="Do not show" />
    ),

    value: null,
  },
];
