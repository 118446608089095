import React from "react";
import { useSelector } from "react-redux";

import { WorkspacePermissionsEnum } from "src/utils/PermissionsEnums";
import { ContactData } from "./ContactData";
import { CategoriesAndBio } from "./CategoriesAndBio";

export function BioWithContactData(props) {
  const identity = useSelector((state) => state.mainReducer.identity);

  return (
    <div className="profile-data-tab__section-wrapper">
      <div className="profile-data-tab__section-two-boxes">
        <CategoriesAndBio profileData={props.profileData} />
        {identity?.permissions?.workspace?.includes(
          WorkspacePermissionsEnum.DISPLAY_CONTACT_SECTION,
        ) && <ContactData profileData={props.profileData} />}
      </div>
    </div>
  );
}
