import React, { useState } from "react";
import "./ReportTopBar.scss";

// import CreatorPlaceholder from "../../utils/creator-placeholder.svg";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import axios from "axios";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import pako from "pako";
import { ManagePagesModal } from "src/app/generator-data/modals/ManagePagesModal/ManagePagesModal";
import { identify, setActiveWorkspaceUuid } from "src/redux/main/mainActions";
import { ReactComponent as DownloadIcon } from "../../../../images/download-alt.svg";
import { ReactComponent as CrossIcon } from "../../../../images/cross.svg";
import ProjectPlaceholder from "../../../../images/project-placeholder.svg";
import { ReactComponent as ManageIcon } from "../../../../images/manage.svg";
import { Button } from "../../../components/Button/Button";

import {
  arrayBufferToBase64,
  getImageSource,
  scrollbarVisible,
} from "../../../../utils/methods";
import { showToast } from "../../../methods/showToast";
import { API_URLS } from "../../../../utils/API_URLS";
import { setReportPrinting } from "../../../../redux";
import { AppConfig } from "../../../../config/AppConfig";
import {
  ASYNC_GENERATING_CAMPAIGN_REPORT,
  LEVEL_GZIP_REPORT,
} from "../../../CreatorShowcase/utils";
import {
  getReportGeneratorSettings,
  ReportGeneratorContext,
} from "../../utils/reportFunctions";

export function ReportTopBar(props) {
  const { generatorRef, data, format, wsWorkspaceUuid } = props;

  const [showManagePagesModal, setShowManagePagesModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { workspaceId, ids, context } = getReportGeneratorSettings();

  const closeGenerator = () => {
    dispatch(setActiveWorkspaceUuid(workspaceId));
    dispatch(identify(workspaceId));

    switch (context) {
      case ReportGeneratorContext.Campaign: {
        history.push({
          pathname: `/workspace/${workspaceId}/projects/${ids[0]}`,
          search: "taskType=creator",
        });
        break;
      }

      case ReportGeneratorContext.CampaignList:
        history.push(`/workspace/${workspaceId}/projects`);
        break;
    }
  };

  const handleGenerateReport = async () => {
    const reportName = data?.title
      ? `${data.title}.pdf`
      : "campaign-report.pdf";

    document.querySelector(".report-generator").scroll(0, 0);
    dispatch(setReportPrinting(true));

    try {
      const head = document.querySelector("head");
      const headHtml = head.outerHTML.replace(
        "<head>",
        `<head><base href="${AppConfig.getApiHost()}" />`,
      );

      const reportTemplate = document
        .querySelector(".report-template")
        .cloneNode(true);

      const images = reportTemplate.getElementsByTagName("img");

      Array.from(images).forEach((img) => {
        const image = img;
        image.src = img.src.replace(":3003", "");
        reportTemplate.outerHTML.replace(img, image);
      });

      const separators = reportTemplate.getElementsByClassName(
        "report-template__separator-line",
      );

      const pagesCount = separators?.length + 1;
      const pdfHeight = pagesCount * format.value.pageHeight - pagesCount;

      Array.from(separators).forEach((separator, index) => {
        const newSeparator = separator;
        newSeparator.style.opacity = 0;
      });

      reportTemplate.style.overflow = `hidden`;
      reportTemplate.style.height = `${pdfHeight}px`;

      const html = `<!DOCTYPE html><html lang="en">${headHtml}<body>
        ${reportTemplate.outerHTML}</body></!DOCTYPE>`;

      const gzippedHtmlBinary = pako.gzip(html, {
        level: LEVEL_GZIP_REPORT,
      });
      const gzippedHtml = arrayBufferToBase64(gzippedHtmlBinary);

      const response = await axios.post(
        API_URLS.printPdfFromHtml,
        {
          fileName: reportName,
          width: format.value.pagePrintWidth,
          height: format.value.pagePrintHeight,
          gzippedHtml,
          wsWorkspaceUuid,
          async: ASYNC_GENERATING_CAMPAIGN_REPORT,
        },
        { responseType: "blob" },
      );

      if (ASYNC_GENERATING_CAMPAIGN_REPORT) {
        showToast(
          "info",
          <IDHFormattedMessage id="ws_info" defaultMessage="Info" />,
          <IDHFormattedMessage
            id="ws_report_has_been_queued_please_wait_to_download_it"
            defaultMessage="Generating this report may take a while. You will receive an email once it's ready. This may take up to 30 minutes."
          />,
        );

        return;
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", reportName);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error.response);
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_something_went_wrong"
          defaultMessage="Something went wrong."
        />,
      );
    } finally {
      dispatch(setReportPrinting(false));
    }
  };

  return (
    <div
      className={classNames("report-topbar", {
        "report-topbar--scrollbar-space": scrollbarVisible(
          generatorRef?.current,
        ),
      })}
    >
      <div className="report-topbar__left">
        {/* <img
          className="report-topbar__left-cover"
          src={getImageSource(data?.cover, "tiny", ProjectPlaceholder)}
        />
        <span className="report-topbar__left-title">{data?.title}</span> */}
      </div>

      <div className="report-topbar__right">
        <Button size="medium" variant="blue" onClick={handleGenerateReport}>
          <DownloadIcon />
          <IDHFormattedMessage
            id="ws_download_report"
            defaultMessage="Download report"
          />
        </Button>

        <Button
          size="medium"
          variant="white"
          onClick={() => setShowManagePagesModal(true)}
        >
          <ManageIcon />
          <IDHFormattedMessage
            id="ws_manage_pages"
            defaultMessage="Manage pages"
          />
        </Button>

        <Button size="medium" variant="white" onClick={closeGenerator}>
          <CrossIcon />
          <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
        </Button>

        {/* <Button
            className="report-toolbar__draft-button"
            size="medium"
            variant="white-with-black-border"
            onClick={synchronizeData}
          >
            <IDHFormattedMessage
              id="ws_synchronize_data"
              defaultMessage="Synchronize data"
            />
          </Button> */}
      </div>

      {showManagePagesModal && (
        <ManagePagesModal
          onClose={() => setShowManagePagesModal(false)}
          format={format.value}
        />
      )}
    </div>
  );
}
