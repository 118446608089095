import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosResponse } from "axios";
import { useIntl } from "react-intl";

import { API_URLS } from "src/utils/API_URLS";
import { Pane } from "src/app/components/Pane/Pane";
import { useShowPane } from "src/hooks/useShowPane";
import { setCreatorMessagePaneOpen } from "src/redux";
import { ReactComponent as CloseIcon } from "src/images/cross-alt.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "src/redux/reducers";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import responseCodes from "src/utils/responseCodes";
import Loader from "src/app/components/Loader/Loader";
import { translateMessage } from "src/app/methods/translateMessage";
import { EmailContent, MessageDetails, Steps } from "./types";
import CreatorMessagePaneCreateEmail from "./CreatorMessagePaneCreateEmail";
import CreatorMessagePaneManageEmails from "./CreatorMessagePaneManageEmails";
import CreatorMessagePaneConfirmEmail from "./CreatorMessagePaneConfirmEmail";

import "./CreatorMessagePane.scss";

const initialEmailContent = {
  replyToEmail: "",
  includeInCcCampaignMembers: false,
  includeInBccCampaignMembers: false,
  subject: "",
  message: "",
  recipients: [],
};

export default function CreatorMessagePane() {
  const dispatch = useDispatch();

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const { selectedTasks } = useSelector(
    (state: RootState) => state.taskReducer,
  );

  const intl = useIntl();

  const [messageDetails, setMessageDetails] = useState<MessageDetails | null>(
    null,
  );
  const [isGettingMessageDetails, setIsGettingMessageDetails] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isUpdatingCreatorEmails, setIsUpdatingCreatorEmails] = useState(false);

  const isLoading =
    isGettingMessageDetails || isSendingMessage || isUpdatingCreatorEmails;

  const [step, setStep] = useState(Steps.MANAGE_EMAILS);

  const [showPane, hidePane] = useShowPane(() => {
    dispatch(setCreatorMessagePaneOpen(false));
  });

  const [emailContent, setEmailContent] =
    useState<EmailContent>(initialEmailContent);

  const recipientsWithNoEmailCRM = useMemo(() => {
    return messageDetails?.recipients.filter(
      (recipient) => recipient.emailFromCrm === null,
    );
  }, [messageDetails]);

  const selectedTasksUuidsJSON = JSON.stringify(
    selectedTasks.map((selectedTask) => selectedTask.taskId),
  );

  const getMessageDetails = useCallback(async () => {
    setIsGettingMessageDetails(true);
    const wsTasksUuids = JSON.parse(selectedTasksUuidsJSON);
    try {
      const response: AxiosResponse<{ content: MessageDetails }> =
        await axios.post(API_URLS.postDetailsToSendMessage, {
          wsWorkspaceUuid: activeWorkspaceUuid,
          wsTasksUuids,
        });

      if (response.status !== responseCodes["200_OK"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      setMessageDetails(response.data.content);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast();
      }
      console.error(error);
      hidePane();
    } finally {
      setIsGettingMessageDetails(false);
    }
  }, [activeWorkspaceUuid, selectedTasksUuidsJSON]);

  const handleClosePane = () => {
    if (emailContent.message.length > 0) {
      const userConfirmed = window.confirm(
        translateMessage({
          intl,
          id: "ws_unsaved_message_confirm",
          defaultMessage:
            "You have unsaved changes. Are you sure you want to close?",
        }),
      );
      if (userConfirmed) {
        hidePane();
      }
    } else {
      hidePane();
    }
  };

  const renderContent = () => {
    if (isLoading || !messageDetails) {
      return (
        <div className="creator-message-pane__loader">
          <Loader />
        </div>
      );
    }

    if (step === Steps.MANAGE_EMAILS) {
      return (
        <CreatorMessagePaneManageEmails
          emailContent={emailContent}
          setMessageDetails={setMessageDetails}
          setStep={setStep}
          activeWorkspaceUuid={activeWorkspaceUuid}
          recipientsWithNoEmailCRM={recipientsWithNoEmailCRM}
          setIsUpdatingCreatorEmails={setIsUpdatingCreatorEmails}
          getMessageDetails={getMessageDetails}
        />
      );
    }

    if (step === Steps.CREATE_EMAIL) {
      return (
        <CreatorMessagePaneCreateEmail
          messageDetails={messageDetails}
          setStep={setStep}
          emailContent={emailContent}
          setEmailContent={setEmailContent}
          recipientsWithNoEmailCRM={recipientsWithNoEmailCRM}
        />
      );
    }

    if (step === Steps.CONFIRM_EMAIL) {
      return (
        <CreatorMessagePaneConfirmEmail
          activeWorkspaceUuid={activeWorkspaceUuid}
          emailContent={emailContent}
          messageDetails={messageDetails}
          hidePane={hidePane}
          setStep={setStep}
          setIsSendingMessage={setIsSendingMessage}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (recipientsWithNoEmailCRM?.length === 0) {
      setStep(Steps.CREATE_EMAIL);
    }
  }, [recipientsWithNoEmailCRM]);

  useEffect(() => {
    getMessageDetails();
  }, [getMessageDetails]);

  useEffect(() => {
    if (!messageDetails) return;
    setEmailContent((prev) => ({
      ...prev,
      recipients: messageDetails.recipients,
    }));
  }, [messageDetails]);

  return (
    <Pane
      showPane={showPane}
      hidePane={handleClosePane}
      className="creator-message-pane"
    >
      <div className="creator-message-pane__topbar">
        <div className="creator-message-pane__topbar-title">
          {step === Steps.CONFIRM_EMAIL ? (
            <IDHFormattedMessage
              id="ws_confirm_sending_message"
              defaultMessage="Confirm sending message"
            />
          ) : (
            <IDHFormattedMessage
              id="ws_sending_a_message"
              defaultMessage="Sending a message"
            />
          )}
        </div>
        <CloseIcon
          onClick={handleClosePane}
          className="creator-message-pane__topbar-close-icon"
        />
      </div>
      <div className="task__container">{renderContent()}</div>
    </Pane>
  );
}
