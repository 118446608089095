import { canAggregate } from "src/app/ReportGenerator/components/ReportToolbar/components/DataAggregation/utils";
import {
  CLEAR_REPORT_STATE,
  GET_GENERATOR_DATA_SUCCESS,
  SET_APPLY_BOXES_CONTENT,
  SET_GENERATOR_DATA,
  SET_GRID_ELEMENTS,
  SET_HIDDEN_GRID_ELEMENTS,
  SET_LAYOUT,
  SET_LOADING,
  SET_NEWLY_ADDED_PAGES,
  SET_CREATORS_METADATA,
  SET_NUMBERED_HEADERS,
  SET_PRINTING,
  SET_REPORT_SAVED_TEMPLATE,
  SET_REPORT_SELECTED_TEMPLATE,
  SET_LOADER_TEXT,
  SET_SHOW_LOADER,
  SET_REPORT_SEPARATORS,
  SET_PUBLICATIONS_METADATA,
  SET_SUMMARY_STATISTICS,
  SET_PUBLICATIONS_SUMMARY_STATS,
  SET_DATA_AGGREGATION_VALUE,
} from "./reportTypes";

const initialState = {
  publications: [],
  publicationsSummary: {},
  creators: [],
  project: {},
  creatorsInsights: {},
  audienceData: {},
  layout: [],
  gridElements: [],
  hiddenGridElements: [],
  campaigns: {},
  separators: [],
  downloadingData: true,
  loading: true,
  printing: false,
  showLoader: false,
  loaderText: "",
  savedTemplate: [],
  selectedTemplate: { label: "Default", value: {} },
  headerSections: [],
  newlyAddedPages: [],
  savedBoxes: [],
  applyBoxesContent: false,
  creatorsMetadata: {},
  publicationsMetadata: {},
  summaryStatistics: {},
  publicationSummaryStats: {},
  dataAggregation: {
    creatorsInsights: canAggregate(true),
    creatorsInTheCampaign: canAggregate(true),
    campaignAudienceData: canAggregate(true),
    // audienceOverlap: false,
    executiveSummary: canAggregate(false),
    topPerformingPublications: canAggregate(true),
    allPublications: canAggregate(true),
    summary: canAggregate(true),
    commentsAnalysis: canAggregate(true),
  },
};

function reportReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_GENERATOR_DATA_SUCCESS:
      const campaigns = action.payload.data.content;

      return {
        ...state,
        campaigns,
        downloadingData: false,
      };

    case SET_GENERATOR_DATA:
      return {
        ...state,
        campaigns: action.payload.campaigns,
        publicationsSummary: action.payload.publicationsSummary,
      };

    case SET_LAYOUT:
      return {
        ...state,
        layout: action.payload.layout,
      };

    case SET_GRID_ELEMENTS:
      return {
        ...state,
        gridElements: action.payload.gridElements,
      };

    case SET_HIDDEN_GRID_ELEMENTS:
      return {
        ...state,
        hiddenGridElements: action.payload.hiddenGridElements,
      };

    case SET_REPORT_SEPARATORS:
      return {
        ...state,
        separators: action.payload.separators,
      };

    case SET_REPORT_SAVED_TEMPLATE:
      return {
        ...state,
        savedTemplate: action.payload.savedTemplate,
      };

    case SET_REPORT_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload.selectedTemplate,
      };

    case CLEAR_REPORT_STATE:
      return {
        ...state,
        publicationsList: [],
        creatorsList: [],
        projectData: [],
        creatorInsights: {},
        audienceData: {},
        layout: [],
        gridElements: [],
        hiddenGridElements: [],
        loading: true,
        printing: false,
        synchronizing: false,
        savedTemplate: [],
        selectedTemplate: { label: "Default", value: {} },
        numberedHeaders: [],
        downloadingData: true,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case SET_PRINTING:
      return {
        ...state,
        printing: action.payload.printing,
      };

    case SET_SHOW_LOADER:
      return {
        ...state,
        showLoader: action.payload.showLoader,
      };

    case SET_LOADER_TEXT:
      return {
        ...state,
        loaderText: action.payload.loaderText,
      };

    case SET_NUMBERED_HEADERS:
      return {
        ...state,
        numberedHeaders: action.payload.numberedHeaders,
      };

    case SET_NEWLY_ADDED_PAGES:
      return {
        ...state,
        newlyAddedPages: action.payload.newlyAddedPages,
      };

    case SET_APPLY_BOXES_CONTENT:
      return {
        ...state,
        applyBoxesContent: action.payload.applyBoxesContent,
      };

    case SET_CREATORS_METADATA: {
      const { campaignId, creatorsMetadata } = action.payload;
      return {
        ...state,
        creatorsMetadata: {
          ...state.creatorsMetadata,
          [campaignId]: creatorsMetadata,
        },
      };
    }

    case SET_PUBLICATIONS_SUMMARY_STATS: {
      const { provider, publicationSummaryStats } = action.payload;
      return {
        ...state,
        publicationSummaryStats: {
          ...state.publicationSummaryStats,
          [provider]: publicationSummaryStats,
        },
      };
    }

    case SET_PUBLICATIONS_METADATA: {
      const { campaignId, publicationsMetadata } = action.payload;

      return {
        ...state,
        publicationsMetadata: {
          ...state.publicationsMetadata,
          [campaignId]: publicationsMetadata,
        },
      };
    }

    case SET_SUMMARY_STATISTICS: {
      const { provider, summaryStatistics } = action.payload;
      return {
        ...state,
        summaryStatistics: {
          ...state.summaryStatistics,
          [provider]: summaryStatistics,
        },
      };
    }

    case SET_DATA_AGGREGATION_VALUE: {
      const { key, value } = action.payload;

      return {
        ...state,
        dataAggregation: {
          ...state.dataAggregation,
          [key]: value,
        },
      };
    }

    default:
      return state;
  }
}

export default reportReducer;
