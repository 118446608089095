export const getNumberedHeaders = (layout, gridElements) => {
  if (!gridElements?.length || !layout?.length) return;

  const numberedHeaders = [];

  const headers = gridElements
    ?.filter((item) => item.gridBoxType === "header")
    .map((item) => {
      const y = layout?.find((el) => el.i === item.label)?.y;

      if (y) {
        return { ...item, y };
      }
      return item;
    })
    .sort((a, b) => a.y - b.y);

  headers.forEach((item, index) => {
    let numbers = [];

    const previousNumber = numberedHeaders[index - 1] || {};

    const commonPart = previousNumber?.numbers?.slice(0, item.depth - 1);

    if (item.depth <= previousNumber.depth) {
      const newNumber = previousNumber.numbers[item.depth - 1] + 1;
      numbers = [...commonPart, newNumber];
    } else if (item.depth > previousNumber.depth) {
      numbers = [...commonPart, 1];
    } else {
      numbers = [1];
    }

    numberedHeaders.push({
      ...item,
      numbers,
    });
  });

  const mappedNumberedHeaders = numberedHeaders.map((item) => {
    const { numbers, ...rest } = item;
    return {
      ...rest,
      number: numbers.join("."),
    };
  });

  return mappedNumberedHeaders;
};
