export const addTableOfContentsToGridElements = (newGridElements) => {
  const headers = newGridElements?.filter(
    (item) => item.gridBoxType === "header",
  );

  const partsCount = Math.ceil((headers.length + 1) / 20);

  for (let i = 1; i <= partsCount; i++) {
    const newElement = {
      label: `table-of-contents-${i}`,
      gridBoxType: "table-of-contents",
      section: "table-of-contents",
      part: i,
    };

    newGridElements.splice(i, 0, newElement);
  }
};
