import React from "react";

interface ExtensionsListItemContentProps {
  name: React.ReactNode;
  description?: React.ReactNode;
  controls?: React.ReactNode;
  icon?: React.ReactNode;
  extraContent?: React.ReactNode;
}

export default function ExtensionsListItemContent({
  name,
  description,
  controls,
  icon,
  extraContent,
}: ExtensionsListItemContentProps) {
  return (
    <li className="extensions__item">
      <div className="extensions__item-wrapper">
        <div className="extensions__row">
          {icon}
          <div className="extensions__name-and-description">
            <div className="extensions__name">{name}</div>
            {description && (
              <div className="extensions__description">{description}</div>
            )}
          </div>
        </div>
        {controls}
      </div>
      {extraContent}
    </li>
  );
}
