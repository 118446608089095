import React, { CSSProperties } from "react";

import classNames from "classnames";
import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import { valueExists } from "src/app/methods/valueExists";
import { CrossIcon } from "./CrossIcon";

interface SelectLabelProps {
  value?: any;
  onClickHandler?: () => void;
  emptyFieldPlaceholder: React.ReactNode;
  rejectMulti?: any;
  style?: CSSProperties;
  disableTooltip?: boolean;
  readOnly?: boolean;
  maxWidth?: number;
}

function SelectLabel({
  value,
  onClickHandler,
  emptyFieldPlaceholder,
  rejectMulti,
  style,
  disableTooltip,
  readOnly,
  maxWidth,
}: SelectLabelProps) {
  const isMulti = !!rejectMulti;

  return (
    <span
      onClick={!readOnly && onClickHandler ? onClickHandler : undefined}
      className={classNames([
        "select__label",
        "select__label--cell",
        !value?.name && "select__label--placeholder",
        !onClickHandler || (readOnly && "select__label--readOnly"),
      ])}
      style={
        valueExists(value)
          ? {
              ...style,
              color: `#${value?.color}`,
              backgroundColor: `#${value?.backgroundColor}`,
              transition: `color 0.2s ease, background-color 0.2s ease`,
            }
          : style
      }
    >
      {value?.name ? (
        <span
          className={classNames("select__label-text-wrapper", {
            "select__label-text-wrapper--multi": isMulti,
          })}
        >
          {!disableTooltip ? (
            <TooltipedEllipsis
              className="select__label-text"
              maxWidth={maxWidth ?? 112}
              content={value?.name}
            />
          ) : (
            value.name
          )}
        </span>
      ) : (
        emptyFieldPlaceholder
      )}
      {isMulti && (
        <CrossIcon
          fill={`#${value?.color}`}
          onClick={() => rejectMulti(value)}
        />
      )}
    </span>
  );
}

export default SelectLabel;
