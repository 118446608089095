import React, { useEffect, useState } from "react";

import { ReactComponent as TickIcon } from "src/images/tick.svg";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setCreatorsForCover } from "src/redux";

export function MultiCoverSelectorMenu(props) {
  const { visible } = props;

  const showcaseData = useSelector(
    (state) => state.showcaseReducer.showcaseData,
  );
  const creatorsForCover = useSelector(
    (state) => state.showcaseReducer.creatorsForCover,
  );
  const { elements } = showcaseData;

  const [showMenu, setShowMenu] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      setShowMenu(true);
    } else {
      setTimeout(() => {
        setShowMenu(false);
      }, 300);
    }
  }, [visible]);

  const handleOptionClick = (item) => {
    if (creatorsForCover.find((el) => el.id === item.id)) {
      const newCreatorsForCover = creatorsForCover.filter(
        (el) => el.id !== item.id,
      );
      dispatch(setCreatorsForCover(newCreatorsForCover));
    } else if (creatorsForCover.length < 3) {
      const newCreatorsForCover = [...creatorsForCover, item];
      dispatch(setCreatorsForCover(newCreatorsForCover));
    }
  };

  if (!showMenu && !visible) return null;

  return (
    <div
      className={classNames("template-selector__menu", {
        "template-selector__menu--visible": showMenu && visible,
      })}
    >
      <div className="template-selector__menu-separator" />
      <div className="template-selector__menu-list">
        {elements.map((item) => (
          <div
            className="template-selector__menu-item"
            onClick={() => handleOptionClick(item)}
          >
            <div className="template-selector__menu-item-left">
              <div>
                <div className="template-selector__menu-item-title">
                  <span>{item.name}</span>
                  {creatorsForCover?.find((el) => el.id === item.id) && (
                    <TickIcon />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
