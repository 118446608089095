import React, { useState } from "react";
import "./ToolbarSection.scss";

import { ReactComponent as ChevronIcon } from "src/images/chevron-down-alt.svg";
import classNames from "classnames";

export function ToolbarSection(props) {
  const { children, className, icon, name } = props;

  const [showContent, setShowContent] = useState(true);

  return (
    <div className={classNames("toolbar-section", className)}>
      <div
        className="toolbar-section__header"
        onClick={() => setShowContent((v) => !v)}
      >
        <span className="toolbar-section__header-data">
          {icon}
          <span className="toolbar-section__header-data-name">{name}</span>
        </span>

        <ChevronIcon
          className={classNames("arrow", { "arrow--rotated": showContent })}
        />
      </div>

      {showContent && (
        <div className="toolbar-section__content">{children}</div>
      )}
    </div>
  );
}
