export const hasMetricData = (metric, data) => {
  if (!data) return false;

  switch (metric.type) {
    case "audience-age": {
      const {
        user_audience_data: { audienceAgeGroups },
      } = data;

      if (!audienceAgeGroups) return false;
      return true;
    }

    case "audience-brand-affinity": {
      const {
        user_audience_data: { brandAffinity },
      } = data;

      if (!brandAffinity) return false;
      return true;
    }

    case "audience-interests": {
      const {
        user_audience_data: { audienceInterests },
      } = data;

      if (!audienceInterests) return false;
      return true;
    }

    case "avg-views": {
      const {
        user_data: { avgViews },
        viewsStatisticsData,
      } = data;

      if (avgViews === null) return false;
      return true;
    }

    case "bio": {
      const {
        user_data: { bio },
      } = data;

      if (!bio || bio === "-") return false;
      return true;
    }

    case "creators-brand-affinity": {
      let boxData = null;

      if (data?.user_audience_data?.influencersBrandAffinity) {
        boxData = data?.user_audience_data?.influencersBrandAffinity;
      }

      if (data?.creatorsBrandAffinity) {
        boxData = data?.creatorsBrandAffinity;
      }

      if (!boxData) return false;
      return true;
    }

    case "creator-growth": {
      const {
        followersStatisticsData,
        user_data: { followers },
      } = data;

      if (!followersStatisticsData && !followers) return false;
      return true;
    }

    case "creator-interests": {
      let boxData = null;

      if (data?.user_audience_data?.influencersInterests) {
        boxData = data?.user_audience_data?.influencersInterests;
      }

      if (data?.creatorsInterests) {
        boxData = data?.creatorsInterests;
      }

      if (!boxData) return false;
      return true;
    }

    case "credibility": {
      const {
        user_audience_data: { credibility },
      } = data;

      if (!credibility) return false;
      return true;
    }

    case "er": {
      const { user_data, userApiData } = data;

      if (!user_data && !userApiData) return false;
      return true;
    }

    case "female-per-age": {
      const {
        user_audience_data: { audienceWomen, gendersPerAge },
      } = data;

      if (!audienceWomen && !gendersPerAge) return false;
      return true;
    }

    case "followers": {
      const {
        user_audience_data: { notable, paidPostPerformance },
        user_data: { followers },
      } = data;

      if (!notable && !paidPostPerformance && !followers) return false;
      return true;
    }

    case "geographical-reach": {
      const {
        user_audience_data: { audienceGeographicalReach },
      } = data;

      if (!audienceGeographicalReach) return false;
      return true;
    }

    case "hashtags": {
      const { hashtags } = data;

      if (!hashtags) return false;
      return true;
    }

    case "keywords": {
      const { keywords } = data;

      if (!keywords) return false;
      return true;
    }

    case "languages": {
      const {
        user_audience_data: { audienceLanguages },
      } = data;

      if (!audienceLanguages) return false;
      return true;
    }

    case "location-by-city": {
      const {
        user_audience_data: { audienceCityReach },
      } = data;

      if (!audienceCityReach) return false;
      return true;
    }

    case "male-per-age": {
      const {
        user_audience_data: { audienceMen, gendersPerAge },
      } = data;

      if (!audienceMen && !gendersPerAge) return false;
      return true;
    }

    case "recently-published": {
      const { lastPostsData } = data;

      if (!lastPostsData.length) return false;
    }

    default: {
      return true;
    }
  }
};
