import classNames from "classnames";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useFreeSpaceFinder } from "src/app/methods/useFreeSpaceFinder";
import useOnClickOutside from "../../methods/useOnClickOutside";
import "./Dropdown.scss";

enum DropdownSize {
  Default = "default",
  Big = "big",
}

interface DropdownProps {
  toRight?: boolean;
  alignRight?: boolean;
  alignBottom?: boolean;
  toBottom?: boolean;
  fromRight?: boolean;
  block?: boolean;
  toggleGlobalState?: boolean;
  size?: "default" | "big";
  className?: string;
  children: (
    value: boolean,
    setterFunction: Dispatch<SetStateAction<boolean>>,
  ) => React.ReactNode;
}

export function Dropdown({
  toRight,
  alignRight,
  alignBottom,
  toBottom,
  fromRight,
  toggleGlobalState,
  block,
  children,
  size,
  className,
}: DropdownProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false);
    isOpen === true &&
      toggleGlobalState &&
      document.body.classList.remove("dd-active");
  });

  useEffect(() => {
    toggleGlobalState &&
      isOpen === true &&
      document.body.classList.add("dd-active");
  }, [isOpen]);

  return (
    <div
      ref={dropdownRef}
      className={classNames(
        "dropdown",
        {
          "dropdown--open": isOpen,
          "dropdown--to-right": toRight,
          "dropdown--align-right": alignRight,
          "dropdown--align-bottom": alignBottom,
          "dropdown--to-bottom": toBottom,
          "dropdown--from-right": fromRight,
          "dropdown--block": block,
          "dropdown--big": size === DropdownSize.Big,
        },
        className,
      )}
    >
      {children(isOpen, setIsOpen)}
    </div>
  );
}

interface DropdownMenuProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  onClose?: () => void;
  isOpen?: boolean;
}

export function DropdownMenu({
  children,
  className,
  isOpen = true,
  onClose,
  ...restProps
}: DropdownMenuProps) {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setShowMenu(true);
    } else {
      const id = setTimeout(() => {
        onClose && onClose();
        setShowMenu(false);
      }, 200);
      return () => clearTimeout(id);
    }
  }, [isOpen]);

  const variant = useFreeSpaceFinder(menuRef, isOpen);
  const pos = "0";

  return isOpen || showMenu ? (
    <div
      className={classNames(
        "dropdown__menu",
        {
          "dropdown__menu--open": showMenu && isOpen,
        },
        className,
      )}
      {...restProps}
      ref={menuRef}
      style={{
        right: variant === "left" ? pos : undefined,
        left: variant === "right" ? pos : undefined,
      }}
    >
      {children}
    </div>
  ) : null;
}

interface DropdownMenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  toggleGlobalState?: boolean;
  disabled?: boolean;
  dark?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function DropdownMenuItem({
  children,
  className,
  toggleGlobalState,
  disabled,
  onClick = () => undefined,
  dark,
  ...rest
}: DropdownMenuItemProps) {
  return (
    <div
      className={classNames(
        "dropdown__menu-item",
        {
          "dropdown__menu-item--disabled": disabled,
          "dropdown__menu-item--dark": dark,
        },
        className,
      )}
      {...rest}
      onClick={(e) => {
        onClick(e);
        if (toggleGlobalState) {
          document.body.classList.remove("dd-active");
        }
      }}
    >
      {children}
    </div>
  );
}

interface DropdownMenuSeparatorProps {
  children?: React.ReactNode;
  dashed?: boolean;
}

export function DropdownMenuSeparator({
  children,
  dashed,
}: DropdownMenuSeparatorProps) {
  return (
    <div
      className={classNames("dropdown__menu-separator", {
        "dropdown__menu-separator--dashed": dashed,
      })}
    >
      {children}
    </div>
  );
}

interface DropdownMenuTitleProps {
  children: React.ReactNode;
}

export function DropdownMenuTitle({ children }: DropdownMenuTitleProps) {
  return <h3 className="dropdown__menu-title">{children}</h3>;
}

export const DropdownMenuIcon: any = ({ src }: any) => {
  return <img className="dropdown__menu-icon" src={src} role="presentation" />;
};
