import { addHeaderToLayout } from "src/app/ReportGenerator/functions/addHeaderToLayout";
import { addSpaceIfNeeded } from "./addSpaceIfNeeded";

export const addSelectedCommentsToLayout = (
  newLayout,
  format,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.commentsAnalysis;

  Object.keys(campaigns).forEach((key) => {
    const {
      sentimentData: { comments },
      project: { title },
    } = campaigns[key];

    if (!comments) return;

    if (!aggregation && key !== "aggregated") {
      addHeaderToLayout(`comments-analysis-${title}`, newLayout, format, 3);
    }

    Object.keys(comments?.all)?.forEach((commentKey) => {
      const lastEl = addSpaceIfNeeded("comments", newLayout, format);

      let x = 0;
      let y = 0;
      const w = 6;
      const h = 5;

      if (lastEl.w === 12 || lastEl.x === 6) {
        x = 0;
        y = lastEl.y + lastEl.h;
      }
      // second in row and half the width
      else if (lastEl.x === 0) {
        x = lastEl.x + 6;
        y = lastEl.y;
      }

      newLayout.push({ i: `comments-${commentKey}`, x, y, w, h });
    });
  });
};
