import { CoverImage } from "src/redux/project/types";
import { IMember } from "src/app/project/ProjectTypes";
import { Metadata } from "src/app/Task/Subtask/Subtask";

export interface MessageRecipient {
  title: string;
  avatar: CoverImage | null;
  emailFromCrm: string | null;
  wsTaskUuid: string;
  crmEmail: Metadata;
}

export interface MessageDetails {
  dataProcessingInformation: string | null;
  fromEmail: string;
  recipients: MessageRecipient[];
  wsProjectMembers: IMember[];
}

export enum Steps {
  MANAGE_EMAILS,
  CREATE_EMAIL,
  CONFIRM_EMAIL,
}

export interface EmailContent {
  replyToEmail: string;
  includeInCcCampaignMembers: boolean;
  includeInBccCampaignMembers: boolean;
  subject: string;
  message: string;
  recipients: MessageRecipient[];
}
