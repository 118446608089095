import { ExtensionTypeEnum, WsExtensionCreatorMessenger } from "src/types";

export const creatorMessengerSettings = {
  encryption: null,
  fromEmail: null,
  fromName: null,
  host: null,
  login: null,
  password: null,
  port: null,
  wsGlobalTaskMetaFieldUuidWithEmail: null,
  dataProcessingInformation: null,
};

export const creatorMessengerInitialData = {
  enabled: false,
  type: ExtensionTypeEnum.CreatorMessenger,
  uuid: "",
  relatedUuid: null,
  wsWorkspaceUuid: null,
  wsProjectUuid: null,
  settings: creatorMessengerSettings,
} satisfies WsExtensionCreatorMessenger;
