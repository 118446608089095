import React from "react";

import { SocialBio } from "../../../../Task/tabs/ProfileDataTab/single/SocialBio";

export const renderBio = (label, data, layout, GridBoxComponent) => {
  const {
    user_data: { bio },
  } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <SocialBio bio={bio} height={height} />
    </GridBoxComponent>
  );
};
