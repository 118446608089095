import React from "react";
import { uuidv7 as uuid } from "uuidv7";

import { ReactComponent as AdditionIcon } from "src/images/math-operators/addition.svg";
import { ReactComponent as SubtractionIcon } from "src/images/math-operators/subtraction.svg";
import { ReactComponent as MultiplicationIcon } from "src/images/math-operators/multiplication.svg";
import { ReactComponent as DivisionIcon } from "src/images/math-operators/division.svg";
import { ReactComponent as PercentIcon } from "src/images/percent.svg";
import { ReactComponent as CurrencyIcon } from "src/images/currency.svg";
import { ReactComponent as CalendarIcon } from "src/images/calendar.svg";
import { ReactComponent as HashIcon } from "src/images/hash.svg";

import { ImageOption } from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import {
  getMetaFieldTypeOptions,
  MetaFieldType,
} from "src/app/methods/getMetaFieldTypeOptions";
import { ProjectMetaData } from "src/redux/project/types";
import { TasksColumn } from "src/redux/task/taskReducer";
import { tableDataType } from "src/app/components/Table/Table";
import { TaskType } from "src/types";
import {
  MetricsData,
  MFState,
  SelectedFormulaElement,
  SummaryMetricsData,
} from "./types";

export const METRIC_TYPES = [MetaFieldType.Metrics, MetaFieldType.SalesMetrics];
export const GLOBAL_FIELD_TYPES_BLACKLIST = [MetaFieldType.AccessLink];
export const EDITABLE_GLOBAL_FIELD_TYPES = [
  MetaFieldType.Currency,
  MetaFieldType.EMV,
];

export const matchOptions = [
  {
    label: <IDHFormattedMessage id="ws_match_all" defaultMessage="Match all" />,
    value: "AND",
  },
  {
    label: <IDHFormattedMessage id="ws_match_any" defaultMessage="Match any" />,
    value: "OR",
  },
];

export const mathOperatorOptions = [
  {
    label: <ImageOption icon={AdditionIcon} />,
    value: "+",
  },
  {
    label: <ImageOption icon={SubtractionIcon} />,
    value: "-",
  },
  {
    label: <ImageOption icon={MultiplicationIcon} />,
    value: "*",
  },
  {
    label: <ImageOption icon={DivisionIcon} />,
    value: "/",
  },
];

export const formulaMathematicalOptions = [
  {
    label: (
      <IDHFormattedMessage id="ws_sum_values" defaultMessage="Sum values" />
    ),
    value: "SUM",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_values_average"
        defaultMessage="Values average"
      />
    ),
    value: "AVG",
  },
  {
    label: (
      <IDHFormattedMessage id="ws_count_items" defaultMessage="Count items" />
    ),
    value: "COUNT",
  },
];

export const resultFormatOptions = [
  {
    value: MetaFieldType.Number,
    label: (
      <ImageOption icon={HashIcon}>
        <IDHFormattedMessage id="ws_number" defaultMessage="Number" />
      </ImageOption>
    ),
  },
  {
    value: MetaFieldType.Percent,
    label: (
      <ImageOption icon={PercentIcon}>
        <IDHFormattedMessage id="ws_percent" defaultMessage="Percent" />
      </ImageOption>
    ),
  },
  {
    value: MetaFieldType.Currency,
    label: (
      <ImageOption icon={CurrencyIcon}>
        <IDHFormattedMessage id="ws_currency" defaultMessage="Currency" />
      </ImageOption>
    ),
  },
  {
    value: MetaFieldType.Date,
    label: (
      <ImageOption icon={CalendarIcon}>
        <IDHFormattedMessage id="ws_date" defaultMessage="Date" />
      </ImageOption>
    ),
  },
];

interface GenerateProjectFormulaElementOptionsProps {
  projectMetaFields: ProjectMetaData[];
}

export const generateProjectFormulaElementOptions = ({
  projectMetaFields,
}: GenerateProjectFormulaElementOptionsProps): SelectedFormulaElement[] => {
  return projectMetaFields.map((projectMetaField) => ({
    label: projectMetaField.name,
    value: {
      id: projectMetaField.uuid,
      currencyCode: projectMetaField?.data?.currencyCode,
    },
  }));
};

interface GenerateTaskFormulaElementOptionsProps {
  tasksColumns: TasksColumn[];
}

export const generateTaskFormulaElementOptions = ({
  tasksColumns,
}: GenerateTaskFormulaElementOptionsProps): SelectedFormulaElement[] => {
  return tasksColumns.map((taskColumn) => ({
    label: taskColumn.metaFieldName,
    value: {
      id: taskColumn.metaFieldId,
      currencyCode: taskColumn?.data?.currencyCode,
    },
  }));
};

export const getInitialState = (
  data: any,
  defaultFieldName: string | undefined,
  defaultOption: MetaFieldType.AccessLink | undefined,
  taskTypeFromRedux: TaskType,
  dataType: tableDataType,
): MFState => {
  let selectedOption = {};

  if (defaultOption) {
    const foundMetaFieldTypeOption = getMetaFieldTypeOptions(
      taskTypeFromRedux,
      dataType,
    ).find((o) => o.value === defaultOption);
    if (foundMetaFieldTypeOption) {
      selectedOption = foundMetaFieldTypeOption;
    }
  }

  return {
    fieldName: data?.name || defaultFieldName || "",
    description: data?.description || "",
    nameEdited: !!data,
    selectedOption,
    selectedTaskType: { label: "All", value: null },
    metricsData: [],
    salesMetrics: [],
    salesMetricOptions: [],
    isAudienceReach: false,
    selectedMetric: {},
    selectedAudienceCountry: {},
    currenciesData: [],
    selectedCurrency: {},
    formulaTab: "table",
    selectedSummarySources: [],
    targetValue: "",
    formulaValue: {},
    matchValue: matchOptions[0],
    conditionColumns: [],
    conditionValues: [
      {
        uuid: uuid(),
        column: {
          label: "",
          value: null,
        },
        value: {
          label: "",
          value: null,
        },
      },
    ],
    availableTargets: [],
    availableTargetsValue: {},
    targetValueOption: "custom",
    valueSourceOptions: [],
    selectedPlatform: {},
    dataApplied: false,
    selectedColor: "Instagram",
    selectedIcon: {},
    addIcon: false,
    // Formula
    selectedFormulaElements: { a: {}, b: {} },
    selectedFormulaOperator: {},
    selectedResultFormat: {},
    globalMetaFieldData: [],
    teamsData: [],
    filterMemberByTeam: false,
    wsTeamUuids: [],
    showInCreatorDatabase: false,
    enableDictionarySubField: false,
    wsDictionarySubFieldUuid: null,
    relatedMetaField: {},
    relatedMetaFieldAggregationType: {},
    formulaElementOptions: [],
    emvValues: {},
  };
};

export const isMetricsDataArrayType = (
  metricsData: MetricsData | Array<SummaryMetricsData>,
): metricsData is Array<SummaryMetricsData> => {
  return Array.isArray(metricsData);
};
