import React from "react";

import { GeographicalReach } from "../../../../Task/tabs/ProfileDataTab/single/GeographicalReach";

export const renderGeographicalReach = (
  label,
  data,
  layout,
  GridBoxComponent,
) => {
  const {
    user_audience_data: { audienceGeographicalReach },
  } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <GeographicalReach
        audienceGeographicalReach={audienceGeographicalReach}
        height={height}
      />
    </GridBoxComponent>
  );
};
