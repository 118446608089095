import React, { useEffect, useMemo, useReducer, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { isStringUuid } from "src/utils/methods";
import { getGlobalTasks } from "src/redux/creator-database/creatorDatabaseActions";
import WebsocketListener from "src/WebsocketListener";
import { useHistory } from "react-router";
import {
  ReactComponent as PlusIcon,
  ReactComponent as PlusSignIcon,
} from "src/images/plus-white.svg";
import NoResultsScreen from "src/app/components/NoResultsScreen/NoResultsScreen";
import noResultsImg from "src/images/data-cloud.svg";
import noFilteredResultsImg from "src/images/empty-folder-group.svg";
import { ROUTES, getRoute } from "src/utils/ROUTES";
import moment from "moment";
import { uuidv7 } from "uuidv7";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { useQuery } from "../methods/useQuery";
import { GlobalTaskDetails } from "../components/GlobalTaskDetails/GlobalTaskDetails";
import { openGlobalTaskDetails } from "../components/GlobalTaskDetails/methods";
import { Button } from "../components/Button/Button";
import AddCreatorsModal from "../modals/AddCreatorsModal/AddCreatorsModal";
import creatorDatabaseFiltersReducer, {
  creatorDatabaseFiltersReducerInitialState,
  generateLackingRanks,
  generateMetadataRanks,
} from "./redux/creatorDatabaseFiltersReducer";
import { ReactComponent as FilterIcon } from "../../images/filter.svg";
import DictionarySortDropdown from "../components/DictionarySortDropdown/DictionarySortDropdown";
import { DictionaryFilters } from "../components/DictionaryFilters/DictionaryFilters";
import CreatorDatabaseExtensionDropdown from "../components/CreatorDatabaseExtensionDropdown/CreatorDatabaseExtensionDropdown";
import GlideDataGridWrapper from "../components/GlideDataGrid/GlideDataGridWrapper";
import MetaDataOptionsDropdown, {
  MetaDataOptionsContext,
} from "../components/TableOptionsDropdown/MetaDataOptionsDropdown";
import {
  resetHiddenColumns,
  setDictionaryFilter,
  setDictionaryFilters,
  setDictionaryInitialColumnRanks,
  setDictionarySort,
  setDictionarySortBy,
  setHiddenDictionaryColumnIds,
  setSelectedTableView,
  toggleDictionaryColumnVisibility,
} from "../components/DictionaryFilters/redux/dictionaryFiltersActions";
import { tableDataType } from "../components/Table/Table";
import resetDictionaryFilters from "./redux/creatorDatabaseFiltersActions";
import { FiltersContext } from "../components/DictionaryFilters/dictionaryFiltersData";
import { TaskType } from "../../types";
import TableViewSelector from "../TableView/TableViewSelector";
import { createCrmTableView } from "../TableView/tableViewFunctions";
import { SaveTableViewModal } from "../TableView/components/SaveTableViewModal/SaveTableViewModal";

export default function CreatorDatabase() {
  const activeWorkspaceUuid = useSelector(
    (state) => state.mainReducer.activeWorkspaceUuid,
  );

  const globalTasks = useSelector(
    (state) => state.creatorDatabaseReducer.globalTasks,
  );
  const globalTaskColumns = useSelector(
    (state) => state.creatorDatabaseReducer.globalTaskColumns,
  );
  const globalTasksTotalCount = useSelector(
    (state) => state.creatorDatabaseReducer.globalTasksTotalCount,
  );
  const wsTableViews = useSelector(
    (state) => state.creatorDatabaseReducer.wsTableViews,
  );
  const identity = useSelector((state) => state.mainReducer.identity);
  const loading = useSelector((state) => state.creatorDatabaseReducer.loading);

  const [showAddCreatorsModal, setShowAddCreatorsModal] = useState(false);
  const [creatorDatabaseFilters, dispatchCDF] = useReducer(
    creatorDatabaseFiltersReducer,
    creatorDatabaseFiltersReducerInitialState(globalTaskColumns),
  );
  const [elementToScrollUuid, setElementToScrollUuid] = useState(null);
  const [initialRankGenerationDone, setInitialRankGenerationDone] =
    useState(false);
  const [showSaveTableViewModal, setShowSaveTableViewModal] = useState(false);
  const [newTableViewName, setNewTableViewName] = useState("");

  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const [showFilters, setShowFilters] = useState(false);
  const showGlobalTask = isStringUuid(query.get("displayGlobalTask") || "");
  const {
    filters,
    sortBy,
    sort,
    hiddenGlobalTaskColumnIds,
    creatorDatabaseColumnRanks,
    selectedTableView = {
      label: "Custom",
      value: null,
    },
  } = creatorDatabaseFilters;

  const filtersLength =
    Object.keys(filters).length > 0 && `: ${Object.keys(filters).length}`;

  useEffect(() => {
    dispatch(getGlobalTasks(activeWorkspaceUuid, { filters, sortBy, sort }));
  }, [filters, sortBy, sort]);

  useEffect(() => {
    if (initialRankGenerationDone) return;
    const settingsData = JSON.parse(
      localStorage.getItem("ws-creator-database-settings"),
    );

    let settingsForWorkspace;

    if (settingsData) {
      settingsForWorkspace = settingsData[activeWorkspaceUuid];
    }
    if (
      globalTaskColumns?.length &&
      !settingsForWorkspace?.creatorDatabaseColumnRanks?.length
    ) {
      dispatchCDF(
        setDictionaryInitialColumnRanks(
          generateMetadataRanks(globalTaskColumns),
        ),
      );
      setInitialRankGenerationDone(true);
    } else if (
      globalTaskColumns.length &&
      globalTaskColumns.length !== creatorDatabaseColumnRanks.length
    ) {
      dispatchCDF(
        setDictionaryInitialColumnRanks(
          generateLackingRanks(
            globalTaskColumns,
            settingsForWorkspace?.creatorDatabaseColumnRanks,
          ),
        ),
      );
      setInitialRankGenerationDone(true);
    }
  }, [
    activeWorkspaceUuid,
    initialRankGenerationDone,
    globalTaskColumns,
    creatorDatabaseColumnRanks,
  ]);

  const handleRowNameClick = (taskUuid) => {
    openGlobalTaskDetails(history, taskUuid);
  };

  const handleRestoreDefault = () => {
    const lsItem = localStorage.getItem("ws-creator-database-settings");

    if (!lsItem) {
      return;
    }

    const settingsData = lsItem ? JSON.parse(lsItem) : null;
    delete settingsData[activeWorkspaceUuid];

    localStorage.setItem(
      "ws-creator-database-settings",
      JSON.stringify(settingsData),
    );

    dispatchCDF(
      setDictionaryInitialColumnRanks(generateMetadataRanks(globalTaskColumns)),
    );
    dispatchCDF(resetHiddenColumns());
    setInitialRankGenerationDone(true);
  };

  const saveTableView = async () => {
    const visibleColumns = globalTaskColumns
      .filter((field) => !hiddenGlobalTaskColumnIds?.includes(field.uuid))
      .map((field) => ({ uuid: field.uuid, exampleKey: "aaaa" }));

    const uuid = uuidv7();

    await createCrmTableView({
      filters: creatorDatabaseFilters.filters,
      sortBy: creatorDatabaseFilters.sortBy,
      sort: creatorDatabaseFilters.sort,
      visibleColumns,
      wsWorkspaceUuid: activeWorkspaceUuid,
      taskType: TaskType.Creator,
      name: newTableViewName,
      uuid,
    });
    dispatch(getGlobalTasks(activeWorkspaceUuid, { filters, sortBy, sort }));
    dispatchCDF(
      setSelectedTableView({
        label: newTableViewName,
        value: uuid,
      }),
    );
  };

  const renderHeaderToolbar = () => (
    <>
      {globalTasks.length > 0 && (
        <Button
          variant="blue"
          size="small"
          textWithIcon
          onClick={() => setShowAddCreatorsModal(true)}
        >
          <PlusIcon />
          <IDHFormattedMessage
            id="ws_add_creator"
            defaultMessage="Add Creator"
          />
        </Button>
      )}

      <Button
        variant="white"
        size="small"
        textWithIcon
        onClick={() => setShowFilters((prev) => !prev)}
        active={Object.keys(filters).length > 0}
      >
        <FilterIcon />
        <span>
          <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
          {filtersLength}
        </span>
      </Button>

      {globalTasks.length > 0 && (
        <>
          <DictionarySortDropdown
            fields={globalTaskColumns}
            dictionaryFilters={creatorDatabaseFilters}
            dispatchDictionaryFilters={dispatchCDF}
          />
          <CreatorDatabaseExtensionDropdown />
        </>
      )}

      {identity?.permissions?.workspace?.includes("manage_table_views") && (
        <Button
          variant="white"
          size="small"
          textWithIcon
          onClick={() => setShowSaveTableViewModal(true)}
        >
          <IDHFormattedMessage id="ws_save_view" defaultMessage="Save view" />
        </Button>
      )}

      <TableViewSelector
        wsTableViews={wsTableViews}
        selectedWsTableView={selectedTableView}
        setSelectedWsTableView={(newValue) =>
          dispatchCDF(setSelectedTableView(newValue))
        }
        setFilters={(newFilters) =>
          dispatchCDF(setDictionaryFilters(newFilters))
        }
        setFilter={(uuid, value) =>
          dispatchCDF(setDictionaryFilter(uuid, value))
        }
        setHiddenColumnIds={(columnIds) =>
          dispatchCDF(setHiddenDictionaryColumnIds(columnIds))
        }
        setSortBy={(newValue) => dispatchCDF(setDictionarySortBy(newValue))}
        setSort={(newValue) => dispatchCDF(setDictionarySort(newValue))}
        resetToDefaultSettings={handleRestoreDefault}
        columnIds={globalTaskColumns.map((item) => item.uuid)}
      />

      <MetaDataOptionsDropdown
        context={MetaDataOptionsContext.CreatorDatabase}
        fields={globalTaskColumns}
        fieldRanks={creatorDatabaseColumnRanks}
        hiddenColumns={hiddenGlobalTaskColumnIds}
        dispatchFunction={dispatchCDF}
        resetToDefaultSettings={handleRestoreDefault}
      />
    </>
  );

  const filteredColumns = useMemo(
    () =>
      globalTaskColumns
        .filter((col) => !hiddenGlobalTaskColumnIds?.includes(col.uuid))
        .sort((a, b) => {
          const rankA = creatorDatabaseColumnRanks?.find(
            (col) => col.uuid === a.uuid,
          )?.rank;
          const rankB = creatorDatabaseColumnRanks?.find(
            (col) => col.uuid === b.uuid,
          )?.rank;

          if (rankA && rankB) return rankA.localeCompare(rankB);
          return 0;
        }),
    [globalTaskColumns, hiddenGlobalTaskColumnIds, creatorDatabaseColumnRanks],
  );

  return (
    <>
      <GlideDataGridWrapper
        rowsData={globalTasks}
        columnsData={filteredColumns}
        totalRowsCount={globalTasksTotalCount}
        isLoading={loading}
        filtersAndSortingOptions={creatorDatabaseFilters}
        filtersAndSortingOptionsDispatch={dispatchCDF}
        header={
          <IDHFormattedMessage
            id="ws_creator_database"
            defaultMessage="Creator Database"
          />
        }
        description={
          <IDHFormattedMessage
            id="ws_creator_database_description"
            defaultMessage="Here you can manage a database of creators."
          />
        }
        renderHeaderToolbar={renderHeaderToolbar}
        titleColumnLabel="Creator"
        context={tableDataType.CreatorDatabase}
        handleRowNameClick={handleRowNameClick}
        elementToScrollUuid={elementToScrollUuid}
        setElementToScrollUuid={setElementToScrollUuid}
        noResultsScreen={
          <NoResultsScreen
            title={
              <IDHFormattedMessage
                id="ws_there_are_no_creators_in_database"
                defaultMessage="There are no Creators in database"
              />
            }
            subtitle={
              <IDHFormattedMessage
                id="ws_add_your_first_creator"
                defaultMessage="Add your first creator and start building your creator database. \n Analyze and benefit from a history of collaboration."
              />
            }
            imgUrl={noResultsImg}
            bottomContent={
              <Button
                size="large"
                variant="blue"
                onClick={() => setShowAddCreatorsModal(true)}
              >
                <PlusSignIcon />
                <IDHFormattedMessage
                  id="ws_add_creator"
                  defaultMessage="Add Creator"
                />
              </Button>
            }
          />
        }
        noFilteredResultsScreen={
          <NoResultsScreen
            title={
              <IDHFormattedMessage
                id="ws_no_results_found"
                defaultMessage="No results found"
              />
            }
            subtitle={
              <IDHFormattedMessage
                id="ws_remove_filters"
                defaultMessage="No results match the filter criteria. Remove filter or clear all filters to show results."
              />
            }
            imgUrl={noFilteredResultsImg}
            bottomContent={
              <Button
                size="large"
                variant="blue"
                onClick={() => dispatchCDF(resetDictionaryFilters())}
              >
                <IDHFormattedMessage
                  id="ws_clear_filters"
                  defaultMessage="Clear filters"
                />
              </Button>
            }
          />
        }
        openGlideElement={(elementUuid) =>
          getRoute(
            ROUTES.GLOBAL_TASK_DETAILS,
            { wsWorkspaceUuid: activeWorkspaceUuid },
            { displayGlobalTask: elementUuid },
          )
        }
      />
      <DictionaryFilters
        context={FiltersContext.CreatorDatabase}
        dictionaryFilters={creatorDatabaseFilters}
        dispatchDictionaryFilters={dispatchCDF}
        setFiltersVisible={setShowFilters}
        filtersVisible={showFilters}
        columns={globalTaskColumns}
        selectedWsTableViewUuid={selectedTableView.value}
      />
      {showGlobalTask && <GlobalTaskDetails />}
      {showAddCreatorsModal && (
        <AddCreatorsModal
          onClose={() => setShowAddCreatorsModal(false)}
          setElementToScrollUuid={setElementToScrollUuid}
          globalTaskMode
        />
      )}

      {showSaveTableViewModal && (
        <SaveTableViewModal
          onClose={() => setShowSaveTableViewModal(false)}
          name={newTableViewName}
          setName={setNewTableViewName}
          saveFunction={saveTableView}
        />
      )}

      <WebsocketListener />
    </>
  );
}
