import React from "react";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { setSummaryPlacement } from "src/redux";
import { summaryPlacementOptions } from "./summaryPlacementOptions";

export function SummaryPlacementSelector() {
  const summaryPlacement = useSelector(
    (state) => state.showcaseReducer.summaryPlacement,
  );

  const dispatch = useDispatch();

  return (
    <CustomSelect
      value={summaryPlacement}
      options={summaryPlacementOptions}
      onChange={(newValue) => dispatch(setSummaryPlacement(newValue))}
    />
  );
}
