import React from "react";
import axios from "axios";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { WsTableView } from "../../redux/dictionary/types";
import { API_URLS } from "../../utils/API_URLS";
import { TaskType } from "../../types";
import { showErrorToast } from "../../utils/methods";
import { showToast } from "../methods/showToast";

type DropdownOption = {
  label: React.ReactNode;
  value: string | null;
};

export const getTableViewOptions = (
  wsTableViews: WsTableView[],
): DropdownOption[] => {
  if (!wsTableViews) return [];

  const options: DropdownOption[] = [
    {
      label: "Custom",
      value: null,
    },
  ];

  wsTableViews.forEach((wsTableView) => {
    options.push({
      label: wsTableView.name,
      value: wsTableView.uuid,
    });
  });

  return options;
};

// CREATE TABLE VIEW
interface CRMViewProps {
  filters: any;
  sortBy: string | null;
  sort: string | null;
  visibleColumns: any;
  wsWorkspaceUuid: string;
  taskType: TaskType;
  uuid: string;
  name: string;
}

interface DictionaryViewProps {
  filters: any;
  sortBy: string | null;
  sort: string | null;
  visibleColumns: any;
  wsWorkspaceUuid: string;
  wsDictionaryUuid: string;
  uuid: string;
  name: string;
}

interface ProjectListViewProps {
  filters: any;
  sortBy: string | null;
  sort: string | null;
  visibleColumns: any;
  wsWorkspaceUuid: string;
  uuid: string;
  name: string;
}

export type TableViewProps =
  | CRMViewProps
  | DictionaryViewProps
  | ProjectListViewProps;

const createTableView = async (data: TableViewProps) => {
  try {
    await axios.post(API_URLS.createTableView, {
      ...data,
    });
  } catch (error) {
    console.error(error);
    showErrorToast();
  } finally {
    showToast(
      "success",
      <IDHFormattedMessage id="ws_success" defaultMessage="success" />,
      <IDHFormattedMessage
        id="ws_table_view_has_been_saved"
        defaultMessage="Table view has been saved"
      />,
    );
  }
};

export const createCrmTableView = async (data: CRMViewProps) => {
  await createTableView(data);
};

export const createDictionaryTableView = async (data: DictionaryViewProps) => {
  await createTableView(data);
};

export const createProjectListTableView = async (
  data: ProjectListViewProps,
) => {
  await createTableView(data);
};
