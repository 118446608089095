import React from "react";

import { Credibility } from "../../../../Task/tabs/ProfileDataTab/single/Credibility";

export const renderCredibility = (label, data, layout, GridBoxComponent) => {
  const {
    user_audience_data: {
      credibility,
      credibilityAudienceTypes,
      credibilityAudienceReachability,
    },
  } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <Credibility
        credibility={credibility}
        height={height}
        credibilityAudienceTypes={credibilityAudienceTypes}
        credibilityAudienceReachability={credibilityAudienceReachability}
      />
    </GridBoxComponent>
  );
};
