import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { REPORT_ADDITIONAL_PUBLICATION_METAFIELDS } from "src/app/ReportGenerator/functions/grid-elements/addPublicationsToGridElements";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import ToolbarList from "src/app/generator-data/components/ToolbarList/ToolbarList";
import { ToolbarListWrapper } from "src/app/generator-data/components/ToolbatListWrapper/ToolbarListWrapper";
import { ReactComponent as ChevronDown } from "src/images/chevron-down.svg";
import "./TopPublicationsOnToolbar.scss";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export function TopPublicationsOnToolbar(props) {
  const { loading, format, toolbarListProps, publications } = props;

  const [elements, setElements] = useState([]);
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    const publicationTopMetadata = publications[0]?.metadata
      .filter((item) =>
        REPORT_ADDITIONAL_PUBLICATION_METAFIELDS.includes(item.type),
      )
      .sort((a, b) => a.rank.localeCompare(b.rank));

    setElements(publicationTopMetadata || []);
  }, [publications]);

  return (
    <ToolbarListWrapper loading={loading}>
      <div className="toolbar-list">
        <div className="toolbar-list__content">
          <div className="toolbar-list__item">
            <div className="toolbar-list__item-wrapper">
              <CustomCheckbox
                className="top-publications-on-toolbar__checkbox"
                id="top-publications"
                checked={false}
                onChange={() => null}
                blue
              />
              <a href="#header-publication">
                <IDHFormattedMessage
                  id="ws_top_performing_publications"
                  defaultMessage="Top Performing Publications"
                />
              </a>
            </div>
            <span
              className={classNames("toolbar-list__item-button", {
                "toolbar-list__item-button--clicked": showList,
              })}
              onClick={() => setShowList((v) => !v)}
            >
              <ChevronDown />
            </span>
          </div>

          {showList && (
            <div className="toolbar-list__list" key={elements.length}>
              {elements.map((item) => {
                return (
                  <ToolbarList
                    provider={item.name}
                    format={format.value}
                    open={false}
                    toolbarListProps={toolbarListProps}
                    disableExpanding
                    isReport
                    dynamicProvider={`top-publication-${item.uuid}`}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </ToolbarListWrapper>
  );
}
