import { API_URLS } from "src/utils/API_URLS";
import { TaskType } from "src/types";
import {
  ADD_EXISTING_TASKS_TO_DATABASE,
  CLEAR_GLOBAL_TASK_CREATOR_LISTS,
  CLEAR_GLOBAL_TASK_DETAILS,
  CLEAR_GLOBAL_TASK_PROJECTS,
  CREATE_GLOBAL_TASKS,
  GET_GLOBAL_TASKS,
  GET_GLOBAL_TASK_CREATOR_LISTS,
  GET_GLOBAL_TASK_DETAILS,
  GET_GLOBAL_TASK_PROJECTS,
  GET_GLOBAL_TASK_RELATED_TASKS,
  GET_SINGLE_GLOBAL_TASK_FROM_LIST,
  REMOVE_GLOBAL_TASKS,
  SET_GLOBAL_TASK_META_VALUE,
  UPDATE_CREATOR_DATABASE_SELECT_FIELD,
  CLEAR_GLOBAL_TASK_RELATED_TASKS,
} from "./creatorDatabaseTypes";
import { GlobalTaskSocialProfile } from "./types";

export function getGlobalTasks(wsWorkspaceUuid: string, params?: any) {
  const url = API_URLS.getWsGlobalTasksByWorkspaceUuid.replace(
    ":wsWorkspaceUuid:",
    wsWorkspaceUuid,
  );
  return {
    type: GET_GLOBAL_TASKS,
    payload: { request: { url, params } },
  };
}

export function getSingleGlobalTaskFromList(wsGlobalTaskUuid: string) {
  const url = API_URLS.getSingleGlobalTaskFromList.replace(
    ":wsGlobalTaskUuid:",
    wsGlobalTaskUuid,
  );
  return {
    type: GET_SINGLE_GLOBAL_TASK_FROM_LIST,
    payload: { request: { url } },
  };
}

export function deleteGlobalTasks(uuids: string[]) {
  const url = API_URLS.deleteGlobalTasks;
  return {
    type: REMOVE_GLOBAL_TASKS,
    payload: {
      request: {
        method: "delete",
        url,
        data: {
          uuids,
        },
      },
    },
  };
}

export function getGlobalTaskDetails(taskId: string, skipLoading?: boolean) {
  const url = API_URLS.getGlobalTaskDetails.replace(":taskUuid:", taskId);

  return {
    type: GET_GLOBAL_TASK_DETAILS,
    payload: {
      request: { url },
      skipLoading,
    },
  };
}

export function clearGlobalTaskDetails() {
  return { type: CLEAR_GLOBAL_TASK_DETAILS };
}

export function setGlobalTaskMetaValue(
  ids: string | string[],
  metaFieldUuid: string,
  metaValue: any,
) {
  const uuids = typeof ids === "string" ? [ids] : ids;
  const url = API_URLS.updateGlobalTaskFieldValue;
  return {
    type: SET_GLOBAL_TASK_META_VALUE,
    payload: {
      request: {
        method: "put",
        url,
        data: {
          uuids,
          metaFieldUuid,
          metaValue,
        },
      },
      uuids,
      metaFieldUuid,
      metaValue,
    },
  };
}

export function createGlobalTasks(
  wsWorkspaceUuid: string,
  taskType: string,
  wsGlobalTasksData: {
    uuid: string;
    name: string;
    displayName?: string | null;
    socialProfiles: GlobalTaskSocialProfile[];
  }[],
) {
  return {
    type: CREATE_GLOBAL_TASKS,
    payload: {
      request: {
        method: "post",
        url: API_URLS.createGlobalTasks,
        data: {
          wsWorkspaceUuid,
          taskType,
          wsGlobalTasksData,
        },
      },
    },
  };
}

export function addExistingTasksToDatabase(wsTasksUuids: string[]) {
  return {
    type: ADD_EXISTING_TASKS_TO_DATABASE,
    payload: {
      request: {
        method: "post",
        url: API_URLS.addExistingTasksToDatabase,
        data: { wsTasksUuids },
      },
    },
  };
}

export function createPaymentsForSelectedTask(wsTasksUuids: string[]) {
  return {
    type: ADD_EXISTING_TASKS_TO_DATABASE,
    payload: {
      request: {
        method: "post",
        url: API_URLS.addExistingTasksToDatabase,
        data: { wsTasksUuids },
      },
    },
  };
}

export function updateCreatorDatabaseSelectField(
  metaFieldUuid: string,
  singleSelectOptions: any,
  wsWorkspaceUuid: string,
) {
  return {
    type: UPDATE_CREATOR_DATABASE_SELECT_FIELD,
    payload: {
      request: {
        method: "post",
        url: API_URLS.updateTaskGlobalSelectField,
        data: {
          metaFieldUuid,
          wsWorkspaceUuid,
          singleSelectOptions,
        },
      },
      metaFieldUuid,
      singleSelectOptions,
    },
  };
}

export function getGlobalTaskProjects(workspaceUuid: string, taskId: string) {
  const url = API_URLS.getGlobalTaskProjects
    .replace(":wsWorkspaceUuid:", workspaceUuid)
    .replace(":wsGlobalTaskUuid:", taskId);

  return {
    type: GET_GLOBAL_TASK_PROJECTS,
    payload: {
      request: { url },
    },
  };
}

export function clearGlobalTaskProjects() {
  return {
    type: CLEAR_GLOBAL_TASK_PROJECTS,
  };
}

export function getGlobalTaskCreatorLists(
  workspaceUuid: string,
  taskId: string,
) {
  const url = API_URLS.getGlobalTaskCreatorLists
    .replace(":wsWorkspaceUuid:", workspaceUuid)
    .replace(":wsGlobalTaskUuid:", taskId);

  return {
    type: GET_GLOBAL_TASK_CREATOR_LISTS,
    payload: {
      request: { url },
    },
  };
}

export function clearGlobalTaskCreatorLists() {
  return {
    type: CLEAR_GLOBAL_TASK_CREATOR_LISTS,
  };
}

export function getRelatedGlobalTasks(
  wsWorkspaceUuid: string,
  wsGlobalTaskUuid: string,
  taskType: TaskType,
) {
  const url = API_URLS.getTasksByWsGlobalTaskUuid
    .replace(":wsWorkspaceUuid:", wsWorkspaceUuid)
    .replace(":wsGlobalTaskUuid:", wsGlobalTaskUuid)
    .replace(":taskType:", taskType);

  return {
    type: GET_GLOBAL_TASK_RELATED_TASKS,
    payload: {
      request: { url },
      taskType,
    },
  };
}

export function clearRelatedGlobalTasks() {
  return {
    type: CLEAR_GLOBAL_TASK_RELATED_TASKS,
  };
}
