import React, { useEffect, useRef, useState } from "react";
import "./MultiCoverSelector.scss";

import classNames from "classnames";
import { ReactComponent as ChevronDown } from "src/images/chevron-down.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useDispatch, useSelector } from "react-redux";
import { setCreatorsForCover } from "src/redux";
import useOnClickOutside from "../../../methods/useOnClickOutside";
import { MultiCoverSelectorMenu } from "./MultiCoverSelectorMenu";

export function MultiCoverSelector(props) {
  const { disabled } = props;

  const [showMenu, setShowMenu] = useState(false);

  const creatorsForCover = useSelector(
    (state) => state.showcaseReducer.creatorsForCover,
  );
  const showcaseData = useSelector(
    (state) => state.showcaseReducer.showcaseData,
  );
  const { elements } = showcaseData;

  const selectorRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!elements?.length) return;

    dispatch(setCreatorsForCover(elements.slice(0, 3)));
  }, [elements]);

  useOnClickOutside(selectorRef, () => setShowMenu(false));

  return (
    <div
      ref={selectorRef}
      className={classNames("template-selector", {
        "template-selector--disabled": disabled,
      })}
    >
      <div
        className={classNames("template-selector__value", {
          "template-selector__value--menu-open": showMenu,
        })}
        onClick={() => setShowMenu(true)}
      >
        <span className="template-selector__value-placeholder">
          <IDHFormattedMessage
            id="ws_select_creators"
            defaultMessage="Select creators"
          />
          ...
        </span>
        <span className="template-selector__value-wrapper">
          {creatorsForCover?.length || 0}
          &nbsp;
          <IDHFormattedMessage
            id="ws_creators_selected"
            defaultMessage="Creators selected"
          />
        </span>
        <ChevronDown className="template-selector__value-chevron" />
      </div>

      <MultiCoverSelectorMenu visible={showMenu} />
    </div>
  );
}
