import React from "react";
import "./DataAggregation.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ToolbarSection } from "src/app/generator-data/components/ToolbarSection/ToolbarSection";
import { ReactComponent as DataAggregationIcon } from "src/images/data-aggregation.svg";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataAggregationValue,
  setReportGridElements,
  setReportHiddenGridElements,
  setReportLayout,
} from "src/redux";
import { sortLayout } from "src/app/generator-data/functions/sortLayout";
import { formatOptions } from "src/app/ReportGenerator/utils/sizingData";
import { addElementToLayoutAlt } from "src/app/generator-data/functions/addElementToLayoutAlt";
import { DATA_AGGREGATION_SECTIONS } from "./utils";

export function DataAggregation() {
  const dataAggregation = useSelector(
    (state) => state.reportReducer.dataAggregation,
  );
  const layout = useSelector((state) => state.reportReducer.layout);
  const gridElements = useSelector((state) => state.reportReducer.gridElements);
  const hiddenGridElements = useSelector(
    (state) => state.reportReducer.hiddenGridElements,
  );

  const format = formatOptions[0].value;

  const dispatch = useDispatch();

  const toggleSectionAggregation = (type) => {
    // show aggregated campaigns
    const newHiddenGridElements = hiddenGridElements.filter(
      (el) => el.gridBoxType !== type && el.headerType !== type,
    );
    const hiddenGridElementsToAdd = gridElements.filter(
      (el) => el.gridBoxType === type || el.headerType === type,
    );

    // hide separate campaigns
    const newGridElements = gridElements.filter(
      (el) => el.gridBoxType !== type && el.headerType !== type,
    );
    const gridElementsToAdd = hiddenGridElements.filter(
      (el) => el.gridBoxType === type || el.headerType === type,
    );

    const firstElementIndex = sortLayout(layout).findIndex(
      (item) => item.i === hiddenGridElementsToAdd[0].label,
    );

    const newLayout = sortLayout([...layout]).filter(
      (item) => !item.i.includes(`${type}-`),
    );

    const firstLayoutPart = layout.slice(0, firstElementIndex);
    let lastPart = newLayout.slice(firstElementIndex + 1, newLayout.length - 1);

    const lastElY = newLayout[firstElementIndex].y;

    dispatch(
      setReportHiddenGridElements([
        ...newHiddenGridElements,
        ...hiddenGridElementsToAdd,
      ]),
    );
    dispatch(setReportGridElements([...newGridElements, ...gridElementsToAdd]));

    for (let i = 0; i < gridElementsToAdd.length; i++) {
      addElementToLayoutAlt(
        gridElementsToAdd[i],
        firstLayoutPart,
        format,
        hiddenGridElements,
      );
    }

    const lastEl2 = firstLayoutPart[firstLayoutPart.length - 1];
    const lastEl2Y = lastEl2.y + lastEl2.h;

    const difference =
      Math.ceil((lastEl2Y - lastElY) / format.rows) * format.rows;

    lastPart = lastPart.map((item) => {
      if (item.i.includes("separator")) return item;
      return {
        ...item,
        y: item.y + difference,
      };
    });

    dispatch(setReportLayout([...firstLayoutPart, ...lastPart]));
  };

  const handleChange = (item, checked) => {
    const name = item.value;
    dispatch(setDataAggregationValue(name, checked));

    toggleSectionAggregation(item.type);
  };

  return (
    <ToolbarSection
      className="data-aggregation"
      icon={<DataAggregationIcon />}
      name={
        <IDHFormattedMessage
          id="ws_data_aggregation"
          defaultMessage="Data Aggregation"
        />
      }
    >
      {DATA_AGGREGATION_SECTIONS.map((item) => (
        <div className="data-aggregation__item">
          {item.label}
          <CustomSwitch
            checked={dataAggregation[item.value]}
            onChange={(e) => handleChange(item, e.target.checked)}
          />
        </div>
      ))}
    </ToolbarSection>
  );
}
