import React from "react";
import { Img } from "src/app/components/Img/Img";
import { ToolbarSection } from "src/app/generator-data/components/ToolbarSection/ToolbarSection";
import { getImageSource } from "src/utils/methods";
import ProjectPlaceholder from "src/images/project-placeholder.svg";

export function CampaignData(props) {
  const { children, data } = props;

  const { cover, title } = data?.project;

  return (
    <ToolbarSection
      icon={
        <Img
          className="glide-data-grid__hovered-item-cover"
          src={getImageSource(cover, "tiny", ProjectPlaceholder)}
          fallbackImageSrc={ProjectPlaceholder}
        />
      }
      name={title}
    >
      {children}
    </ToolbarSection>
  );
}
