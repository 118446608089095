import { PictogramVariants } from "src/app/components/Pictogram/Pictogram";

export enum FieldType {
  SingleSelect = "singleSelect",
  MultiSelect = "multiSelect",
  AggregatedSelect = "aggregatedSelect",
  Date = "date",
  BoolVal = "boolVal",
  DictionaryElement = "dictionaryElement",
  Rating = "rating",
}

export enum FieldKey {
  Completed = "completed",
  Assignee = "assignee",
  DueDate = "dueDate",
  PublishingDueDate = "Publishing Due Date",
}

export enum MetafieldAccessMode {
  write = "write",
  read = "read",
}
export enum MemberWithNotificationType {
  member = "member",
  team = "team",
}

export interface SelectOption {
  backgroundColor: string;
  color: string;
  name: string;
  rank: string;
  value: string;
  icon?: {
    name: string;
    variant: PictogramVariants;
  };
}
