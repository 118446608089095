import React, { useState } from "react";
import "./CoverSummary.scss";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useDispatch, useSelector } from "react-redux";
import { setTextSummary } from "src/redux";
import { ShowcaseSeparator } from "../../../../components/ShowcaseSeparator/ShowcaseSeparator";

export function CoverSummary(props) {
  const { workspaceData, printing } = props;

  const textSummary = useSelector((state) => state.showcaseReducer.textSummary);
  const summaryPlacement = useSelector(
    (state) => state.showcaseReducer.summaryPlacement,
  );
  const placement = summaryPlacement.value;

  const identity = useSelector((state) => state.mainReducer.identity);
  const primaryColor = identity.customPrimaryColor || "#5D78FF";

  const [value, setValue] = useState(textSummary);

  const dispatch = useDispatch();

  const handleBlur = (e) => {
    dispatch(setTextSummary(e.target.value));
  };

  return (
    <div className="cover-summary">
      {placement === "top" && (
        <ShowcaseSeparator
          workspaceData={workspaceData}
          hideInfo
          printing={printing}
        />
      )}

      <div className="cover-summary__content">
        <div className="cover-summary__box">
          <div className="cover-summary__box-title">
            <IDHFormattedMessage id="ws_summary" defaultMessage="Summary" />
          </div>

          <textarea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={handleBlur}
          />
        </div>
      </div>

      {placement === "bottom" && (
        <ShowcaseSeparator
          workspaceData={workspaceData}
          primaryColor={primaryColor}
          printing={printing}
        />
      )}
    </div>
  );
}
