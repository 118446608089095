import React, { useMemo, useRef } from "react";
import "./TableViewSelector.scss";
import { ReactComponent as EyeIcon } from "src/images/eye.svg";
import { ReactComponent as SelectedIcon } from "src/images/selected.svg";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "../components/Dropdown/Dropdown";
import { Button } from "../components/Button/Button";
import { getTableViewOptions } from "./tableViewFunctions";
import { DropdownPopper } from "../components/DropdownPopper/DropdownPopper";

export default function TableViewSelector({
  wsTableViews,
  selectedWsTableView,
  setSelectedWsTableView,
  setFilters,
  setFilter,
  setHiddenColumnIds,
  setSortBy,
  setSort,
  resetToDefaultSettings,
  columnIds,
}) {
  const dropdownRef = useRef(null);

  const options = useMemo(
    () => getTableViewOptions(wsTableViews),
    [wsTableViews],
  );

  const localSelectedOption = useMemo(() => {
    const option = options.find(
      (item) => item.value === selectedWsTableView.value,
    );

    if (option) return option;

    return {
      label: "Custom",
      value: null,
    };
  }, [options, selectedWsTableView]);

  const onChangeWsTableView = (wsTableViewUuid) => {
    if (wsTableViewUuid.value === null) {
      setFilters([]);
      resetToDefaultSettings();

      setSelectedWsTableView({
        label: "Custom",
        value: null,
      });

      return;
    }

    const sourceOfSelectedWsTableView = wsTableViews.find(
      (wsTableView) => wsTableView.uuid === wsTableViewUuid.value,
    );

    if (!sourceOfSelectedWsTableView) {
      return;
    }

    if (
      sourceOfSelectedWsTableView &&
      sourceOfSelectedWsTableView.data &&
      sourceOfSelectedWsTableView.data.filters
    ) {
      const { filters } = sourceOfSelectedWsTableView.data;

      Object.entries(filters).forEach(([wsFieldUuid, value]) => {
        setFilter(wsFieldUuid, value.value);
      });
    }

    const visibleColumnIds = sourceOfSelectedWsTableView.data.fields.map(
      (field) => field.uuid,
    );

    const hiddenColumnIds = columnIds.filter(
      (id) => !visibleColumnIds.includes(id),
    );

    setHiddenColumnIds(hiddenColumnIds);
    setSortBy(sourceOfSelectedWsTableView.data.sortBy);
    setSort(sourceOfSelectedWsTableView.data.sort);
    setSelectedWsTableView(wsTableViewUuid);
  };

  return (
    <DropdownPopper
      overlay={
        <DropdownMenu isOpen>
          {options.length ? (
            options.map((option) => (
              <DropdownMenuItem
                key={option.value}
                dark={option.value === localSelectedOption?.value}
                onClick={() => {
                  onChangeWsTableView(option);
                  dropdownRef.current.forceVisible(false);
                }}
              >
                {option.label}

                {option.value === localSelectedOption?.value && (
                  <SelectedIcon style={{ marginLeft: 8, marginRight: 0 }} />
                )}
              </DropdownMenuItem>
            ))
          ) : (
            <DropdownMenuItem>
              <IDHFormattedMessage
                id="ws_nothing_to_sort"
                defaultMessage="Nothing to sort"
              />
            </DropdownMenuItem>
          )}
        </DropdownMenu>
      }
      ref={dropdownRef}
      placement="bottom-start"
    >
      <Button size="small" textWithIcon variant="white">
        <EyeIcon />
        <IDHFormattedMessage id="ws_view" defaultMessage="View" />
        :&nbsp;
        {localSelectedOption?.label}
      </Button>
    </DropdownPopper>
  );
}
