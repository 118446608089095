import React from "react";

import { AudienceAgeAndGender } from "../../../../Task/tabs/ProfileDataTab/single/AudienceAgeAndGender";

export const renderAudienceAge = (label, data, layout, GridBoxComponent) => {
  const {
    user_audience_data: { audienceAgeGroups },
  } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <AudienceAgeAndGender
        audienceAgeGroups={audienceAgeGroups}
        height={height}
      />
    </GridBoxComponent>
  );
};
