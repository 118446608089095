import { renderAudienceAge } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderAudienceAge";
import { renderAudienceBrandAffinity } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderAudienceBrandAffinity";
import { renderAudienceInterests } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderAudienceInterests";
import { renderCreatorInterests } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderCreatorInterests";
import { renderCreatorsBrandAffinity } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderCreatorsBrandAffinity";
import { renderCredibility } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderCredibility";
import { renderFemalePerAge } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderFemalePerAge";
import { renderGeographicalReach } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderGeographicalReach";
import { renderLanguages } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderLanguages";
import { renderLocationByCity } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderLocationByCity";
import { renderMalePerAge } from "../../CreatorShowcase/components/ShowcaseTemplate/metrics/renderMalePerAge";
import { ReportGridBox } from "../components/ReportTemplate/components/ReportGridBox/ReportGridBox";

export const renderAudienceData = (data, audienceData, layout) => {
  if (!audienceData) return;

  const userAudienceData = { user_audience_data: audienceData };

  switch (data.type) {
    case "credibility":
      return renderCredibility(
        data.label,
        userAudienceData,
        layout,
        ReportGridBox,
      );

    case "audience-age":
      return renderAudienceAge(
        data.label,
        userAudienceData,
        layout,
        ReportGridBox,
      );

    case "female-per-age":
      return renderFemalePerAge(
        data.label,
        userAudienceData,
        layout,
        ReportGridBox,
      );

    case "male-per-age":
      return renderMalePerAge(
        data.label,
        userAudienceData,
        layout,
        ReportGridBox,
      );

    case "languages":
      return renderLanguages(
        data.label,
        userAudienceData,
        layout,
        ReportGridBox,
      );

    case "geographical-reach":
      return renderGeographicalReach(
        data.label,
        userAudienceData,
        layout,
        ReportGridBox,
      );

    case "location-by-city":
      return renderLocationByCity(
        data.label,
        userAudienceData,
        layout,
        ReportGridBox,
      );

    case "audience-interests":
      return renderAudienceInterests(
        data.label,
        userAudienceData,
        layout,
        ReportGridBox,
      );

    case "audience-brand-affinity":
      return renderAudienceBrandAffinity(
        data.label,
        userAudienceData,
        layout,
        ReportGridBox,
      );
  }
};
