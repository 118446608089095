import React from "react";
import "./StackedAvatars.scss";
import classNames from "classnames";

import CreatorPlaceholder from "../../../images/creator-placeholder.svg";
import AvatarPlaceholder from "../../../images/avatar-purple.svg";
import CustomImage from "../CustomImage/CustomImage";

interface Props {
  avatars: any[];
  maxAvatars: number;
  stackedUsers?: boolean;
  small?: boolean;
  onClick?: () => void;
}

export const StackedAvatars: React.FC<Props> = (props) => {
  const { avatars, maxAvatars, stackedUsers, small, onClick } = props;

  const avatarsLeft = avatars.length - maxAvatars;

  const getImgSrc = (item: any) => {
    if (stackedUsers) {
      return item.avatarUrl || AvatarPlaceholder;
    }

    if (typeof item === "string") return item;

    return item.url || CreatorPlaceholder;
  };

  return (
    <div
      className={classNames("stacked-avatars", {
        "stacked-avatars--users": stackedUsers,
        "stacked-avatars--with-on-click": onClick,
        "stacked-avatars--small": small,
      })}
      onClick={onClick}
    >
      {avatars
        ?.slice(0, maxAvatars)
        .map((item) => (
          <CustomImage
            key={getImgSrc(item)}
            className={classNames([
              "avatar",
              item.isClient && "avatar--client",
            ])}
            src={getImgSrc(item)}
            alt="avatar"
            loading="lazy"
          />
        ))}

      {avatarsLeft > 0 && <span className="avatars-left">+{avatarsLeft}</span>}
    </div>
  );
};
