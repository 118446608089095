export const GET_SHOWCASE_DATA = "GET_SHOWCASE_DATA";
export const CLEAR_SHOWCASE_DATA = "CLEAR_SHOWCASE_DATA";
export const CLEAR_SELECTED_CREATOR_DATA = "CLEAR_SELECTED_CREATOR_DATA";
export const GET_SHOWCASE_DATA_SUCCESS = "GET_SHOWCASE_DATA_SUCCESS";
export const SET_SELECTED_CREATOR_DATA = "SET_SELECTED_CREATOR_DATA";
export const SET_GENERATING = "SET_GENERATING";
export const SET_PRINTING = "SET_PRINTING";
export const SET_LAYOUT = "SET_LAYOUT";
export const SET_SAVED_TEMPLATE = "SET_SAVED_TEMPLATE";
export const SET_GRID_ELEMENTS = "SET_GRID_ELEMENTS";
export const SET_HIDDEN_GRID_ELEMENTS = "SET_HIDDEN_GRID_ELEMENTS";
export const SET_SELECTED_CREATORS = "SET_SELECTED_CREATORS";
export const SET_SHOWCASE_ELEMENTS = "SET_SHOWCASE_ELEMENTS";
export const SET_MULTIPLE_LAYOUTS = "SET_MULTIPLE_LAYOUTS";
export const SET_SELECTED_TEMPLATE = "SET_SELECTED_TEMPLATE";
export const SET_STARTING_TEMPLATES = "SET_STARTING_TEMPLATES";
export const SET_TEMPLATE_BASE = "SET_TEMPLATE_BASE";
export const SET_SHOWCASE_TRANSITION = "SET_SHOWCASE_TRANSITION";
export const SET_CREATORS_FOR_COVER = "SET_CREATORS_FOR_COVER";
export const SET_TEXT_SUMMARY = "SET_TEXT_SUMMARY";
export const SET_SUMMARY_PLACEMENT = "SET_SUMMARY_PLACEMENT";
export const SET_SHOWCASE_TITLE = "SET_SHOWCASE_TITLE";
