import React, { useEffect, useRef, useState } from "react";
import "./CreatorSelector.scss";

import { useHistory } from "react-router";

import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import CreatorPlaceholder from "src/images/creator-placeholder.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import useOnClickOutside from "../../../methods/useOnClickOutside";

import CustomImage from "../../../components/CustomImage/CustomImage";
import { ReactComponent as ChevronDown } from "../../../../images/chevron-down.svg";
import { setMultipleLayouts, setShowcaseElements } from "../../../../redux";
import { CreatorSelectorMenu } from "./CreatorSelectorMenu";
import {
  getShowcaseSettingByKey,
  showAnotherCreator,
  updateModeParam,
} from "../../utils";

export function CreatorSelector(props) {
  const { disabled } = props;

  const [activeCreator, setActiveCreator] = useState({});
  const [showMenu, setShowMenu] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const multipleLayouts = useSelector(
    (state) => state.showcaseReducer.multipleLayouts,
  );
  const showcaseData = useSelector(
    (state) => state.showcaseReducer.showcaseData,
  );

  const { elements } = showcaseData;

  const creatorId = getShowcaseSettingByKey("creatorId");

  const selectorRef = useRef();

  useOnClickOutside(selectorRef, () => setShowMenu(false));

  useEffect(() => {
    setActiveCreator(elements?.find((item) => item.id === creatorId));
  }, [creatorId, elements]);

  const handleItemClick = (targetCreatorId) => {
    setShowMenu(false);
    showAnotherCreator(history, targetCreatorId);
  };

  const handleDeleteClick = (e, targetCreatorId) => {
    e.stopPropagation();

    const newSelectedCreators = elements.filter(
      (item) => item.id !== targetCreatorId,
    );

    const newMultipleLayouts = multipleLayouts.filter(
      (item) => item.creatorId !== targetCreatorId,
    );

    updateModeParam(
      history,
      "ids",
      newSelectedCreators.map((item) => item.id),
    );
    dispatch(setShowcaseElements(newSelectedCreators));
    dispatch(setMultipleLayouts(newMultipleLayouts));
    // dispatch(setSelectedCreators(newSelectedCreators));
  };

  return (
    <div
      ref={selectorRef}
      className={classNames("creator-selector", {
        "creator-selector--disabled": disabled,
      })}
    >
      <div
        className={classNames("creator-selector__value", {
          "creator-selector__value--menu-open": showMenu,
        })}
        onClick={() => setShowMenu(true)}
      >
        <span className="creator-selector__value-placeholder">
          <IDHFormattedMessage
            id="ws_select_creator"
            defaultMessage="Select Creator"
          />
          ...
        </span>
        <span className="creator-selector__value-wrapper">
          <CustomImage
            src={activeCreator?.cover}
            fallbackImageSrc={CreatorPlaceholder}
          />
          <span>{activeCreator?.name}</span>
        </span>
        <ChevronDown className="creator-selector__value-chevron" />
      </div>

      <CreatorSelectorMenu
        visible={showMenu}
        handleCreatorClick={handleItemClick}
        handleDeleteClick={handleDeleteClick}
      />
    </div>
  );
}
