import React, { useState } from "react";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import axios from "axios";

import { ReactComponent as LoaderSpinner } from "src/images/loader-dots.svg";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import { translateMessage } from "src/app/methods/translateMessage";
import { Button } from "src/app/components/Button/Button";
import { ReactComponent as LightningIcon } from "src/images/lightning_grey.svg";
import { ModalColumn } from "src/app/components/Modal/Modal";
import {
  WsExtensionCreatorMessenger,
  WsExtensionCreatorMessengerDetails,
} from "src/types";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import { API_URLS } from "src/utils/API_URLS";
import responseCodes from "src/utils/responseCodes";

interface ServerConfigurationTabProps {
  messengerExtensionDetails: WsExtensionCreatorMessengerDetails;
  handleUpdateSettings: (
    fieldName: keyof WsExtensionCreatorMessenger["settings"],
  ) => Promise<void>;
  handleChangeSettings: (
    fieldName: keyof WsExtensionCreatorMessenger["settings"],
    value: string | null,
  ) => void;
}

export default function ServerConfigurationTab({
  messengerExtensionDetails,
  handleUpdateSettings,
  handleChangeSettings,
}: ServerConfigurationTabProps) {
  const intl = useIntl();

  const portValue =
    (typeof messengerExtensionDetails.port === "number" &&
      messengerExtensionDetails.port === 0) ||
    messengerExtensionDetails.port === null
      ? ""
      : messengerExtensionDetails.port.toString();

  const [isTestingConnection, setIsTestingConnection] =
    useState<boolean>(false);

  const handleTestConnection = async () => {
    setIsTestingConnection(true);
    const url = API_URLS.testMessengerConnection.replace(
      ":wsExtensionUuid:",
      messengerExtensionDetails.uuid,
    );
    try {
      const response = await axios.get(url);

      if (response.status !== responseCodes["200_OK"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      } else {
        toast.success(
          <IDHFormattedMessage
            id="ws_test_completed_successfully"
            defaultMessage="Test completed successfully"
          />,
        );
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({ id: "ws_test_failed", defaultMessage: "Test failed" });
      }
      console.error(error);
    } finally {
      setIsTestingConnection(false);
    }
  };
  return (
    <div className="creator-messenger__general-wrapper">
      <div className="creator-messenger__input-row">
        <ModalColumn>
          <CustomInputLabel htmlFor="name">
            <IDHFormattedMessage id="ws_name" defaultMessage="Name" />
          </CustomInputLabel>
          <CustomInput
            id="name"
            type="text"
            value={messengerExtensionDetails.fromName ?? ""}
            placeholder={translateMessage({
              intl,
              id: "ws_enter_name",
              defaultMessage: "Enter name",
            })}
            onChange={(e) => handleChangeSettings("fromName", e.target.value)}
            onBlur={() => handleUpdateSettings("fromName")}
          />
        </ModalColumn>
        <ModalColumn>
          <CustomInputLabel htmlFor="email-address">
            <IDHFormattedMessage
              id="ws_email_address"
              defaultMessage="E-mail address"
            />
          </CustomInputLabel>
          <CustomInput
            id="email-address"
            name="fromEmail"
            type="text"
            value={messengerExtensionDetails.fromEmail ?? ""}
            placeholder={translateMessage({
              intl,
              id: "ws_enter_email",
              defaultMessage: "Enter email",
            })}
            onChange={(e) => handleChangeSettings("fromEmail", e.target.value)}
            onBlur={() => handleUpdateSettings("fromEmail")}
          />
        </ModalColumn>
      </div>

      <div className="creator-messenger__input-row">
        <ModalColumn>
          <CustomInputLabel htmlFor="login">
            <IDHFormattedMessage id="ws_login" defaultMessage="Login" />
          </CustomInputLabel>
          <CustomInput
            id="login"
            name="login"
            type="text"
            value={messengerExtensionDetails.login ?? ""}
            placeholder={translateMessage({
              intl,
              id: "ws_enter_login",
              defaultMessage: "Enter login",
            })}
            onChange={(e) => handleChangeSettings("login", e.target.value)}
            onBlur={() => handleUpdateSettings("login")}
          />
        </ModalColumn>
        <ModalColumn>
          <CustomInputLabel htmlFor="password">
            <IDHFormattedMessage id="ws_password" defaultMessage="Password" />
          </CustomInputLabel>
          <CustomInput
            id="password"
            name="password"
            type="password"
            autoComplete="off"
            value={messengerExtensionDetails.password ?? ""}
            placeholder={translateMessage({
              intl,
              id: "ws_enter_password",
              defaultMessage: "Enter password",
            })}
            onChange={(e) => handleChangeSettings("password", e.target.value)}
            onBlur={() => handleUpdateSettings("password")}
          />
        </ModalColumn>
      </div>

      <div className="creator-messenger__input-row">
        <ModalColumn>
          <CustomInputLabel htmlFor="host">
            <IDHFormattedMessage id="ws_host" defaultMessage="Host" />
          </CustomInputLabel>
          <CustomInput
            id="host"
            name="host"
            type="text"
            value={messengerExtensionDetails.host ?? ""}
            placeholder={translateMessage({
              intl,
              id: "ws_enter_host",
              defaultMessage: "Enter host",
            })}
            onChange={(e) => handleChangeSettings("host", e.target.value)}
            onBlur={() => handleUpdateSettings("host")}
          />
        </ModalColumn>
        <ModalColumn>
          <CustomInputLabel htmlFor="port">
            <IDHFormattedMessage id="ws_port" defaultMessage="Port" />
          </CustomInputLabel>
          <CustomInput
            id="port"
            name="port"
            type="number"
            value={portValue}
            placeholder={translateMessage({
              intl,
              id: "ws_enter_port",
              defaultMessage: "Enter port",
            })}
            onChange={(e) => handleChangeSettings("port", e.target.value)}
            onBlur={() => handleUpdateSettings("port")}
          />
        </ModalColumn>
      </div>

      <div className="creator-messenger__input-row">
        <ModalColumn>
          <CustomInputLabel htmlFor="encryption">
            <IDHFormattedMessage
              id="ws_encryption"
              defaultMessage="Encryption"
            />
          </CustomInputLabel>
          <CustomInput
            id="encryption"
            name="encryption"
            type="text"
            value={messengerExtensionDetails.encryption ?? ""}
            placeholder={translateMessage({
              intl,
              id: "ws_enter_encryption",
              defaultMessage: "Enter encryption",
            })}
            onChange={(e) => handleChangeSettings("encryption", e.target.value)}
            onBlur={() => handleUpdateSettings("encryption")}
          />
        </ModalColumn>
      </div>

      <div>
        {isTestingConnection ? (
          <span className="creator-messenger__connection-test">
            <LoaderSpinner />
            <IDHFormattedMessage
              id="ws_checking_connection"
              defaultMessage="Checking connection. Please wait..."
            />
          </span>
        ) : (
          <Button
            variant="white-with-light-grey-border"
            size="thin"
            onClick={handleTestConnection}
          >
            <LightningIcon />
            <IDHFormattedMessage
              id="ws_test_connection"
              defaultMessage="Test connection"
            />
          </Button>
        )}
      </div>
    </div>
  );
}
