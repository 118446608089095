import React, {
  Dispatch,
  DispatchWithoutAction,
  useMemo,
  useRef,
  useState,
} from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import "./MetaDataOptionsDropdown.scss";

import { DropdownPopper } from "src/app/components/DropdownPopper/DropdownPopper";
import {
  DropdownMenu,
  DropdownMenuSeparator,
  DropdownMenuTitle,
} from "src/app/components/Dropdown/Dropdown";

import { ReactComponent as CustomizeIcon } from "src/images/settings-alt.svg";
import { Button } from "src/app/components/Button/Button";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { generateRankString } from "src/utils/rankStrings";
import { setProjectColumnRank } from "src/redux";
import { generateLackingRanks } from "src/app/CreatorDatabase/redux/creatorDatabaseFiltersReducer";
import AnimatedDiv from "../AnimatedDiv/AnimatedDiv";
import { setDictionaryColumnRank } from "../DictionaryFilters/redux/dictionaryFiltersActions";
import { MetaDataOptionsFieldSwitch } from "./components/MetaDataOptionsFieldSwitch";

export enum MetaDataOptionsContext {
  CreatorDatabase = "creator-database",
  Dictionary = "dictionary",
  ProjectList = "project-list",
}

interface SimpleField {
  name: string;
  uuid: string;
}

export interface FieldRank {
  uuid: string;
  rank: string;
}
interface MetaDataOptionsDropdownInterface {
  context: MetaDataOptionsContext;
  fields: SimpleField[];
  fieldRanks: FieldRank[];
  hiddenColumns: string[];
  dispatchFunction: DispatchWithoutAction | Dispatch<any>;
  resetToDefaultSettings: () => void;
  selectedWsTableViewUuid?: string | null;
}

export default function MetaDataOptionsDropdown({
  context,
  fields,
  fieldRanks,
  hiddenColumns,
  dispatchFunction,
  resetToDefaultSettings,
}: MetaDataOptionsDropdownInterface) {
  const dropdownRef = useRef(null);

  const [placeholderProps, setPlaceholderProps] = useState<any>({});

  const sortedRanks: FieldRank[] = useMemo(() => {
    const ranks = fieldRanks?.sort((a, b) => a.rank.localeCompare(b.rank));
    return generateLackingRanks(fields, ranks);
  }, [fields, fieldRanks]);

  const sortedFields = useMemo(
    () =>
      fields?.sort((a, b) => {
        const rankA = sortedRanks?.find((col) => col.uuid === a.uuid)?.rank;
        const rankB = sortedRanks?.find((col) => col.uuid === b.uuid)?.rank;
        if (rankA && rankB) return rankA.localeCompare(rankB);
        return 0;
      }),
    [fields, sortedRanks],
  );

  const handleDragUpdate = (update: any) => {
    if (!update.destination) {
      return;
    }

    const queryAttr = "data-rbd-drag-handle-draggable-id";

    const { draggableId } = update;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery) as {
      clientHeight: number;
      clientWidth: number;
      parentNode: any;
    };

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
      ),
    });
  };

  const handleDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    const { destination, source } = result;

    const sourceField: any = sortedFields[source.index];
    const targetField: any = sortedFields[destination.index];
    const fieldIndex = sortedFields.findIndex(
      (field) => field.uuid === targetField.uuid,
    );

    let newRank = "";
    if (destination.index < source.index) {
      newRank = generateRankString(
        sortedRanks[fieldIndex - 1]?.rank || "",
        sortedRanks[fieldIndex]?.rank || "",
      );
    } else {
      newRank = generateRankString(
        sortedRanks[fieldIndex].rank,
        sortedRanks[fieldIndex + 1]?.rank || "",
      );
    }

    updateRank(sourceField.uuid, newRank);
    setPlaceholderProps({});
  };

  const updateRank = (uuid: string, rank: string) => {
    switch (context) {
      case MetaDataOptionsContext.CreatorDatabase:
      case MetaDataOptionsContext.Dictionary:
        dispatchFunction(setDictionaryColumnRank(uuid, rank));
        break;

      case MetaDataOptionsContext.ProjectList:
        dispatchFunction(setProjectColumnRank(uuid, rank));
        break;
    }
  };

  return (
    // @ts-ignore
    <DropdownPopper
      ref={dropdownRef}
      placement="bottom-end"
      overlay={
        <DropdownMenu isOpen className="metadata-options-dropdown">
          <div className="metadata-options-dropdown__tab-name">
            <IDHFormattedMessage id="ws_campaigns" defaultMessage="Campaigns" />
          </div>
          <DropdownMenuTitle>
            <IDHFormattedMessage
              id="ws_table_settings"
              defaultMessage="Table settings"
            />
          </DropdownMenuTitle>
          <div className="metadata-options-dropdown__subheader">
            <IDHFormattedMessage id="ws_columns" defaultMessage="Columns" />
            <Button
              variant="bare"
              size="thin"
              onClick={() => resetToDefaultSettings()}
            >
              <IDHFormattedMessage
                id="ws_setting_restore_default"
                defaultMessage="Restore default"
              />
            </Button>
          </div>
          <DropdownMenuSeparator dashed />

          <DragDropContext
            onDragUpdate={handleDragUpdate}
            onDragEnd={(result) => handleDragEnd(result)}
          >
            <Droppable droppableId="droppable" direction="vertical">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className="metadata-options-dropdown__field-switch-list"
                  {...provided.droppableProps}
                >
                  {sortedFields.map((field, index) => (
                    <MetaDataOptionsFieldSwitch
                      key={field.uuid}
                      index={index}
                      context={context}
                      field={field}
                      hiddenColumns={hiddenColumns}
                      dispatchFunction={dispatchFunction}
                    />
                  ))}

                  {provided.placeholder}
                  {placeholderProps && snapshot.isDraggingOver && (
                    <AnimatedDiv
                      className="option__placeholder"
                      style={{
                        top: placeholderProps?.clientY,
                        left: placeholderProps?.clientX,
                        height: placeholderProps?.clientHeight,
                        width: placeholderProps?.clientWidth,
                      }}
                    />
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </DropdownMenu>
      }
    >
      <Button variant="white" size="small" textWithIcon>
        <CustomizeIcon />
        <IDHFormattedMessage id="ws_settings" defaultMessage="Settings" />
      </Button>
    </DropdownPopper>
  );
}
