import { setCreatorsMetadata } from "src/redux";
import { sortTasksByColumn } from "../sortTasksByColumn";
import { isMetaFieldMetric } from "../../components/ReportTemplate/components/CreatorBox/CreatorBox";
import { addHeaderToArray } from "../addHeaderToArray";

export const REPORT_ADDITIONAL_CREATOR_METAFIELDS = [
  "currency",
  "number",
  "percent",
  "rating",
];

export const addCreatorsToGridElements = (
  dispatch,
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  membersList,
  contentList,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.creatorsInTheCampaign;
  const settings = JSON.parse(localStorage.getItem("ws-project-settings"));

  Object.keys(campaigns).map((key) => {
    const { creators, project } = campaigns[key];
    const { projectId, title } = project;

    if (key !== "aggregated") {
      addHeaderToArray(
        `Creators in the campaign - ${title}`,
        !aggregation ? newGridElements : newHiddenGridElements,
        3,
        `creator-${title}`,
        savedTemplate,
        templateApplied,
        "creator",
      );
    }

    dispatch(
      setCreatorsMetadata(
        key,
        creators[0]?.metadata
          .filter((item) =>
            REPORT_ADDITIONAL_CREATOR_METAFIELDS.includes(item.type),
          )
          .map((item) => ({
            ...item,
            reportVisible: isMetaFieldMetric(item),
          })),
      ),
    );

    const projectSettings = settings?.find(
      (item) => item.projectId === projectId,
    );
    const { activeSortingColumn, sortingDirection } =
      projectSettings?.creator || {};

    creators
      .sort((a, b) =>
        sortTasksByColumn(
          a,
          b,
          activeSortingColumn,
          sortingDirection,
          creators,
          membersList,
          contentList,
        ),
      )
      .forEach((item) => {
        const label = `creator-${item.title
          .replaceAll(" ", "-")
          ?.replaceAll(".", "")
          .toLowerCase()}-${item.taskId}`;

        const element = {
          ...item,
          label,
          gridBoxType: "creator",
          section: "creatorsInTheCampaign",
          campaignId: key,
        };

        if (
          (!aggregation && key === "aggregated") ||
          (aggregation && key !== "aggregated")
        ) {
          newHiddenGridElements.push(element);
        } else if (savedTemplate?.length && !templateApplied) {
          if (savedTemplate.find((item) => item.i === element.label)) {
            newGridElements.push(element);
          } else {
            newHiddenGridElements.push(element);
          }
        }
        // applying default template
        else {
          newGridElements.push(element);
        }
      });
  });
};
