import React from "react";
import { AudienceInterests } from "../../../../Task/tabs/ProfileDataTab/single/AudienceInterests";

export const renderAudienceInterests = (
  label,
  data,
  layout,
  GridBoxComponent,
) => {
  const {
    user_audience_data: { audienceInterests },
  } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <AudienceInterests
        audienceInterests={audienceInterests}
        height={height}
      />
    </GridBoxComponent>
  );
};
