import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { API_URLS } from "src/utils/API_URLS";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import responseCodes from "src/utils/responseCodes";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tab from "src/app/components/Tabs/Tab";
import Tabs from "src/app/components/Tabs/Tabs";
import { Button } from "src/app/components/Button/Button";
import { AvatarAndName } from "src/app/components/GlobalTaskDetails/tabs/GlobalActivityTab/GlobalActivityTabCampaigns";
import { ReactComponent as InfoIcon } from "src/images/info-circle-blue.svg";
import placeholderIcon from "src/images/placeholder-image.svg";
import { EmailContent, MessageDetails, Steps } from "./types";

enum ActiveTab {
  CREATOR,
  BCC,
  CC,
}

interface CreatorMessagePaneConfirmEmailProps {
  activeWorkspaceUuid: string;
  emailContent: EmailContent;
  messageDetails: MessageDetails;
  hidePane: () => void;
  setStep: React.Dispatch<React.SetStateAction<Steps>>;
  setIsSendingMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CreatorMessagePaneConfirmEmail({
  activeWorkspaceUuid,
  emailContent,
  messageDetails,
  hidePane,
  setStep,
  setIsSendingMessage,
}: CreatorMessagePaneConfirmEmailProps) {
  const [activeTab, setActiveTab] = useState(ActiveTab.CREATOR);

  const {
    replyToEmail,
    includeInCcCampaignMembers,
    includeInBccCampaignMembers,
    subject,
    message,
    recipients,
  } = emailContent;

  const recipientsWithEmails = recipients.filter(
    (recipient) => recipient.emailFromCrm,
  );

  const handleSendEmail = async () => {
    setIsSendingMessage(true);
    try {
      const response = await axios.post(API_URLS.sendMessage, {
        wsWorkspaceUuid: activeWorkspaceUuid,
        wsTasksUuids: recipientsWithEmails.map(
          (recipient) => recipient.wsTaskUuid,
        ),
        replyToEmail,
        includeInCcCampaignMembers,
        includeInBccCampaignMembers,
        subject,
        message,
      });

      if (response.status !== responseCodes["200_OK"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      hidePane();
      toast.success(
        <IDHFormattedMessage
          id="ws_your_message_has_been_sent"
          defaultMessage="Your message has been sent"
        />,
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({
          id: "ws_an_error_occurred_while_sending_message",
          defaultMessage:
            "Something went wrong. Please try sending the message again.",
        });
      }
      console.error(error);
    } finally {
      setIsSendingMessage(false);
    }
  };
  return (
    <>
      <div className="creator-message-pane__manage-emails">
        <div className="creator-message-pane__info">
          <InfoIcon />
          <div className="creator-message-pane__info-box">
            <div className="creator-message-pane__info-title">
              <IDHFormattedMessage id="ws_info" defaultMessage="Info" />
            </div>
            {messageDetails.dataProcessingInformation ? (
              <div className="creator-message-pane__info-text">
                <p>
                  <IDHFormattedMessage
                    id="ws_the_following_information_will_be_included_in_the_footer"
                    defaultMessage="The following information will be included in the footer of the email"
                  />
                  :
                </p>

                <p>{messageDetails.dataProcessingInformation}</p>
              </div>
            ) : (
              <div className="creator-message-pane__info-text">
                <IDHFormattedMessage
                  id="ws_no_data_processing_information"
                  defaultMessage={`By clicking "Send," I declare that I voluntarily waive the opportunity to use the tool that allows the user to review information regarding personal data, and I accept any potential responsibility for failing to fulfill this obligation.`}
                />
              </div>
            )}
          </div>
        </div>

        <Tabs>
          <Tab
            tabText={
              <IDHFormattedMessage id="ws_creator" defaultMessage="Creator" />
            }
            active={activeTab === ActiveTab.CREATOR}
            onClick={() => setActiveTab(ActiveTab.CREATOR)}
            count={recipientsWithEmails.length}
          />
          {includeInBccCampaignMembers && (
            <Tab
              tabText={<IDHFormattedMessage id="ws_bcc" defaultMessage="BCC" />}
              active={activeTab === ActiveTab.BCC}
              onClick={() => setActiveTab(ActiveTab.BCC)}
              count={messageDetails.wsProjectMembers.length}
            />
          )}
          {includeInCcCampaignMembers && (
            <Tab
              tabText={<IDHFormattedMessage id="ws_cc" defaultMessage="CC" />}
              active={activeTab === ActiveTab.CC}
              onClick={() => setActiveTab(ActiveTab.CC)}
              count={messageDetails.wsProjectMembers.length}
            />
          )}
        </Tabs>
        <div className="creator-message-pane__cols">
          <div className="creator-message-pane__header">
            {activeTab === ActiveTab.CREATOR ? (
              <IDHFormattedMessage id="ws_creator" defaultMessage="Creator" />
            ) : (
              <IDHFormattedMessage id="ws_member" defaultMessage="Member" />
            )}
          </div>
          <div className="creator-message-pane__header">
            <IDHFormattedMessage id="ws_email" defaultMessage="Email" />
          </div>
        </div>
        <div className="creator-message-pane__emails-list">
          {activeTab === ActiveTab.CREATOR &&
            recipientsWithEmails.map((recipient) => (
              <div
                className="creator-message-pane__cols"
                key={recipient.wsTaskUuid}
              >
                <AvatarAndName
                  name={recipient.title}
                  key={recipient.wsTaskUuid}
                  avatar={recipient.avatar}
                  placeholder={placeholderIcon}
                />
                <div className="creator-message-pane__email-address">
                  {recipient.emailFromCrm}
                </div>
              </div>
            ))}
          {(activeTab === ActiveTab.CC || activeTab === ActiveTab.BCC) &&
            messageDetails.wsProjectMembers.map((member) => (
              <div className="creator-message-pane__cols" key={member.id}>
                <AvatarAndName
                  name={member.name}
                  avatar={member.avatarUrl}
                  placeholder={placeholderIcon}
                />
                <div className="creator-message-pane__email-address">
                  {member.email}
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="creator-message-pane__footer">
        <Button
          size="large"
          variant="white-with-light-grey-border"
          onClick={() => setStep(Steps.CREATE_EMAIL)}
        >
          <IDHFormattedMessage id="ws_back" defaultMessage="Back" />
        </Button>
        <Button variant="blue" size="large" onClick={handleSendEmail}>
          <IDHFormattedMessage id="ws_send" defaultMessage="Send" />
        </Button>
      </div>
    </>
  );
}
