import React, { useEffect, useRef, useState } from "react";
import "./Navbar.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { AppLocation } from "src/redux/main/mainReducer";
import { getWorkspacesList } from "src/redux/main/mainActions";
import { RootState } from "src/redux/reducers";
import { WsExtension } from "src/types";
import {
  closeTrialExceededModal,
  closeTrialLimitationsModal,
  getMembersList,
} from "../../redux";
import { uploadAvatarBlob } from "../../utils/uploadFunctions";
import { SkeletonCircle } from "../components/Skeleton/Skeleton";
import CustomImage from "../components/CustomImage/CustomImage";
import TopSearch from "./TopSearch/TopSearch";
import ImageCropModal from "../components/CroppedImageUploader/ImageCropModal/ImageCropModal";
import { TrialLimitationsModal } from "../modals/TrialLimitationsModal/TrialLimitationsModal";
import { TrialExceededModal } from "../modals/TrialExceededModal/TrialExceededModal";
import { RolesInfoModal } from "../modals/ShareModal/RolesInfoModal/RolesInfoModal";
import { WorkspaceRolesInfoModal } from "../modals/ShareModal/WorkspaceRolesInfoModal/WorkspaceRolesInfoModal";
import { SettingsModal } from "../SettingsModal/SettingsModal";
import { SettingsContext, SettingsTab } from "../SettingsModal/enums";
import SettingsTrialDropdown from "../dropdowns/SettingsTrialDropdown/SettingsTrialDropdown";
import {
  closeSettingsModal,
  openSettingsModal,
} from "../SettingsModal/methods";

interface WorkspacePermissions {
  workspace: string[];
}
interface WorkspaceDictionaryListItem {
  wsDictionaryUuid: string;
  wsDictionaryName: string;
  wsDictionaryElementName: null | string;
  wsDictionaryUrl: null | string;
  wsDictionaryIcon: null | string;
}
export interface IIdentity {
  id: string;
  name: string;
  email: string;
  errors: string[];
  avatar: any;
  avatarUrl: string;
  websocketChallenge: string;
  permissions: WorkspacePermissions;
  trialLimits: TrialLimits;
  intercomId: string;
  lastWorkspaceUuid: string;
  sidebarItems: [];
  workspaceDictionaryList: WorkspaceDictionaryListItem[];
  wsExtensionXtrmPaymentsUuid: string | null;
  wsWorkspaceExtensions: WsExtension[];
}

export interface TrialLimits {
  campaignsLimit: number;
  daysLimit: number;
  listsLimit: number;
  searchRequestsLimit: number;
  tasksLimit: number;
}

interface Props {
  identity?: IIdentity;
  notifications: any;
}

const shouldShowTopSearch = (pathName: string) => {
  const blacklist = ["creator-discovery-tool"];
  return !blacklist.some((item) => pathName.includes(item));
};

const Navbar: React.FC<Props> = (props) => {
  const { identity, notifications } = props;

  const [loading, setLoading] = useState(false);
  const [showImageCropModal, setShowImageCropModal] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<any>("");
  const [showRolesInfoModal, setShowRolesInfoModal] = useState(false);
  const [showWorkspaceRolesInfoModal, setShowWorkspaceRolesInfoModal] =
    useState(false);

  const {
    globalModalsReducer: {
      trialExceededModalVisible,
      trialLimitationsModalVisible,
    },
    mainReducer: { activeWorkspaceUuid, workspacesList, settingsModalData },
    projectReducer: { membersList, membersListLoading },
    audiencesManagerReducer: { trialExceededCDTModalVisible },
  } = useSelector((state: RootState) => state);

  const workspaceData = workspacesList?.find(
    (workspace) => workspace.uuid === activeWorkspaceUuid,
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const inputRef = useRef<HTMLInputElement>(null);

  const onChangeAvatar = async (file: any) => {
    if (identity?.id) {
      setLoading(true);
      setAvatarUrl("");
      await uploadAvatarBlob(file, dispatch, identity?.id, activeWorkspaceUuid);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!workspacesList.length) {
      dispatch(getWorkspacesList());
    }
  }, []);

  useEffect(() => {
    if (
      activeWorkspaceUuid &&
      identity?.id &&
      !membersList?.length &&
      !membersListLoading
    ) {
      dispatch(getMembersList(activeWorkspaceUuid));
    }
  }, [activeWorkspaceUuid]);

  // avatar always visible fallback
  useEffect(() => {
    if (identity?.avatar) {
      setAvatarUrl(
        identity?.avatar?.thumbnails.tiny || identity?.avatar?.publicUrl,
      );
    }
  }, [identity?.avatar]);

  const handleOpenSettingsModal = (
    context: SettingsContext,
    tab: SettingsTab,
  ) => {
    openSettingsModal(history, AppLocation.Navbar, context, tab);
  };

  const renderTrialLimitationsModal =
    trialLimitationsModalVisible &&
    identity?.trialLimits &&
    trialExceededModalVisible === false &&
    trialExceededCDTModalVisible === false;

  return (
    <header className="navbar" style={{ marginTop: notifications.length * 44 }}>
      <div className="navbar__left" onClick={() => inputRef.current?.focus()}>
        {shouldShowTopSearch(window.location.pathname) && <TopSearch />}
      </div>
      <div className="navbar__right">
        {loading ? (
          <SkeletonCircle size={32} marginRight={12} />
        ) : (
          <div className={classNames("navbar__right-avatar")}>
            <CustomImage
              src={`${avatarUrl}`}
              alt="avatar"
              onClick={() => setShowImageCropModal(true)}
            />
          </div>
        )}
        <div className="navbar__right-user-data">
          <div className="navbar__right-name">{identity?.name}</div>
          <div className="navbar__right-workspace-name">
            {workspaceData?.name}
          </div>
        </div>
        <SettingsTrialDropdown />
      </div>

      {settingsModalData?.location === AppLocation.Navbar && (
        <SettingsModal
          onClose={() => closeSettingsModal(history)}
          members={{
            openShareModal: () =>
              openSettingsModal(
                history,
                AppLocation.Navbar,
                SettingsContext.Project,
                SettingsTab.Members,
              ),
            openRolesInfoModal: () => setShowRolesInfoModal(true),
            openWorkspaceRolesInfoModal: () =>
              setShowWorkspaceRolesInfoModal(true),
          }}
          contextTargetUuid={activeWorkspaceUuid || ""}
          disableTransition
        />
      )}

      {showRolesInfoModal && (
        <RolesInfoModal
          onClose={() => setShowRolesInfoModal(false)}
          openShareModal={() =>
            handleOpenSettingsModal(
              SettingsContext.Workspace,
              SettingsTab.Members,
            )
          }
        />
      )}

      {showWorkspaceRolesInfoModal && (
        <WorkspaceRolesInfoModal
          onClose={() => setShowWorkspaceRolesInfoModal(false)}
        />
      )}

      {showImageCropModal && (
        <ImageCropModal
          uploadFunction={onChangeAvatar}
          onClose={() => setShowImageCropModal(false)}
          circle
        />
      )}
      {trialExceededModalVisible && (
        <TrialExceededModal
          onClose={() => dispatch(closeTrialExceededModal())}
        />
      )}
      {renderTrialLimitationsModal && (
        <TrialLimitationsModal
          onClose={() => dispatch(closeTrialLimitationsModal())}
        />
      )}
    </header>
  );
};

const mapStateToProps = (state: any) => ({
  identity: state.mainReducer.identity,
  notifications: state.mainReducer.notifications,
});

export default connect(mapStateToProps)(Navbar);
