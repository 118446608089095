import React from "react";
import "./CoverFooter.scss";
import { IDHAALogo } from "src/app/components/IDHAALogo/IDHAALogo";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useSelector } from "react-redux";

export function CoverFooter(props) {
  const { renderCreators, workspaceData } = props;

  const showcaseTitle = useSelector(
    (state) => state.showcaseReducer.showcaseTitle,
  );
  const identity = useSelector((state) => state.mainReducer.identity);
  const primaryColor = identity.customPrimaryColor || "#5D78FF";

  return (
    <div className="cover-footer">
      <div className="cover-footer__info">
        {Object.keys(workspaceData || {}).length > 0 ? (
          <IDHAALogo size={80} customLogo={workspaceData?.smallLogo} />
        ) : null}

        {renderCreators()}

        <div className="cover-footer__info-text">
          <div className="cover-footer__info-text-showcase">
            {showcaseTitle}
          </div>

          <div className="cover-footer__info-text-thank-you">
            <IDHFormattedMessage id="ws_thank_you" defaultMessage="Thank you" />
          </div>
        </div>
      </div>

      <div
        className="cover-footer__background-color"
        style={{ backgroundColor: primaryColor }}
      />
    </div>
  );
}
