import { addHeaderToArray } from "../addHeaderToArray";
import { addAudienceDataToGridElements } from "./addAudienceDataToGridElements";
import { addCreatorInsightsToGridElements } from "./addCreatorInsightsToGridElements";
import { addCreatorsToGridElements } from "./addCreatorsToGridElements";
import { addCustomGridElement } from "./addCustomGridElement";
import { addCampaignDetailsToGridElements } from "./addCampaignDetailsToGridElements";
import { addPostsDataToGridElements } from "./addPostDataToGridElements";
import { addPublicationsToGridElements } from "./addPublicationsToGridElements";
import { addTopPerformingPublicationsToGridElements } from "./addTopPerformingPublicationsToGridElements";
import { addSelectedCommentsToGridElements } from "./addSelectedCommentsToGridElements";
import { addSentimentAnalysisToGridElements } from "./addSentimentAnalysisToGridElements";
import { shouldDisplaySentiments } from "../shouldDisplaySentiments";
import { addTableSummaryToGridElements } from "./addTableSummaryToGridElements";
import { addTableOfContentsToGridElements } from "./addTableOfContentsToGridElements";

export const getReportGridElements = async (props) => {
  const {
    comments,
    contentAutocomplete: contentList,
    dataAggregation,
    formatMessage,
    membersList,
    campaigns,
    savedTemplate,
    sentimentValues,
    templateApplied,
    //
    dispatch,
    setGridElements,
    setHiddenGridElements,
    setShouldRerenderLayout,
  } = props;

  const newGridElements = [];
  const newHiddenGridElements = [];

  // Title box
  await addCustomGridElement(
    "title-box",
    newGridElements,
    newHiddenGridElements,
    savedTemplate,
    templateApplied,
  );

  // PROJECT OVERVIEW
  await addHeaderToArray(
    formatMessage({
      id: "ws_campaign_overview",
      defaultMessage: "Campaign overview",
    }),
    newGridElements,
    1,
    "campaign-overview",
    savedTemplate,
    templateApplied,
  );

  // ProjectDetails
  addHeaderToArray(
    formatMessage({
      id: "ws_campaign_details",
      defaultMessage: "Campaign details",
    }),
    newGridElements,
    2,
    "metadata",
    savedTemplate,
    templateApplied,
  );
  await addCampaignDetailsToGridElements(
    campaigns,
    newGridElements,
    newHiddenGridElements,
    savedTemplate,
    templateApplied,
  );

  // Creators insights
  await addHeaderToArray(
    formatMessage({
      id: "ws_campaign_insights",
      defaultMessage: "Creator insights",
    }),
    newGridElements,
    2,
    "creator-insights",
    savedTemplate,
    templateApplied,
  );
  await addCreatorInsightsToGridElements(
    newGridElements,
    newHiddenGridElements,
    savedTemplate,
    templateApplied,
    campaigns,
    dataAggregation,
  );

  // Creators in the campaign
  addHeaderToArray(
    formatMessage({
      id: "ws_creators_in_the_campaign",
      defaultMessage: "Creators in the campaign",
    }),
    newGridElements,
    2,
    "creator",
    savedTemplate,
    templateApplied,
  );
  await addCreatorsToGridElements(
    dispatch,
    newGridElements,
    newHiddenGridElements,
    savedTemplate,
    templateApplied,
    membersList,
    contentList,
    campaigns,
    dataAggregation,
  );

  // Campaign audience data
  await addHeaderToArray(
    formatMessage({
      id: "ws_campaign_audience_data",
      defaultMessage: "Campaign audience data",
    }),
    newGridElements,
    2,
    "audience-data",
    savedTemplate,
    templateApplied,
  );
  await addAudienceDataToGridElements(
    newGridElements,
    newHiddenGridElements,
    savedTemplate,
    templateApplied,
    campaigns,
    dataAggregation,
  );

  // PROJECT DATA
  await addHeaderToArray(
    formatMessage({
      id: "ws_campaign_data",
      defaultMessage: "Campaign data",
    }),
    newGridElements,
    1,
    "campaign-data",
    savedTemplate,
    templateApplied,
  );

  // Posts data
  addHeaderToArray(
    formatMessage({
      id: "ws_executive_summary",
      defaultMessage: "Executive summary",
    }),
    newGridElements,
    2,
    "post-data",
    savedTemplate,
    templateApplied,
  );
  await addPostsDataToGridElements(
    newGridElements,
    newHiddenGridElements,
    savedTemplate,
    templateApplied,
    campaigns,
    dataAggregation,
  );

  // CAMPAIGN PERFORMANCE
  addHeaderToArray(
    formatMessage({
      id: "ws_campaign_performance",
      defaultMessage: "Campaign performance",
    }),
    newGridElements,
    1,
    "publication",
    savedTemplate,
    templateApplied,
  );

  await addTopPerformingPublicationsToGridElements(
    formatMessage,
    newGridElements,
    newHiddenGridElements,
    savedTemplate,
    templateApplied,
    campaigns,
    dataAggregation,
  );

  await addPublicationsToGridElements(
    dispatch,
    newGridElements,
    newHiddenGridElements,
    savedTemplate,
    templateApplied,
    membersList,
    contentList,
    formatMessage,
    campaigns,
    dataAggregation,
  );

  // TABLE SUMMARY
  await addHeaderToArray(
    formatMessage({
      id: "ws_summary",
      defaultMessage: "Summary",
    }),
    newGridElements,
    1,
    "table-summary",
    savedTemplate,
    templateApplied,
  );
  await addTableSummaryToGridElements(
    dispatch,
    newGridElements,
    newHiddenGridElements,
    savedTemplate,
    templateApplied,
    formatMessage,
    campaigns,
    dataAggregation,
  );

  // COMMENTS ANALYSIS
  if (shouldDisplaySentiments(sentimentValues)) {
    await addHeaderToArray(
      formatMessage({
        id: "ws_comments_analysis",
        defaultMessage: "Comments analysis",
      }),
      newGridElements,
      1,
      "comments-analysis",
      savedTemplate,
      templateApplied,
    );
    addHeaderToArray(
      formatMessage({
        id: "ws_sentiment_analysis_summary",
        defaultMessage: "Sentiment analysis - Summary",
      }),
      newGridElements,
      2,
      "sentiment-analysis-summary",
      savedTemplate,
      templateApplied,
    );

    addSentimentAnalysisToGridElements(
      newGridElements,
      newHiddenGridElements,
      savedTemplate,
      templateApplied,
      campaigns,
      dataAggregation,
    );

    addHeaderToArray(
      formatMessage({
        id: "ws_selected_comments",
        defaultMessage: "Selected comments",
      }),
      newGridElements,
      2,
      "selected-comments",
      savedTemplate,
      templateApplied,
    );

    addSelectedCommentsToGridElements(
      newGridElements,
      newHiddenGridElements,
      savedTemplate,
      templateApplied,
      campaigns,
      dataAggregation,
    );
  }

  // SUMMARY & LEARNINGS
  await addHeaderToArray(
    formatMessage({
      id: "ws_summary_learnings",
      defaultMessage: "Summary & Learnings",
    }),
    newGridElements,
    1,
    "summary-learnings",
    savedTemplate,
    templateApplied,
  );
  addCustomGridElement(
    "summary-learnings",
    newGridElements,
    newHiddenGridElements,
    savedTemplate,
    templateApplied,
  );

  // GLOSSARY
  await addHeaderToArray(
    formatMessage({
      id: "ws_glossary",
      defaultMessage: "Glossary",
    }),
    newGridElements,
    1,
    "glossary",
    savedTemplate,
    templateApplied,
  );
  addCustomGridElement(
    "glossary",
    newGridElements,
    newHiddenGridElements,
    savedTemplate,
    templateApplied,
  );
  addCustomGridElement(
    "glossary-two",
    newGridElements,
    newHiddenGridElements,
    savedTemplate,
    templateApplied,
  );

  await addTableOfContentsToGridElements(newGridElements);

  setShouldRerenderLayout(true);
  setGridElements(newGridElements);
  setHiddenGridElements(newHiddenGridElements);
};
