import React, { useEffect, useState } from "react";

import { ReactComponent as TrashCan } from "src/images/trash-can.svg";
import { ReactComponent as TickIcon } from "src/images/tick.svg";
import classNames from "classnames";

export function TemplateSelectorMenu(props) {
  const {
    visible,
    templateOptions,
    selectedTemplate,
    handleTemplateClick,
    handleDeleteClick,
  } = props;

  const [showMenu, setShowMenu] = useState(false);
  const [defaultTemplates, setDefaultTemplates] = useState([]);
  const [customTemplates, setCustomTemplates] = useState([]);

  useEffect(() => {
    const newDefaultTemplates = [];
    const newCustomTemplates = [];

    templateOptions.forEach((item) => {
      switch (item.value.type) {
        case "default":
          newDefaultTemplates.push(item);
          return;

        case "custom":
          newCustomTemplates.push(item);
      }
    });

    setDefaultTemplates(newDefaultTemplates);
    setCustomTemplates(newCustomTemplates);
  }, [templateOptions]);

  useEffect(() => {
    if (visible) {
      setShowMenu(true);
    } else {
      setTimeout(() => {
        setShowMenu(false);
      }, 300);
    }
  }, [visible]);

  if (!showMenu && !visible) return null;

  return (
    <div
      className={classNames("template-selector__menu", {
        "template-selector__menu--visible": showMenu && visible,
      })}
    >
      <div className="template-selector__menu-separator" />
      <div className="template-selector__menu-list">
        {defaultTemplates.map((item) => (
          <div
            key={item.value.uuid}
            className="template-selector__menu-item"
            onClick={() => handleTemplateClick(item)}
          >
            <div className="template-selector__menu-item-left">
              <div>
                <div className="template-selector__menu-item-title">
                  <span>{item.label}</span>
                  {item.value.uuid === selectedTemplate.value.uuid && (
                    <TickIcon />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {customTemplates.length > 0 && (
        <>
          <div className="template-selector__menu-separator" />
          <div className="template-selector__menu-list">
            {customTemplates.map((item) => (
              <div
                className="template-selector__menu-item"
                onClick={() => handleTemplateClick(item)}
              >
                <div className="template-selector__menu-item-left">
                  <div>
                    <div className="template-selector__menu-item-title">
                      <span>{item.label}</span>
                      {item.value.uuid === selectedTemplate.value.uuid && (
                        <TickIcon />
                      )}
                    </div>
                  </div>
                </div>
                {item.value.uuid !== selectedTemplate.value.uuid && (
                  <span className="template-selector__menu-item-trashcan">
                    <TrashCan
                      onClick={(e) => handleDeleteClick(e, item.value.uuid)}
                    />
                  </span>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
