import InstagramIcon from "src/images/social/instagram-color.svg";
import TikTokIcon from "src/images/social/tiktok-color.svg";
import YouTubeIcon from "src/images/social/youtube-color.svg";
import XIcon from "src/images/social/x.svg";
import DiscordIcon from "src/images/social/discord.svg";
import TwitchIcon from "src/images/social/twitch.svg";
import SnapchatIcon from "src/images/social/snapchat.svg";
import FacebookIcon from "src/images/social/facebook-color.svg";
import TelegramIcon from "src/images/social/telegram.svg";
import LinkIcon from "src/images/link.svg";

export interface ErrorMessageAndField {
  field: string;
  message: string;
}

export interface ReduxError {
  field?: string;
  message: string;
  status: number;
}

export interface ReduxAction<T> {
  type: string;
  payload: T;
}

/* SOCIAL PROVIDERS DECLARATION BELOW */
export enum SocialProvider {
  Instagram = "instagram",
  TikTok = "tiktok",
  Youtube = "youtube",
  Twitter = "twitter",
  Snapchat = "snapchat",
  Discord = "discord",
  Twitch = "twitch",
  Facebook = "facebook",
  Telegram = "telegram",
  Custom = "custom",
}

export const SOCIAL_ICONS: Record<string, string> = {
  instagram: InstagramIcon,
  tiktok: TikTokIcon,
  tik_tok: TikTokIcon,
  youtube: YouTubeIcon,
  twitter: XIcon,
  discord: DiscordIcon,
  twitch: TwitchIcon,
  snapchat: SnapchatIcon,
  facebook: FacebookIcon,
  telegram: TelegramIcon,
  custom: LinkIcon,
};

export const SocialProviders = [
  {
    label: "Instagram",
    value: SocialProvider.Instagram,
    icon: InstagramIcon,
  },
  {
    label: "TikTok",
    value: SocialProvider.TikTok,
    icon: TikTokIcon,
  },
  { label: "YouTube", value: SocialProvider.Youtube, icon: YouTubeIcon },
  { label: "X", value: SocialProvider.Twitter, icon: XIcon },
  {
    label: "Discord",
    value: SocialProvider.Discord,
    icon: DiscordIcon,
  },
  {
    label: "Twitch",
    value: SocialProvider.Twitch,
    icon: TwitchIcon,
  },
  {
    label: "Snapchat",
    value: SocialProvider.Snapchat,
    icon: SnapchatIcon,
  },
  {
    label: "Facebook",
    value: SocialProvider.Facebook,
    icon: FacebookIcon,
  },
  {
    label: "Telegram",
    value: SocialProvider.Telegram,
    icon: TelegramIcon,
  },
];

export const REGEX_BY_PROVIDER = {
  instagram: /.*instagram\.com\/([._a-z\d]*)/i,
  tiktok: /https\:\/\/www\.tiktok\.com\/@([._a-z\d]*)/i,
  youtube:
    /https\:\/\/(?:www\.|m.)?youtube\.com\/(?:c\/|channel\/|user\/|@)([._\-a-ząćęłńóśźżĄĆĘŁŃÓŚŻŹ%\d]*)/i,
  twitter: /https\:\/\/(?:twitter|x)\.com\/([._a-z\d]*)/i,
  discord: /https\:\/\/discord\.com\/([._a-z\d\/]*)/i,
  twitch: /https\:\/\/www\.twitch\.tv\/([._a-z\d]*)/i,
  snapchat: /https\:\/\/.*snapchat\.com\/(?:add|p)\/([-._a-zA-Z\d]*)/i,
  facebook:
    /https:\/\/(?:www\.)?(?:facebook\.com|fb\.me)\/(?:profile\.php\?id=)?(?:groups\/)?([._\-a-zA-Z\d]*)/i,
  telegram: /https\:\/\/t\.me\/([._a-z\d+-]*)/i,
};

export const CUSTOM_URL_REGEX = /^https?:\/\/(?!.*:\/\/)\S+/;

export enum SocialProfileStatus {
  Fresh = 0,
  Verified = 1,
  Error = 2,
  NotFound = 3,
}

export enum SocialProfileFlags {
  Private = "PRIVATE",
  Restricted = "RESTRICTED",
  ApiPaired = "API_PAIRED",
}

export const isFacebookNumericUsername = (username: string) => {
  return /^\d+$/.test(username);
};
/* SOCIAL PROVIDERS DECLARATION ABOVE */
