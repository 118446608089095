import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import CreatorPlaceholder from "src/images/creator-placeholder.svg";
import SocialProfileIcons from "../../../components/IconsWithStatus/SocialProfileIcons/SocialProfileIcons";
import CustomImage from "../../../components/CustomImage/CustomImage";
import { ReactComponent as TrashCan } from "../../../../images/trash-can.svg";
import { ReactComponent as TickIcon } from "../../../../images/tick.svg";
import { getShowcaseSettings } from "../../utils";

export function CreatorSelectorMenu({
  visible,
  handleCreatorClick,
  handleDeleteClick,
}) {
  const [showMenu, setShowMenu] = useState(false);

  const showcaseData = useSelector(
    (state) => state.showcaseReducer.showcaseData,
  );

  const { elements } = showcaseData;
  const { creatorId } = getShowcaseSettings();

  useEffect(() => {
    if (visible) {
      setShowMenu(true);
    } else {
      setTimeout(() => {
        setShowMenu(false);
      }, 300);
    }
  }, [visible]);

  if (!showMenu && !visible) return null;

  return (
    <div
      className={classNames("creator-selector__menu", {
        "creator-selector__menu--visible": showMenu && visible,
      })}
    >
      <div className="creator-selector__menu-list">
        {elements?.map((item) => (
          <div
            key={item.id}
            className="creator-selector__menu-item"
            onClick={() => handleCreatorClick(item.id)}
          >
            <div className="creator-selector__menu-item-left">
              <CustomImage
                className="creator-selector__menu-item-cover"
                src={item?.cover}
                fallbackImageSrc={CreatorPlaceholder}
              />
              <div>
                <div className="creator-selector__menu-item-title">
                  <span>{item.name}</span>
                  {item.id === creatorId && <TickIcon />}
                </div>
                <SocialProfileIcons
                  data={
                    item?.socialProfiles?.map((profile) => ({
                      ...profile,
                      status: 1,
                    })) || []
                  }
                  size="small"
                />
              </div>
            </div>
            {item.id !== creatorId && (
              <span className="creator-selector__menu-item-trashcan">
                <TrashCan onClick={(e) => handleDeleteClick(e, item.id)} />
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
