import { getStatisticHeader } from "src/helpers/statistic-list-item";
import { capitalizeFirstLetter } from "src/utils/methods";
import { addHeaderToArray } from "../addHeaderToArray";

export const addSentimentAnalysisToGridElements = (
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.commentsAnalysis;

  Object.keys(campaigns).forEach((key) => {
    const {
      sentimentData: { sentimentValues },
      project: { title },
    } = campaigns[key];

    if (!sentimentValues) return;

    if (key !== "aggregated") {
      addHeaderToArray(
        `Sentiment Analysis - ${title}`,
        !aggregation ? newGridElements : newHiddenGridElements,
        !aggregation ? 3 : 2,
        `sentiment-analysis-${title}`,
        savedTemplate,
        templateApplied,
      );
    }

    Object.entries(sentimentValues).forEach((item) => {
      const [key, value] = item;

      const element = {
        key,
        value,
        label: `sentiment-analysis-${key}`,
        name: capitalizeFirstLetter(getStatisticHeader(key)),
        gridBoxType: "sentiment-analysis",
        section: "sentiment-analysis",
      };

      // applying saved template

      if (
        (!aggregation && key === "aggregated") ||
        (aggregation && key !== "aggregated")
      ) {
        newHiddenGridElements.push(element);
      } else if (savedTemplate?.length && !templateApplied) {
        if (savedTemplate.find((item) => item.i === element.label)) {
          newGridElements.push(element);
        } else {
          newHiddenGridElements.push(element);
        }
      }
      // applying default template
      // else if (comments.all[key]?.length) {
      newGridElements.push(element);
      // } else {
      // newHiddenGridElements.push(element);
      // }
    });
  });
};
