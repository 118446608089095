import React, { useEffect, useRef, useState } from "react";
import "./Chart.scss";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import moment from "moment";
import { splitNumbers } from "../../../utils/methods";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { momentDateFormat } from "../CustomDatePicker/utils";

function Chart({ title, data, color, icon, header, height }) {
  const [chartData, setChartData] = useState([]);

  const useMockedData = false;
  const YAxisMargin = 8;

  // TODO:: mocked data for dev purpose only
  useEffect(() => {
    if (useMockedData) {
      setChartData(
        parseChartStats({
          "2020-03-30": 20,
          "2020-04-06": 26,
          "2020-04-13": 35,
          "2020-04-20": 40,
          "2020-04-27": 70,
          "2020-05-04": 79,
          "2020-05-11": 85,
          "2020-05-18": 93,
          "2020-06-01": 120,
          "2020-06-08": 150,
          "2020-06-22": 157,
          "2020-06-29": 580,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (data && !useMockedData) {
      setChartData(parseChartStats(data));
    }
  }, [data]);

  const setYAxisWidth = () => {
    const maxValue = Math.max(...chartData.map((item) => item.value));
    const maxValueDigitsCount = maxValue.toString().length;
    const approximatelyDigitWidth = 11;

    return maxValueDigitsCount * approximatelyDigitWidth;
  };

  const parseChartStats = (receivedData) => {
    const keys = Object.keys(receivedData);
    return keys.map((label) => {
      return {
        name: moment(label, momentDateFormat).format("MM"),
        value: receivedData[label],
      };
    });
  };

  const checkChartValues = () => {
    const chartsItemsWithValue = chartData.filter((chartDataItem) => {
      return chartDataItem.value > 0;
    });

    return chartsItemsWithValue.length === 0;
  };

  if (!chartData || chartData?.length === 0 || checkChartValues()) {
    return null;
  }

  return (
    <div className="chart">
      {header && (
        <div className="chart__header">
          <div className="chart__info">
            <div className={`chart__icon chart__icon--${icon}`} />
            <div className="chart__title">{title}</div>
          </div>
          <div className="chart__value">
            {splitNumbers(chartData[chartData.length - 1].value)}
          </div>
        </div>
      )}

      <ResponsiveContainer width="100%" height={height || 300}>
        <AreaChart
          data={chartData}
          margin={{ top: 5, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id={`color-${color}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.2} />
              <stop offset="75%" stopColor={color} stopOpacity={0} />
            </linearGradient>
          </defs>

          <XAxis
            dataKey="name"
            padding={{ left: 15, right: 15 }}
            interval={Math.floor(chartData.length / 10)}
            tick={{ fontSize: 14, fontWeight: 600 }}
            tickMargin={16}
          />
          <YAxis
            tickCount={7}
            allowDecimals={false}
            // Some kind of magic - recharts do not support ticks to left by default,
            // so I've added textAnchor: "left" , and it destroyed package calculations its width
            // Now it calculates approximately axis width based on numbers of digits of max value
            tick={{ fontSize: 14, fontWeight: 600, textAnchor: "left" }}
            tickMargin={setYAxisWidth()}
            width={setYAxisWidth() + YAxisMargin}
          />
          <CartesianGrid strokeDasharray="2 2" vertical={false} />
          <Tooltip content={<CustomTooltip color={color} />} />

          <Area
            dataKey="value"
            stroke={color}
            fillOpacity={1}
            strokeWidth={4}
            dot={{ strokeWidth: 3, stroke: color, fill: color }}
            activeDot={{ strokeWidth: 3, stroke: color, fill: color }}
            fill={`url(#color-${color})`}
            isAnimationActive={false}
            type="linear"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

function CustomTooltip({ active, payload, label, color }) {
  if (active && payload && payload.length) {
    return (
      <div className="chart-tooltip">
        <div className="chart-tooltip__label">{label}</div>
        <div style={{ color }}>
          <IDHFormattedMessage id="ws_value" defaultMessage="Value" />
          <span> : </span>
          {payload[0].value}
        </div>
      </div>
    );
  }

  return null;
}

export default Chart;
