import React from "react";

import { TableSummary } from "../components/ReportTemplate/components/TableSummary/TableSummary";

export const renderTableSummary = (summary, GridBox, layout) => {
  const key = summary.label;

  const height = layout?.find((item) => item.i === key)?.h;

  return (
    <GridBox key={key} id={key} noPadding>
      <TableSummary
        id={key}
        boxData={summary.data}
        height={height}
        campaignId={summary.campaignId}
      />
    </GridBox>
  );
};
