import React from "react";
import {
  type CustomCell,
  type CustomRenderer,
  GridCellKind,
} from "@glideapps/glide-data-grid";

import {
  SOCIAL_ICONS,
  SocialProfileFlags,
  SocialProfileStatus,
} from "src/types";
import { SocialProfile } from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import WarningYellowIcon from "src/images/warning-orange.svg";
import VerifiedIcon from "src/images/verified.svg";
import {
  existAtLeastOneSocialProfileWithWarningIcon,
  existAtLeastOneSocialProfileWithStatus,
  socialProfileStatusesWithDecreasedOpacity,
  socialProfileStatusesWithWarning,
} from "src/app/components/IconsWithStatus/SocialProfileIcons/utils";
import CreatorAwaitingIcon from "src/images/post-check-awaiting.svg";
import { shouldDecreaseIconOpacityBasedOnStatus } from "src/app/components/IconsWithStatus/utils";
import { getEmHeight } from "../../../utils";

interface TitleCellProps {
  readonly kind: "title-cell";
  readonly name: string;
  readonly image?: string;
  readonly placeholderImage: string;
  readonly socialProfiles?: SocialProfile[];
}

export type TitleCell = CustomCell<TitleCellProps>;

const titleCellRenderer: CustomRenderer<TitleCell> = {
  kind: GridCellKind.Custom,
  isMatch: (cell: CustomCell): cell is TitleCell =>
    (cell.data as any).kind === "title-cell",
  draw: (args, cell) => {
    const { ctx, rect, theme, imageLoader, col, row } = args;
    const { name, image, placeholderImage, socialProfiles } = cell.data;

    ctx.fillStyle = "transparent";

    const { x, width } = rect;
    const font = `${theme.baseFontStyle} ${theme.fontFamily}`;
    const emHeight = getEmHeight(ctx, font);
    const h = Math.min(
      rect.height,
      Math.max(16, Math.ceil(emHeight * theme.lineHeight) * 2),
    );
    const y = Math.floor(rect.y + (rect.height - h) / 2);

    ctx.save();

    let imageToDraw;
    if (image) {
      imageToDraw = imageLoader.loadOrGetImage(image, col, row);
    }

    if (image && !imageToDraw) {
      imageToDraw = imageLoader.loadOrGetImage(placeholderImage, col, row);
    }

    if (imageToDraw) {
      ctx.save();
      ctx.beginPath();
      ctx.arc(x + 44, y + h / 2 + 1, 24, 0, Math.PI * 2, false);
      ctx.strokeStyle = "transparent";
      ctx.stroke();
      ctx.clip();
      ctx.drawImage(imageToDraw, x + 20, y + h / 2 - 23, 48, 48);
      ctx.restore();
    }

    ctx.fillRect(x + 1, y + 1, width - 1, h - 1);
    ctx.fillStyle = "#74788d";
    ctx.font = "medium 13px sans-serif";
    const textY = socialProfiles?.length ? y + h / 2 - 10 : y + h / 2;
    ctx.fillText(name, x + 80, textY);

    if (socialProfiles?.length) {
      let idx = 0;
      Object.keys(SOCIAL_ICONS).forEach((icon) => {
        const foundProvider = socialProfiles.find(
          (item) => item.socialProvider === icon,
        );
        if (foundProvider) {
          const SOCIAL_ICON = new Image();
          SOCIAL_ICON.src = SOCIAL_ICONS[icon];
          const socialIcon = imageLoader.loadOrGetImage(
            SOCIAL_ICON.src,
            col,
            row,
          );
          if (socialIcon) {
            if (
              shouldDecreaseIconOpacityBasedOnStatus(
                foundProvider.status,
                socialProfileStatusesWithDecreasedOpacity,
              )
            ) {
              ctx.globalAlpha = 0.5;
            }

            ctx.drawImage(socialIcon, x + 80 + idx * 23, y + h / 2 + 1, 18, 18);
            ctx.globalAlpha = 1;
            idx += 1;

            if (foundProvider?.flags?.includes(SocialProfileFlags.ApiPaired)) {
              const ApiPairedIcon = imageLoader.loadOrGetImage(
                VerifiedIcon,
                col,
                row,
              );

              if (ApiPairedIcon) {
                ctx.drawImage(
                  ApiPairedIcon,
                  x + 88 + (idx - 1) * 23,
                  y + h / 2 + 9,
                  16,
                  16,
                );
                ctx.globalAlpha = 1;
              }
            }
          }
        }
      });
      if (
        existAtLeastOneSocialProfileWithWarningIcon(
          socialProfiles,
          socialProfileStatusesWithWarning,
        )
      ) {
        const warningIcon = imageLoader.loadOrGetImage(
          WarningYellowIcon,
          col,
          row,
        );
        if (warningIcon) {
          ctx.drawImage(warningIcon, x + 80 + idx * 22, y + h / 2 + 1, 18, 18);
        }
      }
      if (
        existAtLeastOneSocialProfileWithStatus(
          SocialProfileStatus.Fresh,
          socialProfiles,
        )
      ) {
        const creatorAwaitingIcon = imageLoader.loadOrGetImage(
          CreatorAwaitingIcon,
          col,
          row,
        );
        if (creatorAwaitingIcon) {
          ctx.drawImage(
            creatorAwaitingIcon,
            x + 80 + idx * 22,
            y + h / 2 + 1,
            18,
            18,
          );
        }
      }
    }
    ctx.restore();
    return true;
  },
};

export default titleCellRenderer;
