import { CREATOR_INSIGHTS } from "../../utils/variables";
import { addHeaderToArray } from "../addHeaderToArray";

export const addCreatorInsightsToGridElements = (
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.creatorsInsights;

  Object.keys(campaigns).forEach((key) => {
    const campaign = campaigns[key];

    const {
      creatorsInsights,
      project: { title },
    } = campaign;

    if (!creatorsInsights) return;

    if (key !== "aggregated") {
      addHeaderToArray(
        `Creator Insights - ${title}`,
        !aggregation ? newGridElements : newHiddenGridElements,
        3,
        `creator-insights-${title}`,
        savedTemplate,
        templateApplied,
        "creator-insights",
      );
    }

    CREATOR_INSIGHTS.forEach((item) => {
      const element = {
        label: `creator-insights-${item.value}-${key}`,
        provider: "creator-insights",
        type: item.value,
        name: item.label,
        gridBoxType: "creator-insights",
        campaignId: key,
      };

      // applying saved template
      if (
        (!aggregation && key === "aggregated") ||
        (aggregation && key !== "aggregated")
      ) {
        newHiddenGridElements.push(element);
      } else if (savedTemplate?.length && !templateApplied) {
        if (savedTemplate.find((item) => item.i === element.label)) {
          newGridElements.push(element);
        } else {
          newHiddenGridElements.push(element);
        }
      } else if (
        item.initialHidden ||
        !creatorsInsights[item.source] ||
        creatorsInsights[item.source].length === 0
      ) {
        newHiddenGridElements.push(element);
      }
      // applying default template
      else {
        newGridElements.push(element);
      }
    });
  });
};
