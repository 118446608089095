import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Loader from "src/app/components/Loader/Loader";
import {
  WsExtensionXtrmPayments,
  XtrmPaymentsDetails,
  ExtensionTypeEnum,
} from "src/types";
import { clearSettingsGlobalFields } from "src/redux";
import Tab from "src/app/components/Tabs/Tab";
import Tabs from "src/app/components/Tabs/Tabs";
import { SettingsButtons } from "../../../components/SettingsTabContent/SettingsTabContent";
import { IntegrationsSection } from "../Integrations";
import { ChangeSettingsSectionParam } from "../../../methods";
import { getExtensionDetailsByUuid } from "../../Extensions/utils";
import XTRMPaymentsWallets from "./tabs/XTRMPaymentsWallets";
import XTRMPaymentsGeneral from "./tabs/XTRMPaymentsGeneral";
import XTRMPaymentsSettings from "./tabs/XTRMPaymentsSettings";
import ExtensionView from "../../Extensions/ExtensionView";

import "./XTRMPayments.scss";

interface XTRMPaymentsProps {
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
}

export enum ActiveTabs {
  Wallets = "wallets",
  General = "general",
  Settings = "settings",
}

export const xtrmPaymentsDetailsInitialData: XtrmPaymentsDetails = {
  amountWsTaskGlobalFieldUuid: null,
  emailWsTaskGlobalFieldUuid: null,
  beneficiaryId: "",
  companyContact: "",
  companyEmail: "",
  companyName: "",
  identityLevel: "",
  paymentsHistoryUrl: "",
  type: ExtensionTypeEnum.XtrmPayments,
  uuid: "",
  wallets: [],
};

interface XTRMPaymentsProps {
  onClose: () => void;
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
  xtrmPaymentsExtension: WsExtensionXtrmPayments;
}

export default function XTRMPayments({
  onClose,
  setExtensionsSection,
  xtrmPaymentsExtension,
}: XTRMPaymentsProps) {
  const [activeTab, setActiveTab] = useState<ActiveTabs>(ActiveTabs.Wallets);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [xtrmPaymentsDetails, setXtrmPaymentsDetails] =
    useState<XtrmPaymentsDetails>(xtrmPaymentsDetailsInitialData);

  const dispatch = useDispatch();

  const renderActiveTab = () => {
    switch (activeTab) {
      case ActiveTabs.Wallets:
        return <XTRMPaymentsWallets wallets={xtrmPaymentsDetails.wallets} />;
      case ActiveTabs.General:
        return (
          <XTRMPaymentsGeneral xtrmPaymentsDetails={xtrmPaymentsDetails} />
        );
      case ActiveTabs.Settings:
        return (
          <XTRMPaymentsSettings xtrmPaymentsDetails={xtrmPaymentsDetails} />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const getXtrmPaymentsDetails = async (uuid: string) => {
      const data = await getExtensionDetailsByUuid<{
        content: XtrmPaymentsDetails;
      }>({
        uuid,
        initialCallback: () => setIsLoading(true),
        finallyCallback: () => setIsLoading(false),
      });
      if (data) {
        setXtrmPaymentsDetails(data.content);
      }
    };
    getXtrmPaymentsDetails(xtrmPaymentsExtension.uuid);
  }, [xtrmPaymentsExtension.uuid]);

  useEffect(() => {
    return () => {
      dispatch(clearSettingsGlobalFields());
    };
  }, []);

  return (
    <ExtensionView
      title={<IDHFormattedMessage id="ws_xtrm" defaultMessage="XTRM" />}
      handleGoBack={() =>
        setExtensionsSection(IntegrationsSection.ExtensionsList)
      }
      goBackButtonLabel={
        <IDHFormattedMessage
          id="ws_integrations"
          defaultMessage="Integrations"
        />
      }
    >
      <div className="xtrmPayments">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="xtrmPayments__content">
              <Tabs>
                <div className="extensions__tabs">
                  <Tab
                    tabText={
                      <IDHFormattedMessage
                        id="ws_wallets"
                        defaultMessage="Wallets"
                      />
                    }
                    onClick={() => setActiveTab(ActiveTabs.Wallets)}
                    active={activeTab === ActiveTabs.Wallets}
                  />
                  <Tab
                    tabText={
                      <IDHFormattedMessage
                        id="ws_general"
                        defaultMessage="General"
                      />
                    }
                    onClick={() => setActiveTab(ActiveTabs.General)}
                    active={activeTab === ActiveTabs.General}
                  />
                  <Tab
                    tabText={
                      <IDHFormattedMessage
                        id="ws_settings"
                        defaultMessage="Settings"
                      />
                    }
                    onClick={() => setActiveTab(ActiveTabs.Settings)}
                    active={activeTab === ActiveTabs.Settings}
                  />
                </div>
              </Tabs>
              {renderActiveTab()}
            </div>
            <SettingsButtons onClose={onClose} />
          </>
        )}
      </div>
    </ExtensionView>
  );
}
