import React from "react";
import classNames from "classnames";

import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";

import "./Tabs.scss";

interface Props {
  titleId?: string;
  disabled?: boolean;
  underlined?: boolean;
  bigTitle?: boolean;
  children: React.ReactNode;
}

function Tabs(props: Props) {
  const { children, titleId, disabled, underlined, bigTitle } = props;
  return (
    <div
      className={classNames("tabs", {
        "tabs--disabled": disabled,
        "tabs--underlined": underlined,
      })}
    >
      {titleId && (
        <div
          className={classNames("tabs__title", {
            "tabs__title--big": bigTitle,
          })}
        >
          <IDHFormattedMessage id="ws_not_used" defaultMessage="Not used" />
        </div>
      )}
      <ul className="tabs__content">{children}</ul>
    </div>
  );
}

export default Tabs;
