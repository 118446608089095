import React, { useMemo } from "react";
import "./TitleBox.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useSelector } from "react-redux";
import ProjectPlaceholder from "../../../../../../images/project-placeholder.svg";
import { getImageSource } from "../../../../../../utils/methods";
import { getAggregatedOrFirstByKey } from "../../../ReportToolbar/components/DataAggregation/utils";

export function TitleBox() {
  const campaigns = useSelector((state) => state.reportReducer.campaigns);

  const data = useMemo(() => {
    return getAggregatedOrFirstByKey(campaigns, "project");
  }, [campaigns]);

  return (
    <div className="title-box">
      {Object.keys(campaigns).length === 1 ? (
        <img src={getImageSource(data?.cover, "small", ProjectPlaceholder)} />
      ) : null}
      <div className="title-box__info">
        <span className="title-box__info-header">
          <IDHFormattedMessage
            id="ws_campaign_report"
            defaultMessage="Campaign report"
          />
        </span>
        <span className="title-box__info-value">{data.title}</span>
      </div>
    </div>
  );
}
