import { setPublicationsMetadata, setPublicationSummaryStats } from "src/redux";
import { stripEmojis } from "../../../../utils/methods";
import { sortTasksByColumn } from "../sortTasksByColumn";
import { addHeaderToArray } from "../addHeaderToArray";

export const REPORT_ADDITIONAL_PUBLICATION_METAFIELDS = [
  "currency",
  "number",
  "percent",
];

export const addPublicationsToGridElements = (
  dispatch,
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  membersList,
  contentList,
  formatMessage,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.allPublications;

  addHeaderToArray(
    formatMessage({
      id: "ws_all_publications",
      defaultMessage: "All Publications",
    }),
    newGridElements,
    2,
    `publication`,
    savedTemplate,
    templateApplied,
  );

  Object.keys(campaigns).forEach((key) => {
    const campaign = campaigns[key];

    const {
      publications,
      creators,
      project: { title, projectId },
    } = campaign;

    if (key !== "aggregated") {
      addHeaderToArray(
        `All Publications - ${title}`,
        !aggregation ? newGridElements : newHiddenGridElements,
        3,
        `publication-${title}`,
        savedTemplate,
        templateApplied,
      );
    }

    dispatch(
      setPublicationsMetadata(
        key,
        publications[0]?.metadata
          .filter((item) => {
            const containsPublicationVS =
              item.fieldValueSource?.includes("publication.");

            return (
              !containsPublicationVS &&
              REPORT_ADDITIONAL_PUBLICATION_METAFIELDS.includes(item.type)
            );
          })
          .map((item) => ({
            ...item,
            reportVisible: false,
          })),
      ),
    );

    const settings = JSON.parse(localStorage.getItem("ws-project-settings"));
    const projectSettings = settings?.find(
      (item) => item.projectId === projectId,
    );
    const { activeSortingColumn, sortingDirection } =
      projectSettings?.publication || {};

    publications
      .sort((a, b) =>
        sortTasksByColumn(
          a,
          b,
          activeSortingColumn,
          sortingDirection,
          creators,
          membersList,
          contentList,
        ),
      )
      .forEach((item) => {
        const label = `publication-${stripEmojis(item.title)
          .slice(0, 15)
          .replace(/(\r\n|\n|\r)/gm, "")
          .replaceAll(" ", "-")
          .replaceAll(".", "")
          .replaceAll("/", "")
          .replaceAll("?", "")
          .toLowerCase()}-${item.taskId}`;

        const element = {
          ...item,
          label,
          gridBoxType: "publication",
          section: "all-publications",
          campaignId: key,
        };

        if (
          (!aggregation && key === "aggregated") ||
          (aggregation && key !== "aggregated")
        ) {
          newHiddenGridElements.push(element);
        } else if (savedTemplate?.length && !templateApplied) {
          if (savedTemplate.find((item) => item.i === element.label)) {
            newGridElements.push(element);
          } else {
            newHiddenGridElements.push(element);
          }
        }
        // applying default template
        else {
          newGridElements.push(element);
        }
      });
  });
};
