import { addHeaderToLayout } from "src/app/ReportGenerator/functions/addHeaderToLayout";
import { addMetaFieldToLayout } from "./addMetaFieldToLayout";

export const addCampaignDetailsToLayout = (
  campaigns,
  gridElements,
  newLayout,
  newSeparators,
  format,
) => {
  Object.keys(campaigns).map((key) => {
    if (key === "aggregated") return;

    const item = campaigns[key];

    const { title, projectId } = item.project;
    addHeaderToLayout(title, newLayout, format, 3);

    gridElements
      .filter(
        (item) =>
          item.gridBoxType === "metadata" && item.campaignId === projectId,
      )
      .forEach((box) => {
        addMetaFieldToLayout(box, newLayout, newSeparators, format);
      });
  });
};
