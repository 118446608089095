import React from "react";
import { CreatorsBrandAffinity } from "../../../../Task/tabs/ProfileDataTab/single/CreatorsBrandAffinity";

export const renderCreatorsBrandAffinity = (
  label,
  data,
  layout,
  GridBoxComponent,
) => {
  let boxData = null;

  if (data?.user_audience_data?.influencersBrandAffinity) {
    boxData = data?.user_audience_data?.influencersBrandAffinity;
  }

  if (data?.creatorsBrandAffinity) {
    boxData = data?.creatorsBrandAffinity;
  }

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <CreatorsBrandAffinity data={boxData} height={height} />
    </GridBoxComponent>
  );
};
