import React, { ReactNode, FunctionComponent, SVGProps } from "react";

import {
  MetaFieldType,
  getMetaFieldIcon,
} from "src/app/methods/getMetaFieldTypeOptions";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { GlobalField } from "./GlobalFields";

export const searchObjectsByName = (
  input: string,
  objectsArray: GlobalField[],
) => {
  const inputLowerCase = input.toLowerCase();
  return objectsArray.filter(
    (obj) =>
      obj.name?.toLowerCase().includes(inputLowerCase) ||
      obj.description?.toLowerCase().includes(inputLowerCase),
  );
};

export const renderTooltipIcon = (
  type: MetaFieldType,
  valueSource: string | null,
) => {
  if (typeof valueSource === "string") {
    if (valueSource.startsWith("tableFormula")) {
      return getMetaFieldIcon(MetaFieldType.Formula);
    }
    if (
      valueSource.startsWith("socialProfile") ||
      valueSource.startsWith("publication")
    )
      return getMetaFieldIcon(MetaFieldType.Metrics);
    if (valueSource.includes("wsCouponRedemptions"))
      return getMetaFieldIcon(MetaFieldType.SalesMetrics);
  }

  return getMetaFieldIcon(type);
};

function TooltipContentWrapper({
  title,
  description,
}: {
  title?: ReactNode;
  description?: ReactNode;
}) {
  return (
    <div className="global-fields__tooltip-wrapper">
      {title && <div className="global-fields__tooltip-title">{title}</div>}
      {description && <p>{description}</p>}
    </div>
  );
}

export const renderTooltipContent = (
  type: MetaFieldType,
  valueSource: string | null,
) => {
  if (typeof valueSource === "string") {
    if (valueSource.startsWith("tableFormula")) {
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_formula_type_field"
              defaultMessage="Formula type field"
            />
          }
        />
      );
    }

    if (valueSource.startsWith("socialProfile")) {
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_creator_metric_type_field"
              defaultMessage="Creator metric type field"
            />
          }
        />
      );
    }
    if (valueSource.startsWith("publication")) {
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_publication_metric_type_field"
              defaultMessage="Publication metric type field"
            />
          }
        />
      );
    }
    if (valueSource.includes("wsCouponRedemptions")) {
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_sales_tracking_metric_type_field"
              defaultMessage="Sales tracking metric type field"
            />
          }
        />
      );
    }
  }

  switch (type) {
    case MetaFieldType.Currency:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_currency_type_field"
              defaultMessage="Currency type field"
            />
          }
          description={
            <IDHFormattedMessage
              id="ws_currency_type_field_description"
              defaultMessage="Default currency selected during campaign creation."
            />
          }
        />
      );
    case MetaFieldType.Text:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_text_type_field"
              defaultMessage="Text type field"
            />
          }
        />
      );
    case MetaFieldType.SingleSelect:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_single_select_type_field"
              defaultMessage="Single select type field"
            />
          }
        />
      );
    case MetaFieldType.Percent:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_percent_type_field"
              defaultMessage="Percent type field"
            />
          }
        />
      );
    case MetaFieldType.Date:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_date_type_field"
              defaultMessage="Date type field"
            />
          }
        />
      );
    case MetaFieldType.Member:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_member_type_field"
              defaultMessage="Member type field"
            />
          }
        />
      );
    case MetaFieldType.Number:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_number_type_field"
              defaultMessage="Number type field"
            />
          }
        />
      );
    case MetaFieldType.Coupon:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_coupon_type_field"
              defaultMessage="Coupon type field"
            />
          }
        />
      );
    case MetaFieldType.MultiSelect:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_multi_select_type_field"
              defaultMessage="Multi select type field"
            />
          }
        />
      );
    case MetaFieldType.AggregatedSelect:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_deliverables_type_field"
              defaultMessage="Deliverables type field"
            />
          }
        />
      );
    case MetaFieldType.AccessLink:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_access_link_type_field"
              defaultMessage="Access link type field"
            />
          }
        />
      );
    case MetaFieldType.File:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_file_type_field"
              defaultMessage="File type field"
            />
          }
        />
      );
    case MetaFieldType.Creator:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_creator_type_field"
              defaultMessage="Creator type field"
            />
          }
        />
      );
    case MetaFieldType.Content:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_content_type_field"
              defaultMessage="Content type field"
            />
          }
        />
      );
    case MetaFieldType.PublicationRelated:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_publication_type_field"
              defaultMessage="Publication type field"
            />
          }
        />
      );
    case MetaFieldType.BoolVal:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_boolval_type_field"
              defaultMessage="BoolVal type field"
            />
          }
        />
      );
    case MetaFieldType.Rating:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_rating_type_field"
              defaultMessage="Rating type field"
            />
          }
        />
      );
    case MetaFieldType.Project:
      return (
        <TooltipContentWrapper
          title={
            <IDHFormattedMessage
              id="ws_project_field"
              defaultMessage="Project related field"
            />
          }
        />
      );
    default:
      return null;
  }
};

export function MetaFieldIcon({
  icon: Icon,
}: {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}) {
  return <Icon />;
}
