import { uuidv7 as uuid } from "uuidv7";
import { Dispatch } from "redux";

import { isStringUuid } from "src/utils/methods";
import { TaskType } from "src/types";
import {
  addProjectMetaField,
  createGlobalProjectMetaField,
  createNewProjectGlobalField,
  getTasksList,
  updateGlobalProjectField,
  updateProjectMetaField,
} from "src/redux";
import { generateRankString } from "src/utils/rankStrings";
import {
  DynamicValueProviderName,
  MetaFieldType,
} from "src/app/methods/getMetaFieldTypeOptions";
import { tableDataType } from "../../../components/Table/Table";
import {
  FormulaTab,
  IAccessLinkData,
  MFState,
  MFStateEntries,
  MetaFieldTypeOption,
  SelectedFormulaElement,
} from "../types";
import {
  matchOptions,
  mathOperatorOptions,
  resultFormatOptions,
  formulaMathematicalOptions,
} from "../utils";
// SET PROJECT FIELD DATA TO EDIT

interface SetDataProps {
  availableTargets: any;
  conditionColumns: any;
  data: any;
  metaFieldTypeOptions: MetaFieldTypeOption[];
  setBasicTypes: any;
  valueSourceOptions: any;
  setState: (type: MFStateEntries, value: any) => void;
  formulaElementOptions: SelectedFormulaElement[];
}

export const setProjectFieldData = async (functionProps: SetDataProps) => {
  const {
    availableTargets,
    conditionColumns,
    data,
    metaFieldTypeOptions,
    setBasicTypes,
    valueSourceOptions,
    setState,
    formulaElementOptions,
  } = functionProps;

  const initialData = data.data || {};
  const fieldType =
    initialData.arithmeticFieldOptions || initialData.summaryFieldOptions
      ? "formula"
      : data.metaFieldSource && data.metaFieldSource !== "accessLink"
        ? "metrics"
        : data.type;

  if (data.type === MetaFieldType.DictionaryElement) {
    setState(
      "selectedOption",
      metaFieldTypeOptions.find((option) =>
        option.value.includes(initialData.wsDictionaryUuid),
      ) || {},
    );
  } else {
    setState(
      "selectedOption",
      metaFieldTypeOptions.find((option) => option.value === fieldType) || {},
    );
  }

  switch (fieldType) {
    case MetaFieldType.Formula:
      const summaryField = initialData.summaryFieldOptions;
      const formulaArithmetics = initialData.arithmeticFieldOptions;

      if (!summaryField && !formulaArithmetics) return;

      if (summaryField) {
        setState("formulaTab", "table");

        const summaryFieldDataProps = {
          initialData,
          summaryField,
          availableTargets,
          conditionColumns,
          valueSourceOptions,
          setState,
        };

        setSummaryFieldData(summaryFieldDataProps);
      }

      if (formulaArithmetics) {
        setState("formulaTab", "overview");

        const formulaArithmeticsDataProps = {
          formulaArithmetics,
          formulaElementOptions,
          setState,
        };

        setFormulaArithmeticsData(formulaArithmeticsDataProps);
      }

      if (initialData.icon?.name) {
        setState("selectedIcon", {
          value: initialData.icon.name,
          label: initialData.icon.name,
        });
        setState("selectedColor", initialData.icon.color);
        setState("addIcon", true);
      }

      break;
    case MetaFieldType.Member:
      setState("filterMemberByTeam", initialData.wsTeamUuids?.length > 0);
      setState("wsTeamUuids", initialData.wsTeamUuids ?? []);
      break;
    case MetaFieldType.DictionaryElement:
      setState(
        "enableDictionarySubField",
        initialData?.wsDictionarySubFieldUuid,
      );
      setState(
        "wsDictionarySubFieldUuid",
        initialData?.wsDictionarySubFieldUuid ?? null,
      );
      break;
    default:
      setBasicTypes(fieldType, initialData);
      break;
  }
};

interface SummaryFieldDataProps {
  summaryField: any;
  availableTargets: any;
  conditionColumns: any;
  valueSourceOptions: any;
  setState: (type: MFStateEntries, value: any) => void;
}

const setSummaryFieldData = (props: SummaryFieldDataProps) => {
  const {
    summaryField,
    availableTargets,
    conditionColumns,
    valueSourceOptions,
    setState,
  } = props;

  if (summaryField.formula) {
    setState(
      "formulaValue",
      formulaMathematicalOptions.find(
        (item: any) => item.value === summaryField.formula,
      ) || {},
    );
  }
  if (summaryField.source.length) {
    setState(
      "selectedSummarySources",
      summaryField.source
        .filter((fieldId: any) =>
          valueSourceOptions.find((item: any) => item.value === fieldId),
        )
        .map((fieldId: any) => {
          return valueSourceOptions.find((item: any) => item.value === fieldId);
        }),
    );
  }
  if (summaryField.targetValue) {
    setState("targetValueOption", "custom");
    // setAddTargetValue(true);
    setState("targetValue", summaryField?.targetValue);
  }
  if (summaryField.targetSource) {
    setState("targetValueOption", "from-source");
    // setAddTargetValue(true);
    setState(
      "availableTargetsValue",
      availableTargets.find(
        (item: any) =>
          item.projectMetaFieldUuid ===
          summaryField.targetSource.projectMetaFieldUuid,
      ) || {},
    );
  }
  if (summaryField.conditions) {
    // setAddFieldCountCondition(true);

    setState(
      "matchValue",
      matchOptions.find(
        (item) => item.value === summaryField.conditions.operator,
      ) || matchOptions[0],
    );

    setState("conditionValues", [
      ...summaryField.conditions.fields
        .filter((item: any) =>
          conditionColumns?.find(
            (column: any) => item.taskMetaFieldUuid === column.value.value,
          ),
        )
        .map((item: any) => {
          const column = conditionColumns?.find(
            (column: any) => item.taskMetaFieldUuid === column.value.value,
          );

          return {
            column,
            value: { label: item.value, value: item.value },
            uuid: uuid(),
          };
        }),
      {
        uuid: uuid(),
        column: {
          label: "",
          value: null,
        },
        value: {
          label: "",
          value: null,
        },
      },
    ]);
  }
};

interface FormulaArithmeticsDataProps {
  formulaArithmetics: any;
  formulaElementOptions: SelectedFormulaElement[];
  setState: (type: MFStateEntries, value: any) => void;
}

const setFormulaArithmeticsData = ({
  formulaArithmetics,
  formulaElementOptions,
  setState,
}: FormulaArithmeticsDataProps) => {
  const operator = mathOperatorOptions.find(
    (item) => item.value === formulaArithmetics.operator,
  );

  const resultFormat = resultFormatOptions.find(
    (item) => item.value === formulaArithmetics.resultFormat,
  );

  let elA;
  let elB;

  if (isStringUuid(formulaArithmetics.elements[0])) {
    elA = formulaElementOptions.find(
      (item) => item?.value?.id === formulaArithmetics.elements[0],
    );
  } else {
    elA = {
      value: formulaArithmetics.elements[0],
      label: formulaArithmetics.elements[0],
    };
  }

  if (isStringUuid(formulaArithmetics.elements[1])) {
    elB = formulaElementOptions.find(
      (item) => item?.value?.id === formulaArithmetics.elements[1],
    );
  } else {
    elB = {
      value: formulaArithmetics.elements[1],
      label: formulaArithmetics.elements[1],
    };
  }

  setState("selectedFormulaOperator", operator);
  setState("selectedResultFormat", resultFormat);

  setState("selectedFormulaElements", { a: elA ?? {}, b: elB ?? {} });
};

const getProjectFieldType = (
  metaFieldType: string,
  formulaTab: FormulaTab,
  selectedResultFormat: any,
  workspaceDictionaryList: any[],
) => {
  const type = metaFieldType.split("_")[0];

  if (
    workspaceDictionaryList?.length > 0 &&
    workspaceDictionaryList.find(
      (item) =>
        item?.wsDictionaryElementName?.toLowerCase() === type?.toLowerCase(),
    )
  ) {
    return "dictionaryElement";
  }

  switch (type) {
    case "formula":
      if (formulaTab === "overview") {
        return selectedResultFormat.value;
      }

      return "number";

    case "brief":
    case "client":
    case "brand":
    case "agency":
      return "dictionaryElement";

    default:
      return metaFieldType;
  }
};

// CREATE PROJECT FIELD

interface CreateProps {
  taskType: TaskType;
  dataType: tableDataType;
  dispatch: Dispatch<any>;
  metaFieldType: any;
  projectId: string;
  projectMetaFields: any;
  isNewOverviewForClient: any;
  state: MFState;
  accessLinkData: IAccessLinkData;
  activeWorkspaceUuid: string;
  identity: any;
  globalFieldMode?: boolean;
}

export const createProjectField = async (functionProps: CreateProps) => {
  const {
    taskType,
    dispatch,
    dataType,
    metaFieldType,
    projectId,
    projectMetaFields,
    isNewOverviewForClient,
    state: {
      description,
      availableTargetsValue,
      conditionValues,
      fieldName,
      formulaValue,
      matchValue,
      selectedOption,
      selectedCurrency,
      selectedSummarySources,
      targetValue,
      targetValueOption,
      selectedIcon,
      selectedColor,
      selectedFormulaElements,
      selectedFormulaOperator,
      selectedResultFormat,
      formulaTab,
      globalMetaFieldData,
      wsTeamUuids,
      enableDictionarySubField,
      wsDictionarySubFieldUuid,
      filterMemberByTeam,
    },
    accessLinkData,
    activeWorkspaceUuid,
    identity,
    globalFieldMode,
  } = functionProps;

  const projectFields = projectMetaFields || [];

  const filteredConditions = conditionValues.filter(
    (item: any) => item.column.value && item.column.label,
  );

  const summaryFieldOptions = {
    source: selectedSummarySources.map((item: { value: any }) => item.value),
    targetSource:
      targetValueOption === "custom"
        ? null
        : {
            projectMetaFieldUuid: availableTargetsValue.projectMetaFieldUuid,
            projectMetaFieldTaskType:
              availableTargetsValue.projectMetaFieldTaskType,
          },
    formula: formulaValue.value,
    targetValue:
      targetValueOption === "custom"
        ? targetValue
        : availableTargetsValue.targetValue,
    conditions: {
      operator: matchValue.value,
      fields: filteredConditions.map((item: any) => ({
        taskMetaFieldUuid: item.column.value.value,
        value: item.value.value,
      })),
    },
  };

  const arithmeticFieldOptions = {
    elements: [
      selectedFormulaElements.a.value?.id || selectedFormulaElements.a.value,
      selectedFormulaElements.b.value?.id || selectedFormulaElements.b.value,
    ],
    resultFormat: selectedResultFormat.value,
    operator: selectedFormulaOperator.value,
  };

  let metaFieldValueSource = null;

  switch (selectedOption.value) {
    case MetaFieldType.AccessLink:
      metaFieldValueSource = DynamicValueProviderName.AccessLink;
      break;
    case MetaFieldType.Project:
      metaFieldValueSource = DynamicValueProviderName.Project;
      break;
  }

  const formulaCurrency =
    selectedFormulaElements.a.value?.currencyCode ||
    selectedFormulaElements.b.value?.currencyCode;
  const currencyCode =
    formulaCurrency ||
    (selectedOption.value === "currency" ? selectedCurrency?.value : null);

  const fieldType = getProjectFieldType(
    metaFieldType || "text",
    formulaTab,
    selectedResultFormat,
    identity?.workspaceDictionaryList,
  );

  const id = uuid();
  const lastElementRank =
    projectFields[projectFields.length - 1]?.rank || "aaa";

  const rank = generateRankString(lastElementRank, "");

  if (globalFieldMode) {
    dispatch(
      createNewProjectGlobalField({
        wsWorkspaceUuid: activeWorkspaceUuid,
        metaFieldName: fieldName,
        metaFieldUuid: id,
        metaFieldDescription: description,
        metaFieldType: fieldType,
        metaFieldTaskType: taskType,
        metaFieldCurrency: currencyCode,
        arithmeticFieldOptions:
          selectedOption.value === "formula" && formulaTab === "overview"
            ? arithmeticFieldOptions
            : null,
        summaryFieldOptions:
          selectedOption.value === "formula" && formulaTab === "table"
            ? summaryFieldOptions
            : null,
        wsDictionaryUuid:
          fieldType === "dictionaryElement"
            ? metaFieldType.split("_")[1]
            : null,
        wsTeamUuids:
          selectedOption.value === "member" && filterMemberByTeam
            ? wsTeamUuids
            : null,
        wsDictionarySubFieldUuid:
          fieldType === "dictionaryElement" && enableDictionarySubField
            ? wsDictionarySubFieldUuid
            : null,
        metaFieldIcon: selectedIcon.value
          ? { name: selectedIcon.value, color: selectedColor }
          : null,
        metaFieldValueSource,
      }),
    );
  } else if (globalMetaFieldData.length > 0) {
    let prevRank = "";
    const globalMetaFieldDataForBackend = globalMetaFieldData.map(
      (field, i) => {
        let rank;

        if (i === 0) {
          const lastElementRank =
            projectFields[projectFields.length - 1]?.rank || "aaa";
          rank = generateRankString(lastElementRank, "");
          prevRank = rank;
        } else {
          rank = generateRankString(prevRank, "");
          prevRank = rank;
        }

        const id = uuid();
        return {
          wsGlobalProjectMetaFieldUuid: field.uuid,
          wsProjectUuid: projectId,
          wsProjectMetaFieldRank: rank,
          wsProjectMetaFieldUuid: id,
          taskType: null,
        };
      },
    );
    await dispatch(createGlobalProjectMetaField(globalMetaFieldDataForBackend));
  } else {
    await dispatch(
      addProjectMetaField({
        uuid: id,
        projectId,
        name: fieldName,
        type: getProjectFieldType(
          metaFieldType || "text",
          formulaTab,
          selectedResultFormat,
          identity?.workspaceDictionaryList,
        ),
        taskType: dataType === tableDataType.ProjectDetails ? null : taskType,
        rank,
        shared: isNewOverviewForClient,
        wsMetaFieldsToCreateAccess:
          selectedOption.value === "accessLink" ? accessLinkData : null,
        metaFieldValueSource,
        currencyCode,
        summaryFieldOptions:
          selectedOption.value === "formula" && formulaTab === "table"
            ? summaryFieldOptions
            : null,
        arithmeticFieldOptions:
          selectedOption.value === "formula" && formulaTab === "overview"
            ? arithmeticFieldOptions
            : null,
        icon: selectedIcon.value
          ? { name: selectedIcon.value, color: selectedColor }
          : null,
        wsDictionaryUuid:
          fieldType === "dictionaryElement"
            ? metaFieldType.split("_")[1]
            : null,
        wsTeamUuids:
          selectedOption.value === "member" && filterMemberByTeam
            ? wsTeamUuids
            : null,
        wsDictionarySubFieldUuid:
          fieldType === "dictionaryElement" && enableDictionarySubField
            ? wsDictionarySubFieldUuid
            : null,
      }),
    );
  }

  if (dataType === tableDataType.Task) {
    await dispatch(getTasksList(projectId, taskType));
  }
};

// UPDATE PROJECT FIELD

interface UpdateProps {
  data: any;
  dispatch: Dispatch<any>;
  state: MFState;
  accessLinkData: IAccessLinkData;
  activeWorkspaceUuid: string;
  editingGlobalField?: boolean;
  identity: any;
}

export const updateProjectField = async (functionProps: UpdateProps) => {
  const {
    data,
    dispatch,
    state: {
      description,
      availableTargetsValue,
      conditionValues,
      fieldName,
      formulaValue,
      matchValue,
      selectedOption,
      selectedCurrency,
      selectedSummarySources,
      targetValue,
      targetValueOption,
      selectedIcon,
      selectedColor,
      selectedFormulaElements,
      selectedFormulaOperator,
      selectedResultFormat,
      formulaTab,
      wsTeamUuids,
      enableDictionarySubField,
      wsDictionarySubFieldUuid,
      filterMemberByTeam,
    },
    accessLinkData,
    activeWorkspaceUuid,
    editingGlobalField,
    identity,
  } = functionProps;

  const filteredConditions = conditionValues.filter(
    (item: any) => item.column.value && item.column.label,
  );

  const summaryFieldOptions = data.valueSource
    ? {
        source: selectedSummarySources.map((item: any) => item.value),
        targetSource:
          targetValueOption === "custom"
            ? null
            : {
                projectMetaFieldUuid:
                  availableTargetsValue.projectMetaFieldUuid,
                projectMetaFieldTaskType:
                  availableTargetsValue.projectMetaFieldTaskType,
              },
        formula: formulaValue.value,
        targetValue:
          targetValueOption === "custom"
            ? targetValue
            : availableTargetsValue.targetValue,
        conditions: {
          operator: matchValue?.value,
          fields: filteredConditions.map((item: any) => ({
            taskMetaFieldUuid: item.column.value.value,
            value: item.value.value,
          })),
        },
      }
    : null;

  const arithmeticFieldOptions = {
    elements: [
      selectedFormulaElements.a.value?.id || selectedFormulaElements.a.value,
      selectedFormulaElements.b.value?.id || selectedFormulaElements.b.value,
    ],
    resultFormat: selectedResultFormat.value,
    operator: selectedFormulaOperator.value,
  };
  const formulaCurrency =
    selectedFormulaElements.a.value?.currencyCode ||
    selectedFormulaElements.b.value?.currencyCode;

  const fieldType = getProjectFieldType(
    selectedOption.value || "text",
    formulaTab,
    selectedResultFormat,
    identity?.workspaceDictionaryList,
  );

  if (editingGlobalField) {
    dispatch(
      updateGlobalProjectField({
        wsWorkspaceUuid: activeWorkspaceUuid,
        metaFieldName: fieldName,
        metaFieldUuid: data.id,
        metaFieldDescription: description,
        metaFieldCurrency:
          formulaCurrency ||
          (selectedOption.value === "currency"
            ? selectedCurrency?.value
            : null),
        arithmeticFieldOptions:
          selectedOption.value === "formula" && formulaTab === "overview"
            ? arithmeticFieldOptions
            : null,
        summaryFieldOptions:
          selectedOption.value === "formula" && formulaTab === "table"
            ? summaryFieldOptions
            : null,
        metaFieldIcon: selectedIcon.value
          ? {
              name: selectedIcon.value,
              color: selectedColor,
            }
          : null,
        wsDictionarySubFieldUuid:
          fieldType === "dictionaryElement" && enableDictionarySubField
            ? wsDictionarySubFieldUuid
            : null,
        metaFieldValueSource:
          selectedOption.value === MetaFieldType.Formula
            ? null
            : data.valueSource,
        wsTeamUuids:
          selectedOption.value === "member" && filterMemberByTeam
            ? wsTeamUuids
            : null,
        wsDictionaryUuid:
          fieldType === "dictionaryElement"
            ? selectedOption.value.split("_")[1]
            : null,
      }),
    );
  } else {
    await dispatch(
      updateProjectMetaField({
        metaFieldUuid: data.uuid,
        metaFieldNewName: fieldName,
        wsMetaFieldsToCreateAccess:
          selectedOption.value === "accessLink" ? accessLinkData : null,
        metaFieldValueSource:
          selectedOption.value === "accessLink" ? "accessLink" : null,
        currencyCode:
          formulaCurrency ||
          (selectedOption.value === "currency"
            ? selectedCurrency?.value
            : null),
        summaryFieldOptions:
          selectedOption.value === "formula" && formulaTab === "table"
            ? summaryFieldOptions
            : null,
        arithmeticFieldOptions:
          selectedOption.value === "formula" && formulaTab === "overview"
            ? arithmeticFieldOptions
            : null,
        icon: selectedIcon.value
          ? {
              name: selectedIcon.value,
              color: selectedColor,
            }
          : null,
        wsTeamUuids:
          selectedOption.value === "member" && filterMemberByTeam
            ? wsTeamUuids
            : null,
        wsDictionarySubFieldUuid:
          fieldType === "dictionaryElement" && enableDictionarySubField
            ? wsDictionarySubFieldUuid
            : null,
      }),
    );
  }
};
