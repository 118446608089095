import React, { Dispatch, SetStateAction, useState } from "react";
import "./TaskMenuDropdown.scss";

import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DeleteTaskConfirmationModal } from "src/app/modals/DeleteTaskConfirmationModal/DeleteTaskConfirmationModal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { TaskType } from "src/types";
import { openGlobalTaskDetails } from "src/app/components/GlobalTaskDetails/methods";
import { addExistingTasksToDatabase } from "src/redux/creator-database/creatorDatabaseActions";
import { getTasksList } from "src/redux";
import {
  WorkspacePermissionsEnum,
  ProjectPermissionsEnum,
} from "src/utils/PermissionsEnums";
import { Button } from "src/app/components/Button/Button";
import { RootState } from "src/redux/reducers";
import {
  CreatorShowcaseContext,
  openCreatorShowcase,
} from "src/app/CreatorShowcase/utils";
import {
  openReportGenerator,
  ReportGeneratorContext,
} from "src/app/ReportGenerator/utils/reportFunctions";
import { ReactComponent as OpenIcon } from "../../../images/cursor-loading.svg";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import { ReactComponent as MoveRightIcon } from "../../../images/move-right.svg";
import { ReactComponent as LinkIcon } from "../../../images/link.svg";
import { ReactComponent as BinIcon } from "../../../images/trash-can.svg";
import { ReactComponent as ShowcaseIcon } from "../../../images/showcase.svg";
import { ReactComponent as ReportIcon } from "../../../images/report.svg";
import { ReactComponent as CreatorIcon } from "../../../images/creator.svg";
import { ReactComponent as SettingsIcon } from "../../../images/settings.svg";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "../../components/Dropdown/Dropdown";
import { MoveToPane } from "./MoveToPane";
import { Metadata } from "../../Task/Subtask/Subtask";
import { showToast } from "../../methods/showToast";

type Props = {
  data?: any;
  menuButton?: JSX.Element;
  taskId?: string;
  onOpen?: () => void;
  editTask?: () => void;
  enableMove?: boolean;
  showDeleteButton?: boolean;
  deleteSubtask?: () => void;
  copyLink?: () => void;
  openLink?: () => void;
  boardColumns?: Metadata[];
  projectId?: string;
};

const TaskMenuDropdown: React.FC<Props> = (props) => {
  const {
    data,
    menuButton,
    taskId,
    onOpen,
    editTask,
    enableMove,
    showDeleteButton,
    deleteSubtask,
    copyLink,
    openLink,
    boardColumns,
    projectId,
  } = props;

  const [showMoveToPane, setShowMoveToPane] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const params = useParams<{ projectId: string }>();
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();

  const dispatch = useDispatch();

  const {
    mainReducer: { activeWorkspaceUuid, identity },
    projectReducer: { projectBasicData },
  } = useSelector((state: RootState) => state);

  const { taskType } = data || {};

  const openFunction = (e: React.MouseEvent, setOpen: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const openShowcase = () => {
    if (taskId) {
      openCreatorShowcase({
        history,
        context: CreatorShowcaseContext.CreatorList,
        creatorId: taskId,
        ids: [taskId],
        workspaceId: activeWorkspaceUuid,
      });
    }
  };

  const showRemoveBtn = () => {
    if (showDeleteButton) {
      return (
        (boardColumns &&
          projectBasicData?.permissions?.project?.includes(
            ProjectPermissionsEnum.TASK_MANAGEMENT,
          )) ||
        data?.permissions?.project.includes(
          ProjectPermissionsEnum.TASK_MANAGEMENT,
        )
      );
    }
    if (deleteSubtask) {
      return true;
    }

    return false;
  };

  const addCreatorsToDatabase = () => {
    if (!taskId) return;
    dispatch(addExistingTasksToDatabase([taskId]));
    dispatch(getTasksList(params.projectId, taskType));

    showToast(
      "success",
      <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
      <IDHFormattedMessage
        id="ws_creators_queued_to_add_to_the_databse"
        defaultMessage="Creators have been enqueued to add to the database."
      />,
    );
  };

  const renderCreatorDatabaseButton = (
    setIsOpen: Dispatch<SetStateAction<boolean>>,
  ) => {
    if (taskType !== TaskType.Creator || url.includes("/details")) return null;

    const hasAccessToCreatorDatabase =
      identity?.permissions?.workspace?.includes(
        WorkspacePermissionsEnum.CREATOR_DATABASE,
      );

    if (!hasAccessToCreatorDatabase || projectBasicData?.seeAsClientMode)
      return null;

    if (data?.wsGlobalTaskUuid) {
      return (
        <DropdownMenuItem
          onClick={() => openGlobalTaskDetails(history, data.wsGlobalTaskUuid)}
        >
          <CreatorIcon />
          <IDHFormattedMessage
            id="ws_show_in_creator_database"
            defaultMessage="Show in Creator Database"
          />
        </DropdownMenuItem>
      );
    }

    return (
      <DropdownMenuItem
        onClick={() => {
          addCreatorsToDatabase();
          setIsOpen(false);
        }}
      >
        <CreatorIcon />
        <IDHFormattedMessage
          id="ws_add_to_creator_database"
          defaultMessage="Add to Creator Database"
        />
      </DropdownMenuItem>
    );
  };

  return (
    <Dropdown toRight>
      {(isOpen, setIsOpen) => (
        <>
          {menuButton ? (
            <span onClick={(e: React.MouseEvent) => openFunction(e, setIsOpen)}>
              {menuButton}
            </span>
          ) : (
            <Button
              variant="light-grey"
              size="square-small"
              onClick={(e: React.MouseEvent) => openFunction(e, setIsOpen)}
            >
              <SettingsIcon />
            </Button>
          )}

          <DropdownMenu isOpen={isOpen}>
            {onOpen && (
              <DropdownMenuItem onClick={onOpen}>
                <OpenIcon />
                <IDHFormattedMessage id="ws_open" defaultMessage="Open" />
              </DropdownMenuItem>
            )}

            {editTask && (
              <DropdownMenuItem onClick={editTask}>
                <EditIcon />
                <IDHFormattedMessage id="ws_edit" defaultMessage="Edit" />
              </DropdownMenuItem>
            )}

            {renderCreatorDatabaseButton(setIsOpen)}

            {!projectId &&
              data &&
              data.taskType === TaskType.Creator &&
              data.permissions?.project.includes("download_report") && (
                <DropdownMenuItem onClick={openShowcase}>
                  <ShowcaseIcon />
                  <IDHFormattedMessage
                    id="ws_generate_showcase"
                    defaultMessage="Generate showcase"
                  />
                </DropdownMenuItem>
              )}
            {enableMove && boardColumns && boardColumns?.length > 0 && (
              <DropdownMenuItem onClick={() => setShowMoveToPane(true)}>
                <MoveRightIcon />
                <IDHFormattedMessage id="ws_move_to" defaultMessage="Move to" />
              </DropdownMenuItem>
            )}
            {openLink && (
              <DropdownMenuItem onClick={openLink}>
                <OpenIcon />
                <IDHFormattedMessage id="ws_open" defaultMessage="Open" />
              </DropdownMenuItem>
            )}
            {copyLink && (
              <DropdownMenuItem onClick={copyLink}>
                <LinkIcon />
                <IDHFormattedMessage
                  id="ws_copy_link"
                  defaultMessage="Copy link"
                />
              </DropdownMenuItem>
            )}

            {projectId &&
              data.permissions?.project.includes("download_report") && (
                <DropdownMenuItem
                  onClick={() => {
                    openReportGenerator({
                      history,
                      context: ReportGeneratorContext.Campaign,
                      workspaceId: activeWorkspaceUuid,
                      ids: [data.projectId],
                    });
                  }}
                >
                  <ReportIcon />
                  <IDHFormattedMessage
                    id="ws_generate_report"
                    defaultMessage="Generate report"
                  />
                </DropdownMenuItem>
              )}
            {showRemoveBtn() && (
              <DropdownMenuItem
                onClick={deleteSubtask || (() => setShowDeleteModal(true))}
                className="dropdown__menu-item--danger"
              >
                <BinIcon />
                <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
              </DropdownMenuItem>
            )}
            <MoveToPane
              isOpen={showMoveToPane}
              setShowMoveToPane={setShowMoveToPane}
              taskId={taskId || ""}
              boardColumns={boardColumns || []}
            />
          </DropdownMenu>

          {showDeleteModal && (
            <DeleteTaskConfirmationModal
              onClose={() => setShowDeleteModal(false)}
              wsTaskUuid={taskId ?? ""}
              wsProjectUuid={params.projectId}
              taskName={
                data?.metadata?.find(
                  (metafield: Metadata) => metafield.key === "title",
                )?.value || ""
              }
            />
          )}
        </>
      )}
    </Dropdown>
  );
};

export default TaskMenuDropdown;
