import React from "react";
import { Keywords } from "../../../../Task/tabs/ProfileDataTab/single/Keywords";

export const renderKeywords = (label, data, layout, GridBoxComponent) => {
  const { keywords } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <Keywords
        keywords={Object.keys(keywords).map((key) => ({
          value: key,
          count: keywords[key],
        }))}
        height={height}
      />
    </GridBoxComponent>
  );
};
