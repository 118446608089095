import React from "react";
import "./CoverPlaceholder.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as InfoIcon } from "src/images/info-new.svg";
import { updateModeParam } from "src/app/CreatorShowcase/utils";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { ReactComponent as PlaceholderBoxes } from "./placeholder.svg";
import { ShowcaseSeparator } from "../../../../components/ShowcaseSeparator/ShowcaseSeparator";

function CoverPlaceholder(props) {
  const { workspaceData } = props;
  const history = useHistory();

  const summaryPlacement = useSelector(
    (state) => state.showcaseReducer.summaryPlacement,
  );
  const placement = summaryPlacement.value;

  const identity = useSelector((state) => state.mainReducer.identity);
  const primaryColor = identity.customPrimaryColor || "#5D78FF";

  return (
    <div className="cover-placeholder">
      <ShowcaseSeparator workspaceData={workspaceData} hideInfo />

      <div className="cover-placeholder__image">
        <PlaceholderBoxes />
      </div>
      <div className="cover-placeholder__info">
        <InfoIcon />
        <div className="cover-placeholder__info-bold">
          <IDHFormattedMessage
            id="ws_here_will_be_content_of_showcase"
            defaultMessage="Here will be the content of the showcase"
          />
          .
        </div>
        <div className="cover-placeholder__info-text">
          <IDHFormattedMessage
            id="ws_to_edit_this_part_go_to"
            defaultMessage="To edit this part go to the"
          />
          &nbsp;
          <span
            className="cover-placeholder__info-text-underline"
            onClick={() => updateModeParam(history, "tab", "creators")}
          >
            <IDHFormattedMessage id="ws_creators" defaultMessage="Creators" />
          </span>
          &nbsp;
          <IDHFormattedMessage id="ws_tab" defaultMessage="tab" />.
        </div>
      </div>

      <ShowcaseSeparator
        className="cover-placeholder__bottom-separator"
        primaryColor={placement !== "bottom" ? primaryColor : undefined}
        workspaceData={workspaceData}
      />
    </div>
  );
}

export default CoverPlaceholder;
