import React from "react";

import { Er } from "../../../../Task/tabs/ProfileDataTab/single/Er";

export const renderEr = (label, data, layout, GridBoxComponent) => {
  const {
    user_data: {
      avgEngagements,
      avgLikes,
      er,
      socialProvider,
      avgViews,
      avgComments,
      avgReelsPlays,
    },
    userApiData: {
      avgImpressionsPerPost,
      avgImpressionsPerStory,
      avgRealReachPerPost,
      avgRealReachPerStory,
    },
  } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <Er
        er={er}
        avgEngagements={avgEngagements}
        avgLikes={avgLikes}
        avgImpressionsPerPost={avgImpressionsPerPost}
        avgImpressionsPerStory={avgImpressionsPerStory}
        avgRealReachPerPost={avgRealReachPerPost}
        avgRealReachPerStory={avgRealReachPerStory}
        height={height}
        socialProvider={socialProvider}
        avgViews={avgViews}
        avgComments={avgComments}
        avgReelsPlays={avgReelsPlays}
      />
    </GridBoxComponent>
  );
};
