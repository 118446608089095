import React from "react";
import { AudienceBrandAffinity } from "../../../../Task/tabs/ProfileDataTab/single/AudienceBrandAffinity";

export const renderAudienceBrandAffinity = (
  label,
  data,
  layout,
  GridBoxComponent,
) => {
  const {
    user_audience_data: { brandAffinity },
  } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <AudienceBrandAffinity brandAffinity={brandAffinity} height={height} />
    </GridBoxComponent>
  );
};
