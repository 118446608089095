import {
  FIELDS_BLACKLIST,
  META_TYPES_WHITELIST,
} from "../../components/ReportTemplate/ReportTemplate";
import { addHeaderToArray } from "../addHeaderToArray";

export const addCampaignDetailsToGridElements = (
  campaigns,
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
) => {
  Object.keys(campaigns).map((key) => {
    if (key === "aggregated") return;

    const item = campaigns[key];

    const { metadata, title, projectId } = item.project;

    addHeaderToArray(
      item.project.title,
      newGridElements,
      3,
      title,
      savedTemplate,
      templateApplied,
    );

    const filteredMetadata = metadata
      ?.filter(
        (field) =>
          field.taskType === null &&
          !FIELDS_BLACKLIST.includes(field.key) &&
          META_TYPES_WHITELIST.includes(field.type),
      )
      .sort((a, b) => a.rank?.localeCompare(b.rank));

    filteredMetadata.forEach((field) => {
      const label = `metadata-${field.key
        .replaceAll(" ", "-")
        ?.replaceAll(".", "")
        .toLowerCase()}-${field.uuid}-${projectId}`;

      const element = {
        ...field,
        label,
        gridBoxType: "metadata",
        visible: field.isVisible,
        section: "campaignDetails",
        campaignId: projectId,
      };

      // applying saved template
      if (savedTemplate?.length && !templateApplied) {
        if (savedTemplate.find((item) => item.i === element.label)) {
          newGridElements.push(element);
        } else {
          newHiddenGridElements.push(element);
        }
      }
      // applying default template
      else if (field.isVisible && field.value) {
        newGridElements.push(element);
      } else {
        newHiddenGridElements.push(element);
      }
    });
  });
};
