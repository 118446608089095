export const addHeaderToArray = (
  text,
  array,
  depth,
  section,
  savedTemplate,
  templateApplied,
  headerType,
) => {
  const header = {
    text,
    label: `header-${depth}-${section}`,
    gridBoxType: "header",
    visible: true,
    section,
    depth,
    headerType,
  };

  if (savedTemplate?.length && !templateApplied) {
    if (savedTemplate.find((item) => item.i === header.label)) {
      array.push(header);
    }
  } else {
    array.push(header);
  }
};
