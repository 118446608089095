import React from "react";

import classNames from "classnames";

import { DropdownPortalMenu } from "src/app/components/DropdownPortal";

interface MenuOverlayProps {
  showEditOptionPane: boolean;
  handleClick: () => void;
  spaceLeft?: boolean;
  className?: string;
}

export function MenuOverlay({
  showEditOptionPane,
  children,
  handleClick,
  spaceLeft,
  className,
}: React.PropsWithChildren<MenuOverlayProps>) {
  return (
    <DropdownPortalMenu
      className={classNames(
        {
          "dropdown__menu--left": showEditOptionPane && spaceLeft,
        },
        "select__menu",
        className,
      )}
      onClick={handleClick}
    >
      {children}
    </DropdownPortalMenu>
  );
}
