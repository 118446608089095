import React, { useState } from "react";
import "./RenameModal.scss";

import { useDispatch, useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { injectIntl, IntlShape, WrappedComponentProps } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { RootState } from "src/redux/reducers";
import { Button } from "../../components/Button/Button";
import CustomInput, {
  CustomInputLabel,
} from "../../components/CustomInput/CustomInput";
import Modal, {
  hideModal,
  ModalColumn,
  ModalRow,
  ModalText,
  ModalTitle,
} from "../../components/Modal/Modal";
import {
  changeTaskMetaFieldValue,
  getProject,
  getProjectDetails,
  getTaskDetails,
  getTasksList,
  renameProjectOnList,
  updateProjectMetaValue,
} from "../../../redux";
import { capitalizeFirstLetter } from "../../../utils/methods";
import { tableDataType } from "../../components/Table/Table";

interface Props extends WrappedComponentProps<"intl"> {
  onClose: () => void;
  objectId: string;
  taskName: string;
  titleFieldId: string;
  projectId: string;
  dataType: tableDataType;
  isListElement?: boolean;
  intl: IntlShape;
}

function RenameModal(props: Props) {
  const {
    onClose,
    taskName,
    objectId,
    titleFieldId,
    projectId,
    dataType,
    isListElement,
    intl,
  } = props;

  const dispatch = useDispatch();

  const [value, setValue] = useState<string>(taskName);

  const { taskType } = useSelector((state: RootState) => state.projectReducer);

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    switch (dataType) {
      case tableDataType.Task:
        await dispatch(
          changeTaskMetaFieldValue(objectId, titleFieldId, value.trim()),
        );
        dispatch(getTasksList(projectId, taskType));
        dispatch(getTaskDetails(objectId));
        break;

      case tableDataType.Project:
        if (isListElement) {
          await dispatch(
            renameProjectOnList(objectId, titleFieldId, value.trim()),
          );
        } else {
          await dispatch(
            updateProjectMetaValue(objectId, titleFieldId, value.trim()),
          );
        }
        dispatch(getProject(projectId));
        break;

      case tableDataType.ProjectDetails:
        await dispatch(
          updateProjectMetaValue(objectId, titleFieldId, value.trim()),
        );
        dispatch(getProject(projectId));
        dispatch(getProjectDetails(projectId));

        break;
    }

    if (onClose) {
      hideModal(onClose);
    }
  };

  const renderType = () => {
    const type =
      dataType === tableDataType.ProjectDetails
        ? tableDataType.Project
        : dataType;

    return translateMessage({
      intl,
      id: `ws_${type.toLowerCase()}`,
      defaultMessage: type,
    });
  };

  return (
    <Modal
      className="rename-task-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={handleOnSubmit}
      confirmButtonDisabled={!value.trim()}
      confirmButtonText={
        <>
          <IDHFormattedMessage id="ws_rename" defaultMessage="Rename" />
          &nbsp;
          {renderType()}
        </>
      }
    >
      <ModalTitle>
        <IDHFormattedMessage id="ws_rename" defaultMessage="Rename" />
        &nbsp;
        {renderType()}
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_enter_new_name_for_selected_task"
          defaultMessage="Enter below a new name for the selected task."
        />
      </ModalText>
      <form onSubmit={handleOnSubmit}>
        <ModalRow>
          <ModalColumn>
            <CustomInputLabel htmlFor="fieldTitle">
              {capitalizeFirstLetter(renderType())}&nbsp;
              <IDHFormattedMessage id="ws_title" defaultMessage="title" />
            </CustomInputLabel>
            <CustomInput
              id="fieldTitle"
              type="text"
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue(e.target.value.trimStart())
              }
              placeholder={`${translateMessage({
                intl,
                id: "ws_enter",
                defaultMessage: "Enter",
              })} ${renderType()} ${translateMessage({
                intl,
                id: "ws_title",
                defaultMessage: "title",
              })}`}
            />
          </ModalColumn>
        </ModalRow>
      </form>
    </Modal>
  );
}

export default injectIntl(RenameModal);
