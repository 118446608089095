import React, { useState } from "react";
import { useSelector } from "react-redux";
import ImgChecker from "../ImgChecker/ImgChecker";

function PostImagesChecker({
  data,
  render,
  max = 3,
  provider,
  recentlyPublishedReady,
  setRecentlyPublishedReady,
}) {
  const [successImages, setSuccessImages] = useState([]);
  const [dataIndex, setDataIndex] = useState(0);

  const {
    showcaseReducer: { printing },
  } = useSelector((state) => state);

  /* Because sometimes we receive null from image's link and we don't want to
  show placeholders in recent posts column. We use this component to create array
  containing only existing images. */

  const addSuccessImage = (item) => {
    setSuccessImages([...successImages, item]);
    incrementIndex();
  };

  const incrementIndex = () => {
    if (dataIndex + 1 < data.length && successImages.length < max) {
      setDataIndex(dataIndex + 1);
    } else if (
      printing &&
      !recentlyPublishedReady?.includes(provider) &&
      setRecentlyPublishedReady
    ) {
      setRecentlyPublishedReady((items) => [...items, provider]);
    }
  };

  if (data.length === 0) return null;

  return (
    <>
      <ImgChecker
        key={data[dataIndex].thumbnailSrc}
        src={data[dataIndex].thumbnailSrc}
        onSuccess={() => addSuccessImage(data[dataIndex])}
        onError={incrementIndex}
      />
      {successImages.map((item, index) => {
        if (index > max - 1) return null;
        return <>{render(item, index)}</>;
      })}
    </>
  );
}

export default PostImagesChecker;
