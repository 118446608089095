import React from "react";
import "./InfluencerInfo.scss";
import { useHistory } from "react-router-dom";
import { ReactComponent as ShareIcon } from "src/images/share-alt.svg";
import { MultipleSocialProviders } from "../MultipleSocialProviders/MultipleSocialProviders";

function InfluencerInfo({
  socialProvider,
  socialProviders,
  username,
  headerRef,
  influencerName,
}) {
  const history = useHistory();

  const openProfile = () => {
    const pathNameWithProfile = `${window.location.pathname}/profile/${socialProvider}/${username}`;
    const { search } = window.location;

    history.push({
      pathname: pathNameWithProfile,
      search,
    });
  };

  return (
    <div className="influencer-info">
      <div className="influencer-info__username-wrap">
        <div
          className="influencer-info__username"
          onClick={openProfile}
          ref={headerRef}
        >
          <span>{influencerName ?? username}</span>
          <ShareIcon
            className="influencer-info__username-icon"
            width={16}
            height={16}
          />
        </div>
      </div>

      <div className="influencer-info__middle">
        <MultipleSocialProviders
          currentSocialProvider={socialProvider}
          socialProviders={socialProviders}
        />
      </div>
    </div>
  );
}

export default InfluencerInfo;
