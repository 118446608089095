import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  getWorkspaceExtensionList,
  setWorkspaceExtensionList,
} from "src/redux";
import { RootState } from "src/redux/reducers";
import Loader from "src/app/components/Loader/Loader";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ExtensionTypeEnum } from "src/types";
import { SettingsTab } from "../../enums";
import {
  ChangeSettingsSectionParam,
  changeSettingsSection,
  findExtensionByExtensionType,
} from "../../methods";
import ExtensionsList from "../Extensions/components/ExtensionsList/ExtensionsList";
import XTRMPayments from "./XTRMPayments/XTRMPayments";
import CreatorMessenger from "./CreatorMessenger/CreatorMessenger";
import { creatorMessengerInitialData } from "./CreatorMessenger/utils";

export enum IntegrationsSection {
  ExtensionsList = "ExtensionsList",
  XTRMPayments = "XTRMPayments",
  CreatorMessenger = "CreatorMessenger",
}

interface IntegrationsProps {
  onClose: () => void;
  contextData: any;
}

export default function Integrations({
  onClose,
  contextData,
}: IntegrationsProps) {
  const dispatch = useDispatch();
  const {
    mainReducer: { settingsModalData, activeWorkspaceUuid },
    extensionReducer: {
      workspaceExtensionList,
      isWorkspaceExtensionListLoading,
    },
  } = useSelector((state: RootState) => state);

  const history = useHistory();

  const { section: integrationsSection } = settingsModalData;

  const setIntegrationsSection = (section: ChangeSettingsSectionParam) => {
    changeSettingsSection(history, section);
  };

  const renderComponent = () => {
    switch (integrationsSection) {
      case IntegrationsSection.ExtensionsList:
        return (
          <ExtensionsList
            title={
              <IDHFormattedMessage
                id="ws_integrations"
                defaultMessage="Integrations"
              />
            }
            currentSettingsTab={SettingsTab.Integrations}
            onClose={onClose}
            setExtensionsSection={setIntegrationsSection}
            extensionList={workspaceExtensionList}
            permissions={contextData?.permissions}
            wsProjectUuid={null}
            isProjectExtensions={false}
          />
        );
      case IntegrationsSection.XTRMPayments: {
        const xtrmPaymentsExtension = findExtensionByExtensionType(
          workspaceExtensionList,
          ExtensionTypeEnum.XtrmPayments,
        );
        if (
          !xtrmPaymentsExtension ||
          xtrmPaymentsExtension.type !== ExtensionTypeEnum.XtrmPayments
        )
          return null;
        return (
          <XTRMPayments
            onClose={onClose}
            setExtensionsSection={setIntegrationsSection}
            xtrmPaymentsExtension={xtrmPaymentsExtension}
          />
        );
      }
      case IntegrationsSection.CreatorMessenger: {
        const creatorMessengerExtension = findExtensionByExtensionType(
          workspaceExtensionList,
          ExtensionTypeEnum.CreatorMessenger,
        );
        if (
          !creatorMessengerExtension ||
          creatorMessengerExtension.type !== ExtensionTypeEnum.CreatorMessenger
        )
          return null;
        return (
          <CreatorMessenger
            onClose={onClose}
            setExtensionsSection={setIntegrationsSection}
            creatorMessengerExtension={creatorMessengerExtension}
          />
        );
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    const hasCreatorMessengerExtension = workspaceExtensionList.some(
      (extension) => extension.type === ExtensionTypeEnum.CreatorMessenger,
    );

    if (hasCreatorMessengerExtension) return;

    dispatch(
      setWorkspaceExtensionList(
        workspaceExtensionList.concat([creatorMessengerInitialData]),
      ),
    );
  }, [workspaceExtensionList]);

  useEffect(() => {
    if (integrationsSection === IntegrationsSection.ExtensionsList) {
      dispatch(getWorkspaceExtensionList(activeWorkspaceUuid));
    }
  }, [activeWorkspaceUuid, integrationsSection]);

  return (
    <div className="extensions">
      {isWorkspaceExtensionListLoading ? <Loader /> : renderComponent()}
    </div>
  );
}
