import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { ChangeSettingsSectionParam } from "src/app/SettingsModal/methods";
import {
  ExtensionTypeEnum,
  WsExtensionCreatorMessenger,
  WsExtensionCreatorMessengerDetails,
} from "src/types";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import Loader from "src/app/components/Loader/Loader";
import Tab from "src/app/components/Tabs/Tab";
import Tabs from "src/app/components/Tabs/Tabs";
import { API_URLS } from "src/utils/API_URLS";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import responseCodes from "src/utils/responseCodes";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { getWorkspaceExtensionList } from "src/redux";
import { RootState } from "src/redux/reducers";
import { IntegrationsSection } from "../Integrations";
import ExtensionView from "../../Extensions/ExtensionView";
import { getExtensionDetailsByUuid } from "../../Extensions/utils";
import ServerConfigurationTab from "./tabs/ServerConfigurationTab";
import SendingSettingsTab from "./tabs/SendingSettingsTab";
import { creatorMessengerSettings } from "./utils";

import "./CreatorMessenger.scss";

const messengerExtensionDetailsInitialData = {
  ...creatorMessengerSettings,
  wsWorkspaceUuid: "",
  isEnabled: false,
  uuid: "",
  type: ExtensionTypeEnum.CreatorMessenger,
} satisfies WsExtensionCreatorMessengerDetails;

interface CreatorMessengerProps {
  onClose: () => void;
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
  creatorMessengerExtension: WsExtensionCreatorMessenger;
}

enum ActiveTabs {
  ServerConfiguration = "Server configuration",
  SendingSettings = "Sending settings",
}

export default function CreatorMessenger({
  onClose,
  setExtensionsSection,
  creatorMessengerExtension,
}: CreatorMessengerProps) {
  const [activeTab, setActiveTab] = useState<ActiveTabs>(
    ActiveTabs.ServerConfiguration,
  );
  const [messengerExtensionDetails, setMessengerExtensionDetails] =
    useState<WsExtensionCreatorMessengerDetails>(
      messengerExtensionDetailsInitialData,
    );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const getCreatorMessengerExtension = async (uuid: string) => {
    const data = await getExtensionDetailsByUuid<{
      content: WsExtensionCreatorMessengerDetails;
    }>({
      uuid,
      initialCallback: () => setIsLoading(true),
      finallyCallback: () => setIsLoading(false),
    });
    if (data) {
      setMessengerExtensionDetails(data.content);
    }
  };

  const handleChangeSettings = (
    fieldName: keyof typeof creatorMessengerSettings,
    value: string | null,
  ) => {
    setMessengerExtensionDetails((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleUpdateSettings = async (
    fieldName: keyof typeof creatorMessengerSettings,
  ) => {
    if (
      creatorMessengerExtension.settings[fieldName] ===
      messengerExtensionDetails[fieldName]
    ) {
      return;
    }

    const {
      encryption,
      fromEmail,
      fromName,
      host,
      login,
      password,
      port,
      wsGlobalTaskMetaFieldUuidWithEmail,
      dataProcessingInformation,
    } = messengerExtensionDetails;

    const extractedSettings = {
      encryption,
      fromEmail,
      fromName,
      host,
      login,
      password,
      port,
      wsGlobalTaskMetaFieldUuidWithEmail,
      dataProcessingInformation,
    } satisfies WsExtensionCreatorMessenger["settings"];

    try {
      const response = await axios.put(API_URLS.updateMessengerExtension, {
        ...extractedSettings,
        wsExtensionUuid: messengerExtensionDetails.uuid,
      });

      if (response.status !== responseCodes["200_OK"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({
          id: "ws_an_error_ocurred_while_updating_settings",
          defaultMessage: "An error ocurred while updating settings",
        });
      }
      console.error(error);
      getCreatorMessengerExtension(messengerExtensionDetails.uuid);
    }
  };

  const handleUpdateCreatorMessengerStatus = async (enabled: boolean) => {
    setIsLoading(true);

    try {
      const url = API_URLS.updateMessengerExtensionStatus.replace(
        ":wsExtensionUuid:",
        messengerExtensionDetails.uuid,
      );

      const response = await axios.put(url, { enabled });

      if (response.status !== responseCodes["200_OK"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      } else {
        setMessengerExtensionDetails((prevState) => ({
          ...prevState,
          isEnabled: enabled,
        }));
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({
          id: "ws_an_error_ocurred_while_updating_settings",
          defaultMessage: "An error ocurred while updating settings",
        });
      }
      console.error(error);
      getCreatorMessengerExtension(messengerExtensionDetails.uuid);
    } finally {
      setIsLoading(false);
    }
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case ActiveTabs.ServerConfiguration:
        return (
          <ServerConfigurationTab
            messengerExtensionDetails={messengerExtensionDetails}
            handleUpdateSettings={handleUpdateSettings}
            handleChangeSettings={handleChangeSettings}
          />
        );
      case ActiveTabs.SendingSettings:
        return (
          <SendingSettingsTab
            messengerExtensionDetails={messengerExtensionDetails}
            handleUpdateSettings={handleUpdateSettings}
            handleChangeSettings={handleChangeSettings}
            getCreatorMessengerExtension={getCreatorMessengerExtension}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (creatorMessengerExtension.uuid.length === 0) {
      dispatch(getWorkspaceExtensionList(activeWorkspaceUuid));
    } else {
      getCreatorMessengerExtension(creatorMessengerExtension.uuid);
    }
  }, [creatorMessengerExtension, activeWorkspaceUuid]);

  return (
    <ExtensionView
      title={
        <>
          <IDHFormattedMessage
            id="ws_creator_messenger"
            defaultMessage="Creator Messenger"
          />
          <CustomSwitch
            checked={messengerExtensionDetails.isEnabled}
            className="recruitment-form__switch"
            label={
              messengerExtensionDetails.isEnabled ? (
                <IDHFormattedMessage id="ws_active" defaultMessage="Active" />
              ) : (
                <IDHFormattedMessage
                  id="ws_disabled"
                  defaultMessage="Disabled"
                />
              )
            }
            onChange={(e) => {
              handleUpdateCreatorMessengerStatus(e.target.checked);
            }}
          />
        </>
      }
      handleGoBack={() =>
        setExtensionsSection(IntegrationsSection.ExtensionsList)
      }
      goBackButtonLabel={
        <IDHFormattedMessage
          id="ws_integrations"
          defaultMessage="Integrations"
        />
      }
    >
      <div className="creator-messenger">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="creator-messenger__content">
            <Tabs>
              <div className="extensions__tabs">
                <Tab
                  tabText={
                    <IDHFormattedMessage
                      id="ws_server_configuration"
                      defaultMessage="Server configuration"
                    />
                  }
                  onClick={() => setActiveTab(ActiveTabs.ServerConfiguration)}
                  active={activeTab === ActiveTabs.ServerConfiguration}
                />
                <Tab
                  tabText={
                    <IDHFormattedMessage
                      id="ws_sending_settings"
                      defaultMessage="Sending settings"
                    />
                  }
                  onClick={() => setActiveTab(ActiveTabs.SendingSettings)}
                  active={activeTab === ActiveTabs.SendingSettings}
                />
              </div>
            </Tabs>
            {renderActiveTab()}
          </div>
        )}
      </div>
      <SettingsButtons onClose={onClose} />
    </ExtensionView>
  );
}
