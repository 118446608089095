import React from "react";

import { Languages } from "../../../../Task/tabs/ProfileDataTab/single/Languages";

export const renderLanguages = (label, data, layout, GridBoxComponent) => {
  const {
    user_audience_data: { audienceLanguages },
  } = data;

  const height = layout?.find((item) => item.i === label)?.h;

  return (
    <GridBoxComponent key={label} id={label}>
      <Languages audienceLanguages={audienceLanguages} height={height} />
    </GridBoxComponent>
  );
};
