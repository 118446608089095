import { addHeaderToLayout } from "src/app/ReportGenerator/functions/addHeaderToLayout";
import { addSpaceIfNeeded } from "./addSpaceIfNeeded";

export const addCreatorsToLayout = (
  gridElements,
  newLayout,
  format,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.creatorsInTheCampaign;

  Object.keys(campaigns).map((key) => {
    const { project } = campaigns[key];
    const { title } = project;

    if (!aggregation && key !== "aggregated") {
      addHeaderToLayout(`creator-${title}`, newLayout, format, 3);
    }

    gridElements
      .filter(
        (item) => item.gridBoxType === "creator" && item.campaignId === key,
      )
      .forEach((creator) => {
        const lastEl = addSpaceIfNeeded("creator", newLayout, format);

        const x = 0;
        const y = lastEl.y + lastEl.h;
        const w = 12;
        const h = 2;

        const key = `creator-${creator.title
          .replaceAll(" ", "-")
          ?.replaceAll(".", "")
          .toLowerCase()}-${creator.taskId}`;

        newLayout.push({ i: key, x, y, w, h });
      });
  });
};
