import React from "react";
import classNames from "classnames";

import "./CustomRadio.scss";

interface CustomRadioProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
  name?: string;
  label?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  value?: string;
}

function CustomRadio(props: CustomRadioProps) {
  const { id, name, label, checked, onChange, disabled, value, ...rest } =
    props;

  return (
    <div
      className={classNames("ws-radio", {
        "ws-radio--disabled": disabled,
      })}
      {...rest}
    >
      <input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

export default CustomRadio;
