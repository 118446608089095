import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { getSettingsGlobalFields } from "src/redux";
import { RootState } from "src/redux/reducers";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalColumn } from "src/app/components/Modal/Modal";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import {
  TaskType,
  WsExtensionCreatorMessenger,
  WsExtensionCreatorMessengerDetails,
} from "src/types";
import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { API_URLS } from "src/utils/API_URLS";
import responseCodes from "src/utils/responseCodes";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import ExtensionsListItemContent from "../../../Extensions/components/ExtensionsList/ExtensionsListItemContent";

interface MetaFieldOption {
  label: string;
  value: string;
}

const defaultLegalText =
  "Email sent on behalf of [Company name]. If you would like to learn more about how we process your personal data, please review our privacy policy here: [Company name Privacy Policy].";

interface SendingSettingsTabProps {
  messengerExtensionDetails: WsExtensionCreatorMessengerDetails;
  handleUpdateSettings: (
    fieldName: keyof WsExtensionCreatorMessenger["settings"],
  ) => Promise<void>;
  handleChangeSettings: (
    fieldName: keyof WsExtensionCreatorMessenger["settings"],
    value: string | null,
  ) => void;
  getCreatorMessengerExtension: (uuid: string) => Promise<void>;
}

export default function SendingSettingsTab({
  messengerExtensionDetails,
  handleUpdateSettings,
  handleChangeSettings,
  getCreatorMessengerExtension,
}: SendingSettingsTabProps) {
  const {
    mainReducer: { activeWorkspaceUuid },
    settingsReducer: { globalTaskMetaFields },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const emailMetaFieldOptions = globalTaskMetaFields
    .filter(
      (item) =>
        item.taskType === TaskType.Creator && item.type === MetaFieldType.Text,
    )
    .map((item) => ({
      label: item.name,
      value: item.uuid,
    }));

  const chosenEmailMetaFieldOption = emailMetaFieldOptions.find(
    (option) =>
      option.value ===
      messengerExtensionDetails.wsGlobalTaskMetaFieldUuidWithEmail,
  );

  const handleUpdateDataProcessingInformation = async (
    value: string | null,
  ) => {
    const {
      encryption,
      fromEmail,
      fromName,
      host,
      login,
      password,
      port,
      wsGlobalTaskMetaFieldUuidWithEmail,
    } = messengerExtensionDetails;

    const extractedSettings = {
      encryption,
      fromEmail,
      fromName,
      host,
      login,
      password,
      port,
      wsGlobalTaskMetaFieldUuidWithEmail,
      dataProcessingInformation: value,
    } satisfies WsExtensionCreatorMessenger["settings"];

    try {
      const response = await axios.put(API_URLS.updateMessengerExtension, {
        ...extractedSettings,
        wsExtensionUuid: messengerExtensionDetails.uuid,
      });

      if (response.status !== responseCodes["200_OK"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({
          id: "ws_an_error_ocurred_while_updating_settings",
          defaultMessage: "An error ocurred while updating settings",
        });
      }
      console.error(error);
      getCreatorMessengerExtension(messengerExtensionDetails.uuid);
    }
  };

  useEffect(() => {
    dispatch(getSettingsGlobalFields(activeWorkspaceUuid));
  }, [activeWorkspaceUuid]);

  return (
    <>
      <ExtensionsListItemContent
        name={
          <IDHFormattedMessage
            id="ws_email_address_source"
            defaultMessage="Email address source"
          />
        }
        description={
          <IDHFormattedMessage
            id="ws_email_address_source_description"
            defaultMessage="Select fields from the Creator CRM that will be used as the source for email addresses for sending messages."
          />
        }
      />
      <ModalColumn>
        <CustomInputLabel>
          <IDHFormattedMessage
            id="ws_source_field"
            defaultMessage="Source field"
          />
        </CustomInputLabel>

        <CustomSelect
          value={chosenEmailMetaFieldOption}
          options={emailMetaFieldOptions}
          onBlur={() =>
            handleUpdateSettings("wsGlobalTaskMetaFieldUuidWithEmail")
          }
          onChange={(newValue: MetaFieldOption) => {
            handleChangeSettings(
              "wsGlobalTaskMetaFieldUuidWithEmail",
              newValue.value,
            );
          }}
        />
      </ModalColumn>

      <div
        style={{ width: "100%", height: "1px", border: "1px solid #EDEDEF" }}
      />

      <ExtensionsListItemContent
        name={
          <IDHFormattedMessage
            id="ws_data_processing_information"
            defaultMessage="Data processing information"
          />
        }
        description={
          <IDHFormattedMessage
            id="ws_data_processing_information_description"
            defaultMessage="It allows the entry of the company name and a link to the privacy policy for the information that will be included in every sent message."
          />
        }
        controls={
          <CustomSwitch
            checked={
              messengerExtensionDetails.dataProcessingInformation !== null
            }
            onChange={(e) => {
              const { checked } = e.target;
              if (checked) {
                handleUpdateDataProcessingInformation(defaultLegalText);
                handleChangeSettings(
                  "dataProcessingInformation",
                  defaultLegalText,
                );
              } else {
                handleUpdateDataProcessingInformation(null);
                handleChangeSettings("dataProcessingInformation", null);
              }
            }}
          />
        }
      />
      {messengerExtensionDetails.dataProcessingInformation && (
        <ModalColumn className="creator-messenger__legal-text">
          <CustomInputLabel htmlFor="label">
            <IDHFormattedMessage id="ws_label" defaultMessage="Label" />
          </CustomInputLabel>

          <textarea
            className="ws-input creator-messenger__text-area"
            id="label"
            value={messengerExtensionDetails.dataProcessingInformation}
            onChange={(e) => {
              handleChangeSettings("dataProcessingInformation", e.target.value);
            }}
            onBlur={() => handleUpdateSettings("dataProcessingInformation")}
          />
        </ModalColumn>
      )}
    </>
  );
}
