import { TaskType } from "src/types";
import { addHeaderToArray } from "../addHeaderToArray";
import {
  FIELDS_BLACKLIST,
  META_TYPES_WHITELIST,
} from "../../components/ReportTemplate/ReportTemplate";

export const addPostsDataToGridElements = (
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.executiveSummary;

  Object.keys(campaigns).forEach((key) => {
    const campaign = campaigns[key];

    const {
      project: { metadata, projectId, title },
    } = campaign;

    const postsData = metadata
      ?.filter(
        (field) =>
          field.taskType === TaskType.Publication &&
          !FIELDS_BLACKLIST.includes(field.key) &&
          META_TYPES_WHITELIST.includes(field.type),
      )
      .sort((a, b) => a.rank?.localeCompare(b.rank));

    if (key !== "aggregated") {
      addHeaderToArray(
        `Executive Summary - ${title}`,
        !aggregation ? newGridElements : newHiddenGridElements,
        3,
        `post-data-${title}`,
        savedTemplate,
        templateApplied,
        "post-data",
      );
    }

    postsData.forEach((item) => {
      const label = `post-data-${item.name
        .replaceAll(" ", "-")
        ?.replaceAll(".", "")
        .toLowerCase()}-${item.uuid}`;

      const element = {
        ...item,
        label,
        gridBoxType: "post-data",
        section: "postsData",
        campaignId: projectId,
      };

      if (
        (!aggregation && key === "aggregated") ||
        (aggregation && key !== "aggregated")
      ) {
        newHiddenGridElements.push(element);
      } else if (savedTemplate?.length && !templateApplied) {
        if (savedTemplate.find((item) => item.i === element.label)) {
          newGridElements.push(element);
        } else {
          newHiddenGridElements.push(element);
        }
      }
      // applying default template
      else {
        newGridElements.push(element);
      }
    });
  });
};
