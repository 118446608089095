import React from "react";

import { SelectOption } from "src/types";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { Action, SelectActionsForVariant, Variant } from "./types";

export const COLOR_OPTIONS = [
  {
    label: (
      <IDHFormattedMessage id="ws_light_gray" defaultMessage="Light gray" />
    ),
    color: "#8E93AC",
    backgroundColor: "#F2F2F6",
  },
  {
    label: <IDHFormattedMessage id="ws_gray" defaultMessage="Gray" />,
    color: "#74788D",
    backgroundColor: "#E0E2EA",
  },
  {
    label: <IDHFormattedMessage id="ws_brown" defaultMessage="Brown" />,
    color: "#977D71",
    backgroundColor: "#ECE0DB",
  },
  {
    label: <IDHFormattedMessage id="ws_orange" defaultMessage="Orange" />,
    color: "#B4875C",
    backgroundColor: "#FBDFC4",
  },
  {
    label: <IDHFormattedMessage id="ws_yellow" defaultMessage="Yellow" />,
    color: "#D2971A",
    backgroundColor: "#FFEABD",
  },
  {
    label: <IDHFormattedMessage id="ws_green" defaultMessage="Green" />,
    color: "#1D9880",
    backgroundColor: "#DAF3EE",
  },
  {
    label: <IDHFormattedMessage id="ws_blue" defaultMessage="Blue" />,
    color: "#5B86CD",
    backgroundColor: "#E7F0FF",
  },
  {
    label: <IDHFormattedMessage id="ws_purple" defaultMessage="Purple" />,
    color: "#9177DB",
    backgroundColor: "#E6DEFF",
  },
  {
    label: <IDHFormattedMessage id="ws_pink" defaultMessage="Pink" />,
    color: "#EA72C8",
    backgroundColor: "#FFE3F7",
  },
  {
    label: <IDHFormattedMessage id="ws_red" defaultMessage="Red" />,
    color: "#DE4B7C",
    backgroundColor: "#FFD7E4",
  },
];

export const getUnusedColor = (options: SelectOption[]) => {
  const colors = COLOR_OPTIONS.filter(
    (item) =>
      !options.find((option: { color: string }) => {
        return `#${option.color}` === item.color;
      }),
  );

  return colors[0] || COLOR_OPTIONS[options?.length % COLOR_OPTIONS?.length];
};

export const selectActionsForVariant: SelectActionsForVariant = {
  default: ["drag_and_drop", "edit", "add_new_option"],
  disable_all: [],
};

export const shouldDisplayAction = (variant: Variant, action: Action) =>
  selectActionsForVariant[variant].includes(action);
