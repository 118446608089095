import {
  getShowcaseDataUrl,
  getShowcaseSettingByKey,
} from "src/app/CreatorShowcase/utils";
import {
  CLEAR_SELECTED_CREATOR_DATA,
  CLEAR_SHOWCASE_DATA,
  GET_SHOWCASE_DATA,
  SET_CREATORS_FOR_COVER,
  SET_GENERATING,
  SET_GRID_ELEMENTS,
  SET_HIDDEN_GRID_ELEMENTS,
  SET_LAYOUT,
  SET_MULTIPLE_LAYOUTS,
  SET_PRINTING,
  SET_SAVED_TEMPLATE,
  SET_SELECTED_CREATOR_DATA,
  SET_SELECTED_CREATORS,
  SET_SELECTED_TEMPLATE,
  SET_SHOWCASE_ELEMENTS,
  SET_SHOWCASE_TITLE,
  SET_SHOWCASE_TRANSITION,
  SET_STARTING_TEMPLATES,
  SET_SUMMARY_PLACEMENT,
  SET_TEMPLATE_BASE,
  SET_TEXT_SUMMARY,
} from "./showcaseTypes";
import { ShowcaseCreator } from "./showcaseReducer";

export function getShowcaseData() {
  return {
    type: GET_SHOWCASE_DATA,
    payload: {
      request: {
        method: "post",
        url: getShowcaseDataUrl(),
        headers: {
          "Workspace-Uuid": getShowcaseSettingByKey("workspaceId"),
        },
        data: {
          ids: getShowcaseSettingByKey("ids") || [],
        },
      },
    },
  };
}

export function clearShowcaseData() {
  return {
    type: CLEAR_SHOWCASE_DATA,
  };
}

export function clearSelectedCreatorData() {
  return {
    type: CLEAR_SELECTED_CREATOR_DATA,
  };
}

export function setSelectedCreatorData(selectedCreatorData: ShowcaseCreator) {
  return {
    type: SET_SELECTED_CREATOR_DATA,
    payload: { selectedCreatorData },
  };
}

export function setGenerating(generating: boolean) {
  return {
    type: SET_GENERATING,
    payload: { generating },
  };
}

export function setPrinting(printing: boolean) {
  return {
    type: SET_PRINTING,
    payload: { printing },
  };
}

export function setNewLayout(layout: any[]) {
  return {
    type: SET_LAYOUT,
    payload: { layout },
  };
}

export function setNewSavedTemplate(savedTemplate: any[]) {
  return {
    type: SET_SAVED_TEMPLATE,
    payload: { savedTemplate },
  };
}

export function setNewGridElements(gridElements: any[]) {
  return {
    type: SET_GRID_ELEMENTS,
    payload: { gridElements },
  };
}

export function setNewHiddenGridElements(hiddenGridElements: any[]) {
  return {
    type: SET_HIDDEN_GRID_ELEMENTS,
    payload: { hiddenGridElements },
  };
}

export function setSelectedCreators(selectedCreators: any[]) {
  return {
    type: SET_SELECTED_CREATORS,
    payload: { selectedCreators },
  };
}

export function setShowcaseElements(elements: []) {
  return {
    type: SET_SHOWCASE_ELEMENTS,
    payload: { elements },
  };
}

export function setMultipleLayouts(multipleLayouts: any[]) {
  return {
    type: SET_MULTIPLE_LAYOUTS,
    payload: { multipleLayouts },
  };
}

export function setSelectedTemplate(selectedTemplate: any) {
  return {
    type: SET_SELECTED_TEMPLATE,
    payload: { selectedTemplate },
  };
}

export function setStartingTemplates(startingTemplates: any) {
  return {
    type: SET_STARTING_TEMPLATES,
    payload: { startingTemplates },
  };
}

export function setTemplateBase(templateBase: any) {
  return {
    type: SET_TEMPLATE_BASE,
    payload: { templateBase },
  };
}

export function setShowcaseTransition(showcaseTransition: number | null) {
  return {
    type: SET_SHOWCASE_TRANSITION,
    payload: { showcaseTransition },
  };
}

export function setCreatorsForCover(creatorsForCover: any[]) {
  return {
    type: SET_CREATORS_FOR_COVER,
    payload: { creatorsForCover },
  };
}

export function setTextSummary(textSummary: string) {
  return { type: SET_TEXT_SUMMARY, payload: { textSummary } };
}

export function setSummaryPlacement(summaryPlacement: string | null) {
  return { type: SET_SUMMARY_PLACEMENT, payload: { summaryPlacement } };
}

export function setShowcaseTitle(showcaseTitle: string) {
  return { type: SET_SHOWCASE_TITLE, payload: { showcaseTitle } };
}
