import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { uuidv7 as uuid } from "uuidv7";

import {
  deleteSelectedTasks,
  hideShareMultipleColumnsModal,
  hideStopSharingWithClientModal,
  openGroupShareMultipleColumnsModal,
  openStopSharingGroupWithClientModal,
  setSelectedTasks,
} from "src/redux";
import { SelectedTasksBar } from "src/app/components/SelectedTasksBar/SelectedTasksBar";
import AddCreatorsCDTWorkspaceProjectModal, {
  SelectedCreator,
} from "src/app/modals/AddCreatorsCDTWorkspaceProjectModal/AddCreatorsCDTWorkspaceProjectModal";
import { wsAxiosPost } from "src/helpers/wsAxios";
import { API_URLS } from "src/utils/API_URLS";
import { generateRankString } from "src/utils/rankStrings";
import { ifRowMatchesFilters } from "src/app/project/SingleProject/TasksTable/tasksTableSorting/tableSortingFunctions/filterTableData";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as CreatePaymentsIcon } from "src/images/payment.svg";
import { ReactComponent as ShowcaseIcon } from "src/images/showcase.svg";
import { ReactComponent as RubberIcon } from "src/images/rubber.svg";
import { ReactComponent as DeleteIcon } from "src/images/trash-can.svg";
import { ReactComponent as ClientOrangeIcon } from "src/images/client-orange.svg";
import { ReactComponent as ClientStopShareIcon } from "src/images/client-light-grey.svg";
import { ReactComponent as DownloadIcon } from "src/images/download-rounded.svg";
import { TaskType } from "src/types";
import Loader from "src/app/components/Loader/Loader";
import { taskAvatarShape } from "src/app/components/Table/Table";
import StopSharingGroupWithClientModal from "src/app/modals/StopSharingGroupWithClientModal/StopSharingGroupWithClientModal";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { AddToDropdownSelectedTasksBar } from "src/app/components/SelectedTasksBar/components/AddToDropdownSelectedTasksBar";
import { navigateToCreateNewProject } from "src/app/SettingsModal/methods";
import { setCreateNewProjectData } from "src/redux/main/mainActions";
import { ShareMultipleColumnsModal } from "src/app/modals/ShareMultipleColumnsModal/ShareMultipleColumnsModal";
import { RootState } from "src/redux/reducers";
import {
  CreatorShowcaseContext,
  openCreatorShowcase,
  MAX_CREATORS_FOR_GENERATE_SHOWCASE,
} from "src/app/CreatorShowcase/utils";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import {
  ProjectPermissionsEnum,
  WorkspacePermissionsEnum,
} from "src/utils/PermissionsEnums";
import {
  openReportGenerator,
  ReportGeneratorContext,
} from "src/app/ReportGenerator/utils/reportFunctions";

export const selectedCreatorsPayload = (
  selectedCreators: SelectedCreator[],
) => {
  let previousRank = "a";

  return selectedCreators.map((creator: SelectedCreator) => {
    const newRank = generateRankString(previousRank, "");
    previousRank = newRank;

    return {
      uuid: uuid(),
      name: creator.name,
      socialProfiles: creator.socialProfiles,
      rank: newRank,
    };
  });
};

export function SelectedProjectsBar() {
  const {
    taskReducer: {
      showShareGroupMultipleColumnsModal,
      showStopSharingGroupWithClientModal,
    },
    mainReducer: { activeWorkspaceUuid, identity },
    projectReducer: { taskType, selectedProjects },
    taskFiltersReducer: { selectedFilters },
  } = useSelector((state: RootState) => state);

  const [isTooltipOpen, setIsTooltipOpen] = useState(
    window.localStorage.getItem("selectedTasksBarTooltipHidden") === null
      ? true
      : !JSON.parse(
          window.localStorage.getItem(
            "selectedTasksBarTooltipHidden",
          ) as string,
        ),
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const clearSelected = () => {
    dispatch(setSelectedTasks([]));
  };

  const hideTooltip = () => {
    localStorage.setItem("selectedTasksBarTooltipHidden", JSON.stringify(true));
    setIsTooltipOpen(false);
  };

  const openReport = () => {
    if (taskType !== "creator") {
      return;
    }

    const ids = selectedProjects.map((el: any) => el.original.projectId);

    openReportGenerator({
      history,
      context: ReportGeneratorContext.CampaignList,
      workspaceId: activeWorkspaceUuid,
      ids,
    });
  };

  useEffect(() => {
    if (selectedFilters?.selections?.length) {
      const filteredTasks = selectedProjects.filter((task: any) =>
        ifRowMatchesFilters(task, selectedFilters, identity.id),
      );
      dispatch(setSelectedTasks(filteredTasks));
    }
  }, [selectedFilters]);

  return (
    <SelectedTasksBar
      selectedTasksLength={selectedProjects.length}
      isTooltipOpen={isTooltipOpen}
      hideTooltip={hideTooltip}
    >
      <>
        <TooltipPortal
          content={
            <IDHFormattedMessage
              id="ws_cannot_generate_showcase"
              defaultMessage="Cannot generate showcase for more than 100 creators"
            />
          }
          contentHidden={
            selectedProjects.length <= MAX_CREATORS_FOR_GENERATE_SHOWCASE
          }
          up
        >
          <Button
            size="large"
            variant="white"
            onClick={openReport}
            data-action="generateReport"
          >
            <ShowcaseIcon />
            <IDHFormattedMessage
              id="ws_generate_report"
              defaultMessage="Generate Report"
            />
          </Button>
        </TooltipPortal>

        <Button
          size="large"
          variant="white"
          onClick={clearSelected}
          disabled={!selectedProjects.length}
          data-action="clearSelected"
        >
          <RubberIcon />
          <IDHFormattedMessage
            id="ws_clear_selected"
            defaultMessage="Clear selected"
          />
        </Button>
        {/* Confirmation and Sharing Modals */}

        {showShareGroupMultipleColumnsModal && (
          <ShareMultipleColumnsModal
            onClose={() => dispatch(hideShareMultipleColumnsModal())}
            taskAvatarShape={taskAvatarShape.Square}
            tasks={selectedProjects}
            taskType={taskType}
            shouldSelectTasksAfterSharing
          />
        )}
        {showStopSharingGroupWithClientModal && (
          <StopSharingGroupWithClientModal
            onClose={() => dispatch(hideStopSharingWithClientModal())}
            taskAvatarShape={taskAvatarShape.Square}
            tasks={selectedProjects}
            taskType={taskType}
          />
        )}
      </>
    </SelectedTasksBar>
  );
}
