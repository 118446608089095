import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SocialProvider } from "src/types";
import classNames from "classnames";
import { SectionHeader } from "../components/SectionHeader";
import { Followers } from "../single/Followers";
import { Er } from "../single/Er";
import { Credibility } from "../single/Credibility";
import { InfluencerGrowth } from "../single/InfluencerGrowth";
import { EngagementRate } from "../single/EngagementRate";
import { canDisplayChart, isEmpty } from "../../../../../utils/methods";
import { AvgViews } from "../single/AvgViews";
import { SimilarProfiles } from "../single/SimilarProfiles";

export function ProfileData(props) {
  const {
    profileData: {
      similarProfiles,
      user_audience_data: {
        notable,
        credibility,
        paidPostPerformance,
        credibilityAudienceTypes,
        credibilityAudienceReachability,
      },
      user_data: {
        followers,
        avgEngagements,
        avgLikes,
        er,
        avgViews,
        avgComments,
        socialProvider,
        avgReelsPlays,
      },
      userApiData: {
        avgImpressionsPerPost,
        avgImpressionsPerStory,
        avgRealReachPerPost,
        avgRealReachPerStory,
        avgRealReachPerPostToFollowers,
        avgRealReachPerStoryToFollowers,
      },
      followersStatisticsData,
      erStatisticsData,
      viewsStatisticsData,
    },
  } = props;

  const isSnapchat = socialProvider === SocialProvider.Snapchat;
  const isFacebook = socialProvider === SocialProvider.Facebook;

  return (
    <div className="profile-data-tab__section-wrapper">
      <SectionHeader
        text={
          <IDHFormattedMessage
            id="ws_profile_data"
            defaultMessage="Profile Data"
          />
        }
      />

      <div
        className={classNames({
          "profile-data-tab__section-two-boxes": similarProfiles.length,
        })}
      >
        <div
          className={classNames({
            "profile-data-tab__section-two-boxes":
              !isSnapchat && !similarProfiles.length,
            "profile-data-tab__section-column":
              isSnapchat || similarProfiles.length,
          })}
        >
          <Followers
            block={!isSnapchat}
            followers={followers}
            notableUsers={notable}
            paidPostPerformance={paidPostPerformance}
          />
          {/* TODO: create separate <Er /> or <ProfileData /> components for each social provider to avoid multiple if statements inside */}
          {!isSnapchat && !isFacebook && (
            <Er
              socialProvider={socialProvider}
              er={er}
              avgEngagements={avgEngagements}
              avgLikes={avgLikes}
              avgViews={avgViews}
              avgComments={avgComments}
              avgImpressionsPerPost={avgImpressionsPerPost}
              avgImpressionsPerStory={avgImpressionsPerStory}
              avgRealReachPerPost={avgRealReachPerPost}
              avgRealReachPerStory={avgRealReachPerStory}
              avgRealReachPerPostToFollowers={avgRealReachPerPostToFollowers}
              avgRealReachPerStoryToFollowers={avgRealReachPerStoryToFollowers}
              avgReelsPlays={avgReelsPlays}
              adaptiveHeight={Boolean(similarProfiles.length)}
            />
          )}
        </div>
        {similarProfiles.length ? (
          <SimilarProfiles similarProfiles={similarProfiles} />
        ) : null}
      </div>
      {credibility && (
        <Credibility
          credibility={credibility}
          credibilityAudienceTypes={credibilityAudienceTypes}
          credibilityAudienceReachability={credibilityAudienceReachability}
        />
      )}
      {canDisplayChart(followersStatisticsData) && (
        <InfluencerGrowth
          followers={followers}
          followersStatisticsData={followersStatisticsData}
        />
      )}
      {!isEmpty(erStatisticsData) && !isSnapchat && !isFacebook && (
        <EngagementRate er={er} erStatisticsData={erStatisticsData} />
      )}
      {!isEmpty(viewsStatisticsData) &&
        socialProvider !== SocialProvider.Instagram && (
          <AvgViews
            avgViews={avgViews}
            viewsStatisticsData={viewsStatisticsData}
          />
        )}
    </div>
  );
}
