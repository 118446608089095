import {
  SET_PROJECT_SORT,
  SET_PROJECT_SORT_BY,
  SET_PROJECT_FILTERS,
  SET_PROJECT_FILTER,
  TOGGLE_PROJECTS_LIST_COLUMN_VISIBILITY,
  SET_PROJECT_COLUMN_RANK,
  SET_PROJECT_INITIAL_COLUMN_RANKS,
  SET_PROJECT_INITIAL_HIDDEN_COLUMNS,
  SET_SELECTED_TABLE_VIEW,
} from "./projectFiltersTypes";

export function setProjectSortBy(sortBy) {
  return {
    type: SET_PROJECT_SORT_BY,
    payload: {
      sortBy,
    },
  };
}

export function setProjectSort(sort) {
  return {
    type: SET_PROJECT_SORT,
    payload: {
      sort,
    },
  };
}

export function setProjectFilters(filters) {
  return {
    type: SET_PROJECT_FILTERS,
    payload: {
      filters,
    },
  };
}

export function setProjectFilter(fieldUuid, value) {
  return {
    type: SET_PROJECT_FILTER,
    payload: {
      fieldUuid,
      value,
    },
  };
}

export function setProjectInitialHiddenColumns(hiddenColumns) {
  return {
    type: SET_PROJECT_INITIAL_HIDDEN_COLUMNS,
    payload: { hiddenColumns },
  };
}

export function toggleProjectsListColumnVisibility(uuid) {
  return {
    type: TOGGLE_PROJECTS_LIST_COLUMN_VISIBILITY,
    payload: {
      uuid,
    },
  };
}

export function setProjectColumnRank(uuid, rank) {
  return {
    type: SET_PROJECT_COLUMN_RANK,
    payload: { uuid, rank },
  };
}

export function setProjectInitialColumnRanks(ranks, saveConfig = false) {
  return {
    type: SET_PROJECT_INITIAL_COLUMN_RANKS,
    payload: { ranks, saveConfig },
  };
}

export function setSelectedTableView(selectedTableView) {
  return {
    type: SET_SELECTED_TABLE_VIEW,
    payload: { selectedTableView },
  };
}
