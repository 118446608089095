import { TaskType, SelectOption } from "src/types";
import { EmvValues } from "src/app/modals/ManageFieldModal/types";
import { DateObject } from "../../app/project/Project";
import { API_URLS } from "../../utils/API_URLS";
import {
  DELETE_TASK,
  UPDATE_BOARD_TASK_RANK,
  UPDATE_LIST_TASK_RANK,
} from "../project/projectTypes";
import {
  CREATE_SUBTASK,
  CREATE_EMPTY_SUBTASK,
  DELETE_EMPTY_SUBTASK,
  GET_TASK_DETAILS,
  CHANGE_SUBTASK_NAME,
  UPDATE_SUBTASK_RANK,
  GET_MY_TASKS,
  CLEAR_TASK_DETAILS,
  UPDATE_TASK_META_FIELD,
  ADD_TASK_META_FIELD,
  UPDATE_TASK_META_FIELD_RANK,
  UPDATE_TASK_SINGLE_SELECT_FIELD,
  CHANGE_TASK_FIELD_VALUE,
  UPDATE_TASK_META_FIELD_VISIBILITY,
  SET_TASKS_LOADING,
  DELETE_NEW_SUBTASK_WITHOUT_TITLE,
  OPEN_ADD_TASK_MODAL,
  CLOSE_ADD_TASK_MODAL,
  SET_GALLERY_SOURCES,
  OPEN_ADD_CREATORS_MODAL,
  CLOSE_ADD_CREATORS_MODAL,
  OPEN_ADD_PUBLICATION_MODAL,
  CLOSE_ADD_PUBLICATION_MODAL,
  GET_SINGLE_TASK_FOR_LIST,
  SET_TASKS_LIST,
  OPEN_STOP_SHARING_WITH_CLIENT_MODAL,
  OPEN_STOP_SHARING_GROUP_WITH_CLIENT_MODAL,
  HIDE_STOP_SHARING_WITH_CLIENT_MODAL,
  OPEN_SHARE_MULTIPLE_COLUMNS_MODAL,
  OPEN_SHARE_GROUP_MULTIPLE_COLUMNS_MODAL,
  HIDE_SHARE_MULTIPLE_COLUMNS_MODAL,
  CLEAR_TASK_LIST,
  CLEAR_MY_TASKS_LIST,
  GET_SINGLE_TASK_FOR_MY_TASKS_LIST,
  SET_SELECTED_TASKS,
  SET_TASK_FIELD_ADDED_OR_UPDATED,
  CREATE_GLOBAL_TASK_META_FIELD,
  LOCAL_SET_COLUMNS_SHARING,
  LOCAL_SET_COLUMNS_GROUP_SHARING,
  LOCAL_SET_TASKS_SHARING,
  CLEAR_TASK_ERROR_MESSAGES,
  SET_TABLE_INPUT_FOCUSED,
  SET_LAST_SELECTED_ROW_ID,
  DELETE_SELECTED_TASKS,
  PUBLICATION_SYNCHRONIZATION_IN_PROGRESS,
  PUBLICATION_SYNCHRONIZATION_DONE,
} from "./taskTypes";
import { ITask, TasksColumn } from "./taskReducer";

export function getTaskDetails(taskId: string) {
  return {
    type: GET_TASK_DETAILS,
    payload: {
      request: {
        url: API_URLS.getTaskDetails.replace(":taskId:", taskId),
      },
    },
  };
}

export function updatePublicationSynchronizationStatusIcon(
  isInProgress: boolean,
) {
  if (isInProgress) {
    return {
      type: PUBLICATION_SYNCHRONIZATION_IN_PROGRESS,
    };
  }

  return {
    type: PUBLICATION_SYNCHRONIZATION_DONE,
  };
}

export function clearTaskDetails() {
  return {
    type: CLEAR_TASK_DETAILS,
  };
}

export function createSubtask(
  projectId: string,
  taskName: string,
  parentTaskId: string,
  taskId: string,
  taskRank: string,
  activeWorkspaceUuid: string,
) {
  const data = {
    projectId,
    taskName,
    parentTaskId,
    taskId,
    taskRank,
    wsWorkspaceUuid: activeWorkspaceUuid,
  };

  return {
    type: CREATE_SUBTASK,
    payload: {
      request: {
        method: "post",
        url: API_URLS.createTask,
        data,
      },
      data,
    },
  };
}

export function createEmptySubtask() {
  return {
    type: CREATE_EMPTY_SUBTASK,
  };
}

export function deleteEmptySubtask(taskId: string) {
  return {
    type: DELETE_EMPTY_SUBTASK,
    payload: {
      request: {
        method: "delete",
        url: API_URLS.deleteTask.replace(":taskUuid:", taskId),
      },
    },
  };
}

export function deleteNewSubtaskWithoutTitle(taskId: string) {
  return {
    type: DELETE_NEW_SUBTASK_WITHOUT_TITLE,
  };
}

export function changeSubtaskName(
  newValue: string,
  taskId: string,
  uuid: string,
) {
  const data = {
    taskId,
    metaFieldId: uuid,
    metaValue: newValue,
  };
  return {
    type: CHANGE_SUBTASK_NAME,
    payload: {
      request: {
        method: "put",
        url: API_URLS.changeTaskMetaFieldValue,
        data,
      },
      data,
    },
  };
}

export function updateSubtaskRank(subtaskId: string, subtaskRank: string) {
  return {
    type: UPDATE_SUBTASK_RANK,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateTaskRank,
        data: {
          taskId: subtaskId,
          taskRank: subtaskRank,
        },
      },
      taskId: subtaskId,
      taskRank: subtaskRank,
    },
  };
}

export function getMyTasks(userId: string) {
  return {
    type: GET_MY_TASKS,
    payload: {
      request: {
        method: "get",
        url: API_URLS.getMyTasks.replace(":userId:", userId),
      },
    },
  };
}

export function getSingleTaskForMyTaskList(wsTaskUuid: string) {
  return {
    type: GET_SINGLE_TASK_FOR_MY_TASKS_LIST,
    payload: {
      request: {
        url: API_URLS.getSingleTaskForMyTaskList.replace(
          ":wsTaskUuid:",
          wsTaskUuid,
        ),
      },
    },
  };
}

// new

interface CreateTaskMetaField {
  projectId: string;
  metaFieldName: string;
  metaFieldType: string | null;
  uuid: string;
  rank: string;
  taskType: string;
  metaFieldValueSource?: string | null;
  currencyCode?: string | null;
  iconName?: string | null;
  iconColor?: string | null;
  accessLinkData?: any;
  arithmeticFieldOptions?: any;
  wsDictionaryUuid?: string;
  wsTeamUuids?: string[] | null;
  wsDictionarySubFieldUuid?: string | null;
  relatedMetaFieldUuid?: string;
  relatedMetaFieldAggregationType?: string | null;
  emvValues: EmvValues | null;
}

export function createTaskMetaField({
  projectId,
  metaFieldName,
  metaFieldType,
  uuid,
  rank,
  taskType,
  metaFieldValueSource,
  currencyCode,
  iconName,
  iconColor,
  accessLinkData,
  arithmeticFieldOptions,
  wsDictionaryUuid,
  wsTeamUuids,
  wsDictionarySubFieldUuid,
  relatedMetaFieldUuid,
  relatedMetaFieldAggregationType,
  emvValues,
}: CreateTaskMetaField) {
  return {
    type: ADD_TASK_META_FIELD,
    payload: {
      request: {
        method: "post",
        url: API_URLS.createTaskMetaField,
        data: {
          projectId,
          metaFieldName,
          metaFieldType,
          metaFieldId: uuid,
          metaFieldRank: rank,
          taskType,
          ...(metaFieldValueSource && { metaFieldValueSource }),
          currencyCode,
          iconName,
          iconColor,
          wsMetaFieldsToCreateAccess: accessLinkData,
          arithmeticFieldOptions,
          wsDictionaryUuid,
          wsTeamUuids,
          wsDictionarySubFieldUuid,
          relatedMetaFieldUuid,
          relatedMetaFieldAggregationType,
          emvValues,
        },
      },
    },
  };
}

interface UpdateTaskMetaField {
  metaFieldId: string;
  metaFieldNameUpdated: string;
  currencyCode?: string | null;
  metaFieldValueSource?: string;
  wsMetaFieldsToCreateAccess?: any;
  arithmeticFieldOptions?: any;
  wsTeamUuids?: string[] | null;
  wsDictionarySubFieldUuid?: string | null;
  relatedMetaFieldUuid?: string;
  relatedMetaFieldAggregationType?: string | null;
  emvValues: EmvValues | null;
}

export function updateTaskMetaField({
  metaFieldId,
  metaFieldNameUpdated,
  currencyCode,
  metaFieldValueSource,
  wsMetaFieldsToCreateAccess,
  arithmeticFieldOptions,
  wsTeamUuids,
  wsDictionarySubFieldUuid,
  relatedMetaFieldUuid,
  relatedMetaFieldAggregationType,
  emvValues,
}: UpdateTaskMetaField) {
  return {
    type: UPDATE_TASK_META_FIELD,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateTaskMetaField,
        data: {
          metaFieldId,
          metaFieldNameUpdated,
          currencyCode,
          ...(metaFieldValueSource && { metaFieldValueSource }),
          wsMetaFieldsToCreateAccess,
          arithmeticFieldOptions,
          wsTeamUuids,
          wsDictionarySubFieldUuid,
          relatedMetaFieldUuid,
          relatedMetaFieldAggregationType,
          emvValues,
        },
      },
      id: metaFieldId,
      title: metaFieldNameUpdated,
    },
  };
}

export function updateTaskMetaFieldRank(
  metaFieldId: string,
  metaFieldRank: string,
) {
  return {
    type: UPDATE_TASK_META_FIELD_RANK,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateTaskMetaFieldRank,
        data: {
          metaFieldId,
          metaFieldRank,
        },
      },
      metaFieldId,
      metaFieldRank,
    },
  };
}

export function updateTaskSingleSelectField(
  metaFieldId: string,
  metaFieldNameUpdated: string,
  singleSelectOptions: SelectOption[],
) {
  return {
    type: UPDATE_TASK_SINGLE_SELECT_FIELD,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateTaskSingleSelectMetaField,
        data: {
          metaFieldId,
          metaFieldNameUpdated,
          singleSelectOptions,
        },
      },
      metaFieldId,
      metaFieldNameUpdated,
      singleSelectOptions: {
        singleSelectOptions,
      },
    },
  };
}

export function updateBoardTaskRank(taskId: string, taskRank: string) {
  return {
    type: UPDATE_BOARD_TASK_RANK,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateTaskRank,
        data: {
          taskUuid: taskId,
          taskRank,
        },
      },
      taskId,
      taskRank,
    },
  };
}

export function updateListTaskRank(taskId: string, taskRank: string) {
  return {
    type: UPDATE_LIST_TASK_RANK,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateTaskRank,
        data: {
          taskUuid: taskId,
          taskRank,
        },
      },
      taskId,
      taskRank,
    },
  };
}

export function changeTaskMetaFieldValue(
  ids: string | string[],
  fieldId: string,
  newValue: string | boolean | DateObject | null,
) {
  const uuids = typeof ids === "string" ? [ids] : ids;

  return {
    type: CHANGE_TASK_FIELD_VALUE,
    payload: {
      request: {
        method: "put",
        url: API_URLS.changeTaskMetaFieldValue,
        data: {
          uuids,
          metaFieldUuid: fieldId,
          metaValue: newValue,
        },
      },
      uuids,
      fieldId,
      newValue,
    },
  };
}

export function changeFieldVisibility(id: string, isOn: boolean) {
  return {
    type: UPDATE_TASK_META_FIELD_VISIBILITY,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateTaskMetaFieldVisibility,
        data: {
          metaFieldId: id,
          visible: isOn,
        },
      },
      metaFieldId: id,
      visible: isOn,
    },
  };
}

export function setTasksList(
  tasks: any[],
  tasksColumns: TasksColumn[],
  titleMetaFieldId: any,
) {
  return {
    type: SET_TASKS_LIST,
    payload: { tasks, tasksColumns, titleMetaFieldId },
  };
}

export function setSelectedTasks(selectedTasks: any[]) {
  return {
    type: SET_SELECTED_TASKS,
    payload: { selectedTasks },
  };
}

export function deleteTask(taskId: string) {
  return {
    type: DELETE_TASK,
    payload: {
      request: {
        method: "delete",
        url: API_URLS.deleteTask.replace(":taskUuid:", taskId),
      },
      taskId,
    },
  };
}

export function setTasksLoading(tasksLoading: boolean) {
  return {
    type: SET_TASKS_LOADING,
    payload: {
      tasksLoading,
    },
  };
}

export function openAddTaskModal() {
  return {
    type: OPEN_ADD_TASK_MODAL,
  };
}

export function closeAddTaskModal() {
  return {
    type: CLOSE_ADD_TASK_MODAL,
  };
}

export function openAddCreatorsModal() {
  return {
    type: OPEN_ADD_CREATORS_MODAL,
  };
}

export function closeAddCreatorsModal() {
  return {
    type: CLOSE_ADD_CREATORS_MODAL,
  };
}

export function openAddPublicationModal() {
  return {
    type: OPEN_ADD_PUBLICATION_MODAL,
  };
}

export function closeAddPublicationModal() {
  return {
    type: CLOSE_ADD_PUBLICATION_MODAL,
  };
}

export function setGallerySources(gallerySources: any[]) {
  return {
    type: SET_GALLERY_SOURCES,
    payload: {
      gallerySources,
    },
  };
}

export function getSingleTaskForList(wsTaskUuid: string) {
  return {
    type: GET_SINGLE_TASK_FOR_LIST,
    payload: {
      request: {
        url: API_URLS.getSingleTaskForList.replace(":wsTaskUuid:", wsTaskUuid),
      },
    },
  };
}

export function openStopSharingWithClientModal(taskId: string) {
  return {
    type: OPEN_STOP_SHARING_WITH_CLIENT_MODAL,
    payload: {
      taskId,
    },
  };
}

export function openStopSharingGroupWithClientModal(taskIds: any[]) {
  return {
    type: OPEN_STOP_SHARING_GROUP_WITH_CLIENT_MODAL,
    payload: {
      taskIds,
    },
  };
}

export function hideStopSharingWithClientModal() {
  return {
    type: HIDE_STOP_SHARING_WITH_CLIENT_MODAL,
  };
}

export function openShareMultipleColumnsModal(taskId: string) {
  return {
    type: OPEN_SHARE_MULTIPLE_COLUMNS_MODAL,
    payload: {
      taskId,
    },
  };
}

export function openGroupShareMultipleColumnsModal(taskIds: any[]) {
  return {
    type: OPEN_SHARE_GROUP_MULTIPLE_COLUMNS_MODAL,
    payload: {
      taskIds,
    },
  };
}

export function hideShareMultipleColumnsModal() {
  return {
    type: HIDE_SHARE_MULTIPLE_COLUMNS_MODAL,
  };
}

export function clearMyTasksList() {
  return {
    type: CLEAR_MY_TASKS_LIST,
  };
}

export function clearTaskList() {
  return {
    type: CLEAR_TASK_LIST,
  };
}

export function setTaskFieldAddedOrUpdated(taskFieldAddedOrUpdated: boolean) {
  return {
    type: SET_TASK_FIELD_ADDED_OR_UPDATED,
    payload: { taskFieldAddedOrUpdated },
  };
}

export function deleteSelectedTasks(selectedTasks: ITask[]) {
  return {
    type: DELETE_SELECTED_TASKS,
    payload: {
      request: {
        method: "post",
        url: API_URLS.deleteSelectedTasks,
        data: {
          wsTasksData: selectedTasks,
        },
      },
      selectedTasks,
    },
  };
}

interface CreateGlobalTaskMetaField {
  wsGlobalTaskMetaFieldUuid: string;
  wsProjectUuid: string;
  wsTaskMetaFieldRank: string;
  wsTaskMetaFieldUuid: string;
  taskType: TaskType | null;
  wsTeamUuids?: string[] | null;
}

export function createGlobalTaskMetaField(
  wsGlobalTaskMetaField: CreateGlobalTaskMetaField[],
) {
  return {
    type: CREATE_GLOBAL_TASK_META_FIELD,
    payload: {
      request: {
        method: "post",
        url: API_URLS.createGlobalTaskMetaField,
        data: {
          wsGlobalTaskMetaField,
        },
      },
    },
  };
}

export function localSetColumnsSharing(columnIds: string[], value: boolean) {
  return {
    type: LOCAL_SET_COLUMNS_SHARING,
    payload: { columnIds, value },
  };
}

export function localSetColumnsGroupSharing(columnIds: string[]) {
  return {
    type: LOCAL_SET_COLUMNS_GROUP_SHARING,
    payload: { columnIds },
  };
}

export function localSetTasksSharing(taskIds: string[], shared: boolean) {
  return {
    type: LOCAL_SET_TASKS_SHARING,
    payload: { taskIds, shared },
  };
}

export function clearTaskErrorMessages() {
  return {
    type: CLEAR_TASK_ERROR_MESSAGES,
  };
}

export function setTableInputFocused(tableInputFocused: boolean) {
  return {
    type: SET_TABLE_INPUT_FOCUSED,
    payload: { tableInputFocused },
  };
}

export function setLastSelectedRowID(lastSelectedRowID: string) {
  return {
    type: SET_LAST_SELECTED_ROW_ID,
    payload: { lastSelectedRowID },
  };
}
