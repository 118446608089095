import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./ShareProjectOrTaskModal.scss";

import classNames from "classnames";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { IntlShape, WrappedComponentProps, injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { showErrorToasts } from "src/utils/methods";
import { RootState } from "src/redux/reducers";
import { MemberOption } from "src/app/dropdowns/ChoosePersonDropdown/ChoosePersonDropdown";
import Modal, { ModalRow, ModalTitle } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import AvatarPlaceholder from "../../../images/avatar-purple.svg";
import TrashCanIcon from "../../../images/trash-can.svg";
import { IMember } from "../../project/ProjectTypes";
import { showToast } from "../../methods/showToast";
import { setShowTask } from "../../../redux";

interface Props extends WrappedComponentProps<"intl"> {
  onClose: () => void;
  data: any;
  addMemberUrl: string;
  removeMemberUrl: string;
  variableName: string;
  updateFunction: any;
  tab?: string;
  intl: IntlShape;
}

function ShareProjectOrTaskModal(props: Props) {
  const {
    onClose,
    data,
    updateFunction,
    variableName,
    addMemberUrl,
    removeMemberUrl,
    tab,
    intl,
  } = props;

  const {
    mainReducer: { identity, activeWorkspaceUuid },
    projectReducer: { membersList },
  } = useSelector((state: RootState) => state);

  const [activeTab, setActiveTab] = useState<string>(tab || "share");
  const [memberOptions, setMemberOptions] = useState<MemberOption[]>([]);
  const [members, setMembers] = useState<any[]>([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const projectId = data?.projectId || data?.taskId;
  const objectName = data?.projectName || data?.title;

  useEffect(() => {
    if (membersList && data?.members) {
      const list = membersList.filter((option) => {
        if (!data.members?.find((member: IMember) => option.id === member.id)) {
          return option;
        }
      });

      const options = list.map((member) => ({
        label: (
          <div className="member-option">
            <img
              className="member-option__photo"
              src={member.avatarUrl || AvatarPlaceholder}
              alt="avatar"
            />
            <div>
              <span className="member-option__name">{member.name}</span>
              {/* <span className="member-option__dot">・</span> */}
              {/* <span className="member-option__email">{member.email}</span> */}
            </div>
          </div>
        ),
        value: { email: member.email, name: member.name, id: member.id },
      }));

      setMemberOptions(options);
    }
  }, [membersList, data?.members]);

  useEffect(() => {
    setMembers([]);
  }, [activeTab]);

  const addMember = (e: React.FormEvent) => {
    e.preventDefault();

    const wsMembersUuids: string[] = [];
    const wsMembersInviteEmails: string[] = [];

    let breakFunction = false;

    members.forEach((member: any) => {
      if (member.value.id) {
        wsMembersUuids.push(member.value.id);
      } else if (member.value.includes("@")) {
        wsMembersInviteEmails.push(member.value);
      } else {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_invalid_member_email"
            defaultMessage="Invalid member e-mail!"
          />,
        );
        breakFunction = true;
      }
    });

    if (breakFunction) {
      return;
    }

    axios
      .post(addMemberUrl, {
        wsMembersUuids,
        wsMembersInviteEmails,
        [variableName]: projectId,
      })
      .then(() => {
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id={`ws_member${
              wsMembersUuids.length > 1 ? "s_were" : "_was"
            }_invited_successfully`}
            defaultMessage={`Member${
              wsMembersUuids.length > 1 ? "s were" : " was"
            } invited successfully.`}
          />,
        );

        updateFunction();
        setMembers([]);
      })
      .catch((error) => {
        const errorMessages = error.response.data.errors;
        showErrorToasts(errorMessages);
      });
  };

  const removeMember = (uuid: string) => {
    axios
      .post(removeMemberUrl, {
        wsMemberUuid: uuid,
        [variableName]: projectId,
      })
      .then(() => {
        if (uuid === identity.id) {
          dispatch(setShowTask(false));
          history.push(`/workspace/${activeWorkspaceUuid}/my-actions`);
          showToast(
            "success",
            <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
            <>
              <IDHFormattedMessage
                id="ws_you_have_been_successfully_removed_from"
                defaultMessage="You have been successfully removed from"
              />
              &nbsp;
              {objectName}.
            </>,
          );
        } else {
          updateFunction();
          showToast(
            "success",
            <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
            <IDHFormattedMessage
              id="ws_member_was_removed_successfully"
              defaultMessage="Member was removed successfully."
            />,
          );
        }
      })
      .catch((error) => {
        // TODO: Kacper obsłużyć błędy tak jak rozmawialiśmy: https://indahash.slack.com/archives/G4R8J0ZFU/p1660040576727539
        console.error(error);
      });
  };

  const filterOption = (candidate: any, input: any) => {
    return (
      candidate.data.__isNew__ ||
      candidate.value.name.toUpperCase().includes(input.toUpperCase())
    );
  };

  return (
    <Modal
      className="share-project-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={activeTab === "share" && addMember}
      confirmButtonDisabled={!members}
    >
      <ModalTitle>
        <IDHFormattedMessage id="ws_share" defaultMessage="Share" />
        &nbsp;“{objectName}”
      </ModalTitle>
      <div className="share-project-modal__bar">
        <span
          className={classNames("share-project-modal__bar-button", {
            "share-project-modal__bar-button--active": activeTab === "share",
          })}
          onClick={() => setActiveTab("share")}
        >
          <IDHFormattedMessage id="ws_share" defaultMessage="Share" />
        </span>
        <span
          className={classNames("share-project-modal__bar-button", {
            "share-project-modal__bar-button--active": activeTab === "members",
          })}
          onClick={() => setActiveTab("members")}
        >
          <IDHFormattedMessage id="ws_members" defaultMessage="Members" />
        </span>
      </div>

      <form onSubmit={addMember}>
        {activeTab === "share" ? (
          <ModalRow>
            <CustomSelect
              creatable
              value={members}
              isSearchable
              isMulti
              withTiles
              onChange={setMembers}
              options={memberOptions}
              placeholder={`${translateMessage({
                intl,
                id: "add_campaign_members_by_name_or_email",
                defaultMessage: "Add campaign members by name or email",
              })}...`}
              // classNamePrefix="select"
              filterOption={filterOption}
              formatCreateLabel={(inputText: string) =>
                `${translateMessage({
                  intl,
                  id: "ws_invite_by_email",
                  defaultMessage: "Invite by email",
                })} ${inputText}`
              }
            />
          </ModalRow>
        ) : activeTab === "members" ? (
          <div className="share-project-modal__members">
            {data?.members?.map((member: any) => (
              <div className="share-project-modal__members-row" key={member.id}>
                <div className="share-project-modal__members-row-left">
                  <img
                    className="share-project-modal__members-row-avatar"
                    src={member.avatarUrl || AvatarPlaceholder}
                    alt="avatar"
                  />
                  <div className="share-project-modal__members-row-info">
                    <span className="share-project-modal__members-row-name">
                      {member.name}
                    </span>
                    <span className="share-project-modal__members-row-email">
                      {member.email}
                    </span>
                  </div>
                </div>
                <div className="share-project-modal__members-row-right">
                  <img
                    onClick={() => {
                      if (membersList.length >= 1) {
                        removeMember(member.id);
                      }
                    }}
                    className="share-project-modal__members-row-trashcan"
                    src={TrashCanIcon}
                    alt="trash-can"
                  />
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </form>
    </Modal>
  );
}

export default injectIntl(ShareProjectOrTaskModal);
