import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLastSelectedProjectRowID, setSelectedProjects } from "src/redux";

export function ProjectIndeterminateCheckbox({ data }) {
  const { row, rows } = data;

  const ref = useRef(null);

  const dispatch = useDispatch();

  const [shouldShowCheckbox, setShouldShowCheckbox] = useState(false);

  const lastSelectedProjectRowID = useSelector(
    (state) => state.projectReducer.lastSelectedProjectRowID,
  );
  const selectedProjects = useSelector(
    (state) => state.projectReducer.selectedProjects,
  );

  const { projectId } = row.original;

  const checked =
    selectedProjects.findIndex(
      (selectedProject) => selectedProject.original.projectId === projectId,
    ) !== -1;

  // const getRowRange = (rows, currentIndex, selectedIndex) => {
  //   const rangeStart =
  //     selectedIndex > currentIndex ? currentIndex : selectedIndex;
  //   const rangeEnd = rangeStart === currentIndex ? selectedIndex : currentIndex;
  //   return rows.slice(rangeStart, rangeEnd + 1);
  // };

  const handleCheckboxClick = (event) => {
    let newSelectedRows = [...selectedProjects];
    const isChecked = event.target.checked;

    if (!row) return;

    if (event.shiftKey && lastSelectedProjectRowID !== null) {
      const startIndex = rows.findIndex(
        (singleRecord) => singleRecord.original.projectId === projectId,
      );
      const endIndex = rows.findIndex(
        (singleRecord) =>
          singleRecord.original.projectId === lastSelectedProjectRowID,
      );

      const start = Math.min(startIndex, endIndex);
      const end = Math.max(startIndex, endIndex);

      for (let i = start; i <= end; i++) {
        if (
          isChecked &&
          newSelectedRows.findIndex(
            (newSelectedRow) =>
              newSelectedRow.original.projectId === rows[i].original.projectId,
          ) === -1
        ) {
          newSelectedRows.push(rows[i]);
        } else if (
          !isChecked &&
          newSelectedRows.findIndex(
            (newSelectedRow) =>
              newSelectedRow.original.projectId === rows[i].original.projectId,
          ) !== -1
        ) {
          newSelectedRows = newSelectedRows.filter(
            (newSelectedRow) =>
              newSelectedRow.original.projectId !== rows[i].original.projectId,
          );
        }
      }
    } else {
      if (isChecked) {
        newSelectedRows.push(row);
      } else {
        newSelectedRows = newSelectedRows.filter(
          (newSelectedRow) => newSelectedRow.original.projectId !== projectId,
        );
      }
    }

    dispatch(setSelectedProjects(newSelectedRows));
    dispatch(setLastSelectedProjectRowID(projectId));
  };

  return (
    <div
      onMouseEnter={() => {
        setShouldShowCheckbox(true);
      }}
      onMouseLeave={() => {
        setShouldShowCheckbox(false);
      }}
      className="tasks-table__checkbox-wrapper"
    >
      {!checked && !shouldShowCheckbox ? (
        <span>{row.index + 1}</span>
      ) : (
        <input
          type="checkbox"
          className="tasks-table__checkbox"
          checked={checked}
          // style={style}
          onClick={handleCheckboxClick}
        />
      )}
    </div>
  );
}
