import React, { useState } from "react";
import "./CoverTop.scss";
import { useDispatch, useSelector } from "react-redux";
import PostImagesChecker from "src/app/components/PostImagesChecker/PostImagesChecker";
import OuterLink from "src/app/components/OuterLink/OuterLink";
import { IDHAALogo } from "src/app/components/IDHAALogo/IDHAALogo";
import { setShowcaseTitle } from "src/redux";

export function CoverTop(props) {
  const { renderCreators, workspaceData } = props;

  const creatorsForCover = useSelector(
    (state) => state.showcaseReducer.creatorsForCover,
  );
  const printing = useSelector((state) => state.showcaseReducer.printing);
  const showcaseTitle = useSelector(
    (state) => state.showcaseReducer.showcaseTitle,
  );
  const identity = useSelector((state) => state.mainReducer.identity);
  const primaryColor = identity.customPrimaryColor || "#5D78FF";

  const [title, setTitle] = useState(showcaseTitle);

  const dispatch = useDispatch();

  const getLastPostsData = (creator) => {
    const lastPostsData = [];
    Object.values(creator.socialProfilesData).forEach((el) => {
      lastPostsData.push(...el.lastPostsData);
    });
    return lastPostsData;
  };

  const handleBlur = (e) => {
    dispatch(setShowcaseTitle(e.target.value));
  };

  return (
    <div className="cover-top">
      <div
        className="cover-top__background-color"
        style={{ backgroundColor: primaryColor }}
      />

      <div className="cover-top__images">
        {creatorsForCover.map((creator) => (
          <PostImagesChecker
            key={creator.id}
            max={1}
            data={getLastPostsData(creator) || []}
            render={(item, index) => (
              <div
                className="cover-top__images-image"
                key={`${creator.id}-${index}`}
              >
                <OuterLink href={item.postUrl}>
                  <img
                    src={!printing ? item?.thumbnailSrc : undefined}
                    data-img-src={printing ? item?.thumbnailSrc : undefined}
                    alt="post-image"
                    loading="lazy"
                  />
                </OuterLink>
              </div>
            )}
          />
        ))}
      </div>

      <div className="cover-top__info">
        {Object.keys(workspaceData || {}).length > 0 ? (
          <IDHAALogo size={80} customLogo={workspaceData?.smallLogo} />
        ) : null}

        <div className="cover-top__info-wrapper">
          <span className="cover-top__info-title">
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={handleBlur}
            />
          </span>

          {renderCreators()}
        </div>
      </div>
    </div>
  );
}
