import React, { useEffect, useState } from "react";
import "./OverviewFieldsTable.scss";

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { RootState } from "src/redux/reducers";
import { IProject } from "src/redux/project/types";
import { ProjectPermissionsEnum } from "src/utils/PermissionsEnums";
import { DateObject, IColumn } from "../../project/Project";
import {
  getProject,
  getTasksAutocomplete,
  setGallerySources,
  updateProjectMetaFieldRank,
} from "../../../redux";
import { generateRankString } from "../../../utils/rankStrings";
import { HIDDEN_FIELDS, TASK_TYPES } from "../../../utils/variables";
import ColumnOptionsDropdown from "../../dropdowns/ColumnOptionsDropdown";
import { TableField } from "../../project/TableField/TableField";
import { ReactComponent as PlusIcon } from "../../../images/plus-transparent.svg";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { tableDataType } from "../Table/Table";

export enum taskAvatarShape {
  Circle,
  Square,
}

interface Props {
  className?: string;
  data: IProject[];
  fields: any[];
  disableLink?: boolean;
  onTableValueChange?: (
    taskId: string,
    uuid: string,
    newValue: string | DateObject | null | boolean | number,
  ) => void;
  updateData?: () => void;
  addTableElement?: () => void;
  addTableMetaField?: () => void;
  taskAvatarShape: taskAvatarShape;
  singleRow?: boolean;
}

function OverviewFieldsTable(props: Props) {
  const { className, data, fields, onTableValueChange, addTableMetaField } =
    props;

  const [filteredFields, setFilteredFields] = useState<IColumn[]>([]);
  const [autoCompletes, setAutoCompletes] = useState<any>([]);

  const dispatch = useDispatch();

  const params = useParams<{ projectId: string }>();

  const {
    projectReducer: { projectBasicData },
  } = useSelector((state: RootState) => state);

  const wsProjectAutocompleteList = useSelector(
    (state: RootState) => state.projectReducer.wsProjectAutocompleteList,
  );

  // clears data after TaskType change and sets data to display
  useEffect(() => {
    setAutoCompletes([]);
    dispatch(setGallerySources([]));
  }, []);

  useEffect(() => {
    const objectMetaData = data[0]?.metadata;
    const autoCompletesLocal: any = [];
    const newFilteredFields = fields.filter((field) => {
      if (
        TASK_TYPES.includes(field.type) &&
        !autoCompletes.includes(field.type) &&
        !autoCompletesLocal.includes(field.type)
      ) {
        autoCompletesLocal.push(field.type);
        setAutoCompletes((list: any) => [...list, field.type]);
        dispatch(getTasksAutocomplete(params.projectId, field.type));
      }
      const uuid = field.id || field.uuid;
      return (
        field.isVisible &&
        !HIDDEN_FIELDS.includes(field.name) &&
        objectMetaData?.find((metafield: any) => metafield.uuid === uuid)
      );
    });

    setFilteredFields(newFilteredFields);
  }, [fields, data]);

  const handleDragUpdate = (update: any) => {
    if (!update.destination) {
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const { destination, source } = result;

    const sourceField: any = filteredFields[source.index];
    const fieldIndex = destination.index;

    let newRank = "";

    // move left
    if (destination.index < source.index) {
      newRank = generateRankString(
        filteredFields[fieldIndex - 1]?.rank || "",
        filteredFields[fieldIndex].rank,
      );
      // move right
    } else {
      newRank = generateRankString(
        filteredFields[fieldIndex].rank,
        filteredFields[fieldIndex + 1]?.rank || "",
      );
    }
    updateMetaFieldRank(sourceField, newRank);
  };

  const updateMetaFieldRank = async (
    sourceField: { id: string; uuid: string },
    newRank: string,
  ) => {
    await dispatch(
      updateProjectMetaFieldRank(params.projectId, sourceField.uuid, newRank),
    );
    dispatch(getProject(params.projectId));
  };

  return (
    <>
      {data && (
        <div
          className={classNames(
            "ws-overview-fields-table",
            "ws-overview-fields-table--tall",
            filteredFields.length === 0 &&
              !projectBasicData?.permissions?.project.includes(
                "project_meta_fields_management",
              ) &&
              "ws-overview-fields-table--empty",
            className,
          )}
        >
          <DragDropContext
            onDragUpdate={handleDragUpdate}
            onDragEnd={(result) => handleDragEnd(result)}
          >
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  id="table-scroll"
                  className={classNames(
                    "ws-overview-fields-table__columns",
                    {
                      "ws-overview-fields-table__columns--dragging-over":
                        snapshot.isDraggingOver,
                    },
                    "scroll-fixed",
                  )}
                  {...provided.droppableProps}
                >
                  {filteredFields?.map((field: any, index) => {
                    return (
                      <Draggable
                        key={field.id || field.uuid}
                        draggableId={field.id || field.uuid}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            className={classNames(
                              "ws-overview-fields-table__column",
                              {
                                "ws-overview-fields-table__column--dragging":
                                  snapshot.isDragging,
                              },
                            )}
                            {...provided.draggableProps}
                            data-column-name={field.name}
                            data-column-key={field.key}
                          >
                            <div
                              className="ws-overview-fields-table__header"
                              key={index}
                            >
                              <ColumnOptionsDropdown
                                field={field}
                                dataType={tableDataType.Project}
                                dragHandleProps={provided.dragHandleProps}
                              />
                            </div>
                            {data?.map((item: any) => {
                              const itemField: any = item.metadata.find(
                                (meta: any) =>
                                  field.id === meta.uuid ||
                                  field.uuid === meta.uuid,
                              );

                              const fieldValue = itemField?.value?.date
                                ? itemField?.value?.date
                                : itemField?.value;

                              const uuid = itemField?.uuid;

                              if (itemField) {
                                return (
                                  <div
                                    key={item.projectId}
                                    className="ws-overview-fields-table__row"
                                  >
                                    <TableField
                                      fieldValue={
                                        fieldValue === "" ? null : fieldValue
                                      }
                                      fieldData={itemField}
                                      onValueChange={(newValue) => {
                                        if (
                                          (newValue ||
                                            newValue === false ||
                                            newValue === null) &&
                                          onTableValueChange
                                        ) {
                                          onTableValueChange(
                                            item.projectId,
                                            uuid,
                                            newValue,
                                          );
                                        }
                                      }}
                                      objectId={item.projectId}
                                      uuid={uuid}
                                      dataType={tableDataType.Project}
                                      wsProjectAutocompleteList={
                                        wsProjectAutocompleteList
                                      }
                                    />
                                  </div>
                                );
                              }
                            })}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}

                  {projectBasicData?.permissions?.project.includes(
                    ProjectPermissionsEnum.PROJECT_META_FIELDS_MANAGEMENT,
                  ) && (
                    <div
                      className="ws-overview-fields-table__add-button ws-overview-fields-table__add-button--project"
                      onClick={addTableMetaField}
                    >
                      <span
                        className={classNames(
                          "ws-overview-fields-table__add-button-empty ws-overview-fields-table__add-button-empty--circle",
                          {
                            "ws-overview-fields-table__add-button-empty--small":
                              filteredFields.length > 0,
                          },
                        )}
                      >
                        <PlusIcon />
                      </span>
                      <IDHFormattedMessage
                        id="ws_add_overview"
                        defaultMessage="Add overview"
                      />
                      ...
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </>
  );
}

export default OverviewFieldsTable;
