export const SET_DICTIONARY_SORT_BY = "SET_DICTIONARY_SORT_BY";
export const SET_DICTIONARY_SORT = "SET_DICTIONARY_SORT";
export const SET_DICTIONARY_FILTERS = "SET_DICTIONARY_FILTERS";
export const SET_DICTIONARY_FILTER = "SET_DICTIONARY_FILTER";
export const RESET_DICTIONARY_FILTERS_REDUCER =
  "RESET_DICTIONARY_FILTERS_REDUCER";
export const TOGGLE_DICTIONARY_COLUMN_VISIBILITY =
  "TOGGLE_DICTIONARY_COLUMN_VISIBILITY";
export const SET_DICTIONARY_COLUMN_RANK = "SET_DICTIONARY_COLUMN_RANK";
export const SET_DICTIONARY_INITIAL_COLUMN_RANKS =
  "SET_DICTIONARY_INITIAL_COLUMN_RANKS";
export const RESET_HIDDEN_COLUMNS = "RESET_HIDDEN_COLUMNS";
export const SET_SELECTED_TABLE_VIEW = "SET_SELECTED_TABLE_VIEW";
export const SET_HIDDEN_DICTIONARY_COLUMN_IDS =
  "SET_HIDDEN_DICTIONARY_COLUMN_IDS";
