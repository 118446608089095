import { addElementToLayoutAlt } from "src/app/generator-data/functions/addElementToLayoutAlt";
import { addHeaderToLayout } from "./addHeaderToLayout";

export const addAudienceDataToLayout = (
  gridElements,
  newLayout,
  format,
  campaigns,
  dataAggregation,
) => {
  const aggregation = dataAggregation.campaignAudienceData;

  Object.keys(campaigns).forEach((key) => {
    const campaign = campaigns[key];

    const {
      project: { title, projectId },
    } = campaign;

    if (!aggregation && key !== "aggregated") {
      addHeaderToLayout(`audience-data-${title}`, newLayout, format, 3);
    }

    gridElements
      .filter(
        (item) =>
          item.gridBoxType === "audience-data" && item.campaignId === key,
      )
      .forEach((data) => {
        addElementToLayoutAlt(data, newLayout, format, []);
      });
  });
};
