import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";

import { tableDataType } from "src/app/components/Table/Table";
import { Button } from "src/app/components/Button/Button";
import { AvatarAndName } from "src/app/components/GlobalTaskDetails/tabs/GlobalActivityTab/GlobalActivityTabCampaigns";
import placeholderIcon from "src/images/placeholder-image.svg";
import SingleMetaField from "src/app/Task/components/TaskMetaFields/SingleMetaField";
import Tabs from "src/app/components/Tabs/Tabs";
import Tab from "src/app/components/Tabs/Tab";
import { ReactComponent as InfoIcon } from "src/images/info-circle-blue.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import responseCodes from "src/utils/responseCodes";
import { API_URLS } from "src/utils/API_URLS";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { EmailContent, MessageDetails, MessageRecipient, Steps } from "./types";
import EmailDropdown from "./EmailDropdown";

interface CreatorMessagePaneManageEmailsProps {
  emailContent: EmailContent;
  setMessageDetails: React.Dispatch<
    React.SetStateAction<MessageDetails | null>
  >;
  activeWorkspaceUuid: string;
  recipientsWithNoEmailCRM: MessageRecipient[] | undefined;
  setStep: React.Dispatch<React.SetStateAction<Steps>>;
  setIsUpdatingCreatorEmails: React.Dispatch<React.SetStateAction<boolean>>;
  getMessageDetails: () => void;
}

export default function CreatorMessagePaneManageEmails({
  emailContent,
  setMessageDetails,
  activeWorkspaceUuid,
  recipientsWithNoEmailCRM,
  setStep,
  setIsUpdatingCreatorEmails,
  getMessageDetails,
}: CreatorMessagePaneManageEmailsProps) {
  const [updatedCreatorEmails, setUpdatedCreatorEmails] = useState<
    { wsTaskUuid: string; value: string }[]
  >([]);

  const isThereAtLeastOneRecipientWithEmail = emailContent.recipients.some(
    (recipient) => recipient.emailFromCrm,
  );

  const handleUpdateEmailField = (
    newValue: string | null,
    recipient: MessageRecipient,
  ) => {
    setUpdatedCreatorEmails((prev) => {
      if (newValue === null) {
        return prev.filter(
          (prevItem) => prevItem.wsTaskUuid !== recipient.wsTaskUuid,
        );
      }
      return [...prev, { wsTaskUuid: recipient.wsTaskUuid, value: newValue }];
    });

    setMessageDetails((prev) => {
      if (!prev) return null;

      const updatedRecipients = prev.recipients.map((prevRecipient) => {
        if (prevRecipient.wsTaskUuid === recipient.wsTaskUuid) {
          return {
            ...prevRecipient,
            crmEmail: {
              ...prevRecipient.crmEmail,
              value: newValue,
            },
          };
        }
        return prevRecipient;
      });

      return {
        ...prev,
        recipients: updatedRecipients,
      };
    });
  };

  const handleUpdateCreatorEmails = async () => {
    if (updatedCreatorEmails.length === 0) {
      setStep(Steps.CREATE_EMAIL);
      return;
    }

    setIsUpdatingCreatorEmails(true);
    try {
      const response: AxiosResponse<{ content: MessageDetails }> =
        await axios.post(API_URLS.updateCreatorEmails, {
          wsWorkspaceUuid: activeWorkspaceUuid,
          wsTasksData: updatedCreatorEmails,
        });

      if (response.status !== responseCodes["200_OK"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      setStep(Steps.CREATE_EMAIL);
      getMessageDetails();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast();
      }
      console.error(error);
    } finally {
      setIsUpdatingCreatorEmails(false);
    }
  };

  useEffect(() => {
    if (!recipientsWithNoEmailCRM) return;

    const recipientsWithEmails = recipientsWithNoEmailCRM.filter(
      (recipient) => recipient.crmEmail.value,
    );

    if (recipientsWithEmails.length === 0) return;

    const formattedRecipients = recipientsWithEmails.map((recipient) => ({
      wsTaskUuid: recipient.wsTaskUuid,
      value: recipient.crmEmail.value,
    }));

    setUpdatedCreatorEmails(formattedRecipients);
  }, [recipientsWithNoEmailCRM]);

  return (
    <>
      <div className="creator-message-pane__manage-emails">
        <div className="creator-message-pane__info">
          <InfoIcon />
          <div className="creator-message-pane__info-box">
            <div className="creator-message-pane__info-title">
              <IDHFormattedMessage id="ws_info" defaultMessage="Info" />
            </div>
            <div className="creator-message-pane__info-text">
              <IDHFormattedMessage
                id="ws_sending_a_message_info"
                defaultMessage="For the creators below, email addresses are either unavailable or ambigous. Please fill them, or they will be excluded from the sending message. The specified addresses will also be added to the CRM."
              />
            </div>
          </div>
        </div>
        <Tabs>
          <Tab
            tabText={
              <IDHFormattedMessage id="ws_creators" defaultMessage="Creators" />
            }
            active
            count={recipientsWithNoEmailCRM?.length ?? 0}
          />
        </Tabs>
        <div className="creator-message-pane__cols">
          <div className="creator-message-pane__header">
            <IDHFormattedMessage id="ws_creator" defaultMessage="Creator" />
          </div>
          <div className="creator-message-pane__header">
            <IDHFormattedMessage id="ws_email" defaultMessage="Email" />
          </div>
        </div>
        <div className="creator-message-pane__emails-list">
          {recipientsWithNoEmailCRM?.map((recipient) => (
            <div
              className="creator-message-pane__cols"
              key={recipient.wsTaskUuid}
            >
              <AvatarAndName
                name={recipient.title}
                key={recipient.wsTaskUuid}
                avatar={recipient.avatar}
                placeholder={placeholderIcon}
              />
              <div>
                {recipient.crmEmail.type === MetaFieldType.Text && (
                  <SingleMetaField
                    metaData={recipient.crmEmail}
                    dataType={tableDataType.CreatorMessage}
                    handleUpdateMetaValue={(newValue: string | null) =>
                      handleUpdateEmailField(newValue, recipient)
                    }
                  />
                )}
                {recipient.crmEmail.type === MetaFieldType.SingleSelect && (
                  <EmailDropdown
                    setMessageDetails={setMessageDetails}
                    recipient={recipient}
                    handleUpdateMetaValue={(newValue: string | null) => {
                      handleUpdateEmailField(newValue, recipient);
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="creator-message-pane__footer">
        <Button
          variant="blue"
          size="large"
          onClick={handleUpdateCreatorEmails}
          disabled={
            !isThereAtLeastOneRecipientWithEmail &&
            updatedCreatorEmails.length === 0
          }
        >
          <IDHFormattedMessage id="ws_continue" defaultMessage="Continue" />
        </Button>
      </div>
    </>
  );
}
