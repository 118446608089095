import { AppConfig } from "../config/AppConfig";

const API_HOST = AppConfig.getApiHost();
const API_ROUTE = AppConfig.getApiRoute();

const IDENTITY_URL = `${API_ROUTE}/identity`;
const TRANSLATIONS_URL = `${API_ROUTE}/translation`;
const SET_LANGUAGE = `${API_ROUTE}/set-language`;
const PROJECT = `${API_ROUTE}/project`;
const ACCESS_LINK = `${API_ROUTE}/access-link`;
const TASK = `${API_ROUTE}/task`;
const GLOBAL_TASK = `${API_ROUTE}/global-task`;
const ASSET = `${API_ROUTE}/asset`;
const TASK_COMMENT = `${API_ROUTE}/task-comment`;
const WORKSPACE = `${API_ROUTE}/workspace`;
const INFLUENCER_PLUGIN = `${API_ROUTE}/influencer-plugin`;
const SEARCH = `${API_ROUTE}/search`;
const REPORT = `${API_ROUTE}/report`;
const REPORT_DRAFT = `${API_ROUTE}/project-report-draft`;
const AUTOMATION = `${API_ROUTE}/automation`;
const ACTIVITY_LOG = `${API_ROUTE}/activity-log`;
const DOCUMENT = `${API_ROUTE}/document`;
const SETTINGS = `${API_ROUTE}/settings`;
const EXTENSION = `${API_ROUTE}/extension`;
const RECRUITMENT_FORM = `${API_ROUTE}/recruitment-form`;
const COUPON = `${API_ROUTE}/coupon`;
const COUPON_GROUP = `${API_ROUTE}/coupon-group`;
const COUPON_REDEMPTION_LOG_IMPORT_STRATEGY = `${API_ROUTE}/coupon-redemption-log-import-strategy`;
const GLOBAL_TASK_META_FIELD = `${API_ROUTE}/global-task-meta-field`;
const GLOBAL_PROJECT_META_FIELD = `${API_ROUTE}/global-project-meta-field`;
const DICTIONARY = `${API_ROUTE}/dictionary`;
const DICTIONARY_ELEMENT = `${API_ROUTE}/dictionary-element`;
const SETTINGS_GLOBAL_META_FIELD = `${SETTINGS}/workspace-global-meta-fields`;
const TEAM = `${API_ROUTE}/team`;
const META_FIELD = `${API_ROUTE}/meta-field`;
const ANALYTICS = `${API_ROUTE}/analytics`;
const CREDIBILITY_TOOL = `${API_HOST}/credibility-tool`;
const INFLUENCER_INSIGHTS_TOOL = `${API_HOST}/influencer-insights-tool`;
const CDT_REPORT = `${INFLUENCER_INSIGHTS_TOOL}/influencer-report`;
const LIVE_HASHTAGS = `${INFLUENCER_INSIGHTS_TOOL}/live-hashtags`;
const CONTENT_PROPOSAL = `${API_ROUTE}/content-proposal`;
const MESSENGER = `${API_ROUTE}/messenger`;
const SINGLESPLITPAYMENTS = `${API_ROUTE}/single-split-payments`;
const TABLE_VIEWS = `${API_ROUTE}/table-view`;
// const AUTHENTICATION_URL = API_HOST + "/authentication";

// eslint-disable-next-line import/prefer-default-export
export const API_URLS = {
  getTranslationsForLocale: `${TRANSLATIONS_URL}/get-trans-for-locale/:lang:`,
  getTranslationsForMember: `${TRANSLATIONS_URL}/get-trans-for-ws-member`,
  getIdentity: `${IDENTITY_URL}`,
  setLocale: `${IDENTITY_URL}/set-locale`,
  hideTrialLanguage: `${IDENTITY_URL}/hide-trial-language`,
  setLanguage: SET_LANGUAGE,
  getSearchResultsUrl: SEARCH,
  getProjectAutocompleteList: `${SEARCH}/project-autocomplete-list/:wsWorkspaceUuid:`,

  logout: `${IDENTITY_URL}/logout`,
  getLastActiveWorkspaceUuid: `${IDENTITY_URL}/get-last-workspace-uuid`,
  requestMagicLink: `${IDENTITY_URL}/magic-link-request`,
  trialAccount: `${IDENTITY_URL}/trial-account-request`,
  getMemberList: `${IDENTITY_URL}/member/list/:wsWorkspaceUuid:`,
  getActiveMemberList: `${IDENTITY_URL}/member/list/active/:wsWorkspaceUuid:`,
  predesignedRequestForUploadAvatar: `${IDENTITY_URL}/member/presigned-avatar-upload-request`,
  createProfile: `${IDENTITY_URL}/profile/create`,
  getTrialLimits: `${IDENTITY_URL}/get-trial-limits/:wsWorkspaceUuid:`,

  getDocumentsList: `${DOCUMENT}/list`,
  getProjectsList: `${PROJECT}/list/:wsWorkspaceUuid:`,
  getProjectTemplates: `${PROJECT}/metadata/get-project-templates/:wsWorkspaceUuid:`,
  duplicateProject: `${PROJECT}/duplicate/:wsProjectUuid:`,
  deleteProject: `${PROJECT}/delete/:wsProjectUuid:`,
  getProject: `${PROJECT}/:projectUuid:`,
  getProjectDetails: `${PROJECT}/details/:projectUuid:`,
  createNewProject: `${PROJECT}/create`,
  createNewProjectBasedOnExistingOne: `${PROJECT}/create-based-on-another-project-structure`,
  addProjectMembers: `${PROJECT}/add-members`,
  addProjectClients: `${PROJECT}/add-clients`,
  setAsMember: `${PROJECT}/set-as-operator`,
  setAsAdmin: `${PROJECT}/set-as-admin`,
  setAsClient: `${PROJECT}/set-as-client`,
  toggleClientMode: `${PROJECT}/client_mode/:wsProjectUuid:`,
  toggleSeeAsClientMode: `${PROJECT}/see_as_client_mode/:wsProjectUuid:`,
  removeProjectMember: `${PROJECT}/remove-member`,
  presignedRequestForProjectCover: `${PROJECT}/presigned-upload-request`,
  presignedRequestForProjectMetaField: `${PROJECT}/presigned-request-file-meta-field`,

  deleteProjectMetaFieldFileAsset: `${PROJECT}/metadata/delete-meta-field-file-asset/:fieldId:/:assetId:`,
  createProjectMetaField: `${PROJECT}/metadata/create-meta-field`,
  updateProjectMetaValue: `${PROJECT}/metadata/set-meta-value`,
  updateProjectAccessLinkMetaValue: `${PROJECT}/metadata/access-link/set-meta-value`,
  updateProjectMetaField: `${PROJECT}/metadata/update-meta-field`,
  updateProjectMetaFieldRank: `${PROJECT}/metadata/update-meta-field-rank`,
  deleteProjectMetaField: `${PROJECT}/metadata/delete-meta-field/:fieldId:`,
  updateProjectMetaFieldVisibility: `${PROJECT}/metadata/update-meta-field-visibility`,
  updateProjectSingleSelectMetaField: `${PROJECT}/metadata/update-single-select-meta-field`,
  getProjectProviderMetricsPossibleValues: `${PROJECT}/metadata/get-dynamic-provider-metrics-values/:metricsType:/:projectUuid:/:taskType:?shared=:shared:`,
  getAudienceCountries: `${TASK}/metadata/get-audience-country-values`,
  getProjectCountConditions: `${PROJECT}/metadata/get-dynamic-provider-available-conditions/:metricsType:/:projectUuid:/:taskType:?shared=:shared:`,
  getProjectAvailableTargets: `${PROJECT}/metadata/get-dynamic-provider-available-targets/:metricsType:/:projectUuid:?shared=:shared:`,
  getProjectCreatorsInsights: `${PROJECT}/creators-insights/:projectUuid:`,
  getProjectAudienceData: `${PROJECT}/audience-data/:projectUuid:`,

  createTaskMetaField: `${TASK}/metadata/create-meta-field`,
  updateTaskMetaField: `${TASK}/metadata/update-meta-field`,
  updateTaskMetaFieldAccessLinkValue: `${TASK}/metadata/access-link/set-meta-value`,
  changeTaskMetaFieldValue: `${TASK}/metadata/set-meta-value`,
  changeTaskGroupMetaFieldValue: `${TASK}/metadata/set-meta-value-for-group`,
  updateTaskMetaFieldRank: `${TASK}/metadata/update-meta-field-rank`,
  deleteTaskMetaField: `${TASK}/metadata/delete-meta-field/:wsTaskMetaFieldUuid:`,
  updateTaskMetaFieldVisibility: `${TASK}/metadata/update-meta-field-visibility`,
  deleteTaskMetaFieldFileAsset: `${TASK}/metadata/delete-meta-field-file-asset/:fieldId:/:taskId:/:assetId:`,
  updateTaskSingleSelectMetaField: `${TASK}/metadata/update-single-select-meta-field`,
  getProviderMetricsPossibleValues: `${TASK}/metadata/get-dynamic-provider-metrics-values/:metricsType:`,
  getTasksCounters: `${TASK}/counter/:projectUuid:`,
  getCurrencies: `${TASK}/metadata/get-currencies`,
  setTaskMetaValueSocialProfiles: `${TASK}/metadata/social-profiles/set-meta-value`,
  setPublicationMetaValue: `${TASK}/metadata/publication/set-meta-value`,
  patchPublicationStatistic: `${TASK}/metadata/publication-statistics/set-meta-value`,
  postRefreshPublicationStatistics: `${TASK}/metadata/publication-statistics/refresh`,
  shareColumn: `${TASK}/metadata/share/:metaFieldId:`,
  unshareColumn: `${TASK}/metadata/unshare/:metaFieldId:`,
  getTaskDetails: `${TASK}/details/:taskId:`,
  createTask: `${TASK}/create`,
  createMultipleTasks: `${TASK}/create-multiple`,
  getTasksList: `${TASK}/list/:projectUuid:`,
  getSingleTaskForList: `${TASK}/list/single/:wsTaskUuid:`,
  getMyTasks: `${TASK}/my-tasks-list/:userId:`,
  getSingleTaskForMyTaskList: `${TASK}/my-tasks-list/single/:wsTaskUuid:`,
  deleteTask: `${TASK}/delete/:taskUuid:`,
  deleteSelectedTasks: `${TASK}/delete-selected-tasks`,
  createPaymentsToSelectedTasks: `${TASK}/create-payments-for-selected-tasks`,
  duplicateTask: `${TASK}/duplicate`,
  updateTaskRank: `${TASK}/update-rank`,
  addTaskMembers: `${TASK}/add-members`,
  removeTaskMember: `${TASK}/remove-member`,
  presignedRequestForTaskCover: `${TASK}/presigned-upload-request`,
  taskListAutocomplete: `${TASK}/list-autocomplete/:projectUuid:/:taskType:`,
  presignedRequestForTaskMetaField: `${TASK}/presigned-request-file-meta-field`,
  shareTask: `${TASK}/share/:taskId:`,
  shareGroupTask: `${TASK}/share-group`,
  unshareTask: `${TASK}/unshare/:taskId:`,
  unshareGroupTask: `${TASK}/unshare-group`,
  shareMultipleColumns: `${TASK}/metadata/share-bulk/:wsProjectUuid:`,
  getAllTaskMetaFieldsForProject: `${TASK}/meta-fields/:wsProjectUuid:`,
  copySelectedTasksToProject: `${TASK}/copy-selected-tasks-to-project`,

  createAsset: `${ASSET}/create`,
  createMultipleAssets: `${ASSET}/create-multiple`,
  deleteAsset: `${ASSET}/delete`,
  setAsTaskCover: `${ASSET}/set-as-task-cover`,
  createTaskCommentAttachment: `${ASSET}/create-task-comment-attachment`,
  updateTaskCommentAttachments: `${ASSET}/update-task-comment-attachments`,
  deleteRecruitmentFormCover: `${ASSET}/delete-recruitment-form-cover/:wsExtensionUuid:`,

  createTaskComment: `${TASK_COMMENT}/create`,
  deleteTaskComment: `${TASK_COMMENT}/delete`,
  editTaskComment: `${TASK_COMMENT}/edit`,
  presignedRequestForTaskCommentAttachment: `${TASK_COMMENT}/presigned-upload-request`,

  listWorkspacesForUser: `${WORKSPACE}/list-for-user`,
  addWorkspaceMembers: `${WORKSPACE}/add-members`,
  removeWorkspaceMember: `${WORKSPACE}/remove-member`,
  setWorkspaceRole: `${WORKSPACE}/set-workspace-role`,

  getCreatorDiscoveryToolUrl: `${INFLUENCER_PLUGIN}/access-creator-discovery/:wsWorkspaceUuid:`,
  getProfileData: `${INFLUENCER_PLUGIN}/get-profile-data/:workspaceUuid:/:socialProvider:/:socialUsername:`,
  getContactData: `${INFLUENCER_PLUGIN}/get-contact-data/:workspaceUuid:/:wsMemberUuid:/:socialProvider:/:socialUsername:`,
  getCreatorAutoSuggestions: `${SEARCH}/creators-autosuggestions`,

  getGlobalTaskMetaFieldList: `${GLOBAL_TASK_META_FIELD}/:workspaceUuid:/:projectUuid:/:taskType:/list`,
  getGlobalProjectMetaFieldList: `${GLOBAL_PROJECT_META_FIELD}/:workspaceUuid:/:projectUuid:/:taskType:/list`,
  createGlobalTaskMetaField: `${GLOBAL_TASK_META_FIELD}/create-task-meta-field`,
  deleteGlobalTaskMetaField: `${GLOBAL_TASK_META_FIELD}/delete-meta-field-file-asset/:fieldId:/:taskId:/:assetId:`,
  deleteDictionaryMetaField: `${DICTIONARY}/delete-meta-field-file-asset/:fieldId:/:taskId:/:assetId:`,
  // dictionary
  createGlobalProjectMetaField: `${GLOBAL_PROJECT_META_FIELD}/create-project-meta-field`,
  getWsGlobalTasksByWorkspaceUuid: `${GLOBAL_TASK}/:wsWorkspaceUuid:/list`,
  getGlobalProjectProviderMetricsPossibleValues: `${GLOBAL_PROJECT_META_FIELD}/get-dynamic-provider-metrics-values/TableFormula/:wsWorkspaceUuid:/:taskType:`,
  getGlobalProjectCountConditions: `${GLOBAL_PROJECT_META_FIELD}/get-dynamic-provider-available-conditions/TableFormula/:wsWorkspaceUuid:/:taskType:`,
  getGlobalProjectAvailableTargets: `${GLOBAL_PROJECT_META_FIELD}/get-dynamic-provider-available-targets/TableFormula/:wsWorkspaceUuid:/:taskType:`,
  getGlobalProjectFormulaSummaryFields: `${GLOBAL_PROJECT_META_FIELD}/get-dynamic-provider-formula-summary-fields/TableFormula/:wsWorkspaceUuid:/:taskType:`,
  getXlsxReportFileForWorkspace: `${WORKSPACE}/xlsx-report-file/:wsWorkspaceUuid:`,

  // Creator template generator
  printPdfFromHtml: `${REPORT}/pdf`,
  getCampaignCreatorReportData: `${REPORT}/creator-report-data`,
  getCreatorDatabaseReportData: `${REPORT}/creator-database-report-data`,
  getCDTReportData: `${CDT_REPORT}/creator-discovery-list-report-data`,
  getCDTSearchReportData: `${CDT_REPORT}/creator-discovery-search-report-data`,
  getReportTemplates: `${REPORT}/templates/:workspaceUuid:`,
  createReportTemplate: `${REPORT}/templates/create`,
  updateReportTemplate: `${REPORT}/templates/update`,
  deleteReportTemplate: `${REPORT}/templates/delete/:templateUuid:`,

  // Report generator
  getPublicationReportData: `${REPORT}/publication-report-data`,

  // Report draft
  getOrCreateReportDraft: `${REPORT_DRAFT}/:projectUuid:`,

  // Automations
  getProjectAutomations: `${AUTOMATION}/list/:projectUuid:`,
  createAutomation: `${AUTOMATION}/create`,
  updateAutomation: `${AUTOMATION}/update`,
  toggleAutomation: `${AUTOMATION}/toggle`,
  runAutomation: `${AUTOMATION}/run`,
  resultAutomation: `${TASK}/get-ws-tasks-detail-aggregated-by-ws-project`,

  // Activity log
  getActivityList: `${ACTIVITY_LOG}/list`,

  // Access links
  getTaskLinkData: ACCESS_LINK,
  updateTaskLinkData: `${ACCESS_LINK}/update`,
  presignedRequestForExternalTaskField: `${ACCESS_LINK}/presigned-request-file-meta-field`,

  // Settings
  getWorkspaceSettings: `${SETTINGS}/workspace/:workspaceUuid:`,
  getProjectSettings: `${SETTINGS}/project/:projectUuid:`,
  getTaskSettings: `${SETTINGS}/task/:taskUuid:`,
  getMemberSettings: `${SETTINGS}/member`,
  getCreatorDiscoverySettings: `${SETTINGS}/workspace-creator-discovery/:workspaceUuid:`,
  setCreatorDiscoverySettings: `${SETTINGS}/workspace-creator-discovery`,
  getSettingsGlobalFieldsList: `${SETTINGS_GLOBAL_META_FIELD}/list/:workspaceUuid:`,
  createNewTaskMetaField: `${SETTINGS_GLOBAL_META_FIELD}/create-task-meta-field`,
  updateTaskGlobalField: `${SETTINGS_GLOBAL_META_FIELD}/update-task-meta-field`,
  deleteTaskGlobalField: `${SETTINGS_GLOBAL_META_FIELD}/delete-task-meta-field`,
  createNewProjectMetaField: `${SETTINGS_GLOBAL_META_FIELD}/create-project-meta-field`,
  updateProjectGlobalField: `${SETTINGS_GLOBAL_META_FIELD}/update-project-meta-field`,
  deleteProjectGlobalField: `${SETTINGS_GLOBAL_META_FIELD}/delete-project-meta-field`,
  updateTaskGlobalSelectField: `${SETTINGS_GLOBAL_META_FIELD}/update-task-meta-field-select-options`,
  updateProjectGlobalSelectField: `${SETTINGS_GLOBAL_META_FIELD}/update-project-meta-field-select-options`,
  setWorkspaceSetting: `${SETTINGS}/:workspaceUuid:/set-workspace-setting`,
  updateGlobalTaskMetaFieldRank: `${SETTINGS_GLOBAL_META_FIELD}/update-task-meta-field-rank`,
  updateGlobalProjectMetaFieldRank: `${SETTINGS_GLOBAL_META_FIELD}/update-project-meta-field-rank`,
  getGlobalMetaFieldMemberTeamList: `${SETTINGS_GLOBAL_META_FIELD}/get-task-meta-field-member-list/:workspaceUuid:/:globalTaskMetaFieldUuid:`,
  assignMemberTeamToGlobalMetaField: `${SETTINGS_GLOBAL_META_FIELD}/assign-task-meta-field-member`,
  assignMultipleMemberTeamToGlobalMetaField: `${SETTINGS_GLOBAL_META_FIELD}/assign-task-meta-field-multiple-member`,
  updateAccessLevelToGlobalMetaField: `${SETTINGS_GLOBAL_META_FIELD}/update-task-meta-field-access-level`,
  deleteMemberTeamToGlobalMetaField: `${SETTINGS_GLOBAL_META_FIELD}/delete-task-meta-field-member`,
  // Settings / Template Manager
  createProjectTemplate: `${SETTINGS}/create-project-template`,
  updateProjectTemplate: `${SETTINGS}/update-project-template`,
  duplicateProjectTemplate: `${SETTINGS}/duplicate-project-template`,
  deleteProjectTemplate: `${SETTINGS}/delete-project-template`,
  getSettingsProjectTemplates: `${SETTINGS}/get-project-templates/:wsWorkspaceUuid:`,
  updateProjectTemplateRank: `${SETTINGS}/update-project-template-rank`,
  getProjectTemplateDetails: `${SETTINGS}/get-project-template/:wsProjectTemplateUuid:`,

  // Extension
  getExtensionDetails: `${EXTENSION}/details/:wsExtensionUuid:`,
  getProjectExtensionsList: `${EXTENSION}/project/list/:projectUuid:`,
  getWorkspaceExtensionsList: `${EXTENSION}/workspace/list/:wsWorkspaceUuid:`,
  getAvailableFields: `${EXTENSION}/available-fields/:projectUuid:`, // TODO RENAME
  getWorkspaceAvailableFields: `${EXTENSION}/workspace/available-fields/:wsWorkspaceUuid:`,
  getWorkspaceGlobalMetaFields: `${EXTENSION}/global-meta-field-list/:wsWorkspaceUuid:`,
  updateExtensionDetails: `${EXTENSION}/update/:wsExtensionUuid:`,
  updateRecruitmentFormStatus: `${EXTENSION}/update-status/:wsExtensionUuid:`,
  updateRegenerateToken: `${EXTENSION}/regenerate-token/:wsExtensionUuid:`,
  createNewExtension: `${EXTENSION}/create`,
  createNewLockWsProjectEditingExtension: `${EXTENSION}/create-ws-project-lock-extension`,
  getPresignedRequestExtension: `${EXTENSION}/presigned-request-recruitment-form`,
  createInstagramAccountVerification: `${EXTENSION}/create-api-connection-extension`,
  updateInstagramAccountVerification: `${EXTENSION}/update-api-connection-extension`,

  // RECRUITMENT FORM
  getRecruitmentFormLinkData: `${RECRUITMENT_FORM}/:wsExtensionUuid:/:token:`,
  updateRecruitmentFormLinkData: `${RECRUITMENT_FORM}/:wsExtensionUuid:/:token:`,
  presignedRequestForRecruitmentFormMetaField: `${RECRUITMENT_FORM}/presigned-request-file-meta-field`,

  // COUPON
  getCouponList: `${COUPON}/list/:workspaceUuid:`,
  createCoupon: `${COUPON}/create`,
  renameCoupon: `${COUPON}/rename/:couponUuid:`,
  deleteCoupon: `${COUPON}/delete/:couponUuid:`,

  // DICTIONARY
  getDictionaryDetails: `${DICTIONARY}/:dictionaryUuid:/details/:dictionaryElementUuid:`,
  deleteDictionaryElement: `${DICTIONARY}/:dictionaryUuid:/delete`,
  getSingleDictionaryElement: `${DICTIONARY}/:dictionaryUuid:/list/single/:dictionaryElementUuid:`,
  createDictionaryElements: `${DICTIONARY}/:workspaceUuid:/:dictionaryUuid:/create`,
  getDictionaryList: `${DICTIONARY}/:dictionaryUuid:/list`,

  // DICTIONARY ELEMENT
  updateDictionaryMetaValue: `${DICTIONARY_ELEMENT}/metadata/set-meta-value`,
  setMetaValueDictionaryElement: `${DICTIONARY_ELEMENT}/metadata/set-meta-value`,
  updateDictionaryElementSelectMetaField: `${DICTIONARY_ELEMENT}/metadata/update-select-meta-field`,

  // COUPON GROUP
  getCouponGroups: `${COUPON_GROUP}/list/:workspaceUuid:`,
  getCouponGroupDetails: `${COUPON}/list-group/:couponGroupUuid:`,
  createCouponGroup: `${COUPON_GROUP}/create`,
  updateCouponGroup: `${COUPON_GROUP}/update`,
  deleteCouponGroup: `${COUPON_GROUP}/delete/:couponGroupUuid:`,

  // COUPON DATA IMPORT
  convertFileToString: `${COUPON_REDEMPTION_LOG_IMPORT_STRATEGY}/convert-file`,
  getCouponDataImportStrategies: `${COUPON_REDEMPTION_LOG_IMPORT_STRATEGY}/list/:wsWorkspaceUuid:`,
  validateCouponDataImportFile: `${COUPON_REDEMPTION_LOG_IMPORT_STRATEGY}/validate-import/:wsCouponRedemptionLogImportStrategyUuid:`,
  processCouponDataImport: `${COUPON_REDEMPTION_LOG_IMPORT_STRATEGY}/process-import/:wsCouponRedemptionLogImportStrategyUuid:`,

  // GLOBAL TASK
  getGlobalTaskDetails: `${GLOBAL_TASK}/details/:taskUuid:`,
  updateGlobalTaskFieldValue: `${GLOBAL_TASK_META_FIELD}/set-meta-value`,
  createGlobalTasks: `${GLOBAL_TASK}/create-multiple`,
  addExistingTasksToDatabase: `${GLOBAL_TASK}/create-based-on-task`,
  getSingleGlobalTaskFromList: `${GLOBAL_TASK}/single/:wsGlobalTaskUuid:`,
  deleteGlobalTasks: `${GLOBAL_TASK}/delete-multiple`,
  setGlobalTaskMetaValueSocialProfiles: `${GLOBAL_TASK_META_FIELD}/social-profiles/set-meta-value`,
  presignedRequestForGlobalTaskMetaField: `${GLOBAL_TASK_META_FIELD}/presigned-request-file-meta-field`,
  presignedRequestForDictionary: `${DICTIONARY}/presigned-request-file-meta-field`,
  getGlobalTaskProjects: `${GLOBAL_TASK}/:wsWorkspaceUuid:/projects/:wsGlobalTaskUuid:`,
  getGlobalTaskCreatorLists: `${GLOBAL_TASK}/:wsWorkspaceUuid:/creator-lists/:wsGlobalTaskUuid:`,
  getTasksByWsGlobalTaskUuid: `${GLOBAL_TASK}/:wsWorkspaceUuid:/tasks/:wsGlobalTaskUuid:?taskType=:taskType:`,

  // TEAM
  getWorkspaceTeamsList: `${TEAM}/list-for-workspace/:wsWorkspaceUuid:`,

  // TEAMS
  createTeam: `${TEAM}/create`,
  addTeamMember: `${TEAM}/add-member`,
  removeTeamMember: `${TEAM}/remove-member`,
  teamsForWorkspace: `${TEAM}/list-for-workspace/:wsWorkspaceUuid:`,
  deleteTeam: `${TEAM}/delete/:wsTeamUuid:`,
  renameTeam: `${TEAM}/rename`,

  // META FIELD - TEAM MEMBERS
  taskMetaFieldTeamMembers: `${META_FIELD}/meta-field-team-members/:wsWorkspaceUuid:/:wsMetaFieldUuid:/task`,
  projectMetaFieldTeamMembers: `${META_FIELD}/meta-field-team-members/:wsWorkspaceUuid:/:wsMetaFieldUuid:/project`,
  dictionaryMetaFieldTeamMembers: `${META_FIELD}/meta-field-team-members/:wsWorkspaceUuid:/:wsMetaFieldUuid:/dictionary`,
  globalTaskMetaFieldTeamMembers: `${META_FIELD}/meta-field-team-members/:wsWorkspaceUuid:/:wsMetaFieldUuid:/global-task`,
  globalProjectMetaFieldTeamMembers: `${META_FIELD}/meta-field-team-members/:wsWorkspaceUuid:/:wsMetaFieldUuid:/global-project`,

  // META FIELD - SELECT DATA SET
  taskMetaFieldDataSetOptions: `${META_FIELD}/meta-field-select-options/:wsWorkspaceUuid:/:wsMetaFieldUuid:/task`,
  projectMetaFieldDataSetOptions: `${META_FIELD}/meta-field-select-options/:wsWorkspaceUuid:/:wsMetaFieldUuid:/project`,
  dictionaryMetaFieldDataSetOptions: `${META_FIELD}/meta-field-select-options/:wsWorkspaceUuid:/:wsMetaFieldUuid:/dictionary`,
  globalTaskMetaFieldDataSetOptions: `${META_FIELD}/meta-field-select-options/:wsWorkspaceUuid:/:wsMetaFieldUuid:/global-task`,
  globalProjectMetaFieldDataSetOptions: `${META_FIELD}/meta-field-select-options/:wsWorkspaceUuid:/:wsMetaFieldUuid:/global-project`,
  getSelectDataSetOptions: `${META_FIELD}/select-data-set-options/:wsSelectDataSetUuid:`,

  // CREDIBILITY-TOOLS
  credibilityCheckEmail: `${CREDIBILITY_TOOL}/check-email`,
  credibilityRegisterUser: `${CREDIBILITY_TOOL}/register-user`,
  credibilityInfluencersList: `${CREDIBILITY_TOOL}/influencers-list`,
  credibilityCheckInfluencer: `${CREDIBILITY_TOOL}/check-influencer`,
  credibilityShowInfluencer: `${CREDIBILITY_TOOL}/show-influencer`,
  credibilityRefererAccount: `${CREDIBILITY_TOOL}/referer-account`,

  // ANALYTICS
  getGlobalCreatorAnalytics: `${ANALYTICS}/global-creator/:wsWorkspaceUuid:/:wsGlobalCreatorUuid:`,

  // HASHTAG LIVE SEARCH
  getLiveHashtagsFeed: `${LIVE_HASHTAGS}/feed`,

  // CONTENT PROPOSAL
  createContentProposal: `${CONTENT_PROPOSAL}/create`,
  presignedRequestForContentProposal: `${CONTENT_PROPOSAL}/presigned-upload-request`,
  moderateContentProposal: `${CONTENT_PROPOSAL}/moderate`,
  wsContentProposalElementsSummary: `${CONTENT_PROPOSAL}/list/:wsTaskUuid:`,
  downloadContentProposal: `${CONTENT_PROPOSAL}/download-content`,

  // XTRM PAYMENT
  sendXtrmPayment: `${EXTENSION}/xtrm-payments/send-payment`,
  updateXtrmPaymentDetails: `${EXTENSION}/update/xtrm-payments/:wsExtensionUuid:`,
  getXtrmPaymentsDefaultValues: `${EXTENSION}/details/:wsExtensionUuid:/xtrm-payments-default-values/:wsPaymentTaskUuid:`,

  // HEALTH CHECK
  healthCheck: `${API_ROUTE}/ping`,

  // EMV
  getDefaultEmvParams: `${TASK}/metadata/get-default-emv-params`,

  // MESSENGER EXTENSION
  createMessengerExtension: `${EXTENSION}/messenger/create`,
  testMessengerConnection: `${EXTENSION}/messenger/test-connection/:wsExtensionUuid:`,
  updateMessengerExtension: `${EXTENSION}/messenger/update`,
  updateMessengerExtensionStatus: `${EXTENSION}/messenger/update-status/:wsExtensionUuid:`,

  // MESSENGER
  postDetailsToSendMessage: `${MESSENGER}/message/details`,
  updateCreatorEmails: `${MESSENGER}/message/update-creator-emails`,
  sendMessage: `${MESSENGER}/message/send`,

  // PAYMENTS
  createSingleSplitPayments: `${SINGLESPLITPAYMENTS}/create-payments`,

  // TABLE VIEWS
  createTableView: `${TABLE_VIEWS}/create`,
};
