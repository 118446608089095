export const addTableOfContentsToLayout = (newLayout, gridElements) => {
  const headers = gridElements?.filter((item) => item.gridBoxType === "header");

  const partsCount = Math.ceil((headers.length + 1) / 20);

  for (let i = 1; i <= partsCount; i++) {
    newLayout.push({
      i: `table-of-contents-${i}`,
      x: 0,
      y: 17 + 16 * (i - 1),
      w: 12,
      h: 16,
      static: true,
    });
  }
};
