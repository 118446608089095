import "./ProjectSelector.scss";
import React from "react";
import { IProject } from "../../../redux/project/types";
import { getRoute, ROUTES } from "../../../utils/ROUTES";
import { ReactComponent as ShareAltIcon } from "../../../images/share-alt.svg";
import CreatorPlaceholder from "../../../images/creator-placeholder.svg";
import CustomImage from "../CustomImage/CustomImage";
import { getImageSource } from "../../../utils/methods";
import TooltipPortal from "../TooltipPortal/TooltipPortal";

interface ProjectSelectorInterface {
  value: string | null;
  wsProjectAutocompleteList: IProject[];
  wsWorkspaceUuid: string | null;
}

export default function ProjectSelector({
  value,
  wsProjectAutocompleteList,
  wsWorkspaceUuid,
}: ProjectSelectorInterface) {
  if (wsProjectAutocompleteList && value && wsWorkspaceUuid) {
    const selectedWsProject = wsProjectAutocompleteList.find(
      (project: IProject) => project.projectId === value,
    );

    if (typeof selectedWsProject?.projectName === "undefined") {
      return null;
    }

    return (
      <div className="project-selector-field">
        <div className="project-selector-field__cover">
          <CustomImage
            src={getImageSource(
              selectedWsProject?.cover,
              "tiny",
              CreatorPlaceholder,
            )}
          />
        </div>
        <TooltipPortal
          content={selectedWsProject?.projectName}
          className="project-selector-field__name"
        >
          {selectedWsProject?.projectName}
        </TooltipPortal>

        <a
          href={getRoute(ROUTES.PROJECT, {
            wsProjectUuid: selectedWsProject?.projectId,
            wsWorkspaceUuid,
          })}
          target="_blank"
          rel="noreferrer"
          className="project-selector-field__link"
        >
          <ShareAltIcon className="share-icon" />
        </a>
      </div>
    );
  }

  return null;
}
