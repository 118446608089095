import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uuidv7 } from "uuidv7";
import axios from "axios";

import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import {
  ProjectPermissionsEnum,
  WorkspacePermissionsEnum,
} from "src/utils/PermissionsEnums";
import { WsExtension, ExtensionTypeEnum } from "src/types";
import { ChangeSettingsSectionParam } from "src/app/SettingsModal/methods";
import { ReactComponent as XTRMIcon } from "src/images/xtrm.svg";
import { ReactComponent as EditIcon } from "src/images/edit.svg";
import { ReactComponent as CreatorMessengerIcon } from "src/images/creator_messenger.svg";
import { API_URLS } from "src/utils/API_URLS";
import { RootState } from "src/redux/reducers";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import responseCodes from "src/utils/responseCodes";
import { setWorkspaceExtensionList } from "src/redux";
import { CreatorDatabaseConfigurationSection } from "../../../CreatorDatabaseConfiguration/CreatorDatabaseConfiguration";
import { ExtensionsSection } from "../../Extensions";
import ExtensionsListItemContent from "./ExtensionsListItemContent";
import ExtensionListItemEditButton from "./ExtensionListItemEditButton";
import { IntegrationsSection } from "../../../Integrations/Integrations";

interface ExtensionsListItemProps {
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
  data: WsExtension;
  permissions: { project: string[]; workspace: string[] };
}

export default function ExtensionsListItem({
  setExtensionsSection,
  data,
  permissions,
}: ExtensionsListItemProps) {
  const [toggled, setToggled] = useState(data.enabled);

  const {
    mainReducer: { activeWorkspaceUuid },
    extensionReducer: { workspaceExtensionList },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const handleEnableMessengerExtension = async () => {
    try {
      const wsExtensionUuid = uuidv7();
      const response = await axios.post(API_URLS.createMessengerExtension, {
        wsWorkspaceUuid: activeWorkspaceUuid,
        wsExtensionUuid,
        host: null,
        login: null,
        password: null,
        port: null,
        encryption: null,
        fromEmail: null,
        fromName: null,
        wsGlobalTaskMetaFieldUuidWithEmail: null,
      });

      if (response.status === responseCodes["200_OK"]) {
        const updatedExtensionList = workspaceExtensionList.map((extension) => {
          if (extension.type === ExtensionTypeEnum.CreatorMessenger) {
            return {
              ...extension,
              uuid: wsExtensionUuid,
            };
          }
          return extension;
        });
        setTimeout(() => {
          setExtensionsSection(IntegrationsSection.CreatorMessenger);
          dispatch(setWorkspaceExtensionList(updatedExtensionList));
        }, 200);
      } else {
        throw new Error(`Response has failed with code: ${response.status}`);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast();
      }
      setToggled(false);
      setExtensionsSection(IntegrationsSection.ExtensionsList);
      console.error(error);
    }
  };

  switch (data.type) {
    case ExtensionTypeEnum.RecruitmentForm:
      return (
        <ExtensionsListItemContent
          name={
            <IDHFormattedMessage
              id="ws_recruitment_form"
              defaultMessage="Recruitment form"
            />
          }
          description={
            <IDHFormattedMessage
              id="ws_recruitment_form_description"
              defaultMessage="Enable the recruitment of Creators through a dedicated form."
            />
          }
          controls={
            data.enabled ? (
              <ExtensionListItemEditButton
                onClick={() =>
                  setExtensionsSection(ExtensionsSection.RecruitmentForm)
                }
              />
            ) : (
              <CustomSwitch
                checked={toggled}
                onChange={(e) => {
                  setToggled(e.target.checked);
                  setTimeout(() => {
                    setExtensionsSection(ExtensionsSection.RecruitmentForm);
                  }, 200);
                }}
                disabled={
                  !permissions?.project?.includes(
                    ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
                  ) &&
                  !permissions?.workspace?.includes(
                    WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
                  )
                }
              />
            )
          }
        />
      );
    case ExtensionTypeEnum.CreatorDatabaseSubmissionForm:
      return (
        <ExtensionsListItemContent
          name={
            <IDHFormattedMessage
              id="ws_creator_registration_form"
              defaultMessage="Creator Registration Form"
            />
          }
          description={
            <IDHFormattedMessage
              id="ws_recruitment_form_description"
              defaultMessage="Enable the recruitment of Creators through a dedicated form."
            />
          }
          controls={
            data.enabled ? (
              <ExtensionListItemEditButton
                onClick={() =>
                  setExtensionsSection(
                    CreatorDatabaseConfigurationSection.SubmissionForm,
                  )
                }
              />
            ) : (
              <CustomSwitch
                checked={toggled}
                onChange={(e) => {
                  setToggled(e.target.checked);
                  setTimeout(() => {
                    setExtensionsSection(
                      CreatorDatabaseConfigurationSection.SubmissionForm,
                    );
                  }, 200);
                }}
                disabled={
                  !permissions?.workspace?.includes(
                    WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
                  )
                }
              />
            )
          }
        />
      );
    case ExtensionTypeEnum.CreatorDatabaseMetaFieldList:
      return (
        <ExtensionsListItemContent
          name={
            <IDHFormattedMessage
              id="ws_workspace_extension_visibility_and_permissions"
              defaultMessage="Visibility and permissions"
            />
          }
          description={
            <IDHFormattedMessage
              id="ws_workspace_extension_visibility_and_permissions_description"
              defaultMessage="Manage visibility and editing for members and entire teams."
            />
          }
          controls={
            <ExtensionListItemEditButton
              onClick={() =>
                setExtensionsSection(
                  CreatorDatabaseConfigurationSection.MetaFieldList,
                )
              }
              disabled={
                !permissions?.workspace?.includes(
                  WorkspacePermissionsEnum.CREATOR_DATABASE_META_FIELD_ACCESS_MANAGEMENT,
                )
              }
            />
          }
        />
      );
    case ExtensionTypeEnum.ApiConnection:
      return (
        <ExtensionsListItemContent
          name={
            <IDHFormattedMessage
              id="ws_instagram_account_verification"
              defaultMessage="Instagram Account Verification"
            />
          }
          description={
            <IDHFormattedMessage
              id="ws_instagram_account_verification_description"
              defaultMessage="Allows creators to authorize their accounts and share insights from campaigns."
            />
          }
          controls={
            <ExtensionListItemEditButton
              onClick={() =>
                setExtensionsSection(
                  CreatorDatabaseConfigurationSection.InstagramAccountVerification,
                )
              }
            />
          }
        />
      );
    case ExtensionTypeEnum.XtrmPayments:
      return (
        <ExtensionsListItemContent
          icon={<XTRMIcon />}
          name={<IDHFormattedMessage id="ws_xtrm" defaultMessage="XTRM" />}
          description={
            <IDHFormattedMessage
              id="ws_xtrm_payments_description"
              defaultMessage="Connect with your XTRM account to manage and make any type of payments."
            />
          }
          controls={
            <button
              className="extensions__action-btn"
              onClick={() =>
                setExtensionsSection(IntegrationsSection.XTRMPayments)
              }
            >
              <EditIcon />
              <IDHFormattedMessage id="ws_edit" defaultMessage="Edit" />
            </button>
          }
        />
      );
    case ExtensionTypeEnum.CreatorMessenger:
      return (
        <ExtensionsListItemContent
          icon={<CreatorMessengerIcon />}
          name={
            <IDHFormattedMessage
              id="ws_creator_messenger"
              defaultMessage="Creator Messenger"
            />
          }
          description={
            <IDHFormattedMessage
              id="ws_creator_messenger_description"
              defaultMessage="Allows for group email sending to selected creators."
            />
          }
          controls={
            data.uuid ? (
              <ExtensionListItemEditButton
                onClick={() =>
                  setExtensionsSection(IntegrationsSection.CreatorMessenger)
                }
              />
            ) : (
              <CustomSwitch
                checked={toggled}
                onChange={(e) => {
                  setToggled(e.target.checked);
                  handleEnableMessengerExtension();
                }}
              />
            )
          }
        />
      );
    default:
      return null;
  }
}
