import { SelectOption } from "src/types";
import {
  MetaDataValue,
  Row,
  DictionaryList,
  DictionaryDetails,
  DictionaryAutoCompletesGeneric,
  MetaData,
  WsTableView,
} from "./types";
import {
  updateColumns,
  addNewRows,
  removeRows,
  updateList,
  updateRow,
} from "./utils";
import {
  CLEAR_DICTIONARY_DETAILS,
  CLEAR_DICTIONARY_LIST,
  CREATE_DICTIONARY_LIST_ELEMENT_SUCCESS,
  GET_DICTIONARY_AUTOCOMPLETE,
  GET_DICTIONARY_AUTOCOMPLETE_SUCCESS,
  GET_DICTIONARY_DETAILS_SUCCESS,
  GET_DICTIONARY_LIST,
  GET_DICTIONARY_LIST_FAIL,
  GET_DICTIONARY_LIST_SINGLE_ELEMENT_SUCCESS,
  GET_DICTIONARY_LIST_SUCCESS,
  REMOVE_DICTIONARY_ELEMENT_SUCCESS,
  SET_DICTIONARY_AUTOCOMPLETE_LOADING,
  SET_DICTIONARY_ELEMENT_META_VALUE_SUCCESS,
  SET_SHOULD_CLOSE_ADD_NEW_DICTIONARY_ELEMENT_MODAL,
  UPDATE_DICTIONARY_ELEMENT_SELECT_META_FIELD_SUCCESS,
} from "./dictionaryTypes";

export interface DictionaryReducerState {
  dictionaryList: DictionaryList;
  wsTableViews: WsTableView[];
  dictionaryDetails: DictionaryDetails;
  dictionaryAutoCompletes: DictionaryAutoCompletesGeneric<Row>;
  dictionaryAutoCompletesColumns: DictionaryAutoCompletesGeneric<MetaData>;
  isDictionaryListLoading: boolean;
  dictionaryAutocompleteLoading: boolean;
  isDictionaryAutocompleteRequestLoading: boolean;
  shouldCloseAddDictionaryElementModal: boolean;
}

const initialState: DictionaryReducerState = {
  dictionaryList: {
    columns: [],
    list: [],
    totalCount: 0,
  },
  wsTableViews: [],
  dictionaryDetails: {} as DictionaryDetails,
  dictionaryAutoCompletes: {},
  dictionaryAutoCompletesColumns: {},
  isDictionaryListLoading: false,
  dictionaryAutocompleteLoading: false,
  shouldCloseAddDictionaryElementModal: false,
  isDictionaryAutocompleteRequestLoading: false,
};

const dictionaryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_DICTIONARY_LIST:
      return {
        ...state,
        isDictionaryListLoading: true,
      };
    case GET_DICTIONARY_LIST_SUCCESS:
      return {
        ...state,
        isDictionaryListLoading: false,
        dictionaryList: action.payload.data.content,
      };
    case GET_DICTIONARY_LIST_FAIL:
      return {
        ...state,
        isDictionaryListLoading: false,
      };
    case CLEAR_DICTIONARY_LIST:
      return {
        ...state,
        dictionaryList: initialState.dictionaryList,
      };
    case UPDATE_DICTIONARY_ELEMENT_SELECT_META_FIELD_SUCCESS:
      const {
        metaFieldId,
        singleSelectOptions,
      }: { metaFieldId: string; singleSelectOptions: SelectOption[] } =
        action.payload.config.reduxSourceAction.payload;

      const updatedColumns = updateColumns(
        state.dictionaryList.columns,
        metaFieldId,
        singleSelectOptions,
      );

      return {
        ...state,
        dictionaryList: { ...state.dictionaryList, columns: updatedColumns },
      };
    case SET_DICTIONARY_ELEMENT_META_VALUE_SUCCESS:
      const {
        uuids,
        metaFieldUuid,
        metaValue,
      }: {
        uuids: string[];
        metaFieldUuid: string;
        metaValue: MetaDataValue | null;
      } = action.payload.config.reduxSourceAction.payload;

      const updatedList = updateList(
        state.dictionaryList.list,
        uuids,
        metaFieldUuid,
        metaValue,
      );

      return {
        ...state,
        dictionaryList: {
          ...state.dictionaryList,
          list: updatedList,
        },
      };

    case GET_DICTIONARY_DETAILS_SUCCESS:
      return {
        ...state,
        dictionaryDetails: action.payload.data.content,
      };

    case CLEAR_DICTIONARY_DETAILS:
      return {
        ...state,
        dictionaryDetails: {},
      };

    case REMOVE_DICTIONARY_ELEMENT_SUCCESS:
      const {
        uuids: removedUuids,
      }: {
        uuids: string[];
      } = action.payload.config.reduxSourceAction.payload;

      const removedRows = removeRows(state.dictionaryList.list, removedUuids);

      return {
        ...state,
        dictionaryList: {
          ...state.dictionaryList,
          list: removedRows,
        },
      };

    case GET_DICTIONARY_LIST_SINGLE_ELEMENT_SUCCESS:
      const [updatedRow]: Row[] = action.payload.data.content;
      const listWithUpdatedRow = updateRow(
        state.dictionaryList.list,
        updatedRow,
      );

      return {
        ...state,
        dictionaryList: {
          ...state.dictionaryList,
          list: listWithUpdatedRow,
        },
      };

    case CREATE_DICTIONARY_LIST_ELEMENT_SUCCESS:
      const {
        wsDictionaryElementsData,
      }: { wsDictionaryElementsData: { uuid: string; name: string }[] } =
        action.payload.config.reduxSourceAction.payload;

      return {
        ...state,
        shouldCloseAddDictionaryElementModal: true,
        dictionaryList: {
          ...state.dictionaryList,
          list: addNewRows(state.dictionaryList.list, wsDictionaryElementsData),
        },
      };

    case SET_SHOULD_CLOSE_ADD_NEW_DICTIONARY_ELEMENT_MODAL: {
      const { shouldCloseAddDictionaryElementModal } = action.payload;
      return {
        ...state,
        shouldCloseAddDictionaryElementModal,
      };
    }

    case GET_DICTIONARY_AUTOCOMPLETE:
      return {
        ...state,
        isDictionaryAutocompleteRequestLoading: true,
      };

    case GET_DICTIONARY_AUTOCOMPLETE_SUCCESS:
      const url = action.payload.config.url.split("/");
      const dictionaryType = `dictionaryType_${url[url.length - 2]}`;

      return {
        ...state,
        isDictionaryAutocompleteRequestLoading: false,
        dictionaryAutoCompletes: {
          ...state.dictionaryAutoCompletes,
          [dictionaryType]: action.payload.data.content.list,
        },
        dictionaryAutoCompletesColumns: {
          ...state.dictionaryAutoCompletesColumns,
          [dictionaryType]: action.payload.data.content.columns,
        },
      };

    case SET_DICTIONARY_AUTOCOMPLETE_LOADING: {
      const { dictionaryAutocompleteLoading } = action.payload;
      return {
        ...state,
        dictionaryAutocompleteLoading,
      };
    }

    default:
      return state;
  }
};

export default dictionaryReducer;
