import React, { useEffect, useMemo, useRef, useState } from "react";
import "./Project.scss";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import { ReactComponent as DownloadIcon } from "src/images/download.svg";
import {
  getDictionaryAutoCompletesForColumns,
  getSelectDataSetForColumns,
  isStringUuid,
  showErrorToast,
} from "src/utils/methods";
import { RootState } from "src/redux/reducers";
import { uuidv7 } from "uuidv7";
import moment from "moment";
import {
  setFilteredProjectList,
  setProjectColumns,
  setProjectInitialHiddenColumns,
  setProjectsListOffset,
  setProjectInitialColumnRanks,
  setProjectColumnVisibleByDefaultUuids,
  setSelectedTableView,
  setProjectFilters,
  setProjectFilter,
  toggleProjectsListColumnVisibility,
  setProjectSortBy,
  setProjectSort,
  setProjectTableViews,
} from "../../redux";
import PageHeader from "../components/PageHeader/PageHeader";

import { ReactComponent as FilterIcon } from "../../images/filter.svg";
import { ReactComponent as PlusIcon } from "../../images/plus-transparent.svg";

import WebsocketListener from "../../WebsocketListener";
import { API_URLS } from "../../utils/API_URLS";
import { Button } from "../components/Button/Button";
import DictionaryDetails from "../components/DictionaryDetails/DictionaryDetails";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { Pane } from "../components/Pane/Pane";
import ProjectsList from "./ProjectsList/ProjectsList";
import ProjectsTableFilters from "./ProjectsList/partials/ProjectsTable/tableComponents/ProjectsTableFilters/ProjectsTableFilters";
import SortProjectsDropdown from "./ProjectsList/partials/ProjectsTable/tableComponents/SortProjectsDropdown/SortProjectsDropdown";
import ProjectsListElementLoader from "./ProjectsList/partials/ProjectsTable/tableComponents/ProjectsListElementLoader/ProjectsListElementLoader";
import { useQuery } from "../methods/useQuery";
import MetaDataOptionsDropdown, {
  MetaDataOptionsContext,
} from "../components/TableOptionsDropdown/MetaDataOptionsDropdown";
import {
  generateLackingRanks,
  generateMetadataRanks,
} from "../CreatorDatabase/redux/creatorDatabaseFiltersReducer";
import { navigateToCreateNewProject } from "../SettingsModal/methods";
import TableViewSelector from "../TableView/TableViewSelector";
import { createProjectListTableView } from "../TableView/tableViewFunctions";
import { SaveTableViewModal } from "../TableView/components/SaveTableViewModal/SaveTableViewModal";

export type IColumn = {
  id?: string;
  title?: string;
  name: string;
  type: string;
  value?: string;
  isVisible?: boolean;
  rank?: string;
  key?: string;
  isRequired?: boolean;
};

export interface DateObject {
  date: string;
  timezone_type: number;
  timezone: string;
}

export interface ITaskColumn {
  title: string;
  id: string;
  type: string;
  isVisible: boolean;
}

export default function Project() {
  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const identity = useSelector(
    (state: RootState) => state.mainReducer.identity,
  );

  const filteredProjectsList = useSelector(
    (state: RootState) => state.projectReducer.filteredProjectsList,
  );

  const projectsColumns = useSelector(
    (state: RootState) => state.projectReducer.projectsColumns,
  );

  const wsTableViews = useSelector(
    (state: RootState) => state.projectReducer.wsTableViews,
  );

  const projectsListOffset = useSelector(
    (state: RootState) => state.projectReducer.projectsListOffset,
  );

  const projectsListLimit = useSelector(
    (state: RootState) => state.projectReducer.projectsListLimit,
  );

  const projectColumnsVisibleByDefaultUuids = useSelector(
    (state: RootState) =>
      state.projectReducer.projectColumnsVisibleByDefaultUuids,
  );

  const filters = useSelector(
    (state: RootState) => state.projectFiltersReducer.filters,
  );

  const sortBy = useSelector(
    (state: RootState) => state.projectFiltersReducer.sortBy,
  );

  const sort = useSelector(
    (state: RootState) => state.projectFiltersReducer.sort,
  );

  const hiddenColumns = useSelector(
    (state: RootState) => state.projectFiltersReducer.hiddenColumns,
  );

  const projectColumnRanks = useSelector(
    (state: RootState) => state.projectFiltersReducer.projectColumnRanks,
  );

  const selectedTableView = useSelector(
    (state: RootState) => state.projectFiltersReducer.selectedTableView,
  );

  const dispatch = useDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [listLoading, setListLoading] = useState(false);
  const [filtersRefreshing, setFiltersRefreshing] = useState(false);
  const [listInit, setListInit] = useState(false);

  const [endOfList, setEndOfList] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [initialRankGenerationDone, setInitialRankGenerationDone] =
    useState(false);
  const [showSaveTableViewModal, setShowSaveTableViewModal] = useState(false);
  const [newTableViewName, setNewTableViewName] = useState("");

  const history = useHistory();
  const query = useQuery();
  const showDiscoveryDetails = isStringUuid(
    query.get("displayDictionaryPreview") || "",
  );

  const getAllProjects = async () => {
    try {
      setListLoading(true);
      setFiltersRefreshing(true);
      const url = API_URLS.getProjectsList.replace(
        ":wsWorkspaceUuid:",
        activeWorkspaceUuid,
      );
      const {
        data: {
          content: { projects, columns, visibleByDefaultUuids, wsTableViews },
        },
      } = await axios.get(url, {
        params: {
          filters,
          sortBy,
          sort,
          limit: projectsListLimit,
          offset: 0,
        },
      });
      dispatch(setFilteredProjectList(projects));
      dispatch(setProjectColumns(columns));
      setEndOfList(projects?.length !== projectsListLimit);
      dispatch(setProjectsListOffset(projectsListLimit));
      dispatch(setProjectColumnVisibleByDefaultUuids(visibleByDefaultUuids));
      dispatch(setProjectTableViews(wsTableViews));
      setListLoading(false);
      setFiltersRefreshing(false);
    } catch (err) {
      console.error(err);
      showErrorToast();
    } finally {
      scrollRef?.current?.scrollTo({ top: 0, left: 0 });
      setListInit(true);
    }
  };

  const downloadFile = async (url: string) => {
    try {
      const response = await axios.post(
        url,
        {
          filters,
          sortBy,
          sort,
          hiddenColumns,
          projectColumnRanks,
        },
        {
          responseType: "blob",
        },
      );

      const contentDisposition = response.headers["content-disposition"];
      const filenameMatch =
        contentDisposition &&
        contentDisposition.match(/filename\s*=\s*"(.+)"/i);
      const filename = filenameMatch ? filenameMatch[1] : "report.xlsx";

      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const getXlsxReportFileForWorkspace = async () => {
    setIsDownloadingReport(true);

    try {
      const url = API_URLS.getXlsxReportFileForWorkspace.replace(
        ":wsWorkspaceUuid:",
        activeWorkspaceUuid,
      );

      await downloadFile(url);
    } catch (error) {
      console.error(error);
      showErrorToast();
    } finally {
      setIsDownloadingReport(false);
    }
  };

  const saveTableView = async () => {
    const visibleColumns = projectsColumns
      .filter((field) => !hiddenColumns?.includes(field.uuid))
      .map((field) => ({ uuid: field.uuid, exampleKey: "aaaa" }));

    const uuid = uuidv7();

    await createProjectListTableView({
      filters,
      sortBy,
      sort,
      visibleColumns,
      wsWorkspaceUuid: activeWorkspaceUuid,
      uuid,
      name: newTableViewName,
    });
    getAllProjects();
    dispatch(
      setSelectedTableView({
        label: newTableViewName,
        value: uuid,
      }),
    );
  };

  const getProjects = async (loadMore = false) => {
    if (listLoading || endOfList || !listInit) return;

    setListLoading(true);

    try {
      const url = API_URLS.getProjectsList.replace(
        ":wsWorkspaceUuid:",
        activeWorkspaceUuid,
      );

      const {
        data: { content: results },
      } = await axios.get(url, {
        params: {
          filters,
          sortBy,
          sort,
          limit: projectsListLimit,
          offset: projectsListOffset,
        },
      });

      const newElements = results?.projects ?? [];
      const newProjectColumns = results?.columns ?? [];

      if (newElements?.length !== projectsListLimit) {
        setEndOfList(true);

        if (!newElements) {
          return;
        }
      }

      dispatch(setProjectsListOffset(projectsListOffset + projectsListLimit));

      if (loadMore) {
        dispatch(
          setFilteredProjectList(
            listLoading
              ? newElements
              : [...filteredProjectsList, ...newElements],
          ),
        );
      } else {
        dispatch(
          setFilteredProjectList(listLoading ? newElements : [...newElements]),
        );
      }
      dispatch(setProjectColumns(newProjectColumns));
    } catch (err) {
      console.error(err);
      showErrorToast();
    } finally {
      setListLoading(false);
      setListInit(true);
    }
  };

  const getMoreProjects = async () => {
    getProjects(true);
  };

  useEffect(() => {
    getAllProjects();
  }, [filters, sortBy, sort]);

  useEffect(() => {
    let projectsListSettingsObject;
    try {
      const projectsListSettingsJSON = localStorage.getItem(
        "ws-projects-list-settings",
      );

      if (projectsListSettingsJSON) {
        projectsListSettingsObject = JSON.parse(projectsListSettingsJSON);
      }
    } catch {
      console.error("Error parsing json from key: ws-projects-list-settings");
    }

    const settings = projectsListSettingsObject
      ? projectsListSettingsObject[activeWorkspaceUuid]
      : null;

    if (
      !settings?.hiddenColumns &&
      projectColumnsVisibleByDefaultUuids.length
    ) {
      const initialHiddenColumns = projectsColumns
        .filter(
          (item) => !projectColumnsVisibleByDefaultUuids.includes(item.uuid),
        )
        .map((item) => item.uuid);

      if (initialHiddenColumns.length) {
        dispatch(setProjectInitialHiddenColumns(initialHiddenColumns));
      }
    }

    if (initialRankGenerationDone) return;

    if (projectsColumns?.length && !settings?.projectColumnRanks?.length) {
      dispatch(
        setProjectInitialColumnRanks(generateMetadataRanks(projectsColumns)),
      );
      setInitialRankGenerationDone(true);
    } else if (
      projectsColumns.length &&
      projectsColumns.length !== projectColumnRanks.length
    ) {
      dispatch(
        setProjectInitialColumnRanks(
          generateLackingRanks(projectsColumns, settings?.projectColumnRanks),
        ),
      );
      setInitialRankGenerationDone(true);
    }
  }, [
    activeWorkspaceUuid,
    initialRankGenerationDone,
    projectsColumns,
    projectColumnRanks,
    projectColumnsVisibleByDefaultUuids,
  ]);

  const colsString = JSON.stringify(
    projectsColumns.map((column) => ({
      type: column.type,
      options: column.data,
    })),
  );

  useEffect(() => {
    const cols = JSON.parse(colsString);
    getDictionaryAutoCompletesForColumns(dispatch, cols);
    getSelectDataSetForColumns(dispatch, cols);
  }, [colsString]);

  const sortedColumns = useMemo(
    () =>
      projectsColumns?.sort((a, b) => {
        const rankA = projectColumnRanks?.find(
          (col: { uuid: string }) => col.uuid === a.uuid,
        )?.rank;
        const rankB = projectColumnRanks?.find(
          (col: { uuid: string }) => col.uuid === b.uuid,
        )?.rank;
        if (rankA && rankB) return rankA.localeCompare(rankB);
        return 0;
      }) || [],
    [projectColumnRanks, projectsColumns],
  );

  const handleRestoreDefault = () => {
    const initialHiddenColumns = projectsColumns
      .filter(
        (item) => !projectColumnsVisibleByDefaultUuids.includes(item.uuid),
      )
      .map((item) => item.uuid);

    if (initialHiddenColumns.length) {
      dispatch(setProjectInitialHiddenColumns(initialHiddenColumns));
    }
    dispatch(
      setProjectInitialColumnRanks(
        generateMetadataRanks(projectsColumns),
        true,
      ),
    );
    setInitialRankGenerationDone(true);
  };

  return (
    <div className="projects scroll-fixed" ref={scrollRef}>
      <div className="projects__topbar projects__topbar--list">
        <PageHeader
          title={
            <IDHFormattedMessage id="ws_campaigns" defaultMessage="Campaigns" />
          }
          description={
            <IDHFormattedMessage
              id="ws_campaigns_description"
              defaultMessage="Here you can create and manage campaigns."
            />
          }
        />
        <div className="projects__topbar-right">
          {identity.permissions?.workspace.includes("create_projects") && (
            <Button
              size="small"
              textWithIcon
              variant="blue"
              onClick={() =>
                navigateToCreateNewProject(history, activeWorkspaceUuid)
              }
            >
              <PlusIcon />
              <IDHFormattedMessage
                id="ws_add_campaign"
                defaultMessage="Add Campaign"
              />
            </Button>
          )}
          <Button
            variant="white"
            size="small"
            textWithIcon
            onClick={() => setShowFilters((prev) => !prev)}
            active={Object.keys(filters).length > 0}
          >
            <FilterIcon />
            <span>
              <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
              {Object.keys(filters).length > 0 &&
                `: ${Object.keys(filters).length}`}
            </span>
          </Button>
          <SortProjectsDropdown fields={projectsColumns} />
          {identity.permissions?.workspace.includes("xlsx_export_projects") && (
            <Button
              variant="white"
              size="small"
              textWithIcon
              onClick={getXlsxReportFileForWorkspace}
              disabled={isDownloadingReport}
            >
              <DownloadIcon />
              <IDHFormattedMessage
                id="ws_download_xlsx"
                defaultMessage="Download XLSX"
              />
            </Button>
          )}

          {identity?.permissions?.workspace?.includes("manage_table_views") && (
            <Button
              variant="white"
              size="small"
              textWithIcon
              onClick={() => setShowSaveTableViewModal(true)}
            >
              <DownloadIcon />
              <IDHFormattedMessage
                id="ws_save_view"
                defaultMessage="Save view"
              />
            </Button>
          )}

          <TableViewSelector
            wsTableViews={wsTableViews}
            selectedWsTableView={selectedTableView}
            setSelectedWsTableView={(newValue: any) =>
              dispatch(setSelectedTableView(newValue))
            }
            setFilters={(newFilters: any) =>
              dispatch(setProjectFilters(newFilters))
            }
            setFilter={(uuid: string, value: any) =>
              dispatch(setProjectFilter(uuid, value))
            }
            setHiddenColumnIds={(columnIds: any) =>
              dispatch(setProjectInitialHiddenColumns(columnIds))
            }
            setSortBy={(newValue: any) => dispatch(setProjectSortBy(newValue))}
            setSort={(newValue: any) => dispatch(setProjectSort(newValue))}
            resetToDefaultSettings={handleRestoreDefault}
            columnIds={projectsColumns.map((field) => field.uuid)}
          />

          <MetaDataOptionsDropdown
            context={MetaDataOptionsContext.ProjectList}
            fields={projectsColumns}
            fieldRanks={projectColumnRanks}
            hiddenColumns={hiddenColumns}
            dispatchFunction={dispatch}
            resetToDefaultSettings={handleRestoreDefault}
          />
        </div>
      </div>

      <Pane
        showPane={showFilters}
        hidePane={() => setShowFilters(false)}
        width={309}
      >
        <ProjectsTableFilters
          setFiltersVisible={setShowFilters}
          columns={projectsColumns}
        />
      </Pane>

      <InfiniteScroll
        pageStart={0}
        loadMore={getMoreProjects}
        className="projects__scroller"
        hasMore={!endOfList}
        loader={<ProjectsListElementLoader />}
        useWindow={false}
        getScrollParent={() => scrollRef.current}
      >
        <ProjectsList
          projects={filteredProjectsList}
          columns={sortedColumns}
          setShowCreateProjectModal={() =>
            navigateToCreateNewProject(history, activeWorkspaceUuid)
          }
          listLoading={listLoading}
          filtersRefreshing={filtersRefreshing}
          filters={filters}
        />
      </InfiniteScroll>

      {showDiscoveryDetails && <DictionaryDetails />}

      {showSaveTableViewModal && (
        <SaveTableViewModal
          onClose={() => setShowSaveTableViewModal(false)}
          name={newTableViewName}
          setName={setNewTableViewName}
          saveFunction={saveTableView}
        />
      )}

      <WebsocketListener />
    </div>
  );
}
