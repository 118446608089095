import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";

import { useSelector } from "react-redux";
import { ElementsPageNumber } from "./ElementsPageNumber";

export function TableOfContentsElement(props) {
  const { data, pages } = props;

  const {
    reportReducer: { numberedHeaders },
  } = useSelector((state) => state);

  const Number = useMemo(() => {
    if (!numberedHeaders?.length) return null;

    const headerNumber = numberedHeaders?.find((item) => {
      return item.label === data.label;
    })?.number;

    return (
      <span className="table-of-contents__element-number">{headerNumber}.</span>
    );
  }, [numberedHeaders]);

  return (
    <a
      className={classNames("table-of-contents__element", {
        "table-of-contents__element--2": data.depth === 2,
        "table-of-contents__element--3": data.depth === 3,
      })}
      href={`#${data.label}`}
    >
      <div className="table-of-contents__element-content">
        {Number}
        {data.text}
      </div>
      <ElementsPageNumber pages={pages} section={data.label} />
    </a>
  );
}
